import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";
import {
  OtherInformationBodyModel,
  OtherInformationDtoModel,
} from "../../../../model/buyerModel";

export const otherInfoEndpoints = {
  "get-other-information": (id: number) => `/buyer/other-information/get/${id}`,
  "update-other-information": (id: number) =>
    `/buyer/other-information/update/${id}`,
};

export const otherInfoSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOtherInfo: builder.query<OtherInformationDtoModel, number>({
      query: (param) => ({
        url: otherInfoEndpoints["get-other-information"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "other-information",
          id: arg,
        },
      ],
    }),
    updateOtherInfo: builder.mutation<
      void,
      {
        queryParam: number;
        bodyParam: Partial<OtherInformationBodyModel>;
      }
    >({
      query: (param) => ({
        url: otherInfoEndpoints["update-other-information"](param.queryParam),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        { type: "other-information", id: arg.queryParam },
      ],
    }),
  }),
});

export const { useGetOtherInfoQuery, useUpdateOtherInfoMutation } =
  otherInfoSlice;
