import React, { useEffect } from "react";
import _ from "lodash";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import {
  useAddExpenseMutation,
  useUpdateExpenseMutation,
} from "../../../service/slice/system-configuration/expensesTypesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";
import { ExpenseTypesModel } from "../../../model/expensesTypesModel";
import useGetChartAccountsConfig from "../../../hooks/chart-accounts/useGetChartAccountsConfig";

export interface ExpenseForm {
  expenseName: string;
  chartAccount: {
    label: string;
    value: string;
  };
  subChartAccount: {
    label: string;
    value: string | number;
  };
  description: string;
}

type Props = {
  title: string;
  data: ExpenseTypesModel | null;
  closeFn: () => void;
};

const ExpenseTypesForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<ExpenseForm>({
    defaultValues: {
      expenseName: "",
      chartAccount: {
        label: "",
        value: "",
      },
      subChartAccount: {
        label: "",
        value: "",
      },
      description: "",
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const [
    getSubAccountTrigger,
    { chartAccountIsLoading, chartAccountList },
    { subAccountIsLoading, subAccountList },
  ] = useGetChartAccountsConfig();

  const [
    addMutation,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddExpenseMutation();
  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateExpenseMutation();

  const chartAccountWatch = useWatch({
    control,
    name: "chartAccount",
  });
  const subChartAccountWatch = useWatch({
    control,
    name: "subChartAccount",
  });

  const initLoading = chartAccountIsLoading;
  const saveLoading = addisLoading || updateIsLoading;

  const handleChartAccountChange = (value: {
    label: string;
    value: string;
  }) => {
    const accountNumber = value.value.slice(
      value.value.indexOf("-") + 1,
      value.value.length
    );
    getSubAccountTrigger(Number(accountNumber));
    setValue("chartAccount", value);
    setValue("subChartAccount", {
      label: "",
      value: "",
    });
  };

  const validateSelectFields = (): number[] => {
    let errors: number[] = [];
    if (_.isEmpty(chartAccountWatch.value)) {
      setError("chartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }

    if (!_.isNumber(subChartAccountWatch.value)) {
      setError("subChartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }
    return errors;
  };

  const onError = () => {
    validateSelectFields();
  };

  const onSubmit: SubmitHandler<ExpenseForm> = (dataFields) => {
    if (validateSelectFields().length > 0) {
      return;
    }
    const { chartAccount, description, expenseName, subChartAccount } =
      dataFields;

    if (data) {
      updateMutations({
        queryParams: data.id,
        bodyParams: {
          chartAccountId: Number(
            chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
          ),
          description,
          name: expenseName,
          subChartAccountId: subChartAccount.value as number,
        },
      });
    } else {
      addMutation({
        chartAccountId: Number(
          chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
        ),
        description,
        name: expenseName,
        subChartAccountId: subChartAccount.value as number,
      });
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-expense",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Expense successfully added!",
      toastId: "add-expense",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Expense successfully updated!",
      toastId: "update-expense",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-expense",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const { name, description, chartAccount, subChartAccountExpense } = data;

      setValue("expenseName", name);
      setValue("description", description ?? "");
      setValue("chartAccount", {
        label: `${chartAccount.accountName} - ${chartAccount.accountNumber}`,
        value: `${chartAccount.id}-${chartAccount.accountNumber}`,
      });
      setValue("subChartAccount", {
        label: subChartAccountExpense.subAccount,
        value: subChartAccountExpense.id,
      });
    }
  }, [data]);

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          {initLoading ? (
            <LoaderWithText text="Getting additional information" />
          ) : (
            <FormProvider {...form}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="textfieldLabel">Expense Name</Typography>
                  <Controller
                    name="expenseName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Expense Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        // InputProps={{
                        //   readOnly: data ? true : false,
                        // }}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography variant="textfieldLabel">
                    Chart of Account
                  </Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="chartAccount"
                    options={chartAccountList}
                    isRequired
                    placeholder="Chart of Account"
                    // @ts-ignore
                    customOnchange={handleChartAccountChange}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography variant="textfieldLabel">
                    Chart of Account (Sub)
                  </Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="subChartAccount"
                    options={subAccountList}
                    isRequired
                    isDisabled={
                      _.isEmpty(chartAccountWatch.value) || subAccountIsLoading
                    }
                    placeholder="Chart of Account (Sub)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="textfieldLabel">Description</Typography>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Description"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider
                sx={(theme) => ({
                  marginY: theme.spacing(3),
                })}
              />
              <Button
                variant="button-primary"
                sx={{
                  width: 150,
                }}
                startIcon={
                  saveLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
                onClick={
                  saveLoading ? () => {} : handleSubmit(onSubmit, onError)
                }
              >
                {data ? "Update" : "Save"}
              </Button>
            </FormProvider>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ExpenseTypesForm;
