import React, { useState, useEffect, useMemo } from "react";
import { useDebounce } from "use-debounce";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  mdiCardSearchOutline,
  mdiAccountCheckOutline,
  mdiAccountPlus,
} from "@mdi/js";

import CustomPagination from "../../../../common/CustomPagination";
import IconButtonMdi from "../../../../common/IconButtonMdi";
import RowsPerPageComp from "../../../../common/RowsPerPageComp";
import TableSearchField from "../../../../common/textfields/TableSearchField";
import { useLazyGetCryptInformationListQuery } from "../../../../../service/slice/lot-vault-crypt/cryptSlice";
import { errorDisplayOrNavigate } from "../../../../../utils/notificationToast";
import { UnitModel } from "./UnitType";
import { CryptInformationModel } from "../../../../../model/cryptModel";
import CryptDetails from "./CryptDetails";

type Props = {
  units: UnitModel[];
  handleToggleUnit: (
    isAdd: boolean,
    type: string,
    unitTypeId: number,
    unit: any
  ) => void;
};

const UnitListCrypt = ({ units, handleToggleUnit }: Props) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>("");
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [view, setView] = useState<{
    open: boolean;
    unit: CryptInformationModel | null;
  }>({
    open: false,
    unit: null,
  });

  const [debounceSearch] = useDebounce(searchValue, 500);

  const [listTrigger, { data, isLoading, error }] =
    useLazyGetCryptInformationListQuery();

  const loading = isLoading;

  const listData = useMemo(() => {
    return {
      items: data?.data.rows ?? [],
      count: data?.data.count ?? 0,
    };
  }, [data]);

  const handleToggleView = (
    open: boolean,
    unit: CryptInformationModel | null
  ) =>
    setView({
      open,
      unit,
    });

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "lot-list",
    });
  }

  useEffect(() => {
    listTrigger({
      entries,
      page: page - 1,
      search: debounceSearch,
    });
  }, [page, entries, debounceSearch]);

  return (
    <>
      <CryptDetails
        open={view.open}
        unit={view.unit}
        handleToggleUnit={handleToggleUnit}
        handleToggleView={handleToggleView}
        isSelected={
          units.find(
            (item) => item.unitTypeId === view.unit?.id && item.type === "CRYPT"
          )
            ? true
            : false
        }
      />
      <Typography variant="textfieldLabel">Crypt</Typography>
      <Grid container marginBottom={3} alignItems="center">
        <Grid item xs={12} lg={8}>
          <TableSearchField
            searchValue={searchValue ?? ""}
            pathname={``}
            querySearch={(value: string) => ""}
            handleSearchSetState={(e) => setSearchValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entries}
            pathname=""
            querySearch={(entry) => ""}
            changeEntry={(e) => setEntries(Number(e.target.value))}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : listData && listData?.items.length > 0 ? (
              listData.items.map((item, index) => {
                const selected = units.find(
                  (unit) => unit.unitTypeId === item.id && unit.type === "CRYPT"
                );
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": {
                        backgroundColor: "grey.100",

                        td: {
                          backgroundColor: "grey.100",
                        },
                      },
                      "&:nth-of-type(odd) td": {
                        backgroundColor: "common.white",
                      },
                    }}
                  >
                    <TableCell>{`${item.cryptLevel.name} - ${item.cryptColumn.name}`}</TableCell>
                    <TableCell
                      sx={{
                        color: item.cryptStatus.color,
                      }}
                    >
                      {item.cryptStatus.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 800,
                        width: "auto",
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <IconButtonMdi
                          mdiIcon={mdiCardSearchOutline}
                          iconColor={theme.palette.primary.main}
                          tooltipTitle="View"
                          onClick={() => handleToggleView(true, item)}
                        />
                        {item.cryptStatus.name === "Available" && (
                          <IconButtonMdi
                            mdiIcon={
                              selected ? mdiAccountCheckOutline : mdiAccountPlus
                            }
                            iconColor={
                              selected ? "#fff" : theme.palette.success.main
                            }
                            bgColor={
                              selected ? theme.palette.success.main : "#fff"
                            }
                            tooltipTitle={selected ? "Unassign" : "Assign"}
                            onClick={() =>
                              handleToggleUnit(
                                selected ? false : true,
                                "CRYPT",
                                item.id,
                                item
                              )
                            }
                          />
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname=""
        querySearch={(page) => ""}
        totalCount={listData?.count ?? 0}
        currentPage={page}
        pageSize={entries}
        nextPageSetState={() => setPage((state) => state + 1)}
        prevPageSetState={() => setPage((state) => state - 1)}
        selectPageSetState={(page) => setPage(page)}
      />
    </>
  );
};

export default UnitListCrypt;
