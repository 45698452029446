import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { domainLink, thisFieldRequired } from "../../../utils/constants";
import { getUserCredential } from "../../../utils/helpers/storageHelper";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";

import { useGetAgentListConfigQuery } from "../../../service/slice/usersSlice";
import {
  useGetVaultColumnConfigListQuery,
  useGetVaultLevelConfigListQuery,
  useGetVaultStatusConfigListQuery,
  useUploadVaultImageMutation,
  useAddVaultInformationMutation,
  useUpdateVaultInformationMutation,
} from "../../../service/slice/lot-vault-crypt/vaultSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";
import {
  VaultInformationBodyModel,
  UploadVaultImageDtoModel,
  VaultInformationModel,
} from "../../../model/vaultModel";
import { buyersRoute, clientManagementRoute } from "../../../routes/routeKeys";

interface InformationForm {
  owner: string;
  level: {
    label: string;
    value: string | null;
  };
  assignedTo: {
    label: string;
    value: number | null;
  };
  amount: number | string;
  atNeed: number | string;
  status: {
    label: string;
    value: number | null;
  };
  image: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
  lotNumber: string;
  phase: string;
  block: string;
  section: string;
}

type Props = {
  title: string;
  data: VaultInformationModel | null;
  closeFn: () => void;
};

const VaultInformationForm = ({ title, data, closeFn }: Props) => {
  const navigate = useNavigate();
  const user = getUserCredential();
  const form = useForm<InformationForm>({
    defaultValues: {
      owner: "",
      level: {
        label: "",
        value: null,
      },
      assignedTo: {
        label: "",
        value: null,
      },
      amount: "",
      atNeed: "",
      status: {
        label: "",
        value: null,
      },
      image: {
        file: null,
        originalName: "",
        filetype: null,
        rawFile: null,
      },
      lotNumber: "",
      phase: "",
      block: "",
      section: "",
    },
  });

  const {
    setValue,
    control,
    clearErrors,
    formState: { errors },
    handleSubmit,
    setError,
    reset: fieldReset,
  } = form;

  const {
    data: agentList,
    isLoading: agentListIsLoading,
    error: agentListError,
  } = useGetAgentListConfigQuery();

  const {
    data: columnList,
    isLoading: columnListIsLoading,
    error: columnListError,
  } = useGetVaultColumnConfigListQuery();

  const {
    data: statusList,
    isLoading: statusListIsLoading,
    error: statusListError,
  } = useGetVaultStatusConfigListQuery();

  const {
    data: levelList,
    isLoading: levelListIsLoading,
    error: levelListError,
  } = useGetVaultLevelConfigListQuery();

  const [
    uploadImageMutate,
    {
      error: uploadImageError,
      isLoading: uploadImageIsLoading,
      reset: uploadtImageReset,
    },
  ] = useUploadVaultImageMutation();

  const [
    addMutate,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddVaultInformationMutation();

  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateVaultInformationMutation();

  const agentListOpt = agentList
    ? agentList.data
        .filter(
          (item) =>
            item.type === "AREA-DIRECTOR" || item.type === "MARKETING-HEAD"
        )
        .map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }))
    : [];

  const levelListOpt = useMemo(() => {
    return (
      levelList?.data.reduce(
        (
          newArr: {
            label: string;
            value: string | null;
          }[],
          curVal
        ) => {
          let listArray: {
            label: string;
            value: string | null;
          }[] = [];

          if (curVal.vaultColumns.length > 0) {
            curVal.vaultColumns.forEach((item) => {
              listArray.push({
                label: `${curVal.name} - ${item.name}`,
                value: `${curVal.id}|${item.id}`,
              });
            });
          }

          return [...newArr, ...listArray];
        },
        []
      ) ?? []
    );
  }, [levelList]);

  const statusListOpt = statusList
    ? statusList.data.map((item) => ({ label: item.name, value: item.id }))
    : [];

  const initLoading =
    agentListIsLoading ||
    columnListIsLoading ||
    statusListIsLoading ||
    levelListIsLoading;
  const saveLoading = uploadImageIsLoading || addisLoading || updateIsLoading;

  const imageWatch = useWatch({
    control,
    name: "image",
  });

  const levelWatch = useWatch({
    control,
    name: "level",
  });

  const assignedToWatch = useWatch({
    control,
    name: "assignedTo",
  });

  const statusWatch = useWatch({
    control,
    name: "status",
  });

  const isAdminUser = (): boolean =>
    user?.role.name === "Super-Admin" || user?.role.name === "Admin"
      ? true
      : false;

  const handleOtherValidations = (): number[] => {
    const errorsArr: number[] = [];

    // if (isAdminUser() && _.isEmpty(assignedToWatch.label)) {
    //   setError("assignedTo", {
    //     type: "required",
    //     message: thisFieldRequired,
    //   });

    //   errorsArr.push(1);
    // }

    if (_.isEmpty(imageWatch.file)) {
      setError("image", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(levelWatch.label)) {
      setError("level", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(statusWatch.label)) {
      setError("status", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    return errorsArr;
  };

  const onError = () => {
    handleOtherValidations();
  };

  const onSubmit: SubmitHandler<InformationForm> = async (dataFields) => {
    const errorsCount = handleOtherValidations();

    if (errorsCount.length > 0) {
      return;
    }

    const splitLevelValue = dataFields.level.value!.split("|");
    const newBody: VaultInformationBodyModel = {
      levelId: Number(splitLevelValue[0]),
      columnId: Number(splitLevelValue[1]),
      amount: dataFields.amount as string,
      atNeed: dataFields.atNeed as string,
      assignedTo: _.isEmpty(dataFields.assignedTo.label)
        ? null
        : dataFields.assignedTo.value,
      statusId: dataFields.status.value as number,
      picture: "",
      lotNo: dataFields.lotNumber,
      phase: dataFields.phase,
      block: dataFields.block,
      section: dataFields.section,
    };

    if (data) {
      let dataPic: null | UploadVaultImageDtoModel = null;
      if (dataFields.image.rawFile) {
        dataPic = await uploadImageMutate({
          file: dataFields.image.rawFile as FileList,
        }).unwrap();
      }

      await updateMutations({
        queryParams: data.id,
        bodyParams: {
          ...newBody,
          picture: dataFields.image.rawFile
            ? dataPic
              ? dataPic?.data.link
              : ""
            : (dataFields.image.file?.replace(domainLink, "") as string),
        },
      });
    } else {
      const dataLotImg = await uploadImageMutate({
        file: dataFields.image.rawFile as FileList,
      }).unwrap();

      if (dataLotImg.success) {
        await addMutate({
          ...newBody,
          picture: dataLotImg.data.link,
        });
      }
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-vault-information",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Columbarium Information created!",
      toastId: "add-vault-information",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (agentListError) {
    errorDisplayOrNavigate({
      error: agentListError,
      toastId: "agent-list-config",
    });
  }

  if (statusListError) {
    errorDisplayOrNavigate({
      error: statusListError,
      toastId: "vault-status-list-config",
    });
  }

  if (columnListError) {
    errorDisplayOrNavigate({
      error: columnListError,
      toastId: "vault-column-list-config",
    });
  }
  if (levelListError) {
    errorDisplayOrNavigate({
      error: levelListError,
      toastId: "vault-level-list-config",
    });
  }

  if (uploadImageError) {
    errorDisplayOrNavigate({
      error: uploadImageError,
      toastId: "upload-vault-image",
    });

    uploadtImageReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Columbarium Information updated!",
      toastId: "update-vault-information",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-vault-information",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        agent,
        amount,
        vaultColumn,
        vaultLevel,
        vaultStatus,
        picture,
        atNeed,
        phase,
        lotNo,
        block,
        section,
      } = data;

      setValue(
        "assignedTo",
        agent
          ? {
              label: `${agent.firstName} ${agent.lastName}`,
              value: agent.id,
            }
          : {
              value: null,
              label: "",
            }
      );
      setValue("amount", amount);
      setValue("atNeed", atNeed ?? "");
      setValue("level", {
        label: `${vaultLevel.name} - ${vaultColumn.name}`,
        value: `${vaultLevel.id}|${vaultColumn.id}`,
      });

      setValue("status", {
        label: vaultStatus.name,
        value: vaultStatus.id,
      });
      setValue("image", {
        file: picture,
        filetype: null,
        originalName: "",
        rawFile: null,
      });
      setValue("lotNumber", lotNo ?? "");
      setValue("phase", phase ?? "");
      setValue("block", block ?? "");
      setValue("section", section ?? "");
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        {initLoading ? (
          <LoaderWithText text="Getting additional information" />
        ) : (
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Lot/Unit Number</Typography>
                    <Controller
                      name="lotNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Lot Number"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Columbarium
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="level"
                      options={levelListOpt}
                      isRequired
                      placeholder="Columbarium Level"
                      isDisabled={false}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Amount</Typography>
                    <Controller
                      name="amount"
                      control={control}
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Amount"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">At Need</Typography>
                    <Controller
                      name="atNeed"
                      control={control}
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="At need"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  {/* <Box>
                    <Typography variant="textfieldLabel">
                      Assigned To
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="assignedTo"
                      options={agentListOpt}
                      isRequired
                      placeholder="Assigned To"
                      isDisabled={!isAdminUser()}
                    />
                  </Box> */}
                  {data && (
                    <Box>
                      <Typography variant="textfieldLabel" color="transparent">
                        Assign buyer
                      </Typography>
                      <Button
                        variant="button-secondary"
                        onClick={() =>
                          navigate(
                            `${clientManagementRoute}${buyersRoute}/add/buyer-information`,
                            {
                              state: data
                                ? {
                                    unitTypeId: data?.id,
                                    type: "VAULT",
                                    unit: data,
                                  }
                                : null,
                            }
                          )
                        }
                      >
                        Assign Buyer
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Phase</Typography>
                    <Controller
                      name="phase"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Phase"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Block</Typography>
                    <Controller
                      name="block"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Block"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Section</Typography>
                    <Controller
                      name="section"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Section"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Status</Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="status"
                      options={statusListOpt}
                      isRequired
                      placeholder="Status"
                      isDisabled={false}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Lot Image</Typography>
                    <UploadPhotoField
                      setValue={setValue}
                      name="image"
                      clearErrors={clearErrors}
                      pictureValue={imageWatch}
                      errorMsg={errors.image?.message}
                      changeText="Change Lot Image"
                      chooseText="Choose Lot Image"
                      width="100%"
                      height="330px"
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
            >
              {data ? "Update" : "Save"}
            </Button>
          </FormProvider>
        )}
      </Box>
    </Box>
  );
};

export default VaultInformationForm;
