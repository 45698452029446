import React from "react";
import {
  FieldValues,
  Control,
  Path,
  UseFormClearErrors,
  Controller,
  FieldErrors,
} from "react-hook-form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { thisFieldRequired } from "../../../../utils/constants";

interface Props<T extends FieldValues> {
  control: Control<T>;
  nameReservation: Path<T>;
  namePcf: Path<T>;
  nameTransfer: Path<T>;
  nameDays: Path<T>;
  nameInterestOne: Path<T>;
  nameInterestTwo: Path<T>;
  nameInterestThree: Path<T>;
  nameInterestFour: Path<T>;
  nameInterestFive: Path<T>;
  nameInterestSix: Path<T>;
  nameInterestSeven: Path<T>;
  nameInterestEight: Path<T>;
  clearErrors: UseFormClearErrors<T>;
  //   errors: FieldErrors<T>
  title: string;
}

const LotsVaultsForm = <T extends FieldValues>({
  control,
  nameReservation,
  namePcf,
  nameTransfer,
  nameDays,
  nameInterestEight,
  nameInterestFive,
  nameInterestFour,
  nameInterestOne,
  nameInterestSeven,
  nameInterestSix,
  nameInterestThree,
  nameInterestTwo,
  clearErrors,
  //   errors,
  title,
}: Props<T>) => {
  // console.log(errors[name]?.message)
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} lg={6}>
        <Typography variant="textSm" fontWeight={600}>
          {title}
        </Typography>
        <Grid container spacing={2} marginTop={1} alignItems="center">
          <Grid item xs={3.5}>
            <Typography variant="textSm">Reservation Fee</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Controller
              control={control}
              name={nameReservation}
              rules={{
                required: {
                  value: true,
                  message: thisFieldRequired,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  error={fieldState.error ? true : false}
                  helperText={fieldState.error?.message}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant="textSm">PCF Percentage (%)</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Controller
              control={control}
              name={namePcf}
              rules={{
                required: {
                  value: true,
                  message: thisFieldRequired,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  error={fieldState.error ? true : false}
                  helperText={fieldState.error?.message}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant="textSm">Transfer Fee</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Controller
              control={control}
              name={nameTransfer}
              rules={{
                required: {
                  value: true,
                  message: thisFieldRequired,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  error={fieldState.error ? true : false}
                  helperText={fieldState.error?.message}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant="textSm">Days to Void</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Controller
              control={control}
              name={nameDays}
              rules={{
                required: {
                  value: true,
                  message: thisFieldRequired,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  error={fieldState.error ? true : false}
                  helperText={fieldState.error?.message}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="textSm" fontWeight={600}>
          Interest Rate (%)
        </Typography>
        <Grid container spacing={2} marginTop={1} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="textSm">1 Year</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestOne}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="textSm">2 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestTwo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="textSm">3 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestThree}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="textSm">4 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestFour}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="textSm">5 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestFive}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="textSm">6 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestSix}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="textSm">7 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestSeven}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="textSm">8 Years</Typography>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={nameInterestEight}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LotsVaultsForm;
