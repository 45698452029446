import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TransitionProps } from "@mui/material/transitions";
import Stack from "@mui/material/Stack";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import { LoaderWithText } from "../../common/Loaders";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import CardHeader from "../../common/CardHeader";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { thisFieldRequired } from "../../../utils/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  onClose: () => void;
};

export interface FormType {
  sku?: string;
  asset_name: string;
  quantity: string;
  make: string;
  brand: string;
  model: string;
  description: string;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
  perishable: string;
  height: string;
  color: string;
  width: string;
  amount_per_piece: string;
  weight: string;
  total_price: string;
  supplier: string;
  warranty_date: Date | null;
  status: {
    label: string | null;
    value: string | null;
  };
  maintenance_date: Date | null;
  total_price_perishable: string;
  expiry_date: Date | null;
  amount_per_kilo: string;
  temperature: string;
  sell_amount_per_piece: string;
  sell_amount_per_day: string;
  service_charge: string;
  city_tax: string;
  tax: string;
  other_tax: string;
  lost_charge: string;
  damage_charge: string;
}

const AddEditAssetMaterialForm = ({ open, onClose }: Props) => {
  const [openUpload, handleToggleUpload] = useOpenCreateForm<number>();

  const loading = false;

  const form = useForm<FormType>({
    defaultValues: {
      sku: "",
      asset_name: "",
      quantity: "",
      make: "",
      brand: "",
      model: "",
      description: "",
      picture: {
        file: null,
        filetype: null,
        originalName: null,
        rawFile: null,
      },
      perishable: "false",
      height: "",
      color: "",
      width: "",
      amount_per_piece: "",
      weight: "",
      total_price: "",
      supplier: "",
      warranty_date: null,
      status: { value: "New", label: "New" },
      maintenance_date: null,
      total_price_perishable: "",
      expiry_date: null,
      amount_per_kilo: "",
      temperature: "",
      sell_amount_per_piece: "",
      sell_amount_per_day: "",
      service_charge: "",
      city_tax: "",
      tax: "",
      other_tax: "",
      lost_charge: "",
      damage_charge: "",
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    handleSubmit,
    reset: fieldReset,
  } = form;

  const handlePerishableOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue("perishable", value);
    // clearErrors([
    //   "fullPaymentAmount",
    //   "reservationDownpayment",
    //   "scheduledMonths",
    //   "scheduledDownpayment",
    // ]);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: "90vw",
          maxWidth: "90vw",
          height: "90vh",
          borderRadius: 2,
        },
      })}
    >
      {loading ? (
        <Box paddingX={1} height="90vh">
          <Stack alignItems="center" justifyContent="center" height="90vh">
            <LoaderWithText text="Get Additional Details.." />
          </Stack>
        </Box>
      ) : (
        <>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1">
                <Typography component="span" marginRight={1}>
                  Add/Edit Assets
                </Typography>
              </Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "24px",
                }}
                onClick={onClose}
              >
                <Icon path={mdiClose} size={1} />
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box padding={1} paddingTop={1}>
              <FormProvider {...form}>
                <CardHeader title="Asset Information" />
                <Box padding={2} mb={3} mt={1}>
                  <Grid container spacing={3} mt="1px !important">
                    <Grid item xs={12} md={12} lg={6}>
                      <Stack direction="column" spacing={2}>
                        <Box>
                          <Typography variant="textfieldLabel">SKU</Typography>
                          <Controller
                            name="sku"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                placeholder="SKU"
                                error={errors.sku ? true : false}
                                helperText={errors.sku?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Asset Name
                          </Typography>
                          <Controller
                            name="asset_name"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Asset Name"
                                error={errors.asset_name ? true : false}
                                helperText={errors.asset_name?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Stack direction="row" spacing={3}>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Quantity
                            </Typography>
                            <Controller
                              name="quantity"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder="Quantity"
                                  error={errors.quantity ? true : false}
                                  helperText={errors.quantity?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Make
                            </Typography>
                            <Controller
                              name="make"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder="Make"
                                  error={errors.make ? true : false}
                                  helperText={errors.make?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Stack>
                        <Stack direction="row" spacing={3}>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Brand
                            </Typography>
                            <Controller
                              name="brand"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder="Brand"
                                  error={errors.brand ? true : false}
                                  helperText={errors.brand?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Model
                            </Typography>
                            <Controller
                              name="model"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder="Model"
                                  error={errors.model ? true : false}
                                  helperText={errors.model?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <Stack direction="row" spacing={3}>
                        <Box width="100%">
                          <Typography variant="textfieldLabel">
                            Description
                          </Typography>
                          <Controller
                            name="description"
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                placeholder="Description"
                                error={errors.description ? true : false}
                                helperText={errors.description?.message}
                                multiline={true}
                                rows={13}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Asset Image
                          </Typography>
                          <UploadPhotoField
                            name="picture"
                            setValue={setValue}
                            clearErrors={clearErrors}
                            pictureValue={watch("picture")}
                            errorMsg={errors.picture?.message}
                            changeText="Change image"
                            chooseText="Select image"
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <CardHeader title="More Information" />
                <Box padding={2} mb={3} mt={1}>
                  <Controller
                    name="perishable"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <RadioGroup
                          {...field}
                          value={watch("perishable")}
                          onChange={handlePerishableOnChange}
                        >
                          <FormControlLabel
                            sx={{
                              label: {
                                fontSize: ".9rem",
                              },
                            }}
                            value="false"
                            label="Non-Perishable"
                            control={<Radio />}
                          />
                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                              <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={3}>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Height
                                    </Typography>
                                    <Controller
                                      name="quantity"
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          placeholder="Height"
                                          error={errors.height ? true : false}
                                          helperText={errors.height?.message}
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Color
                                    </Typography>
                                    <Controller
                                      name="color"
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          placeholder="Color"
                                          error={errors.color ? true : false}
                                          helperText={errors.color?.message}
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                </Stack>
                                <Stack direction="row" spacing={3}>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Width
                                    </Typography>
                                    <Controller
                                      name="width"
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          placeholder="Width"
                                          error={errors.width ? true : false}
                                          helperText={errors.width?.message}
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Amount
                                    </Typography>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <Controller
                                        name="amount_per_piece"
                                        control={control}
                                        rules={{
                                          required: {
                                            value:
                                              watch("perishable") === "false",
                                            message: thisFieldRequired,
                                          },
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            type="number"
                                            placeholder="Amount"
                                            error={
                                              errors.amount_per_piece
                                                ? true
                                                : false
                                            }
                                            helperText={
                                              errors.amount_per_piece?.message
                                            }
                                            disabled={
                                              watch("perishable") === "true"
                                            }
                                            sx={{
                                              width: "100%",
                                            }}
                                          />
                                        )}
                                      />
                                      <Typography
                                        variant="textfieldLabel"
                                        width="50px"
                                      >
                                        per pc
                                      </Typography>
                                    </Stack>
                                  </Box>
                                </Stack>
                                <Stack direction="row" spacing={3}>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Weight
                                    </Typography>
                                    <Controller
                                      name="weight"
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          placeholder="Weight"
                                          error={errors.weight ? true : false}
                                          helperText={errors.weight?.message}
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Total Price
                                    </Typography>
                                    <Controller
                                      name="total_price"
                                      control={control}
                                      rules={{
                                        required: {
                                          value:
                                            watch("perishable") === "false",
                                          message: thisFieldRequired,
                                        },
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          type="number"
                                          placeholder="Total Price"
                                          error={
                                            errors.total_price ? true : false
                                          }
                                          helperText={
                                            errors.total_price?.message
                                          }
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Stack direction="column" spacing={2}>
                                <Box>
                                  <Typography variant="textfieldLabel">
                                    Supplier Name
                                  </Typography>
                                  <Controller
                                    name="supplier"
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        placeholder="Supplier Name"
                                        error={errors.supplier ? true : false}
                                        helperText={errors.supplier?.message}
                                        disabled={
                                          watch("perishable") === "true"
                                        }
                                        sx={{
                                          width: "100%",
                                        }}
                                      />
                                    )}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant="textfieldLabel">
                                    Warranty Date
                                  </Typography>
                                  <Controller
                                    name="warranty_date"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DatePicker
                                          {...field}
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          slotProps={{
                                            textField: {
                                              variant: "outlined",
                                              error: fieldState.error?.message
                                                ? true
                                                : false,
                                              helperText:
                                                fieldState.error?.message,
                                              fullWidth: true,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant="textfieldLabel">
                                    Status
                                  </Typography>
                                  <CustomReactSelectJs
                                    control={control}
                                    options={[
                                      { value: "New", label: "New" },
                                      { value: "Assigned", label: "Assigned" },
                                      { value: "Unusable", label: "Unusable" },
                                    ]}
                                    name="status"
                                    placeholder="Status"
                                    isDisabled={watch("perishable") === "true"}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant="textfieldLabel">
                                    Maintenance Date
                                  </Typography>
                                  <Controller
                                    name="maintenance_date"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DatePicker
                                          {...field}
                                          disabled={
                                            watch("perishable") === "true"
                                          }
                                          slotProps={{
                                            textField: {
                                              variant: "outlined",
                                              error: fieldState.error?.message
                                                ? true
                                                : false,
                                              helperText:
                                                fieldState.error?.message,
                                              fullWidth: true,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                          <FormControlLabel
                            sx={{
                              label: {
                                fontSize: ".9rem",
                              },
                            }}
                            value="true"
                            label="Perishable"
                            control={<Radio />}
                          />
                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                              <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={3}>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Expiry Date
                                    </Typography>
                                    <Controller
                                      name="expiry_date"
                                      control={control}
                                      render={({ field, fieldState }) => (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DatePicker
                                            {...field}
                                            disabled={
                                              watch("perishable") === "false"
                                            }
                                            slotProps={{
                                              textField: {
                                                variant: "outlined",
                                                error: fieldState.error?.message
                                                  ? true
                                                  : false,
                                                helperText:
                                                  fieldState.error?.message,
                                                fullWidth: true,
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      )}
                                    />
                                  </Box>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Amount
                                    </Typography>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <Controller
                                        name="amount_per_kilo"
                                        control={control}
                                        rules={{
                                          required: {
                                            value:
                                              watch("perishable") === "true",
                                            message: thisFieldRequired,
                                          },
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            type="number"
                                            placeholder="Amount"
                                            error={
                                              errors.amount_per_kilo
                                                ? true
                                                : false
                                            }
                                            helperText={
                                              errors.amount_per_kilo?.message
                                            }
                                            disabled={
                                              watch("perishable") === "false"
                                            }
                                            sx={{
                                              width: "100%",
                                            }}
                                          />
                                        )}
                                      />
                                      <Typography
                                        variant="textfieldLabel"
                                        width="55px"
                                      >
                                        per kilo
                                      </Typography>
                                    </Stack>
                                  </Box>
                                </Stack>
                                <Stack direction="row" spacing={3}>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Temperature
                                    </Typography>
                                    <Controller
                                      name="temperature"
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          placeholder="Temperature"
                                          error={
                                            errors.temperature ? true : false
                                          }
                                          helperText={
                                            errors.temperature?.message
                                          }
                                          disabled={
                                            watch("perishable") === "false"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box width="100%">
                                    <Typography variant="textfieldLabel">
                                      Total Price
                                    </Typography>
                                    <Controller
                                      name="total_price_perishable"
                                      control={control}
                                      rules={{
                                        required: {
                                          value: watch("perishable") === "true",
                                          message: thisFieldRequired,
                                        },
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          type="number"
                                          placeholder="Total Price"
                                          error={
                                            errors.total_price_perishable
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            errors.total_price_perishable
                                              ?.message
                                          }
                                          disabled={
                                            watch("perishable") === "false"
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </RadioGroup>
                        {error && (
                          <FormHelperText error>{error.message}</FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
                <CardHeader title="Rates" />
                <Box padding={2} mb={3} mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2}>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Sell Amount
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Controller
                                name="sell_amount_per_piece"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: thisFieldRequired,
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="number"
                                    placeholder="Amount"
                                    error={
                                      errors.sell_amount_per_piece
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.sell_amount_per_piece?.message
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                              <Typography variant="textfieldLabel" width="50px">
                                per pc
                              </Typography>
                            </Stack>
                          </Box>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              &nbsp;
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Controller
                                name="sell_amount_per_day"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: thisFieldRequired,
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="number"
                                    placeholder="Amount"
                                    error={
                                      errors.sell_amount_per_day ? true : false
                                    }
                                    helperText={
                                      errors.sell_amount_per_day?.message
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                              <Typography variant="textfieldLabel" width="80px">
                                per day(s)
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack direction="row" spacing={3}>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Service Charge
                            </Typography>
                            <Controller
                              name="service_charge"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Service Charge"
                                  error={errors.service_charge ? true : false}
                                  helperText={errors.service_charge?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              City Tax
                            </Typography>
                            <Controller
                              name="city_tax"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="City Tax"
                                  error={errors.city_tax ? true : false}
                                  helperText={errors.city_tax?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Tax
                            </Typography>
                            <Controller
                              name="tax"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Tax"
                                  error={errors.tax ? true : false}
                                  helperText={errors.tax?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                          <Box width="100%">
                            <Typography variant="textfieldLabel">
                              Other Tax
                            </Typography>
                            <Controller
                              name="other_tax"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Other Tax"
                                  error={errors.other_tax ? true : false}
                                  helperText={errors.other_tax?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack direction="row" spacing={3}>
                        <Box width="100%">
                          <Typography variant="textfieldLabel">
                            Lost Charge
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Controller
                              name="lost_charge"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Lost Charge"
                                  error={errors.lost_charge ? true : false}
                                  helperText={errors.lost_charge?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                            <Typography variant="textfieldLabel" width="50px">
                              per pc
                            </Typography>
                          </Stack>
                        </Box>
                        <Box width="100%">
                          <Typography variant="textfieldLabel">
                            Damage Charge
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Controller
                              name="damage_charge"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: thisFieldRequired,
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Damage Charge"
                                  error={errors.damage_charge ? true : false}
                                  helperText={errors.damage_charge?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                            <Typography variant="textfieldLabel" width="50px">
                              per pc
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </FormProvider>
            </Box>
          </DialogContent>
          {/* {openUpload.open && ( */}
          <DialogActions>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              width="100%"
              paddingX={2}
              paddingY={1}
              justifyContent="center"
            >
              <Button
                variant="button-primary"
                onClick={loading ? () => {} : handleSubmit(() => {})}
                sx={{ width: 150 }}
              >
                Submit
              </Button>
              <Button
                variant="button-secondary"
                onClick={onClose}
                sx={{ width: 150 }}
              >
                Close
              </Button>
            </Stack>
          </DialogActions>
          {/* )} */}
        </>
      )}
    </Dialog>
  );
};

export default AddEditAssetMaterialForm;
