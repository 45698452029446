import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type Props = {
  entries: number;
  pathname: string;
  querySearch: (entry: string) => string;
  changeEntry?: (e: SelectChangeEvent) => void;
};

const RowsPerPageComp = ({
  entries,
  pathname,
  querySearch,
  changeEntry,
}: Props) => {
  const navigate = useNavigate();
  const handleChangeEntries = (e: SelectChangeEvent) => {
    changeEntry
      ? changeEntry(e)
      : navigate({
          pathname,
          search: querySearch(e.target.value),
        });
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Typography fontSize={".9rem"}>Show</Typography>
      <Select
        value={entries.toString()}
        onChange={handleChangeEntries}
        disabled={false}
        sx={{
          height: "40.7px",
          borderRadius: 2,
        }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>
      <Typography fontSize={".9rem"}>entries</Typography>
    </Stack>
  );
};

export default RowsPerPageComp;
