import { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
  Path,
} from "react-hook-form";

import {
  emailLabel,
  thisFieldRequired,
  passwordLabel,
  confirmPasswordLabel,
  domainLink,
  enterValidMobileNum,
} from "../../../utils/constants";

import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import PasswordIndicator from "../../common/PasswordIndicator";
import { PasswordIndicators } from "../../../utils/enums";
import {
  containsNumber,
  containsUpperCase,
  hasSpecialCharacter,
} from "../../../utils/helpers/regexHelper";
import { Typography } from "@mui/material";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";
import PhoneNumberField from "../../common/textfields/PhoneNumberField";
import {
  useAddUserMutation,
  useGetRoleListConfigQuery,
  useUploadPictureMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  usersEndPoints,
} from "../../../service/slice/usersSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { skipToken } from "@reduxjs/toolkit/query";
import { LoaderWithText } from "../../common/Loaders";
import { AddUserBodyType, UploadPicDtoModel } from "../../../model/usersModel";

export interface UserForm {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  department: {
    value: string;
    label: string;
  };
  role: {
    value: number | null;
    label: string;
  };
  password: string;
  confirmPassword: string;
  age: number | null;
  sex: {
    value: string;
    label: string;
  };
  civilStatus: {
    value: string;
    label: string;
  };
  dateOfBirth: Date | null;
  phoneNumber: string;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
}

type Props = {
  id: number | null;
  changePass: boolean;
  create: boolean;
  title: string;
  handleCloseCard: () => void;
};

const UserInformation = ({
  title,
  handleCloseCard,
  id,
  changePass,
  create,
}: Props) => {
  const [activeIndicators, setActiveIndicators] = useState<string[]>([
    PasswordIndicators.passwordMatch,
  ]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const form = useForm<UserForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      department: {
        value: "",
        label: "",
      },
      role: {
        value: null,
        label: "",
      },
      password: "",
      confirmPassword: "",
      age: null,
      sex: {
        value: "",
        label: "",
      },
      civilStatus: {
        label: "",
        value: "",
      },
      dateOfBirth: null,
      phoneNumber: "",
      picture: {
        file: null,
        originalName: "",
        filetype: null,
        rawFile: null,
      },
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    handleSubmit,
    reset: fieldReset,
  } = form;

  const phoneNumberWatch = useWatch({
    control,
    name: "phoneNumber",
  });

  const passwordWatch = useWatch({
    control,
    name: "password",
  });
  const confirmPasswordWatch = useWatch({
    control,
    name: "confirmPassword",
  });
  const pictureWatch = useWatch({
    control,
    name: "picture",
  });
  const mobileNumWatch = useWatch({
    control,
    name: "phoneNumber",
  });

  const roleWatch = useWatch({
    control,
    name: "role",
  });

  const departmentWatch = useWatch({
    control,
    name: "department",
  });

  const sexWatch = useWatch({
    control,
    name: "sex",
  });

  const civilStatusWatch = useWatch({
    control,
    name: "civilStatus",
  });

  const [
    addUserMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddUserMutation();

  const [
    uploadPicMustate,
    {
      error: uploadPicError,
      isLoading: uploadPicIsLoading,
      reset: uploadPicReset,
      isSuccess: uploadIsSuccess,
    },
  ] = useUploadPictureMutation();
  const [
    updateUserMutate,
    {
      error: updateUserError,
      isLoading: updateUserIsLoading,
      reset: updateUserReset,
      isSuccess: updateUserIsSuccess,
    },
  ] = useUpdateUserMutation();

  const {
    data: roleList,
    isLoading: roleListIsLoading,
    error: roleListError,
  } = useGetRoleListConfigQuery(undefined);

  const {
    data: userData,
    isLoading: userDataIsLoading,
    error: userDataError,
    isSuccess: userDataIsSuccess,
  } = useGetUserQuery(id ?? skipToken);

  const disableEdit = !changePass ? true : false;
  const disableChangePassword = changePass ? true : false;
  const initLoading = userDataIsLoading;
  const saveLoading = uploadPicIsLoading || addIsLoading || updateUserIsLoading;

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    const name: Path<UserForm> = e.target.name as Path<UserForm>;

    clearErrors(name);
    setValue(name, value);

    if (e.target.name === "password") {
      if (value.length >= 8) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordMinCharacter,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter(
            (item) => item !== PasswordIndicators.passwordMinCharacter
          )
        );
      }

      if (hasSpecialCharacter(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordSpecialChar,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordSpecialChar)
        );
      }

      if (containsNumber(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordNumber,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordNumber)
        );
      }

      if (containsNumber(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordNumber,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordNumber)
        );
      }
      if (containsUpperCase(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordUppercase,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordUppercase)
        );
      }

      if (value === confirmPasswordWatch) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordMatch,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordMatch)
        );
      }
    }

    if (e.target.name === "confirmPassword") {
      if (value === passwordWatch) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordMatch,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordMatch)
        );
      }
    }
  };

  const checkErrors = (): number[] => {
    const errorsArr = [];

    if (!_.isEmpty(phoneNumberWatch)) {
      const formatVal = phoneNumberWatch.replaceAll(" ", "").replace("+63", "");

      if (formatVal.length > 10 || formatVal.length < 10) {
        setError("phoneNumber", {
          type: "required",
          message: enterValidMobileNum,
        });
        errorsArr.push(1);
      }
    }

    if (_.isEmpty(roleWatch.label)) {
      setError("role", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    // if (_.isEmpty(departmentWatch.value)) {
    //   setError("department", {
    //     type: "required",
    //     message: thisFieldRequired,
    //   });

    //   errorsArr.push(1);
    // }

    if (_.isEmpty(sexWatch.value)) {
      setError("sex", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(civilStatusWatch.value)) {
      setError("civilStatus", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(pictureWatch.file)) {
      setError("picture", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(mobileNumWatch)) {
      setError("phoneNumber", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    return errorsArr;
  };

  const onError = () => {
    checkErrors();
  };

  const validateIndicators = (): number[] => {
    const indi: number[] = [];

    if (activeIndicators.includes(PasswordIndicators.passwordMatch)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordMinCharacter)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordNumber)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordSpecialChar)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordUppercase)) {
      indi.push(1);
    }

    return indi;
  };

  const onSubmitUpdate: SubmitHandler<UserForm> = async (data) => {
    const finalDate: string = moment(data.dateOfBirth).format("YYYY-MM-DD");
    const checkIndi = validateIndicators();
    let dataPic: null | UploadPicDtoModel = null;
    const newBody: Partial<AddUserBodyType> = {
      firstName: data.firstName,
      lastName: data.lastName,
      roleId: data.role.value as number,
      sex: data.sex.value,
      civilStatus: data.civilStatus.value,
      birthDate: finalDate,
      email: data.email,
      phoneNumber: data.phoneNumber,
    };

    if (data.picture.rawFile) {
      dataPic = await uploadPicMustate({
        endpoint: usersEndPoints["update-picture-user"],
        file: data.picture.rawFile as FileList,
      }).unwrap();
    }

    if (changePass) {
      if (checkIndi.length !== 5) {
        return;
      }

      Object.assign(newBody, {
        password: data.password,
      });
    }

    await updateUserMutate({
      queryParam: id as number,
      bodyParam: {
        ...newBody,
        picture: data.picture.rawFile
          ? dataPic?.data.link
          : data.picture.file?.replace(domainLink, ""),
      },
    });
  };

  const onSubmit: SubmitHandler<UserForm> = async (data) => {
    const errorCheck = checkErrors();
    const checkIndi = validateIndicators();

    if (errorCheck.length > 0 || checkIndi.length != 5) {
      return;
    }

    const dataPic = await uploadPicMustate({
      endpoint: usersEndPoints["update-picture-user"],
      file: data.picture.rawFile as FileList,
    }).unwrap();

    if (dataPic.success) {
      const finalDate: string = moment(data.dateOfBirth).format("YYYY-MM-DD");
      await addUserMutate({
        firstName: data.firstName,
        lastName: data.lastName,
        roleId: data.role.value as number,
        sex: data.sex.value,
        civilStatus: data.civilStatus.value,
        birthDate: finalDate,
        email: data.email,
        phoneNumber: data.phoneNumber,
        picture: dataPic.data.link,
        password: data.password,
      });
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-user",
    });

    addReset();
  }

  if (updateUserError) {
    errorDisplayOrNavigate({
      error: updateUserError,
      toastId: `user-view-${id}`,
    });

    updateUserReset();
  }

  if (roleListError) {
    errorDisplayOrNavigate({
      error: roleListError,
      toastId: "role-list-config",
    });
  }

  if (userDataError) {
    errorDisplayOrNavigate({
      error: userDataError,
      toastId: `user-view-${id}`,
    });
  }

  if (uploadPicError) {
    errorDisplayOrNavigate({
      error: uploadPicError,
      toastId: "add-user",
    });

    uploadPicReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "User successfully created.",
      toastId: "user-list",
    });

    addReset();
    fieldReset();
    uploadPicReset();
    handleCloseCard();
  }

  if (updateUserIsSuccess) {
    showToastSuccess({
      text: "User successfully updated.",
      toastId: "user-view",
    });

    updateUserReset();
    fieldReset();
    uploadPicReset();
    handleCloseCard();
  }

  useEffect(() => {
    if (userDataIsSuccess) {
      const {
        civilStatus,
        birthDate,
        email,
        firstName,
        lastName,
        phoneNumber,
        picture,
        role,
        sex,
      } = userData.data;

      setValue("civilStatus", {
        label: civilStatus ?? "",
        value: civilStatus ?? "",
      });

      setValue("dateOfBirth", birthDate ? new Date(birthDate) : null);
      setValue("email", email ?? "");
      setValue("firstName", firstName ?? "");
      setValue("lastName", lastName ?? "");
      setValue("phoneNumber", phoneNumber ?? "");
      setValue("picture", {
        file: picture ?? "",
        filetype: null,
        originalName: null,
        rawFile: null,
      });
      setValue("role", {
        label: role ? role.name ?? "" : "",
        value: role ? role.id : null,
      });
      setValue("sex", {
        label: sex ?? "",
        value: sex ?? "",
      });
    }
  }, [userDataIsSuccess]);

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={handleCloseCard} />
        <Box padding={3}>
          {initLoading ? (
            <LoaderWithText text="Getting user information" />
          ) : (
            <FormProvider {...form}>
              <Grid container spacing={2}>
                {!changePass && (
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">
                      {emailLabel}
                    </Typography>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: disableEdit || disableChangePassword,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          type="email"
                          disabled={disableChangePassword}
                          placeholder={emailLabel}
                          error={errors.email ? true : false}
                          helperText={errors.email?.message}
                          sx={{
                            width: "100%",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
                {!changePass && (
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">Role</Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={
                        roleList
                          ? roleList.data.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          : []
                      }
                      name="role"
                      isRequired={disableEdit || disableChangePassword}
                      placeholder="Role"
                      isDisabled={roleListIsLoading || disableChangePassword}
                    />
                  </Grid>
                )}
                {!changePass && (
                  <Grid item xs={12} md={4}>
                    {/* <Typography variant="textfieldLabel">Department</Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="department"
                      options={[
                        {
                          label: "Department 1",
                          value: "Department 1",
                        },
                      ]}
                      isRequired={id ? false : true}
                      placeholder="Department"
                      isDisabled={disableChangePassword}
                    /> */}
                  </Grid>
                )}
                {(create || changePass) && (
                  <>
                    <Grid item xs={12} md={4}>
                      <Typography variant="textfieldLabel">
                        {passwordLabel}
                      </Typography>
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: {
                            value: disableChangePassword
                              ? disableChangePassword
                              : true,
                            message: thisFieldRequired,
                          },
                        }}
                        defaultValue=""
                        // disabled={disableEdit}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type={showPassword ? "text" : "password"}
                            placeholder={passwordLabel}
                            error={errors.password ? true : false}
                            helperText={errors.password?.message}
                            sx={{
                              width: "100%",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {showPassword ? (
                                    <VisibilityOutlinedIcon
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setShowPassword(false)}
                                    />
                                  ) : (
                                    <VisibilityOffOutlinedIcon
                                      onClick={() => setShowPassword(true)}
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            onChange={handleChangePassword}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="textfieldLabel">
                        {confirmPasswordLabel}
                      </Typography>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                          required: {
                            value: disableChangePassword
                              ? disableChangePassword
                              : true,
                            message: thisFieldRequired,
                          },
                        }}
                        defaultValue=""
                        disabled={_.isEmpty(passwordWatch)}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder={confirmPasswordLabel}
                            error={errors.password ? true : false}
                            helperText={errors.password?.message}
                            sx={{
                              width: "100%",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {showConfirmPassword ? (
                                    <VisibilityOutlinedIcon
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setShowConfirmPassword(false)
                                      }
                                    />
                                  ) : (
                                    <VisibilityOffOutlinedIcon
                                      onClick={() =>
                                        setShowConfirmPassword(true)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            onChange={handleChangePassword}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    {!_.isEmpty(passwordWatch) && (
                      <Grid item xs={12} md={6}>
                        <PasswordIndicator
                          activeIndicators={activeIndicators}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              {!changePass && (
                <>
                  <Divider
                    sx={(theme) => ({
                      marginY: theme.spacing(3),
                    })}
                  />
                  <Stack
                    direction={{ xs: "column", lg: "row" }}
                    spacing={{ xs: 0, lg: 2 }}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <UploadPhotoField
                      setValue={setValue}
                      name="picture"
                      clearErrors={clearErrors}
                      pictureValue={pictureWatch}
                      errorMsg={errors.picture?.message}
                      isDisabled={disableChangePassword}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">
                          First Name
                        </Typography>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: {
                              value: disableEdit || disableChangePassword,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              disabled={disableChangePassword}
                              placeholder={"First Name"}
                              error={errors.firstName ? true : false}
                              helperText={errors.firstName?.message}
                              sx={{
                                width: "100%",
                              }}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">
                          Last Name
                        </Typography>
                        <Controller
                          name="lastName"
                          control={control}
                          rules={{
                            required: {
                              value: disableEdit || disableChangePassword,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              disabled={disableChangePassword}
                              placeholder={"Last Name"}
                              error={errors.lastName ? true : false}
                              helperText={errors.lastName?.message}
                              sx={{
                                width: "100%",
                              }}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">
                          Middle Name
                        </Typography>
                        <Controller
                          name="middleName"
                          control={control}
                          rules={{
                            required: {
                              value: false,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              disabled={disableChangePassword}
                              placeholder={"Middle Name"}
                              error={errors.middleName ? true : false}
                              helperText={errors.middleName?.message}
                              sx={{
                                width: "100%",
                              }}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">
                          Phone Number
                        </Typography>
                        <PhoneNumberField
                          setValue={setValue}
                          name="phoneNumber"
                          clearErrors={clearErrors}
                          errorMsg={errors.phoneNumber?.message}
                          mobileValue={mobileNumWatch}
                          isDisabled={disableChangePassword}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">Age</Typography>
                        <Controller
                          name="age"
                          control={control}
                          rules={{
                            required: {
                              value: disableEdit || disableChangePassword,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="number"
                              placeholder={"Age"}
                              disabled={disableChangePassword}
                              error={errors.age ? true : false}
                              helperText={errors.age?.message}
                              sx={{
                                width: "100%",
                              }}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">Sex</Typography>
                        <CustomReactSelectJs
                          control={control}
                          name="sex"
                          options={[
                            {
                              label: "MALE",
                              value: "MALE",
                            },
                            {
                              label: "FEMALE",
                              value: "FEMALE",
                            },
                          ]}
                          isRequired={disableEdit || disableChangePassword}
                          placeholder="Department"
                          isDisabled={disableChangePassword}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">
                          Civil Status
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          name="civilStatus"
                          options={[
                            {
                              label: "MARRIED",
                              value: "MARRIED",
                            },
                            {
                              label: "SINGLE",
                              value: "SINGLE",
                            },
                          ]}
                          isRequired={disableEdit || disableChangePassword}
                          isDisabled={disableChangePassword}
                          placeholder="Department"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="textfieldLabel">
                          Date of Birth
                        </Typography>
                        <Controller
                          name="dateOfBirth"
                          control={control}
                          disabled={disableChangePassword}
                          rules={{
                            required: {
                              value: disableEdit || disableChangePassword,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    error: errors.dateOfBirth?.message
                                      ? true
                                      : false,
                                    helperText:
                                      errors.dateOfBirth?.message ?? "",
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )}
              <Divider
                sx={(theme) => ({
                  marginY: theme.spacing(3),
                })}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="button-primary"
                  sx={{
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={
                    saveLoading
                      ? () => {}
                      : handleSubmit(id ? onSubmitUpdate : onSubmit, onError)
                  }
                >
                  Save
                </Button>
              </Stack>
            </FormProvider>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default UserInformation;
