import { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import {
  mdiAccountCheckOutline,
  mdiAccountEditOutline,
  mdiAccountCancelOutline,
} from "@mdi/js";

import CardHeader from "../../common/CardHeader";
import {
  useGetAgentListQuery,
  useUpdateAgentMutation,
} from "../../../service/slice/usersSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomPagination from "../../common/CustomPagination";
import IconButtonMdi from "../../common/IconButtonMdi";
import {
  salesPersonelRoute,
  userManagementRoute,
} from "../../../routes/routeKeys";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import { addAgentText } from "../../../utils/constants";
import SalesPersonelInformation from "./SalesPersonelInformation";
import { replaceAllHyphenWithSpace } from "../../../utils/helpers/stringManipulate";
import DownloadOptionsList from "../../common/DownloadOptionsList";
import ConfirmModal from "../../common/ConfirmModal";
import { AgentModel } from "../../../model/usersModel";
import { StatusEnum } from "../../../utils/enums";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";

const SalesPersonel = () => {
  const location = useLocation();
  const theme = useTheme();
  const [openCreate, handleToggleCreate] = useOpenCreateForm<number>();
  const [disableAgent, handleToggleDisable] = useDeleteTableItem<AgentModel>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const {
    data: agentList,
    error: agentListError,
    isLoading: agentListIsLoading,
    isFetching: agentListIsFetching,
    refetch,
  } = useGetAgentListQuery({
    entries: entriesParam,
    page: _.isEmpty(searchParam) ? pageParam - 1 : 0,
    search: searchParam ?? "",
  });

  const [
    updateAgentMutate,
    {
      error: updateAgentError,
      isLoading: updateAgentIsLoading,
      reset: updateAgentReset,
      isSuccess: updateAgentIsSuccess,
    },
  ] = useUpdateAgentMutation();

  const error = agentListError;
  const isLoading = agentListIsLoading || agentListIsFetching;
  const list = agentList?.data?.rows ?? [];
  const totalData = agentList?.data?.count ?? 0;

  const confirmDisableAction = () => {
    updateAgentMutate({
      queryParam: disableAgent.data!.id,
      bodyParam: {
        status:
          disableAgent.data?.status === StatusEnum.enabled
            ? StatusEnum.disabled
            : StatusEnum.enabled,
      },
    });
  };

  if (error) {
    errorDisplayOrNavigate({
      error: error,
      toastId: "agent-list",
    });
  }

  if (updateAgentIsSuccess) {
    showToastSuccess({
      text: `${disableAgent.data?.firstName} ${disableAgent.data?.lastName} successfully disabled.`,
      toastId: "disable-agent",
    });

    updateAgentReset();
    handleToggleDisable(false, null);
  }

  if (updateAgentError) {
    errorDisplayOrNavigate({
      error: updateAgentError,
      toastId: "disable-agent",
    });

    updateAgentReset();
  }

  useEffect(() => {
    refetch();
  }, [pageParam, entriesParam, searchParam]);

  return (
    <>
      <ConfirmModal
        isLoading={updateAgentIsLoading}
        open={disableAgent.open}
        text={`Are you sure to ${
          disableAgent.data?.status === StatusEnum.enabled
            ? "disable"
            : "enable"
        } ${disableAgent.data?.firstName} ${disableAgent.data?.lastName}`}
        closeFn={() => handleToggleDisable(false, null)}
        confirmFn={confirmDisableAction}
      />
      {openCreate.open && (
        <SalesPersonelInformation
          title="Agent and Area Directors"
          id={openCreate.data}
          handleClose={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title="Sales Personnel" />
        <Box padding={3}>
          <Grid container marginBottom={2} alignItems="center">
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${userManagementRoute}${salesPersonelRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  disabled={openCreate.open}
                  onClick={(): void =>
                    handleToggleCreate(true, null, addAgentText)
                  }
                  sx={{
                    width: "230px",
                  }}
                >
                  {addAgentText}
                </Button>
                <DownloadOptionsList endpoint="/agent/generate" />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${userManagementRoute}${salesPersonelRoute}`}
                querySearch={(entry: string) =>
                  `?page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "primary.dark",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    User Id
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Reporting to
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                        // hide last border
                        // "&:last-child td, &:last-child th": {
                        //   border: 0,
                        // },
                      }}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>
                        {replaceAllHyphenWithSpace(item.type)}
                      </TableCell>
                      <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                      <TableCell>
                        {item.parent
                          ? `${item.parent.firstName} ${item.parent.lastName}`
                          : ""}
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>
                        <Box
                          sx={(theme) => ({
                            width: "80px",
                            padding: "3px 8px",
                            borderRadius: "4px",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: ".8rem",
                            fontWeight: 500,
                            backgroundColor:
                              item.status === StatusEnum.enabled
                                ? theme.palette.success.dark
                                : theme.palette.error.dark,
                          })}
                        >
                          {item.status}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiAccountEditOutline}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="Edit Sales Person"
                            onClick={() =>
                              handleToggleCreate(
                                true,
                                item.id,
                                "Edit Sales Personnel"
                              )
                            }
                          />
                          <IconButtonMdi
                            mdiIcon={
                              item.status === StatusEnum.enabled
                                ? mdiAccountCancelOutline
                                : mdiAccountCheckOutline
                            }
                            iconColor={
                              item.status === StatusEnum.enabled
                                ? theme.palette.error.main
                                : theme.palette.success.main
                            }
                            tooltipTitle={
                              item.status === StatusEnum.enabled
                                ? "Disable Sales Person"
                                : "Enable Sales Person"
                            }
                            onClick={() => handleToggleDisable(true, item)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${userManagementRoute}${salesPersonelRoute}`}
            querySearch={(page) =>
              `?page=${page}&entries=${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default SalesPersonel;
