import React, { useState, useEffect } from "react";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  Path,
  SubmitHandler,
} from "react-hook-form";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import CardHeader from "../common/CardHeader";
import {
  emailLabel,
  passwordLabel,
  confirmPasswordLabel,
  domainLink,
  enterValidMobileNum,
} from "../../utils/constants";
import PhoneNumberField from "../common/textfields/PhoneNumberField";
import PasswordIndicator from "../common/PasswordIndicator";
import { PasswordIndicators } from "../../utils/enums";
import {
  hasSpecialCharacter,
  containsNumber,
  containsUpperCase,
} from "../../utils/helpers/regexHelper";
import UploadPhotoField from "../common/textfields/UploadPhotoField";
import {
  useGetUserQuery,
  useUpdateUserMutation,
  useUploadPictureMutation,
  usersEndPoints,
} from "../../service/slice/usersSlice";
import { getUserCredential } from "../../utils/helpers/storageHelper";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../utils/notificationToast";
import { LoaderWithText } from "../common/Loaders";
import { AddUserBodyType, UploadPicDtoModel } from "../../model/usersModel";
import { profile } from "console";

export interface FormType {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  address: string;
  region: string;
  country: string;
  district: string;
  city: string;
  zipCode: string;
  password: string;
  confirmPassword: string;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
}

const UserProfile = () => {
  const userCred = getUserCredential();
  const [activeIndicators, setActiveIndicators] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const form = useForm<FormType>({
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      phoneNumber: "",
      address: "",
      region: "",
      country: "",
      district: "",
      city: "",
      zipCode: "",
      password: "",
      confirmPassword: "",
      picture: {
        file: null,
        filetype: null,
        originalName: null,
        rawFile: null,
      },
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    handleSubmit,
    reset: fieldReset,
    setError,
  } = form;

  const {
    data: userData,
    isLoading: userDataIsLoading,
    error: userDataError,
  } = useGetUserQuery(userCred?.id as number);

  const [
    uploadPicMutate,
    {
      error: uploadPicError,
      isLoading: uploadPicIsLoading,
      reset: uploadPicReset,
    },
  ] = useUploadPictureMutation();
  const [
    updateUserMutate,
    {
      error: updateUserError,
      isLoading: updateUserIsLoading,
      reset: updateUserReset,
      isSuccess: updateUserIsSuccess,
    },
  ] = useUpdateUserMutation();

  const initLoading = userDataIsLoading;
  const saveLoading = uploadPicIsLoading || updateUserIsLoading;

  const mobileNumWatch = useWatch({
    control,
    name: "phoneNumber",
  });

  const passwordWatch = useWatch({
    control,
    name: "password",
  });
  const confirmPasswordWatch = useWatch({
    control,
    name: "confirmPassword",
  });

  const pictureWatch = useWatch({
    control,
    name: "picture",
  });

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    const name: Path<FormType> = e.target.name as Path<FormType>;

    clearErrors(name);
    setValue(name, value);

    if (e.target.name === "password") {
      if (value.length >= 8) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordMinCharacter,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter(
            (item) => item !== PasswordIndicators.passwordMinCharacter
          )
        );
      }

      if (hasSpecialCharacter(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordSpecialChar,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordSpecialChar)
        );
      }

      if (containsNumber(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordNumber,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordNumber)
        );
      }

      if (containsNumber(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordNumber,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordNumber)
        );
      }
      if (containsUpperCase(value)) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordUppercase,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordUppercase)
        );
      }

      if (value === confirmPasswordWatch) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordMatch,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordMatch)
        );
      }
    }

    if (e.target.name === "confirmPassword") {
      if (value === passwordWatch) {
        setActiveIndicators((prev) => [
          ...prev,
          PasswordIndicators.passwordMatch,
        ]);
      } else {
        setActiveIndicators((prev) =>
          prev.filter((item) => item !== PasswordIndicators.passwordMatch)
        );
      }
    }
  };

  const validateIndicators = (): number[] => {
    const indi: number[] = [];

    if (activeIndicators.includes(PasswordIndicators.passwordMatch)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordMinCharacter)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordNumber)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordSpecialChar)) {
      indi.push(1);
    }

    if (activeIndicators.includes(PasswordIndicators.passwordUppercase)) {
      indi.push(1);
    }

    return indi;
  };

  const validateOtherFields = (): number[] => {
    const errorsArr: number[] = [];

    if (!_.isEmpty(mobileNumWatch)) {
      const formatVal = mobileNumWatch.replaceAll(" ", "").replace("+63", "");

      if (formatVal.length > 10 || formatVal.length < 10) {
        setError("phoneNumber", {
          type: "required",
          message: enterValidMobileNum,
        });
      }
    }

    return errorsArr;
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const checkIndi = validateIndicators();
    const otherErrors = validateOtherFields();

    if (otherErrors.length > 0) {
      return;
    }

    if (checkIndi.length != 5 && !_.isEmpty(data.password)) {
      return;
    }

    let dataPic: null | UploadPicDtoModel = null;

    const newBody: Partial<AddUserBodyType> = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      password: _.isEmpty(data.password) ? "" : data.password,
      district: data.district,
      city: data.city,
      region: data.region,
      country: data.country,
      address: data.address,
      zipCode: data.zipCode,
    };

    if (data.picture.rawFile) {
      dataPic = await uploadPicMutate({
        endpoint: usersEndPoints["update-picture-user"],
        file: data.picture.rawFile,
      }).unwrap();
    }

    await updateUserMutate({
      queryParam: userCred?.id as number,
      bodyParam: {
        ...newBody,
        picture: data.picture.rawFile
          ? dataPic?.data.link
          : data.picture.file?.replace(domainLink, ""),
      },
    });
  };

  if (updateUserIsSuccess) {
    showToastSuccess({
      text: "Profile updated.",
      toastId: "user-view",
    });

    updateUserReset();
    uploadPicReset();
    fieldReset();
  }

  if (userDataError) {
    errorDisplayOrNavigate({
      error: userDataError,
      toastId: "user-view",
    });
  }

  if (updateUserError) {
    errorDisplayOrNavigate({
      error: updateUserError,
      toastId: `user-view-${userCred?.id}`,
    });

    updateUserReset();
  }

  if (uploadPicError) {
    errorDisplayOrNavigate({
      error: uploadPicError,
      toastId: "add-user",
    });

    uploadPicReset();
  }

  useEffect(() => {
    if (userData) {
      const { firstName, lastName, email, phoneNumber, picture } =
        userData.data;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      setValue("phoneNumber", phoneNumber as string);
      setValue("picture", {
        file: picture as string,
        filetype: null,
        originalName: null,
        rawFile: null,
      });
    }
  }, [userData]);

  return (
    <Paper elevation={3}>
      <CardHeader title="Profile Information" />
      <Box padding={3}>
        {initLoading ? (
          <LoaderWithText text="Getting profile details" />
        ) : (
          <FormProvider {...form}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="textfieldLabel">First Name</Typography>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="First Name"
                        error={errors.firstName ? true : false}
                        helperText={errors.firstName?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="textfieldLabel">Last Name</Typography>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="last Name"
                        error={errors.lastName ? true : false}
                        helperText={errors.lastName?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="textfieldLabel">Middle Name</Typography>
                  <Controller
                    name="middleName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="last Name"
                        error={errors.middleName ? true : false}
                        helperText={errors.middleName?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="textfieldLabel">{emailLabel}</Typography>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Email"
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="textfieldLabel">Phone Number</Typography>
                  <PhoneNumberField
                    setValue={setValue}
                    name="phoneNumber"
                    clearErrors={clearErrors}
                    errorMsg={errors.phoneNumber?.message}
                    mobileValue={mobileNumWatch}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={4}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              />
              <Grid item xs={12} md={4}>
                <Typography variant="textfieldLabel">
                  {passwordLabel}
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  // disabled={disableEdit}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword ? "text" : "password"}
                      placeholder={passwordLabel}
                      error={errors.password ? true : false}
                      helperText={errors.password?.message}
                      sx={{
                        width: "100%",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityOutlinedIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                onClick={() => setShowPassword(true)}
                                sx={{
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChangePassword}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="textfieldLabel">
                  {confirmPasswordLabel}
                </Typography>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  disabled={_.isEmpty(passwordWatch)}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder={confirmPasswordLabel}
                      error={errors.password ? true : false}
                      helperText={errors.password?.message}
                      sx={{
                        width: "100%",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showConfirmPassword ? (
                              <VisibilityOutlinedIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowConfirmPassword(false)}
                              />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                onClick={() => setShowConfirmPassword(true)}
                                sx={{
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChangePassword}
                    />
                  )}
                />
              </Grid>
              {!_.isEmpty(passwordWatch) && (
                <Grid item xs={12} md={6}>
                  <PasswordIndicator activeIndicators={activeIndicators} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  spacing={3}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <UploadPhotoField
                    setValue={setValue}
                    name="picture"
                    clearErrors={clearErrors}
                    pictureValue={pictureWatch}
                    errorMsg={errors.picture?.message}
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="textfieldLabel">District</Typography>
                      <Controller
                        name="district"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="District"
                            error={errors.district ? true : false}
                            helperText={errors.district?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="textfieldLabel">City</Typography>
                      <Controller
                        name="city"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="City"
                            error={errors.city ? true : false}
                            helperText={errors.city?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="textfieldLabel">Region</Typography>
                      <Controller
                        name="region"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Region"
                            error={errors.region ? true : false}
                            helperText={errors.region?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="textfieldLabel">Country</Typography>
                      <Controller
                        name="country"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Country"
                            error={errors.country ? true : false}
                            helperText={errors.country?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="textfieldLabel">Address</Typography>
                      <Controller
                        name="address"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            // multiline
                            // rows={2}
                            placeholder="Address"
                            error={errors.address ? true : false}
                            helperText={errors.address?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography variant="textfieldLabel">Zip Code</Typography>
                      <Controller
                        name="zipCode"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            placeholder="Zip Code"
                            error={errors.zipCode ? true : false}
                            helperText={errors.zipCode?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="button-primary"
                startIcon={
                  saveLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
                sx={{
                  width: 150,
                }}
                onClick={saveLoading ? () => {} : handleSubmit(onSubmit)}
              >
                Update
              </Button>
            </Stack>
          </FormProvider>
        )}
      </Box>
    </Paper>
  );
};

export default UserProfile;
