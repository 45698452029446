import {
  AddPaymentDocBody,
  PaymentDocDtoModel,
} from "../../../../model/buyerModel";
import {
  ExpenseBodyModel,
  ExpenseDtoModel,
  GetExpenseDtoModel,
} from "../../../../model/expensesModel";
import { FetchDtoType, ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const endpoints = {
  "get-expenses-list": (page: number, entries: number, search: string) =>
    `/account-management/payment/expense/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "add-expense": "/account-management/payment/expense/add",
  "upload-doc": "/account-management/upload/document",
  "get-expense": (id: number) =>
    `/account-management/payment/expense/get/${id}`,
  "update-expense": (id: number) =>
    `/account-management/payment/expense/update/${id}`,
  "delete-expense-file": (id: number) => `/buyer/payment/file/delete/${id}`,
  "upload-expense-file": "/buyer/upload/payment",
  "add-expense-file": "/buyer/payment/file/add",
  "get-expense-file-list": (id: number) => `/buyer/payment/file/get/${id}`,
};

export const expensesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccManagExpensesList: builder.query<ExpenseDtoModel, ListFetchQueryType>(
      {
        query: (param) => ({
          url: endpoints["get-expenses-list"](
            param.page,
            param.entries,
            param.search
          ),
          method: "GET",
        }),
        transformErrorResponse: (response: ErrorResponseModel) =>
          errorHandler(response),
        providesTags: (result, error, args) => ["expense-list"],
      }
    ),
    AddAccManagExpense: builder.mutation<
      FetchDtoType<{ id: number }>,
      ExpenseBodyModel
    >({
      query: (body) => ({
        url: endpoints["add-expense"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => ["expense-list"],
    }),
    getAccManagExpense: builder.query<GetExpenseDtoModel, number>({
      query: (param) => ({
        url: endpoints["get-expense"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "expense-item", id: args },
      ],
    }),
    upateAccManagExpense: builder.mutation<
      void,
      { body: ExpenseBodyModel; id: number }
    >({
      query: (body) => ({
        url: endpoints["update-expense"](body.id),
        body: body.body,
        method: "PUT",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        { type: "expense-item", id: args.id },
      ],
    }),
    deleteExpenseFile: builder.mutation<
      void,
      { fileId: number; getId: number }
    >({
      query: (param) => ({
        url: endpoints["delete-expense-file"](param.fileId),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        { type: "expense-file-list", id: args.getId },
      ],
    }),
    addExpenseFile: builder.mutation<void, AddPaymentDocBody>({
      query: (body) => ({
        url: endpoints["add-expense-file"],
        body,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "expense-file-list",
          id: arg.buyerPaymentId,
        },
      ],
    }),
    getExpenseFileList: builder.query<PaymentDocDtoModel, number>({
      query: (param) => ({
        url: endpoints["get-expense-file-list"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "expense-file-list",
          id: arg,
        },
      ],
    }),
  }),
});

export const {
  useGetAccManagExpensesListQuery,
  useAddAccManagExpenseMutation,
  useGetAccManagExpenseQuery,
  useLazyGetAccManagExpenseQuery,
  useUpateAccManagExpenseMutation,
  useDeleteExpenseFileMutation,
  useAddExpenseFileMutation,
  useGetExpenseFileListQuery,
} = expensesSlice;
