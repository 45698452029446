import { ListFetchQueryType } from "../../../model/global";
import {
  UploadVaultImageDtoModel,
  VaulLevelDtoModel,
  VaultColumnBodyModel,
  VaultColumnConfigDtoModel,
  VaultColumnDtoModel,
  VaultInformationBodyModel,
  VaultInformationDtoModel,
  VaultLevelBodyModel,
  VaultLevelConfigDtoModel,
  VaultStatusBodyModel,
  VaultStatusConfigDtoModel,
  VaultStatusDtoModel,
  VaultInformationConfigDtoModel,
} from "../../../model/vaultModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const vaultLevelEndpoints = {
  "add-vault-level": "/vault/level/add",
  "vault-level-list": (page: number, entries: number, search: string) =>
    `/vault/level/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "vault-level-list-config": "/vault/level/list",
  "update-vault-level": (id: number) => `/vault/level/update/${id}`,
  "delete-vault-level": (id: number) => `/vault/level/delete/${id}`,
};

export const vaultColumnEndpoints = {
  "add-vault-column": "/vault/column/add",
  "vault-column-list": (page: number, entries: number, search: string) =>
    `/vault/column/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "vault-column-list-config": "/vault/column/list",
  "update-vault-column": (id: number) => `/vault/column/update/${id}`,
  "delete-vault-column": (id: number) => `/vault/column/delete/${id}`,
};

export const vaultStatusEndpoints = {
  "add-vault-status": "/vault/status/add",
  "vault-status-list": (page: number, entries: number, search: string) =>
    `/vault/status/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "vault-status-list-config": "/vault/status/list",
  "update-vault-status": (id: number) => `/vault/status/update/${id}`,
  "delete-vault-status": (id: number) => `/vault/status/delete/${id}`,
};

export const vaultInformationEndpoints = {
  "upload-lot-image": "/vault/upload/picture",
  "add-vault-information": "/vault/add",
  "vault-information-list": (page: number, entries: number, search: string) =>
    `/vault/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "vault-information-list-config": "/vault/list",
  "update-vault-information": (id: number) => `/vault/update/${id}`,
  "delete-vault-information": (id: number) => `/vault/delete/${id}`,
};

export const vaultLevelSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVaultLevel: builder.mutation<void, VaultLevelBodyModel>({
      query: (body) => ({
        url: vaultLevelEndpoints["add-vault-level"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-level-list", "vault-level-list-config"],
    }),
    getVaultLevelList: builder.query<VaulLevelDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: vaultLevelEndpoints["vault-level-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-level-list"],
    }),
    getVaultLevelConfigList: builder.query<VaultLevelConfigDtoModel, void>({
      query: () => ({
        url: vaultLevelEndpoints["vault-level-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-level-list-config"],
    }),
    updateVaultLevel: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: VaultLevelBodyModel;
      }
    >({
      query: (param) => ({
        url: vaultLevelEndpoints["update-vault-level"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-level-list", "vault-level-list-config"],
    }),
    deleteVaultLevel: builder.mutation<void, number>({
      query: (param) => ({
        url: vaultLevelEndpoints["delete-vault-level"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-level-list", "vault-level-list-config"],
    }),
  }),
});

export const vaultColumnSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVaultColumn: builder.mutation<void, VaultColumnBodyModel>({
      query: (body) => ({
        url: vaultColumnEndpoints["add-vault-column"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-column-list", "vault-column-list-config"],
    }),
    getVaultColumnList: builder.query<VaultColumnDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: vaultColumnEndpoints["vault-column-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-column-list"],
    }),
    getVaultColumnConfigList: builder.query<VaultColumnConfigDtoModel, void>({
      query: () => ({
        url: vaultColumnEndpoints["vault-column-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-column-list-config"],
    }),
    updateVaultColumn: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: VaultColumnBodyModel;
      }
    >({
      query: (param) => ({
        url: vaultColumnEndpoints["update-vault-column"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-column-list", "vault-column-list-config"],
    }),
    deleteVaultColumn: builder.mutation<void, number>({
      query: (param) => ({
        url: vaultColumnEndpoints["delete-vault-column"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-column-list", "vault-column-list-config"],
    }),
  }),
});

export const vaultStatusSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVaultStatus: builder.mutation<void, VaultStatusBodyModel>({
      query: (body) => ({
        url: vaultStatusEndpoints["add-vault-status"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-status-list", "vault-status-list-config"],
    }),
    getVaultStatusList: builder.query<VaultStatusDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: vaultStatusEndpoints["vault-status-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-status-list"],
    }),
    getVaultStatusConfigList: builder.query<VaultStatusConfigDtoModel, void>({
      query: () => ({
        url: vaultStatusEndpoints["vault-status-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-status-list-config"],
    }),
    updateVaultStatus: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: VaultStatusBodyModel;
      }
    >({
      query: (param) => ({
        url: vaultStatusEndpoints["update-vault-status"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-status-list", "vault-status-list-config"],
    }),
    deleteVaultStatus: builder.mutation<void, number>({
      query: (param) => ({
        url: vaultStatusEndpoints["delete-vault-status"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["vault-status-list", "vault-status-list-config"],
    }),
  }),
});

export const vaultInformationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadVaultImage: builder.mutation<
      UploadVaultImageDtoModel,
      {
        file: FileList;
      }
    >({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append("picture", param.file[0]);
        }

        return {
          url: vaultInformationEndpoints["upload-lot-image"],
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
    addVaultInformation: builder.mutation<void, VaultInformationBodyModel>({
      query: (body) => ({
        url: vaultInformationEndpoints["add-vault-information"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: [
        "vault-information-list",
        "vault-information-list-config",
      ],
    }),
    getVaultInformationList: builder.query<
      VaultInformationDtoModel,
      ListFetchQueryType
    >({
      query: (param) => ({
        url: vaultInformationEndpoints["vault-information-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-information-list"],
    }),
    getVaultInformationListConfig: builder.query<
      VaultInformationConfigDtoModel,
      void
    >({
      query: () => ({
        url: vaultInformationEndpoints["vault-information-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["vault-information-list-config"],
    }),
    deleteVaultInformation: builder.mutation<void, number>({
      query: (param) => ({
        url: vaultInformationEndpoints["delete-vault-information"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: [
        "vault-information-list",
        "vault-information-list-config",
      ],
    }),
    updateVaultInformation: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: VaultInformationBodyModel;
      }
    >({
      query: (param) => ({
        url: vaultInformationEndpoints["update-vault-information"](
          param.queryParams
        ),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: [
        "vault-information-list",
        "vault-information-list-config",
      ],
    }),
  }),
});

export const {
  useUploadVaultImageMutation,
  useAddVaultInformationMutation,
  useGetVaultInformationListQuery,
  useLazyGetVaultInformationListQuery,
  useDeleteVaultInformationMutation,
  useUpdateVaultInformationMutation,
  useLazyGetVaultInformationListConfigQuery,
} = vaultInformationSlice;

export const {
  useAddVaultStatusMutation,
  useUpdateVaultStatusMutation,
  useDeleteVaultStatusMutation,
  useGetVaultStatusConfigListQuery,
  useGetVaultStatusListQuery,
} = vaultStatusSlice;

export const {
  useAddVaultColumnMutation,
  useDeleteVaultColumnMutation,
  useUpdateVaultColumnMutation,
  useGetVaultColumnConfigListQuery,
  useGetVaultColumnListQuery,
} = vaultColumnSlice;

export const {
  useAddVaultLevelMutation,
  useGetVaultLevelConfigListQuery,
  useGetVaultLevelListQuery,
  useUpdateVaultLevelMutation,
  useDeleteVaultLevelMutation,
} = vaultLevelSlice;
