import React from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import { thisFieldRequired } from "../../../utils/constants";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import { PriceListUnitType } from "../../../model/priceListModel";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import {
  useAddPriceMutation,
  endpoints,
} from "../../../service/slice/system-configuration/priceListSlice";
import { priceListType } from "../../../utils/enums";
import CardHeader from "../../common/CardHeader";

const titleLabel = {
  [priceListType.lot]: "Lot",
  [priceListType.columbarium]: "Columbarium",
  [priceListType.crypt]: "Crypt",
  [priceListType.other]: "Other Services",
};

type Props = {
  priceUnitType: PriceListUnitType;
  closeFn: () => void;
  title: string;
};

interface PriceListUploadForm {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
  filename: string;
}

const PriceListUpload = ({ priceUnitType, closeFn, title }: Props) => {
  const form = useForm<PriceListUploadForm>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
      filename: "",
    },
  });

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset: fieldReset,
  } = form;

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      reset: addReset,
    },
  ] = useAddPriceMutation();

  const documentWatch = useWatch({
    control,
    name: "document",
  });

  const onSubmit: SubmitHandler<PriceListUploadForm> = (dataFields) => {
    addMutate({
      fileName: dataFields.filename,
      path: dataFields.document[0].path,
      type: priceUnitType,
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Document added!",
      toastId: "add",
    });

    fieldReset();
    addReset();
    closeFn();
  }

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Stack spacing={2}>
            <div>
              <CustomFileUploader
                name="document"
                setValue={setValue}
                clearErrors={clearErrors}
                errorMsg={errors.document?.message}
                endpoint={endpoints["upload"]}
                getValues={getValues}
                isMultiple={false}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Filename</Typography>
              <Controller
                name="filename"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Filename"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
                marginTop: 2,
              }}
              disabled={_.isEmpty(documentWatch[0].path)}
              startIcon={
                addIsLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={addIsLoading ? () => {} : handleSubmit(onSubmit)}
            >
              Upload
            </Button>
            {!_.isEmpty(documentWatch[0].path) && (
              <>
                <Box marginTop={2} />
                <DocViewer
                  documents={[
                    {
                      uri: linkWithDomain(documentWatch[0].path),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              </>
            )}
          </Stack>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default PriceListUpload;
