import {
  GuidelineBodyModel,
  GuidelinesDtoModel,
  GuidelineListQueryModel,
  GeneralNotificationBodyModel,
  LvcModelDto,
  LvcBodyModel,
  ServiceModelDto,
  ServiceBodyModel,
  PaymentScheduleDtoModel,
  PaymentScheduleBodyModel,
} from "../../../model/generalModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const generalEndpoints = {
  "payment-schedule-add": "/configuration/payment-schedule/add",
  "payment-schedule-get": "/configuration/payment-schedule/get",
  "approval-code": (code: string) => `/configuration/send/approval/${code}`,
  "upload-guideline": "/configuration/general/upload/document",
  "add-guideline": "/configuration/general/guideline/add",
  "delete-guideline": (id: number) =>
    `/configuration/general/guideline/delete/${id}`,
  "lvc-get": "/configuration/lvc/get",
  "lvc-add": "/configuration/lvc/add",
  "service-get": "/configuration/service/get",
  "service-add": "/configuration/service/add",
  "add-notification": "/configuration/general/notification/add",
  "guideline-list": (
    page: number = 0,
    entries: number = 10,
    search: string = ""
  ) =>
    `/configuration/general/guideline/list?perPage=${entries}&currentPage=${page}&search=${search}`,
};

export const generalSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentSchedule: builder.query<PaymentScheduleDtoModel, void>({
      query: () => ({
        url: generalEndpoints["payment-schedule-get"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    addPaymentSchedule: builder.mutation<void, PaymentScheduleBodyModel>({
      query: (body) => ({
        url: generalEndpoints["payment-schedule-add"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    addServices: builder.mutation<void, ServiceBodyModel>({
      query: (body) => ({
        url: generalEndpoints["service-add"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getServices: builder.query<ServiceModelDto, void>({
      query: () => ({
        url: generalEndpoints["service-get"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    sendApprovalCode: builder.mutation<void, string>({
      query: (param) => ({
        url: generalEndpoints["approval-code"](param),
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getLvc: builder.query<LvcModelDto, void>({
      query: () => ({
        url: generalEndpoints["lvc-get"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    addLvc: builder.mutation<void, LvcBodyModel>({
      query: (body) => ({
        url: generalEndpoints["lvc-add"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getGuidelineList: builder.query<
      GuidelinesDtoModel,
      GuidelineListQueryModel
    >({
      query: (param) => ({
        url: generalEndpoints["guideline-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["guideline-list"],
    }),
    addGuideline: builder.mutation<void, GuidelineBodyModel>({
      query: (body) => ({
        url: generalEndpoints["add-guideline"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["guideline-list"],
    }),
    deleteGuideline: builder.mutation<void, number>({
      query: (param) => ({
        url: generalEndpoints["delete-guideline"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["guideline-list"],
    }),
    addNotification: builder.mutation<void, GeneralNotificationBodyModel>({
      query: (body) => ({
        url: generalEndpoints["add-notification"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useAddPaymentScheduleMutation,
  useGetPaymentScheduleQuery,
  useAddGuidelineMutation,
  useGetGuidelineListQuery,
  useAddNotificationMutation,
  useGetLvcQuery,
  useAddLvcMutation,
  useDeleteGuidelineMutation,
  useSendApprovalCodeMutation,
  useAddServicesMutation,
  useGetServicesQuery,
} = generalSlice;
