import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { mdiRefresh } from "@mdi/js";

import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import ConfirmModal from "../../common/ConfirmModal";

import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import useTableParameters from "../../../hooks/useTableParameters";
import { buyersRoute, clientManagementRoute } from "../../../routes/routeKeys";
import {
  buyerListEndpoints,
  useGetBuyerListQuery,
  useUpdateBuyerStatusMutation,
  useSendAppovalCodeMutation,
} from "../../../service/slice/client-management/buyer/buyerListSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { BuyerListModel } from "../../../model/buyerModel";
import DownloadOptionsList from "../../common/DownloadOptionsList";
import OtpModal from "../../common/OtpModal";

const headerLabels: string[] = [
  "Reference Number",
  "Date Started",
  "Date, Delinquent",
  "Buyer Name",
  "Unit Type",
  "Payment Terms",
  "Terms Left",
];

type Props = {};

const DelinquentBuyers = (props: Props) => {
  const [openOtp, setOpenOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");

  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const navigate = useNavigate();
  const theme = useTheme();
  const [deleteItem, handelToggleDeleteModal] =
    useDeleteTableItem<BuyerListModel>();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetBuyerListQuery({
    status: 2,
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const [
    updateMutate,
    {
      isLoading: updateIsLoading,
      error: updateError,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateBuyerStatusMutation();

  const [
    sendApprovalMutation,
    {
      isLoading: sendApprovalLoading,
      error: sendApprovalError,
      reset: sendApprovalReset,
      isSuccess: sendApprovalSuccess,
    },
  ] = useSendAppovalCodeMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  const handleConfirmUpdate = () =>
    updateMutate({
      id: deleteItem.data?.id as number,
      status: 0,
      statusToInvalidate: 2,
      code: otp,
    });

  const handleConfirmApproval = () => {
    sendApprovalMutation();
  };

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "buyer-list",
    });
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.referenceNumber} re-instated.`,
      toastId: "update-item-list",
    });

    updateReset();
    setOpenOtp(false);
    setOtp("");
    handelToggleDeleteModal(false, null);
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-item-list",
    });

    updateReset();
  }

  if (sendApprovalError) {
    errorDisplayOrNavigate({
      error: sendApprovalError,
      toastId: "send-approval",
    });

    sendApprovalReset();
  }

  if (sendApprovalSuccess) {
    showToastSuccess({
      text: "Please check your email for the approval code!",
      toastId: "approval",
    });
    setOpenOtp(true);
    handelToggleDeleteModal(false, deleteItem.data);
    sendApprovalReset();
  }

  return (
    <>
      <ConfirmModal
        isLoading={sendApprovalLoading}
        open={deleteItem.open}
        text={`Are you sure to re-instate ${deleteItem.data?.referenceNumber}?`}
        closeFn={() => handelToggleDeleteModal(false, null)}
        confirmFn={handleConfirmApproval}
      />
      <OtpModal
        open={openOtp}
        text="Approval Code"
        closeFn={() => {
          handelToggleDeleteModal(false, null);
          setOpenOtp(false);
        }}
        otpCode={otp}
        handleOtpChange={(val) => setOtp(val)}
        loading={updateIsLoading}
        submit={handleConfirmUpdate}
        resend={handleConfirmApproval}
        resendLoading={sendApprovalLoading}
      />
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${clientManagementRoute}${buyersRoute}`}
              querySearch={(value: string) =>
                `?tab=3&page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
            <Button
              variant="button-secondary"
              onClick={() =>
                navigate(
                  `${clientManagementRoute}${buyersRoute}/add/buyer-information`
                )
              }
              sx={{
                width: "180px",
              }}
            >
              Add New Buyer
            </Button>
            <DownloadOptionsList
              endpoint={buyerListEndpoints["download-list"](2)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${clientManagementRoute}${buyersRoute}`}
            querySearch={(entry: string) =>
              `?tab=3&page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.referenceNumber}</TableCell>
                  <TableCell>{item.startDate}</TableCell>
                  <TableCell>{item.delinquentDate}</TableCell>
                  <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.paymentTerm}</TableCell>
                  <TableCell>{item.term}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiRefresh}
                        iconColor={theme.palette.success.main}
                        tooltipTitle="Re-instate"
                        onClick={() => handelToggleDeleteModal(true, item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${clientManagementRoute}${buyersRoute}`}
        querySearch={(page) =>
          `?tab=3&page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default DelinquentBuyers;
