import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import {
  systemConfigRoute,
  priceListConfigRoute,
} from "../../../routes/routeKeys";
import PriceListComp from "./PriceListComp";
import CardHeader from "../../common/CardHeader";

const tabItems: ItemsProps[] = [
  {
    label: "Lawn Lot",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Columbarium",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Crypt",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Other Services",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const PriceListConfig = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <CardHeader title="Price List" />
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${systemConfigRoute}${priceListConfigRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && (
            <PriceListComp tabIndex={tabValue} />
          )}
          {tabValue === 1 && <PriceListComp tabIndex={tabValue} />}
          {tabValue === 2 && <PriceListComp tabIndex={tabValue} />}
          {tabValue === 3 && <PriceListComp tabIndex={tabValue} />}
        </Box>
      </Box>
    </Paper>
  );
};

export default PriceListConfig;
