import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { useGetBuyerInformationQuery } from "../../../../../service/slice/client-management/buyer/buyerInformationSlice";
import { useLazyGetPaymentsListConfigQuery } from "../../../../../service/slice/system-configuration/paymentsTypesSlice";
import {
  useLazyGetDiscountListConfigQuery,
  useLazyGetPromoListConfigQuery,
} from "../../../../../service/slice/system-configuration/discountPromoSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { errorDisplayOrNavigate } from "../../../../../utils/notificationToast";
import { LoaderWithText } from "../../../../common/Loaders";
import { useGetUnitTypeQuery } from "../../../../../service/slice/client-management/buyer/unitTypeSlice";
import PaymentForm from "./PaymentForm";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../../routes/routeKeys";
import { StepModel, StepperActionCta } from "../../../../common/StepperTabMenu";

type Props = {
  steps: StepModel[];
};

const Payment = ({ steps }: Props) => {
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const [
    getPaymentListTrigger,
    {
      data: paymentListData,
      isLoading: paymentListIsLoading,
      error: paymentListError,
    },
  ] = useLazyGetPaymentsListConfigQuery();

  const [
    getDiscountListTrigger,
    {
      data: discountListData,
      isLoading: discountListIsLoading,
      error: discountListError,
    },
  ] = useLazyGetDiscountListConfigQuery();

  const [
    getPromoListTrigger,
    {
      data: promoListData,
      isLoading: promoListIsLoading,
      error: promoListError,
    },
  ] = useLazyGetPromoListConfigQuery();

  const {
    data: unitTypeData,
    isLoading: unitTypeIsLoading,
    error: unitTypeError,
  } = useGetUnitTypeQuery(buyerId ?? skipToken);

  const loading =
    buyerInfoIsLoading ||
    paymentListIsLoading ||
    discountListIsLoading ||
    promoListIsLoading ||
    unitTypeIsLoading;

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const discountOptions = useMemo(() => {
    return (
      discountListData?.data.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.type}|${item.value}`,
      })) ?? []
    );
  }, [discountListData?.data]);

  const promoOptions = useMemo(() => {
    return (
      promoListData?.data.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.type}|${item.value}`,
      })) ?? []
    );
  }, [promoListData?.data]);

  // const handleChange

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (unitTypeError) {
    errorDisplayOrNavigate({
      error: unitTypeError,
      toastId: "unit-type",
    });
  }

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list-config",
    });
  }

  if (discountListError) {
    errorDisplayOrNavigate({
      error: discountListError,
      toastId: "discount-list-config",
    });
  }

  if (promoListError) {
    errorDisplayOrNavigate({
      error: promoListError,
      toastId: "promo-list-config",
    });
  }

  useEffect(() => {
    if (buyerId) {
      getPaymentListTrigger();
      getDiscountListTrigger();
      getPromoListTrigger();
    }
  }, [buyerId]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting other information.." />
    </Box>
  ) : (
    <>
      <Box marginBottom={4}>
        {buyerId ? (
          <Alert severity="info" icon={false}>
            <Typography variant="body1" marginBottom={1}>
              <Typography component="span" marginRight={1} variant="textSm">
                Buyer:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {`${buyerInfoData?.data.firstName} ${buyerInfoData?.data.lastName}`}
              </Typography>
            </Typography>
            <Typography variant="body1">
              <Typography component="span" marginRight={1} variant="textSm">
                Ref #:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {buyerInfoData?.data.referenceNumber}
              </Typography>
            </Typography>
          </Alert>
        ) : (
          <Alert severity="warning">
            Please add a buyer information or select a buyer to fill up this
            form.
          </Alert>
        )}
        {unitTypeData &&
          unitTypeData.data?.unitType.map((item, key) => (
            <React.Fragment key={key}>
              {key > 0 && (
                <Divider
                  sx={{
                    marginY: 4,
                  }}
                />
              )}
              <Box marginTop={4}>
                <PaymentForm
                  unit={item}
                  paymentTypeOptions={paymentTypeOptions}
                  discountOptions={discountOptions}
                  promoOptions={promoOptions}
                  rawPaymentTypeData={paymentListData?.data}
                />
              </Box>
            </React.Fragment>
          ))}
        <Divider
          sx={(theme) => ({
            marginY: theme.spacing(3),
          })}
        />
        <StepperActionCta
          steps={steps}
          path={
            buyerId
              ? `${clientManagementRoute}${buyersRoute}/edit/${buyerId}`
              : `${clientManagementRoute}${buyersRoute}/add`
          }
        />
      </Box>
    </>
  );
};

export default Payment;
