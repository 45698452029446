import {
  DiscountBodyModel,
  DiscountConfigDtoModel,
  DiscountDtoModel,
  PromoBodyModel,
  PromoConfigDtoModel,
  PromoDtoModel,
} from "../../../model/discountPromoModel";
import { ListFetchQueryType } from "../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const discountPromoEndpoints = {
  "add-discount": "/configuration/discount/add",
  "update-discount": (id: number) => `/configuration/discount/update/${id}`,
  "delete-discount": (id: number) => `/configuration/discount/delete/${id}`,
  "discount-list": (page: number, entries: number, search: string) =>
    `/configuration/discount/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "discount-list-config": "/configuration/discount/list",
  "add-promo": "/configuration/promo/add",
  "update-promo": (id: number) => `/configuration/promo/update/${id}`,
  "promo-list": (page: number, entries: number, search: string) =>
    `/configuration/promo/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "promo-list-config": "/configuration/promo/list",
  "delete-promo": (id: number) => `/configuration/promo/delete/${id}`,
  "upload-banner": "/configuration/promo/upload/banner",
};

export const discountPromoSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDiscountList: builder.query<DiscountDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: discountPromoEndpoints["discount-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["discount-list"],
    }),
    getDiscountListConfig: builder.query<DiscountConfigDtoModel, void>({
      query: () => ({
        url: discountPromoEndpoints["discount-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["discount-list-config"],
    }),
    addDiscount: builder.mutation<void, DiscountBodyModel>({
      query: (body) => ({
        url: discountPromoEndpoints["add-discount"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["discount-list", "discount-list-config"],
    }),
    updateDiscount: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: DiscountBodyModel;
      }
    >({
      query: (param) => ({
        url: discountPromoEndpoints["update-discount"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["discount-list", "discount-list-config"],
    }),
    deleteDiscount: builder.mutation<void, number>({
      query: (param) => ({
        url: discountPromoEndpoints["delete-discount"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["discount-list", "discount-list-config"],
    }),
    addPromo: builder.mutation<void, PromoBodyModel>({
      query: (body) => ({
        url: discountPromoEndpoints["add-promo"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["promo-list", "promo-list-config"],
    }),
    updatePromo: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: PromoBodyModel;
      }
    >({
      query: (param) => ({
        url: discountPromoEndpoints["update-promo"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["promo-list", "promo-list-config"],
    }),
    getPromoList: builder.query<PromoDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: discountPromoEndpoints["promo-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["promo-list"],
    }),
    getPromoListConfig: builder.query<PromoConfigDtoModel, void>({
      query: () => ({
        url: discountPromoEndpoints["promo-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["promo-list-config"],
    }),
    deletePromo: builder.mutation<void, number>({
      query: (param) => ({
        url: discountPromoEndpoints["delete-promo"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["promo-list", "promo-list-config"],
    }),
  }),
});

export const {
  useAddDiscountMutation,
  useGetDiscountListQuery,
  useLazyGetDiscountListConfigQuery,
  useLazyGetPromoListConfigQuery,
  useDeleteDiscountMutation,
  useUpdateDiscountMutation,
  useAddPromoMutation,
  useUpdatePromoMutation,
  useGetPromoListQuery,
  useDeletePromoMutation,
} = discountPromoSlice;
