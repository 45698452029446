// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { StatisticsModel } from "../../../model/dashboardModel";

import Icon from "@mdi/react";
import { mdiGraveStone, mdiAccountGroup } from "@mdi/js";
import {
  buyersRoute,
  clientManagementRoute,
  cryptRoute,
  lawnLotRoute,
  lotVaultCryptRoute,
  vaultRoute,
} from "../../../routes/routeKeys";

type Props = {
  loading: boolean;
  data: StatisticsModel | undefined;
};

interface SalesDataType {
  stats: string;
  title: string;
  color: string;
  icon: string;
  link: string;
}

const renderStats = (
  handleNavigate: (link: string) => void,
  data: SalesDataType[]
) => {
  return data.map((item, index) => (
    <Grid item xs={12} sm={6} key={index}>
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          onClick={() => handleNavigate(item.link)}
          sx={{
            borderRadius: 1,
            mr: 3,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: "common.white",
            backgroundColor: `${item.color}.main`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Icon path={item.icon} size={1} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="caption"
            sx={{
              lineHeight: "1.4",
            }}
          >
            {item.title}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              lineHeight: "1.4",
            }}
          >
            {item.stats}
          </Typography>
        </Box>
      </Box>
    </Grid>
  ));
};

const StatisticsCard = ({ loading, data }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = (link: string) => navigate(link);

  const salesData: SalesDataType[] = [
    {
      stats: data ? data.client.toString() : "245",
      title: "Clients",
      color: "primary",
      icon: mdiAccountGroup,
      link: `${clientManagementRoute}${buyersRoute}?tab=1&page=1&entries=10&search=`,
    },
    {
      stats: data ? data.lot.toString() : "125",
      title: "Lots Available",
      color: "success",
      icon: mdiGraveStone,
      link: `${lotVaultCryptRoute}${lawnLotRoute}?tab=1&page=1&entries=10&search=`,
    },
    {
      stats: data ? data.columbarium.toString() : "154",
      color: "warning",
      title: "Columbariums Available",
      icon: mdiGraveStone,
      link: `${lotVaultCryptRoute}${vaultRoute}?tab=1&page=1&entries=10&search=`,
    },
    {
      stats: data ? data.crypt.toString() : "88",
      color: "info",
      title: "Crypts Available",
      icon: mdiGraveStone,
      link: `${lotVaultCryptRoute}${cryptRoute}?tab=1&page=1&entries=10&search=`,
    },
  ];

  return (
    <Card>
      <CardHeader
        title="Statistics Card"
        titleTypographyProps={{
          sx: {
            mb: 1,
            letterSpacing: "0.15px !important",
          },
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(3)} !important` }}>
        {loading ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <CircularProgress color="primary" style={{ margin: "20px 0" }} />
          </Stack>
        ) : (
          <Grid container spacing={3}>
            {renderStats(handleNavigate, salesData)}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
