import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import { lotVaultCryptRoute, vaultRoute } from "../../../routes/routeKeys";
import VaultLevel from "./VaultLevel";
import VaultColumn from "./VaultColumn";
import VaultStatus from "./VaultStatus";
import VaultInformation from "./VaultInformation";

const tabItems: ItemsProps[] = [
  {
    label: "Columbarium Information",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Columbarium Level",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Columbarium Column",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Columbarium Statuses",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const Vault = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${lotVaultCryptRoute}${vaultRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && <VaultInformation />}
          {tabValue === 1 && <VaultLevel />}
          {tabValue === 2 && <VaultColumn />}
          {tabValue === 3 && <VaultStatus />}
        </Box>
      </Box>
    </Paper>
  );
};

export default Vault;
