// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header-bar {
  display: none;
}

.react-pdf__Page__annotations.annotationLayer,
.react-pdf__Page__textContent.textLayer {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/client-management/buyers/active-buyer-form/guidelines/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;AACf","sourcesContent":["#header-bar {\n  display: none;\n}\n\n.react-pdf__Page__annotations.annotationLayer,\n.react-pdf__Page__textContent.textLayer {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
