import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation, useParams } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import { mdiDeleteOutline, mdiCardSearchOutline } from "@mdi/js";

import CustomPagination from "../../../../common/CustomPagination";
import IconButtonMdi from "../../../../common/IconButtonMdi";
import RowsPerPageComp from "../../../../common/RowsPerPageComp";
import TableSearchField from "../../../../common/textfields/TableSearchField";
import ConfirmModal from "../../../../common/ConfirmModal";
import FileViewModal from "../../../../common/FileViewModal";
import useDeleteTableItem from "../../../../../hooks/useDeleteTableItem";
import {
  useGetIdentificationListQuery,
  useDeleteIdentificationMutation,
} from "../../../../../service/slice/client-management/buyer/identificationSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import { BuyerIndetificationModel } from "../../../../../model/buyerModel";

type Props = {};

const IdentificationList = (props: Props) => {
  const { id } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const buyerId = id ? Number(id) : null;
  const [deleteItem, handelToggleDeleteModal] =
    useDeleteTableItem<BuyerIndetificationModel>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const [viewDoc, setViewDoc] = useState<{
    open: boolean;
    path: string;
  }>({
    open: false,
    path: "",
  });

  const {
    data: idenData,
    isLoading: idenIsLoading,
    error: idenError,
    isFetching: idenIsFetching,
  } = useGetIdentificationListQuery({
    id: buyerId as number,
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const [
    deleteMutation,
    {
      error: deleteError,
      isLoading: deleteIsLoading,
      reset: deleteReset,
      isSuccess: deleteIsSuccess,
    },
  ] = useDeleteIdentificationMutation();

  const loading = idenIsLoading || idenIsFetching;
  const count = idenData?.data.count ?? 0;
  const list = idenData?.data.rows ?? [];

  const handleToggleViewDoc = (open: boolean, path: string) =>
    setViewDoc({
      open,
      path,
    });

  const handleConfirmDelete = () =>
    deleteMutation(deleteItem.data?.id as number);

  if (idenError) {
    errorDisplayOrNavigate({
      error: idenError,
      toastId: "identification-list",
    });
  }

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} deleted!`,
      toastId: "delete-identification",
    });

    deleteReset();
    handelToggleDeleteModal(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-identification",
    });

    deleteReset();
  }

  return (
    <>
      <FileViewModal
        open={viewDoc.open}
        path={viewDoc.path}
        title={viewDoc.path.substring(
          viewDoc.path.lastIndexOf("/") + 1,
          viewDoc.path.length
        )}
        onClose={() => handleToggleViewDoc(false, "")}
      />
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handelToggleDeleteModal(false, null)}
        confirmFn={handleConfirmDelete}
      />
      <Grid container marginBottom={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          <TableSearchField
            searchValue={searchParam ?? ""}
            pathname={``}
            querySearch={(value: string) =>
              `?page=${pageParam}&entries=${entriesParam}&search=${value}`
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={``}
            querySearch={(entry: string) =>
              `?page=${1}&entries=${entry}&search=${searchParam}`
            }
            // changeEntry={handleChangeEntries}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Number
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Format
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list.length > 0 ? (
              list.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                    // hide last border
                    // "&:last-child td, &:last-child th": {
                    //   border: 0,
                    // },
                  }}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.number}</TableCell>
                  <TableCell>{item.format}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiCardSearchOutline}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="View"
                        onClick={() => handleToggleViewDoc(true, item.path)}
                      />
                      <IconButtonMdi
                        mdiIcon={mdiDeleteOutline}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delete"
                        onClick={() => handelToggleDeleteModal(true, item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={``}
        querySearch={(page) =>
          `?page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={count}
        currentPage={pageParam}
        pageSize={entriesParam}
        // nextPageSetState={handleNextPage}
        // prevPageSetState={handlePrevPage}
        // selectPageSetState={handleSelectPage}
      />
    </>
  );
};

export default IdentificationList;
