import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import { searchText } from "../../../utils/constants";

type Props = {
  searchValue: string;
  pathname: string;
  querySearch: (value: string) => string;
  handleSearchSetState?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TableSearchField = ({
  searchValue,
  pathname,
  querySearch,
  handleSearchSetState,
}: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchInput, setSearchInput] = useState<string>(searchValue);
  //   const [initialRender, setInitialRender] = useState(false);
  const debounceSearch = useDebounce(searchInput, 1000);

  //   useEffect(() => {
  //     setInitialRender(true);
  //   }, []);

  useEffect(() => {
    if (handleSearchSetState === undefined) {
      navigate(
        {
          pathname,
          search: querySearch(debounceSearch),
        },
        { state }
      );
    }
  }, [debounceSearch]);

  return (
    <TextField
      defaultValue={debounceSearch}
      onChange={
        handleSearchSetState
          ? handleSearchSetState
          : (e) => setSearchInput(e.target.value)
      }
      placeholder={searchText}
      sx={(theme) => ({
        "& .MuiOutlinedInput-root": {
          borderRadius: theme.spacing(1),
          backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={{
                fontSize: 20,
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TableSearchField;
