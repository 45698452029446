import React from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";

import CardHeader from "../../common/CardHeader";
import { OrModel } from "../../../model/OrModel";
import { thisFieldRequired } from "../../../utils/constants";
import { useAddOrMutation } from "../../../service/slice/account-management/OR/OrSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { removeNonNumeric } from "../../../utils/helpers/stringManipulate";

interface OrForm {
  bookletCode: string;
  numStart: string;
  numPage: string;
}

type Props = {
  title: string;
  data: OrModel | null;
  closeFn: () => void;
};

const OfficialReceiptForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<OrForm>({
    defaultValues: {
      bookletCode: "",
      numPage: "",
      numStart: "",
    },
  });

  const { control, handleSubmit, reset: fieldReset, setValue } = form;

  const [
    addMutate,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddOrMutation();

  const saveLoading = addisLoading;

  // const handleCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  //   setValue("bookletCode", removeNonNumeric(e.target.value));

  const onSubmit: SubmitHandler<OrForm> = (dataField) => {
    addMutate({
      code: dataField.bookletCode,
      pages: Number(dataField.numPage),
      start: Number(dataField.numStart),
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-or",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Booklet created!",
      toastId: "add-or",
    });
    addReset();
    fieldReset();
    closeFn();
  }

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="textfieldLabel">Booklet Code</Typography>
                <Controller
                  name="bookletCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // onChange={handleCodeInputChange}
                      placeholder="Booklet Code"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="textfieldLabel">
                  OR Number Start
                </Typography>
                <Controller
                  name="numStart"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      placeholder="OR Number Start"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="textfieldLabel">
                  Number of Pages
                </Typography>
                <Controller
                  name="numPage"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      placeholder="Number of Pages"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={saveLoading ? () => {} : handleSubmit(onSubmit)}
            >
              {data ? "Update" : "Save"}
            </Button>
          </FormProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default OfficialReceiptForm;
