import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type Props = {};

const FourOOne = (props: Props) => {
  return (
    <div>
      <Typography variant="h1">401</Typography>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "1.5rem !important" }}>
        You are not authorized! 🔐
      </Typography>
      <Typography variant="body2">
        You don&prime;t have permission to access this page. Go Home!
      </Typography>
    </div>
  );
};

export default FourOOne;
