import React, { useEffect, useRef } from "react";
import _ from "lodash";
import ReactQuill from "react-quill";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import "react-quill/dist/quill.bubble.css";

import { useGetCompanyProfilePublicQuery } from "../../service/slice/profileSlice";
import { errorDisplayOrNavigate } from "../../utils/notificationToast";

type Props = {
  handleSetHeight?: (val: number) => void;
};

const HeaderPanel = (props: Props) => {
  const ref = useRef<Element>();
  const { data, isLoading, error } = useGetCompanyProfilePublicQuery();

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "company-profile",
    });
  }

  useEffect(() => {
    if (ref?.current && props.handleSetHeight) {
      props.handleSetHeight(ref?.current?.clientHeight + 1 ?? 0);
    } else if (
      data?.data?.header &&
      data?.data.header.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
      props.handleSetHeight
    ) {
      props.handleSetHeight(0);
    }
  }, [ref.current, data?.data?.header]);

  return (
    <>
      {data?.data.header &&
        data.data.header.replace(/<(.|\n)*?>/g, "").trim().length > 0 && (
          <Box
            width="100%"
            position="fixed"
            bgcolor="#F4F5FA"
            zIndex={1000}
            ref={ref}
            sx={{
              borderBottom: "1px solid rgba(58, 53, 65, 0.12)",
            }}
          >
            <ReactQuill
              theme="bubble"
              readOnly
              value={data?.data.header as string}
            />
          </Box>
        )}
    </>
  );
};

export default HeaderPanel;
