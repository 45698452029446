import React from "react";
import Typography from "@mui/material/Typography";

type Props = {};

const FourOFour = (props: Props) => {
  return (
    <div>
      {" "}
      <Typography variant="h1">404</Typography>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "1.5rem !important" }}>
        Page Not Found ⚠️
      </Typography>
      <Typography variant="body2">
        We couldn&prime;t find the page you are looking for.
      </Typography>
    </div>
  );
};

export default FourOFour;
