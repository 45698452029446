import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import {
  mdiClose,
  mdiRefresh,
  mdiPencil,
  mdiDeleteOutline,
  mdiCalendar,
} from "@mdi/js";

import useTableParameters from "../../../hooks/useTableParameters";
import { rentalAndServicesRoute } from "../../../routes/routeKeys";
import ConfirmModal from "../../common/ConfirmModal";
import TableSearchField from "../../common/textfields/TableSearchField";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import {
  useDeletePriceListMutation,
  useGetPriceListQuery,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import { StepModel } from "../../common/StepperTabMenu";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import AddServiceForm from "./AddServiceForm";

interface Props {
  scheduleForm: any;
  serviceType: string;
}

const headerLabels: string[] = [
  "Service",
  "Regular Rate",
  "Holiday Rate",
  "Senior/PWD",
  "Holiday Rate Senior/PWD",
  "Inclusions",
];

const PriceList = ({ serviceType, scheduleForm }: Props) => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openCreate, handleToggleCreate] = useOpenCreateForm<PriceListModel>();
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<PriceListModel>();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetPriceListQuery({
    type: serviceType.toUpperCase(),
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePriceListMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  const confirmDelete = () => deleteMutate(deleteItem.data?.id as number);

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} successfully deleted`,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
  }

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "rental-services-price-list",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      {openCreate.open && (
        <AddServiceForm
          serviceType={serviceType}
          closeFn={() => handleToggleCreate(false, null, "")}
          data={openCreate.data}
          title={openCreate.title}
          scheduleForm={scheduleForm}
        />
      )}
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${rentalAndServicesRoute}/${serviceType.toLowerCase()}/price-list`}
              querySearch={(value: string) =>
                `?tab=1&page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
            <Button
              variant="button-secondary"
              disabled={openCreate.open}
              onClick={() =>
                handleToggleCreate(
                  true,
                  null,
                  `New ${_.capitalize(serviceType)} Service`
                )
              }
              sx={{
                width: "280px",
              }}
            >
              Add {_.capitalize(serviceType)} Service
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${rentalAndServicesRoute}/${serviceType.toLowerCase()}/price-list`}
            querySearch={(entry: string) =>
              `?tab=1&page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.regular}</TableCell>
                  <TableCell>{item.holiday}</TableCell>
                  <TableCell>{item.sp}</TableCell>
                  <TableCell>{item.spHoliday}</TableCell>
                  <TableCell>{item.inclusions}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiCalendar}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Schedule Now"
                        onClick={async () => {
                          scheduleForm.setValue("service", item);
                          scheduleForm.setValue("price", item.regular);
                          scheduleForm.setValue(
                            "serviceDetails.price",
                            item.regular
                          );
                          navigate(
                            `${rentalAndServicesRoute}/${serviceType.toLowerCase()}/schedule`
                          );
                        }}
                      />
                      <IconButtonMdi
                        mdiIcon={mdiPencil}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Edit"
                        onClick={() =>
                          handleToggleCreate(
                            true,
                            item,
                            `Update ${_.capitalize(serviceType)} Service`
                          )
                        }
                      />
                      {item.canDelete === 1 && (
                        <IconButtonMdi
                          mdiIcon={mdiDeleteOutline}
                          iconColor={theme.palette.error.main}
                          tooltipTitle="Delete"
                          onClick={() => handleToggleDelete(true, item)}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${rentalAndServicesRoute}/${serviceType.toLowerCase()}/price-list`}
        querySearch={(page) =>
          `?tab=1&page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default PriceList;
