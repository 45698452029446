import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import {
  mdiDeleteOutline,
  mdiCursorPointer,
} from "@mdi/js";

import useTableParameters from "../../../hooks/useTableParameters";
import {
  inventoryConfigurationRoute,
  inventoryManagementRoute,
} from "../../../routes/routeKeys";
import ConfirmModal from "../../common/ConfirmModal";
import TableSearchField from "../../common/textfields/TableSearchField";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import {
  useDeletePriceListMutation,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import AssetLocationModal from "../asset-location/AssetLocationModal";

interface Props {
  route: string;
}

const headerLabels: string[] = [
  "Location",
  "Total Quantity",
  "Total Amount",
  "Status",
  "Date Required",
];

const Locations = ({
  route = `${inventoryConfigurationRoute}/locations`,
}: Props) => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openCreate, handleToggleCreate] = useOpenCreateForm<PriceListModel>();
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<PriceListModel>();
  const [openMove, handleToggleMove] = useOpenCreateForm<PriceListModel>();

  // const {
  //   data: listData,
  //   isLoading: listIsLoading,
  //   error: listError,
  //   isFetching: listIsFetching,
  // } = useGetPriceListQuery({
  //   type: serviceType.toUpperCase(),
  //   entries: entriesParam,
  //   page: pageParam - 1,
  //   search: searchParam ?? "",
  // });

  const listData: any = { data: { rows: [{}] } };
  const listIsLoading = false;
  const listError = false;
  const listIsFetching = false;

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePriceListMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data?.rows ?? [];
  const totalData = listData?.data?.count ?? 0;

  const confirmDelete = () => deleteMutate(deleteItem.data?.id as number);

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} successfully deleted`,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
  }

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "rental-services-price-list",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      {openMove.open && (
        <AssetLocationModal
          open={openMove.open}
          onClose={() => handleToggleMove(false, null, "")}
        />
      )}
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${inventoryManagementRoute}${route}`}
              querySearch={(value: string) =>
                `?page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${inventoryManagementRoute}${route}`}
            querySearch={(entry: string) =>
              `?page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item: any, key: any) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>Test</TableCell>
                  <TableCell>{item.regular}</TableCell>
                  <TableCell>{item.holiday}</TableCell>
                  <TableCell>{item.sp}</TableCell>
                  <TableCell>{item.spHoliday}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiCursorPointer}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Edit"
                        onClick={() =>
                          handleToggleMove(true, null, `Asset Locations - LOT 23`)
                        }
                      />
                      {item.canDelete === 1 && (
                        <IconButtonMdi
                          mdiIcon={mdiDeleteOutline}
                          iconColor={theme.palette.error.main}
                          tooltipTitle="Delete"
                          onClick={() => handleToggleDelete(true, item)}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${inventoryManagementRoute}${route}`}
        querySearch={(page) =>
          `?page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default Locations;
