import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import {
  useAddPriceListMutation,
  useUpdatePriceListMutation,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";

interface PriceListFormType {
  id?: number | undefined;
  code: string;
  name: string;
  unit_type: string;
  perishable: boolean;
  description: string;
  canEditName: number;
}

type Props = {
  title: string;
  data: PriceListModel | null;
  closeFn: () => void;
};

const CategoriesForm = ({
  title,
  data,
  closeFn,
}: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const form = useForm<PriceListFormType>({
    defaultValues: {
      id: undefined,
      code: "",
      name: "",
      unit_type: "",
      perishable: false,
      description: "",
      canEditName: 1,
    },
  });

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddPriceListMutation();
  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdatePriceListMutation();

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const saveLoading = addIsLoading || updateIsLoading;

  const onError = () => {};

  const onSubmit: SubmitHandler<PriceListFormType> = (dataFields) => {
    const { name, unit_type, perishable, description } = dataFields;

    console.log(dataFields);
    
    // if (data) {
    //   updateMutation({
    //     queryParams: {
    //       type: serviceType.toUpperCase(),
    //       id: data.id,
    //     },
    //     bodyParams: {
    //       name,
    //       unit_type,
    //       perishable,
    //       description,
    //     },
    //   });
    // } else {
    //   addMutation({
    //     queryParams: serviceType.toUpperCase(),
    //     bodyParams: {
    //       name,
    //       unit_type,
    //       perishable,
    //       description,
    //     },
    //   });
    // }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-rental-services-price-list",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `New category successfully added!`,
      toastId: "add-rental-services-price-list",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: `Category successfully updated!`,
      toastId: "update-rental-services-price-list",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-rental-services-price-list",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        id,
        name,
        regular,
        holiday,
        sp,
        spHoliday,
        inclusions,
        canEditName,
      } = data;

      setValue("id", id);
      setValue("name", name);
      // setValue("regular", regular);
      // setValue("holiday", holiday);
      // setValue("sp", sp);
      // setValue("spHoliday", spHoliday);
      // setValue("inclusions", inclusions);
      // setValue("canEditName", canEditName);
    }
  }, [data]);

  return (
    <Box
      marginBottom={3}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">
                    Category Name
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Category Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        disabled={form.watch("canEditName") === 0}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <div style={{ width: "-webkit-fill-available" }}>
                      <Typography variant="textfieldLabel">
                        Unit Type
                      </Typography>
                      <Controller
                        name="unit_type"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            placeholder="Unit Type"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginTop: 26, width: 165 }}>
                      <Controller
                        name="perishable"
                        control={control}
                        render={({ field }) => (
                          <Switch checked={field.value} {...field} />
                        )}
                      />
                      <Typography variant="textfieldLabel">
                        Perishable
                      </Typography>
                    </div>
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">Description</Typography>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Description"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6} />
          </Grid>
          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default CategoriesForm;
