import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import {
  inventoryManagementRoute,
  trackingRoute,
} from "../../../routes/routeKeys";
import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import Tracker from "./Tracker";
import Dispatch from "./Dispatch";

const tabItems: ItemsProps[] = [
  {
    label: "Dispatch",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Tracker",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const Tracking = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${inventoryManagementRoute}${trackingRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && <Dispatch/>}
          {tabValue === 1 && <Tracker />}
        </Box>
      </Box>
    </Paper>
  );
};

export default Tracking;
