import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  useForm,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";

import CustomFileUploader from "../../../../common/textfields/CustomFileUploader";
import { buyerGuidelineEndpoints } from "../../../../../service/slice/client-management/buyer/buyerGuidelineSlice";
import { linkWithDomain } from "../../../../../utils/helpers/stringManipulate";
import {
  useAddBuyerGuidelineMutation,
  useDownloadBuyerGuidelineQuery,
} from "../../../../../service/slice/client-management/buyer/buyerGuidelineSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import { skipToken } from "@reduxjs/toolkit/query";
import { LoaderWithText } from "../../../../common/Loaders";

import "./index.css";

interface UploadForm {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
}

type Props = {
  title: string;
  type: "RR" | "TC" | "GDL" | "PS" | "BIS" | "RS";
  uploadTitle: string;
};

const GuidlineViewUploader = ({ title, type, uploadTitle }: Props) => {
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;
  const form = useForm<UploadForm>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
    },
  });

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddBuyerGuidelineMutation();

  const {
    data: guidelineData,
    isLoading: guidelineIsLoading,
    error: guidelineError,
    isFetching: guidelineIsFetching,
  } = useDownloadBuyerGuidelineQuery(
    buyerId
      ? {
          buyerId,
          buyerUnitTypeId: 0,
          type: type,
        }
      : skipToken
  );

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset: fieldReset,
  } = form;

  const loading = guidelineIsLoading || guidelineIsFetching;

  const documentWatch = useWatch({
    control,
    name: "document",
  });

  const onSubmit: SubmitHandler<UploadForm> = (dataFields) => {
    addMutate({
      buyerId: buyerId as number,
      name: dataFields.document[0].name,
      path: dataFields.document[0].path,
      type,
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: `add ${title}`,
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `${title} updated!`,
      toastId: `add ${title}`,
    });
    addReset();
    fieldReset();
  }

  if (guidelineError) {
    errorDisplayOrNavigate({
      error: guidelineError,
      toastId: title,
    });
  }

  return loading ? (
    <LoaderWithText text={`Getting ${title} data`} />
  ) : (
    <Stack direction={{ lg: "row", xs: "column" }} spacing={2} marginBottom={6}>
      <Box height={600} width={{ lg: "50%", xs: "100%" }}>
        <Typography variant="textfieldLabel">{title}</Typography>
        {guidelineData?.data ? (
          <DocViewer
            documents={[
              {
                uri: linkWithDomain(
                  guidelineData?.data ? guidelineData?.data.link : ""
                ),
              },
            ]}
            pluginRenderers={DocViewerRenderers}
          />
        ) : (
          <Alert severity="warning">{`No File for ${title}`}</Alert>
        )}
      </Box>
      <Box height={600} width={{ lg: "50%", xs: "100%" }}>
        <Typography variant="textfieldLabel">{uploadTitle}</Typography>
        <FormProvider {...form}>
          <CustomFileUploader
            name="document"
            setValue={setValue}
            clearErrors={clearErrors}
            errorMsg={errors.document?.message}
            endpoint={buyerGuidelineEndpoints["upload-guideline"]}
            getValues={getValues}
            isMultiple={false}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
              marginTop: 2,
            }}
            disabled={_.isEmpty(documentWatch[0].path)}
            startIcon={
              addIsLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={addIsLoading ? () => {} : handleSubmit(onSubmit)}
          >
            Upload
          </Button>
        </FormProvider>
        {!_.isEmpty(documentWatch[0].path) && (
          <Box marginTop={2} height={"428px"}>
            <DocViewer
              documents={[
                {
                  uri: linkWithDomain(documentWatch[0].path),
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default GuidlineViewUploader;
