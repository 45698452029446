import React, { useEffect } from "react";
import _ from "lodash";
import Circle from "@uiw/react-color-circle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { colorPalette, thisFieldRequired } from "../../../utils/constants";
import {
  useAddVaultStatusMutation,
  useUpdateVaultStatusMutation,
} from "../../../service/slice/lot-vault-crypt/vaultSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { VaultStatusModel } from "../../../model/vaultModel";

type StatusForm = {
  name: string;
  description: string;
  color: string;
};

type Props = {
  title: string;
  data: VaultStatusModel | null;
  closeFn: () => void;
};

const VaultStatusForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<StatusForm>({
    defaultValues: {
      name: "",
      color: "",
      description: "",
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit } = form;

  const [
    addMutation,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddVaultStatusMutation();
  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateVaultStatusMutation();

  const colorWatch = useWatch({
    control,
    name: "color",
  });

  const onSubmit: SubmitHandler<StatusForm> = (dataFields) => {
    if (data) {
      updateMutations({
        queryParams: data.id,
        bodyParams: dataFields,
      });
    } else {
      addMutation(dataFields);
    }
  };

  const saveLoading = addisLoading || updateIsLoading;

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-vault-status",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Columbarium Status created!",
      toastId: "add-vault-status",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Columbarium Status updated!",
      toastId: "update-vault-status",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-vault-status",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("description", data.description);
      setValue("color", data.color);
    }
  }, [data]);
  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant="textfieldLabel">Status Name</Typography>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Status Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="textfieldLabel">Color</Typography>
              <Circle
                colors={colorPalette}
                color={colorWatch}
                onChange={(color) => {
                  setValue("color", color.hex);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="textfieldLabel">Description</Typography>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Description"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={(theme) => ({
              width: 150,
              backgroundColor: _.isEmpty(colorWatch)
                ? theme.palette.primary.main
                : colorWatch,
            })}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default VaultStatusForm;
