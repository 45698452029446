import React from "react";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  useFieldArray,
  useWatch,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
  Controller,
} from "react-hook-form";

import IconButtonMdi from "../../../../common/IconButtonMdi";

import { OtherForm } from "./OtherInfo";
import { thisFieldRequired } from "../../../../../utils/constants";
import AuthorizedContacts from "./AuthorizedContacts";

type Props = {
  control: Control<OtherForm>;
  clearErrors: UseFormClearErrors<OtherForm>;
  setValue: UseFormSetValue<OtherForm>;
  errors: FieldErrors<OtherForm>;
};

const AuthorizedInfo = ({ control, clearErrors, setValue, errors }: Props) => {
  const theme = useTheme();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "authorized",
  });

  const authorizedInfoWatch = useWatch({
    control,
    name: "authorized",
  });

  const handleAddAuthorized = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        contact: [
          {
            id: null,
            value: "",
          },
        ],
      });
    }
  };

  return (
    <div>
      {fields.map((item, key) => (
        <Stack
          direction="row"
          spacing={1}
          key={key}
          paddingTop={key === 0 ? 0 : 3}
        >
          <Box paddingTop="28px">
            <IconButtonMdi
              mdiIcon={
                key + 1 === authorizedInfoWatch.length
                  ? key === 2
                    ? mdiMinusCircleOutline
                    : mdiPlusCircleOutline
                  : mdiMinusCircleOutline
              }
              iconColor={
                key + 1 === authorizedInfoWatch.length
                  ? key === 2
                    ? theme.palette.error.main
                    : theme.palette.info.main
                  : theme.palette.error.main
              }
              onClick={() =>
                handleAddAuthorized(
                  key + 1 === authorizedInfoWatch.length
                    ? key === 2
                      ? key
                      : null
                    : key
                )
              }
            />
          </Box>
          <Box width={"100%"}>
            <Stack spacing={2}>
              <div>
                <Typography variant="textfieldLabel">First Name</Typography>
                <Controller
                  name={`authorized.${key}.firstName`}
                  control={control}
                  rules={{
                    required: {
                      value: _.isEmpty(authorizedInfoWatch[0].firstName)
                        ? true
                        : key === 0
                        ? true
                        : false,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="First Name"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={authorizedInfoWatch[key]?.firstName ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Last Name</Typography>
                <Controller
                  name={`authorized.${key}.lastName`}
                  control={control}
                  rules={{
                    required: {
                      value: _.isEmpty(authorizedInfoWatch[0].lastName)
                        ? true
                        : key === 0
                        ? true
                        : false,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Last Name"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={authorizedInfoWatch[key]?.lastName ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Middle Name</Typography>
                <Controller
                  name={`authorized.${key}.middleName`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Middle Name"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={authorizedInfoWatch[key]?.middleName ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Email Address</Typography>
                <Controller
                  name={`authorized.${key}.email`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      value={authorizedInfoWatch[key]?.email ?? ""}
                      type="email"
                      placeholder="Email Address"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <AuthorizedContacts
                  parentIndex={key}
                  clearErrors={clearErrors}
                  control={control}
                  disabled={false}
                  errors={errors}
                  setValue={setValue}
                />
              </div>
            </Stack>
          </Box>
        </Stack>
      ))}
    </div>
  );
};

export default AuthorizedInfo;
