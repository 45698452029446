import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { mdiClose, mdiRefresh, mdiPencil } from "@mdi/js";

import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import ConfirmModal from "../../common/ConfirmModal";

import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import useTableParameters from "../../../hooks/useTableParameters";
import { buyersRoute, clientManagementRoute } from "../../../routes/routeKeys";
import {
  buyerListEndpoints,
  useGetBuyerListQuery,
  useUpdateBuyerStatusMutation,
} from "../../../service/slice/client-management/buyer/buyerListSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { BuyerListModel } from "../../../model/buyerModel";
import DownloadOptionsList from "../../common/DownloadOptionsList";
import { BuyerStatusColors } from "../../../utils/helpers/colorHelper";

const headerLabels: string[] = [
  "Reference Number",
  "Date Started",
  "Buyer Name",
  "Unit Type",
  "Payment Terms",
  "Terms Left",
];

const ActiveBuyers = () => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const navigate = useNavigate();
  const theme = useTheme();
  const [deleteItem, handelToggleDeleteModal] = useDeleteTableItem<{
    buyerData: BuyerListModel;
    status: number;
  }>();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetBuyerListQuery({
    status: 0,
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const [
    updateMutate,
    {
      isLoading: updateIsLoading,
      error: updateError,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateBuyerStatusMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  const handleConfirmUpdate = () =>
    updateMutate({
      id: deleteItem.data?.buyerData?.id as number,
      status: deleteItem.data?.status ?? 1,
      statusToInvalidate: 0,
    });

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "buyer-list",
    });
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.buyerData.referenceNumber} ${
        deleteItem.data?.status === 1 ? "forfeited" : "moved to delinquent"
      }.`,
      toastId: "update-item-list",
    });

    updateReset();
    handelToggleDeleteModal(false, null);
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-item-list",
    });

    updateReset();
  }

  return (
    <>
      <ConfirmModal
        isLoading={updateIsLoading}
        open={deleteItem.open}
        text={
          deleteItem.data?.status === 1
            ? `Are you sure to forfeit ${deleteItem.data?.buyerData.referenceNumber}?`
            : `Are you sure to move ${deleteItem.data?.buyerData.referenceNumber} to delinquent?`
        }
        closeFn={() => handelToggleDeleteModal(false, null)}
        confirmFn={handleConfirmUpdate}
      />
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${clientManagementRoute}${buyersRoute}`}
              querySearch={(value: string) =>
                `?tab=1&page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
            <Button
              variant="button-secondary"
              onClick={() =>
                navigate(
                  `${clientManagementRoute}${buyersRoute}/add/buyer-information`
                )
              }
              sx={{
                width: "180px",
              }}
            >
              Add New Buyer
            </Button>
            <DownloadOptionsList
              endpoint={buyerListEndpoints["download-list"](0)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${clientManagementRoute}${buyersRoute}`}
            querySearch={(entry: string) =>
              `?tab=1&page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor:
                        item.forForfeit === 1
                          ? BuyerStatusColors[item.forForfeit]
                          : "grey.100",

                      td: {
                        backgroundColor:
                          item.forForfeit === 1
                            ? BuyerStatusColors[item.forForfeit]
                            : "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor:
                        item.forForfeit === 1
                          ? BuyerStatusColors[item.forForfeit]
                          : "common.white",
                    },
                  }}
                >
                  <TableCell>{item.referenceNumber}</TableCell>
                  <TableCell>{item.startDate}</TableCell>
                  <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.paymentTerm}</TableCell>
                  <TableCell>{item.term}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiPencil}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Edit"
                        onClick={() =>
                          navigate(
                            `${clientManagementRoute}${buyersRoute}/edit/${item.buyerId}/buyer-information`
                          )
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiClose}
                        iconColor={theme.palette.warning.dark}
                        tooltipTitle="Forfeit"
                        onClick={() =>
                          handelToggleDeleteModal(true, {
                            buyerData: item,
                            status: 1,
                          })
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiClose}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delinquent"
                        onClick={() =>
                          handelToggleDeleteModal(true, {
                            buyerData: item,
                            status: 2,
                          })
                        }
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${clientManagementRoute}${buyersRoute}`}
        querySearch={(page) =>
          `?tab=1&page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default ActiveBuyers;
