import React, { useRef, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

import { useGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import {
  useAddPaymentAccountManagMutation,
  useLazyGetAccountManagReceiptQuery,
} from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import { thisFieldRequired } from "../../../utils/constants";

import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { ServicePaymentModel } from "../../../model/accountManagementPaymentModel";
import {
  replaceAllSpaceWithHyphen,
  replaceAllHyphenWithSpace,
} from "../../../utils/helpers/stringManipulate";
import { LoaderWithText } from "../../common/Loaders";
import CardHeader from "../../common/CardHeader";
import { receiptOpt } from "../../../utils/constants";
import { PaymentFieldsBody } from "../../../model/buyerModel";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../common/FileViewModal";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import PayModalUpload from "../payment/PayModalUpload";

interface DetailsDataModel extends ServicePaymentModel {
  type: "SERVICE";
}

interface PaymentForm {
  paymentType: {
    label: string | null;
    value: number | null;
  };
  paymentFields: {
    [name: string]: string | Date | null | undefined;
  }[];
  receipt: {
    label: string | null;
    value: string | null;
  };
  description: string;
}

const OtherPayment = () => {
  const location = useLocation();
  const detailsData: DetailsDataModel | null = location.state;
  const paymentFieldRef = useRef<string[]>([]);
  const paymentFieldIdRef = useRef<number[]>([]);
  const [viewDoc, handelToggleViewDoc] = useFileView();
  const [openUpload, handleToggleUpload] = useOpenCreateForm<number>();

  const {
    data: paymentListData,
    isLoading: paymentListIsLoading,
    error: paymentListError,
  } = useGetPaymentsListConfigQuery();

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
      data: addData,
    },
  ] = useAddPaymentAccountManagMutation();

  const [
    getReceiptTrigger,
    { data: receiptData, isSuccess: receiptIsSuccess, error: receiptError },
  ] = useLazyGetAccountManagReceiptQuery();

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const form = useForm<PaymentForm>({
    defaultValues: {
      paymentFields: [],
      paymentType: {
        label: "",
        value: null,
      },
      receipt: {
        label: null,
        value: null,
      },
      description: "",
    },
  });

  const { control, handleSubmit, setError, clearErrors, getValues, setValue } =
    form;

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const receiptWatch = useWatch({
    control,
    name: "receipt",
  });

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (dataFields.paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    if (dataFields.receipt.value === null) {
      setError("receipt", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const onSubmit: SubmitHandler<PaymentForm> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const { paymentFields, paymentType, description } = dataFields;

    let finalPaymentAmount = 0;

    paymentFields.forEach((item, key) => {
      const itemKeyName = paymentFieldRef.current[key];

      if (
        itemKeyName.toLowerCase().includes("cash") ||
        itemKeyName.toLowerCase().includes("amount")
      ) {
        finalPaymentAmount = parseInt(item[itemKeyName] as string);
      }
    });

    const finalPaymentFields: PaymentFieldsBody[] = paymentFields.map(
      (item, key) => {
        const itemId = paymentFieldIdRef.current[key];
        const itemKeyName = paymentFieldRef.current[key];
        return {
          fieldId: itemId,
          value: itemKeyName.toLowerCase().includes("date")
            ? (moment(item[itemKeyName]).format("YYYY-MM-DD") as string)
            : (item[itemKeyName] as string),
        };
      }
    );

    addMutate({
      id: detailsData?.id ?? 0,
      type: detailsData?.type ?? "SERVICE",
      bodyData: {
        id: detailsData?.id ?? 0,
        payment: {
          paymentId: Number(paymentType.value),
          amount: finalPaymentAmount,
          paymentFields: finalPaymentFields,
        },
      },
    });
  };

  const onError = () => {
    checkErrors();
  };

  const { fields: paymentFieldsWatch, replace: paymentFieldsReplace } =
    useFieldArray({
      control,
      name: "paymentFields",
    });

  const loading = paymentListIsLoading;
  const saveLoading = addIsLoading;

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list-config",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-payment",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Successfully Paid!",
      toastId: "add-payment",
    });

    getReceiptTrigger({
      id: addData?.data.id as number,
      receiptType:
        receiptWatch.label === "Official Receipt"
          ? "OR"
          : receiptWatch.label === "Acknowledgement Receipt"
          ? "AR"
          : "PR",
      paymentType: detailsData?.type ?? "SERVICE",
    });

    addReset();
  }

  if (receiptError) {
    errorDisplayOrNavigate({
      error: receiptError,
      toastId: "receipt",
    });
  }

  useEffect(() => {
    if (paymentTypeWatch.value) {
      const selectedPayType = paymentListData?.data?.find(
        (item) => item.id === Number(paymentTypeWatch.value)
      );

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map(
            (item): { [name: string]: string } => ({
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
            })
          )
        );
        paymentFieldRef.current = selectedPayType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = selectedPayType.paymentFields.map(
          (item) => item.id
        );
      }
    }
  }, [paymentTypeWatch]);

  useEffect(() => {
    if (receiptIsSuccess) {
      handelToggleViewDoc(true, receiptData?.data.link ?? "");
    }
  }, [receiptIsSuccess]);

  return (
    <Paper elevation={3}>
      <CardHeader title="Pay - Other Payment" />
      <Box padding={3}>
        {loading ? (
          <Box paddingX={2} height="90vh">
            <Stack alignItems="center" justifyContent="center" height="90vh">
              <LoaderWithText text="Get Additional Details.." />
            </Stack>
          </Box>
        ) : (
          <>
            {viewDoc.open && (
              <FileViewModal
                open={viewDoc.open}
                path={viewDoc.path}
                title={viewDoc.path.substring(
                  viewDoc.path.lastIndexOf("/") + 1,
                  viewDoc.path.length
                )}
                onClose={() => handelToggleViewDoc(false, "")}
              />
            )}
            {openUpload.open && (
              <PayModalUpload
                handleToggleUpload={() => handleToggleUpload(false, null, "")}
                id={openUpload.data ?? 0}
                type={detailsData?.type ? "service" : "service"}
              />
            )}

            {detailsData && (
              <Box marginBottom={4}>
                <Alert severity="info" icon={false}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 1, md: 3, lg: 5 }}
                  >
                    <Typography variant="body1" marginBottom={0.5}>
                      <Typography
                        component="span"
                        marginRight={1}
                        variant="textSm"
                      >
                        Reference No.:
                      </Typography>
                      <Typography
                        component="span"
                        variant="textSm"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: 700,
                        })}
                      >
                        {detailsData.referenceNumber}
                      </Typography>
                    </Typography>
                    <Typography variant="body1" marginBottom={0.5}>
                      <Typography
                        component="span"
                        marginRight={1}
                        variant="textSm"
                      >
                        Buyer:
                      </Typography>
                      <Typography
                        component="span"
                        variant="textSm"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: 700,
                        })}
                      >
                        {detailsData.name}
                      </Typography>
                    </Typography>
                    <Typography variant="body1" marginBottom={0.5}>
                      <Typography
                        component="span"
                        marginRight={1}
                        variant="textSm"
                      >
                        Service:
                      </Typography>
                      <Typography
                        component="span"
                        variant="textSm"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: 700,
                        })}
                      >
                        {detailsData.service}
                      </Typography>
                    </Typography>
                    <Typography variant="body1" marginBottom={0.5}>
                      <Typography
                        component="span"
                        marginRight={1}
                        variant="textSm"
                      >
                        Status:
                      </Typography>
                      <Typography
                        component="span"
                        variant="textSm"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: 700,
                        })}
                      >
                        {detailsData.status}
                      </Typography>
                    </Typography>
                    <Typography variant="body1" marginBottom={0.5}>
                      <Typography
                        component="span"
                        marginRight={1}
                        variant="textSm"
                      >
                        Balance:
                      </Typography>
                      <Typography
                        component="span"
                        variant="textSm"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: 700,
                        })}
                      >
                        {detailsData.balance}
                      </Typography>
                    </Typography>
                    <Typography variant="body1" marginBottom={0.5}>
                      <Typography
                        component="span"
                        marginRight={1}
                        variant="textSm"
                      >
                        Amount:
                      </Typography>
                      <Typography
                        component="span"
                        variant="textSm"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: 700,
                        })}
                      >
                        {detailsData.amount}
                      </Typography>
                    </Typography>
                  </Stack>
                </Alert>
              </Box>
            )}

            <FormProvider {...form}>
              <Stack spacing={2} width={{ xs: "100%", lg: "50%" }}>
                <div>
                  <Typography variant="textfieldLabel">Payment Type</Typography>
                  <CustomReactSelectJs
                    control={control}
                    options={paymentTypeOptions}
                    name="paymentType"
                    placeholder="Payment Type"
                  />
                </div>
                {!_.isNull(paymentTypeWatch.value) && (
                  <>
                    {paymentFieldsWatch.map((item, key) => {
                      const itemKeyName = Object.keys(item)[0];

                      const itemLabelName =
                        replaceAllHyphenWithSpace(itemKeyName);

                      return (
                        <div key={key}>
                          <Typography variant="textfieldLabel">
                            {itemLabelName}
                          </Typography>
                          <Controller
                            name={`paymentFields.${key}.${itemKeyName}`}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            control={control}
                            render={({ field, fieldState }) => {
                              if (itemKeyName.toLowerCase().includes("date")) {
                                return (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      {...field}
                                      slotProps={{
                                        textField: {
                                          variant: "outlined",
                                          error: fieldState.error?.message
                                            ? true
                                            : false,
                                          helperText: fieldState.error?.message,
                                          fullWidth: true,
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                );
                              }
                              return (
                                <TextField
                                  {...field}
                                  placeholder={itemLabelName}
                                  error={fieldState.error ? true : false}
                                  helperText={fieldState.error?.message}
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
                <div>
                  <Typography variant="textfieldLabel">Description</Typography>
                  <Controller
                    name={"description"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder={"Description"}
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        rows={2}
                        multiline
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">Receipt</Typography>
                  <CustomReactSelectJs
                    control={control}
                    options={receiptOpt}
                    name="receipt"
                    placeholder="Receipt"
                  />
                </div>
                <Stack direction="row" spacing={2} paddingTop={2}>
                  <Button
                    variant="button-primary"
                    sx={{
                      width: 150,
                    }}
                    disabled={detailsData ? false : true}
                    startIcon={
                      saveLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    Pay
                  </Button>
                  {/* <Button
                    variant="button-secondary"
                    sx={{
                      width: 150,
                    }}
                    onClick={() =>
                      handleToggleUpload(true, detailsData?.id ?? 0, "")
                    }
                  >
                    Upload Documents
                  </Button> */}
                </Stack>
              </Stack>
            </FormProvider>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default OtherPayment;
