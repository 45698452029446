import React from "react";
import _ from "lodash";
import ReactQuill from "react-quill";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import "react-quill/dist/quill.bubble.css";

import { useGetCompanyProfilePublicQuery } from "../../service/slice/profileSlice";
import { errorDisplayOrNavigate } from "../../utils/notificationToast";

const FooterPanel = () => {
  const { data, isLoading, error } = useGetCompanyProfilePublicQuery();

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "company-profile",
    });
  }

  return (
    <>
      <Box width="100%">
        {isLoading ? (
          <div></div>
        ) : (
          <ReactQuill
            theme="bubble"
            readOnly
            value={data?.data.footer as string}
          />
        )}
      </Box>
    </>
  );
};

export default FooterPanel;
