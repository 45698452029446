import React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import CircularProgress from "@mui/material/CircularProgress";
import { TransitionProps } from "@mui/material/transitions";
import Icon from "@mdi/react";
import { mdiClose, mdiCancel } from "@mdi/js";

import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import { OrBookletModel, OrModel } from "../../../model/OrModel";
import ConfirmModal from "../../common/ConfirmModal";
import IconButtonMdi from "../../common/IconButtonMdi";
import {
  useGetOrQuery,
  useVoidOrMutation,
} from "../../../service/slice/account-management/OR/OrSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";

const headerLabel: string[] = ["OR Number", "Issued To", "Action"];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  data: OrModel | null;
  closeFn: () => void;
};

const OfficialReceiptView = ({ open, data, closeFn }: Props) => {
  const theme = useTheme();
  const [voidItem, handleToggleVoid] = useDeleteTableItem<OrBookletModel>();
  const {
    data: orData,
    isLoading: orIsLoading,
    error: orError,
  } = useGetOrQuery(data?.id ?? skipToken);

  const [
    voidMutation,
    {
      isLoading: voidIsLoading,
      isSuccess: voidIsSuccess,
      reset: voidReset,
      error: voidError,
    },
  ] = useVoidOrMutation();

  const loading = orIsLoading;
  const list = orData?.data ?? [];

  const confirmVoid = () => voidMutation(voidItem.data?.id as number);

  if (voidIsSuccess) {
    showToastSuccess({
      text: `${voidItem.data?.number} successfully void!`,
      toastId: "or-get",
    });

    handleToggleVoid(false, null);
    voidReset();
  }

  if (voidError) {
    errorDisplayOrNavigate({
      error: voidError,
      toastId: "or-get",
    });

    voidReset();
  }

  if (orError) {
    errorDisplayOrNavigate({
      error: orError,
      toastId: "or-get",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={voidIsLoading}
        open={voidItem.open}
        text={`Are you sure to void ${voidItem.data?.number}`}
        closeFn={() => handleToggleVoid(false, null)}
        confirmFn={confirmVoid}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={(theme) => ({
          "& .MuiDialog-paper": {
            width: "70vw",
            maxWidth: "70vw",
            height: "90vh",
            borderRadius: 2,
          },
        })}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{data?.code ?? ""}</Typography>
            <Box
              sx={{
                cursor: "pointer",
                height: "24px",
              }}
              onClick={closeFn}
            >
              <Icon path={mdiClose} size={1} />
            </Box>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <LoaderWithText text="Getting OR Booklets" />
            </Stack>
          ) : (
            <TableContainer
              sx={{
                height: "77vh",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headerLabel.map((item, key) => (
                      <TableCell key={key} variant="head">
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.length > 0 ? (
                    list.map((item, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:nth-of-type(even)": {
                            backgroundColor: "grey.100",

                            td: {
                              backgroundColor: "grey.100",
                            },
                          },
                          "&:nth-of-type(odd) td": {
                            backgroundColor: "common.white",
                          },
                        }}
                      >
                        <TableCell>{item.number}</TableCell>
                        <TableCell>{item.issuedTo ?? ""}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <IconButtonMdi
                              mdiIcon={mdiCancel}
                              iconColor={
                                item.voided
                                  ? theme.palette.common.white
                                  : theme.palette.error.main
                              }
                              tooltipTitle={
                                item.voided
                                  ? "OR Number already void"
                                  : "Void OR Number"
                              }
                              onClick={
                                item.voided
                                  ? undefined
                                  : () => handleToggleVoid(true, item)
                              }
                              bgColor={
                                item.voided
                                  ? theme.palette.error.main
                                  : undefined
                              }
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        NO DATA FOUND
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OfficialReceiptView;
