import React from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Stack from "@mui/material/Stack";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import { thisFieldRequired } from "../../../utils/constants";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import {
  endpoints,
  useAddProvisionalDocMutation,
} from "../../../service/slice/account-management/provisional-receipt/provisionalReceiptSlice";
import ProvisionalUploadList from "./ProvisionalUploadList";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ProvisionalUploadForm {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
  filename: string;
  description: string;
}

type Props = {
  handleToggleUpload: () => void;
  id: number;
  open: boolean;
  title: string;
};

const ProvisionalUpload = ({ handleToggleUpload, id, open, title }: Props) => {
  const form = useForm<ProvisionalUploadForm>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
      filename: "",
      description: "",
    },
  });

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset: fieldReset,
  } = form;

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      reset: addReset,
    },
  ] = useAddProvisionalDocMutation();

  const documentWatch = useWatch({
    control,
    name: "document",
  });

  const onSubmit: SubmitHandler<ProvisionalUploadForm> = (dataFields) => {
    addMutate({
      buyerPaymentId: id,
      description: dataFields.description,
      fileName: dataFields.filename,
      path: dataFields.document[0].path,
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Document added!",
      toastId: "add",
    });

    fieldReset();
    addReset();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: "90vw",
          maxWidth: "90vw",
          height: "90vh",
          borderRadius: 2,
        },
      })}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="span" marginRight={1}>
            {title}
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              height: "24px",
            }}
            onClick={handleToggleUpload}
          >
            <Icon path={mdiClose} size={1} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box padding={2} paddingTop={1}>
          <Grid container columnSpacing={6} rowSpacing={2}>
            <Grid item xs={12} lg={4} height="100%">
              <FormProvider {...form}>
                <Stack spacing={2}>
                  <div>
                    <Typography variant="textfieldLabel">
                      Upload Files
                    </Typography>
                    <CustomFileUploader
                      name="document"
                      setValue={setValue}
                      clearErrors={clearErrors}
                      errorMsg={errors.document?.message}
                      endpoint={endpoints["upload-provisional-receipt-doc"]}
                      getValues={getValues}
                      isMultiple={false}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">Filename</Typography>
                    <Controller
                      name="filename"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Filename"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">
                      Description
                    </Typography>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Description"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          multiline={true}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <Button
                    variant="button-primary"
                    sx={{
                      width: 150,
                      marginTop: 2,
                    }}
                    disabled={_.isEmpty(documentWatch[0].path)}
                    startIcon={
                      addIsLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={addIsLoading ? () => {} : handleSubmit(onSubmit)}
                  >
                    Upload
                  </Button>
                  {!_.isEmpty(documentWatch[0].path) && (
                    <>
                      <Box marginTop={2} />
                      <DocViewer
                        documents={[
                          {
                            uri: linkWithDomain(documentWatch[0].path),
                          },
                        ]}
                        pluginRenderers={DocViewerRenderers}
                      />
                    </>
                  )}
                </Stack>
              </FormProvider>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box marginTop={3}>
                <ProvisionalUploadList id={id} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProvisionalUpload;
