import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { mdiCreditCardOutline } from "@mdi/js";

import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import IconButtonMdi from "../../common/IconButtonMdi";

import useTableParameters from "../../../hooks/useTableParameters";

import { useGetAccManagServicePaymentListQuery } from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import { errorDisplayOrNavigate } from "../../../utils/notificationToast";
import {
  accountManagementRoute,
  otherPaymentRoute,
  paymentRoute,
} from "../../../routes/routeKeys";

const headerLabels: string[] = [
  "Reference No.",
  "Full Name",
  "Phone",
  "Email",
  "Service Acquired",
  "Date From",
  "Date To",
  "Status",
];

const ServicePayment = () => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetAccManagServicePaymentListQuery({
    type: "services",
    page: pageParam - 1,
    search: searchParam ?? "",
    entries: entriesParam,
  });

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "service-payment-list",
    });
  }

  return (
    <>
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <TableSearchField
            searchValue={searchParam ?? ""}
            pathname={`${accountManagementRoute}${paymentRoute}`}
            querySearch={(value: string) =>
              `?tab=2&page=${pageParam}&entries=${entriesParam}&search=${value}`
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${accountManagementRoute}${paymentRoute}`}
            querySearch={(value: string) =>
              `?tab=2&page=${pageParam}&entries=${value}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.referenceNumber}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.contact}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.service}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiCreditCardOutline}
                        iconColor={theme.palette.info.main}
                        tooltipTitle="Pay"
                        onClick={() =>
                          navigate(
                            `${accountManagementRoute}${otherPaymentRoute}`,
                            {
                              state: { ...item, type: "SERVICE" },
                            }
                          )
                        }
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${accountManagementRoute}${paymentRoute}`}
        querySearch={(page) =>
          `?tab=2&page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default ServicePayment;
