import { ErrorResponseModel, apiSlice, errorHandler } from "../apiSlice";
import {
  CompanyProfileBodyModel,
  CompanyProfileDtoModel,
  UploadLogoDtoModel,
} from "../../model/companyProfileModel";

export const profileEndPoints = {
  "company-profile": "/company/get",
  "upload-logo": "/company/upload/logo",
  "upload-doc": "/company/upload/document",
  "update-company": "/company/update",
  "company-profile-public": "/company/get/public",
};

export const profileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyProfile: builder.query<CompanyProfileDtoModel, void>({
      query: () => ({
        url: profileEndPoints["company-profile"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["company-profile"],
    }),
    getCompanyProfilePublic: builder.query<CompanyProfileDtoModel, void>({
      query: () => ({
        url: profileEndPoints["company-profile-public"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["company-profile-public"],
    }),
    uploadLogo: builder.mutation<UploadLogoDtoModel, FileList>({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append("logo", param[0]);
        }

        return {
          url: profileEndPoints["upload-logo"],
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
    updateCompanyProfile: builder.mutation<
      void,
      {
        queryParam: number;
        bodyParam: Partial<CompanyProfileBodyModel>;
      }
    >({
      query: (param) => ({
        url: `${profileEndPoints["update-company"]}/${param.queryParam}`,
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["company-profile", "company-profile-public"],
    }),
  }),
});

export const {
  useGetCompanyProfileQuery,
  useGetCompanyProfilePublicQuery,
  useUploadLogoMutation,
  useUpdateCompanyProfileMutation,
} = profileSlice;
