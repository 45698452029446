import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { domainLink } from "../../utils/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  path: string;
  title: string;
  onClose: () => void;
};

const FileViewModal = ({ open, path, onClose, title }: Props) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: "70vw",
          maxWidth: "70vw",
          height: "90vh",
          borderRadius: 2,

          "& #file-name": {
            display: "none",
          },
        },
      })}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>{title}</Typography>
          <Box
            sx={{
              cursor: "pointer",
              height: "24px",
            }}
            onClick={onClose}
          >
            <Icon path={mdiClose} size={1} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DocViewer
          documents={[
            {
              uri: path.includes(domainLink) ? path : `${domainLink}${path}`,
            },
          ]}
          pluginRenderers={DocViewerRenderers}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FileViewModal;
