import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mdi/react";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  onClick?: () => void;
  mdiIcon: string;
  hasShadow?: boolean;
  iconColor?: string;
  bgColor?: string;
  hoverColor?: string;
  tooltipTitle?: string;
  isLoading?: boolean;
  index?: number;
};

const IconButtonMdi = ({
  onClick,
  mdiIcon,
  iconColor,
  bgColor,
  hoverColor,
  tooltipTitle,
  isLoading,
  hasShadow,
  index,
}: Props) => {
  return isLoading ? (
    <Box
      sx={{
        paddingRight: "8px",
      }}
    >
      <CircularProgress
        color="primary"
        size={25}
        style={{ margin: "20px 0" }}
      />
    </Box>
  ) : (
    <Tooltip title={tooltipTitle}>
      <Box
        sx={(theme) => ({
          padding: "5px 8px",
          height: "40.7px",
          width: "40.7px",
          borderRadius: theme.spacing(1),
          backgroundColor: bgColor ?? "common.white",
          cursor: onClick ? "pointer" : "default",
          "&:hover": {
            backgroundColor: hoverColor ?? bgColor ?? "common.white",
          },
          boxShadow: hasShadow ? theme.shadows[3] : "none",
        })}
        onClick={onClick}
      >
        <Icon
          path={mdiIcon}
          size={1}
          color={iconColor}
          style={{
            marginTop: 3,
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default IconButtonMdi;
