import React from "react";
import { useLocation } from "react-router-dom";

const useTableParameters = () => {
  const location = useLocation();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  return { pageParam, searchParam, entriesParam };
};

export default useTableParameters;
