import React from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  FieldValues,
  UseFormClearErrors,
  Path,
  UseFormGetValues,
} from "react-hook-form";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Icon from "@mdi/react";
import { mdiFileUploadOutline } from "@mdi/js";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { thisFieldRequired } from "../../../utils/constants";
import { useUploadDocsMutation } from "../../../service/apiSlice";
import { errorDisplayOrNavigate } from "../../../utils/notificationToast";
import { LoaderWithText } from "../Loaders";

interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  setValue: any;
  clearErrors: UseFormClearErrors<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  isMultiple?: boolean;
  errorMsg?: string;
  fileTypes?: string[];
  endpoint: string;
  isWhiteBg?: boolean;
  formKey?: string;
  isDisabled?: boolean;
}

const CustomFileUploader = <TFieldValues extends FieldValues>({
  isMultiple = true,
  name,
  setValue,
  clearErrors,
  errorMsg,
  fileTypes,
  endpoint,
  getValues,
  isWhiteBg = false,
  formKey = "document",
  isDisabled = false,
}: Props<TFieldValues>) => {
  const theme = useTheme();

  const [uploadMutate, { isLoading, reset, error }] = useUploadDocsMutation();

  const handleFileChange = async (value: File) => {
    const data = await uploadMutate({
      endpoint,
      file: value,
      formKey,
    }).unwrap();

    if (data) {
      const currentValue = getValues(name);

      setValue(
        name,
        isMultiple
          ? [
              ...currentValue,
              {
                id: null,
                path: data.data.link,
              },
            ]
          : [
              {
                id: currentValue[0]?.id ?? null,
                path: data.data.link,
                name: data.data.name,
                format: data.data.format,
              },
            ]
      );

      clearErrors([name]);
    }
  };

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "upload-doc",
    });

    reset();
  }

  return (
    <>
      <FileUploader
        handleChange={isLoading ? () => {} : handleFileChange}
        multiple={false}
        disabled={isDisabled}
        // files={fileValues}
        children={
          <Box
            sx={(theme) => ({
              width: "100%",
              maxWidth: "100%",
              minWidth: "100%",
              height: "104px",
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
              padding: 1,
              backgroundColor: isWhiteBg
                ? theme.palette.common.white
                : theme.palette.grey[100],
            })}
          >
            <Stack
              direction="row"
              justifyContent="center"
              spacing={1}
              alignItems="center"
              height="100%"
            >
              {isLoading ? (
                <LoaderWithText text="Uploading document" />
              ) : (
                <>
                  <Icon
                    path={mdiFileUploadOutline}
                    size={1.7}
                    color={theme.palette.primary.main}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: ".95rem",
                      fontWeight: 400,
                      color: theme.palette.primary.main,
                    })}
                  >
                    Click here or drag and drop your document
                  </Typography>
                </>
              )}
            </Stack>
          </Box>
        }
      />
      {errorMsg && <FormHelperText error>{thisFieldRequired}</FormHelperText>}
    </>
  );
};

export default CustomFileUploader;
