import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { mdiDeleteOutline, mdiReceiptTextEditOutline } from "@mdi/js";
import CardHeader from "../../common/CardHeader";
import TableSearchField from "../../common/textfields/TableSearchField";
import {
  paymentsTypesRoute,
  systemConfigRoute,
} from "../../../routes/routeKeys";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import CustomPagination from "../../common/CustomPagination";
import PaymentsChartsForm from "./PaymentsChartsForm";
import {
  useGetPaymentsListQuery,
  useDeletePaymentMutation,
} from "../../../service/slice/system-configuration/paymentsTypesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import IconButtonMdi from "../../common/IconButtonMdi";
import { PaymentTypesModel } from "../../../model/paymentsTypesModel";
import ConfirmModal from "../../common/ConfirmModal";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";

const PaymentsCharts = () => {
  const [openCreate, handleToggleCreate] =
    useOpenCreateForm<PaymentTypesModel>();
  const [deletePayment, handleToggleDeletePayment] =
    useDeleteTableItem<PaymentTypesModel>();

  const theme = useTheme();
  const location = useLocation();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const {
    data: dataList,
    isLoading: dataIsLoading,
    error: dataError,
    isFetching: dataIsFetching,
  } = useGetPaymentsListQuery({
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam as string,
  });

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePaymentMutation();

  const loading = dataIsLoading || dataIsFetching;
  const list = dataList?.data?.rows ?? [];
  const totalData = dataList?.data?.count ?? 0;

  const confirmDelete = () => {
    deleteMutate(deletePayment.data?.id as number);
  };

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deletePayment.data?.name} successfully deleted.`,
      toastId: "delete-payment",
    });

    deleteReset();
    handleToggleDeletePayment(false, null);
  }

  if (dataError) {
    errorDisplayOrNavigate({
      error: dataError,
      toastId: "payments-list",
    });
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-payment",
    });

    deleteReset();
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deletePayment.open}
        text={`Are you sure to delete ${deletePayment.data?.name}?`}
        closeFn={() => handleToggleDeletePayment(false, null)}
        confirmFn={confirmDelete}
      />
      {openCreate.open && (
        <PaymentsChartsForm
          title={openCreate.title}
          data={openCreate.data}
          closeFn={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title="Payments Types" />
        <Box padding={3}>
          <Grid container marginBottom={2} alignItems="center">
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${systemConfigRoute}${paymentsTypesRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  disabled={openCreate.open}
                  onClick={() => handleToggleCreate(true, null, "New Payment")}
                  sx={{
                    width: "180px",
                  }}
                >
                  Add Payment
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${systemConfigRoute}${paymentsTypesRoute}`}
                querySearch={(entry: string) =>
                  `?page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "primary.dark",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Payment Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Payment Description
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Chart of Account
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                      }}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.chartAccount.accountName}</TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiReceiptTextEditOutline}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="Edit Payment"
                            onClick={() =>
                              handleToggleCreate(true, item, "Update Payment")
                            }
                          />
                          <IconButtonMdi
                            mdiIcon={mdiDeleteOutline}
                            iconColor={theme.palette.error.main}
                            tooltipTitle="Delete Payment"
                            onClick={() =>
                              handleToggleDeletePayment(true, item)
                            }
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${systemConfigRoute}${paymentsTypesRoute}`}
            querySearch={(page) =>
              `?page=${page}&entries${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default PaymentsCharts;
