import { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import {
  mdiAccountLockOutline,
  mdiAccountEditOutline,
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
} from "@mdi/js";

import CardHeader from "../../common/CardHeader";
import {
  useGetUserListQuery,
  useUpdateUserMutation,
} from "../../../service/slice/usersSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomPagination from "../../common/CustomPagination";
import IconButtonMdi from "../../common/IconButtonMdi";
import { userManagementRoute, usersRoute } from "../../../routes/routeKeys";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import UserInformation from "./UserInformation";
import { createUserText, userInformationText } from "../../../utils/constants";
import DownloadOptionsList from "../../common/DownloadOptionsList";
import ConfirmModal from "../../common/ConfirmModal";
import { UserModel } from "../../../model/usersModel";
import { StatusEnum } from "../../../utils/enums";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";

const Users = () => {
  const location = useLocation();
  const theme = useTheme();

  const [openCreate, setOpenCreate] = useState<{
    open: boolean;
    data: null | number;
    isChangePassword: boolean;
    subTitle: string | null;
  }>({
    open: false,
    data: null,
    isChangePassword: false,
    subTitle: null,
  });

  const [disableUser, toggleDisableUser] = useDeleteTableItem<UserModel>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const {
    data: userList,
    error: userListError,
    isLoading: userListIsLoading,
    isFetching: userListIsFetching,
    refetch,
  } = useGetUserListQuery({
    entries: entriesParam,
    page: _.isEmpty(searchParam) ? pageParam - 1 : 0,
    search: searchParam ?? "",
  });

  const [
    updateUserMutate,
    {
      error: updateUserError,
      isLoading: updateUserIsLoading,
      reset: updateUserReset,
      isSuccess: updateUserIsSuccess,
    },
  ] = useUpdateUserMutation();

  const error = userListError;
  const isLoading = userListIsLoading || userListIsFetching;
  const list = userList?.data?.rows ?? [];
  const totalData = userList?.data?.count ?? 0;

  const confirmDisableAction = () =>
    updateUserMutate({
      queryParam: disableUser.data!.id,
      bodyParam: {
        status:
          disableUser.data?.status === StatusEnum.enabled
            ? StatusEnum.disabled
            : StatusEnum.enabled,
      },
    });

  if (updateUserIsSuccess) {
    showToastSuccess({
      text: `${disableUser.data?.firstName} ${
        disableUser.data?.lastName
      } successfully ${
        disableUser.data?.status === StatusEnum.enabled ? "disabled" : "enabled"
      }.`,
      toastId: "delete-user",
    });

    updateUserReset();
    toggleDisableUser(false, null);
  }

  if (updateUserError) {
    errorDisplayOrNavigate({
      error: updateUserError,
      toastId: "delete-user",
    });

    updateUserReset();
  }

  if (error) {
    errorDisplayOrNavigate({
      error: error,
      toastId: "user-list",
    });
  }

  useEffect(() => {
    refetch();
  }, [pageParam, entriesParam, searchParam]);

  return (
    <>
      <ConfirmModal
        isLoading={updateUserIsLoading}
        open={disableUser.open}
        text={`Are you sure to ${
          disableUser.data?.status === StatusEnum.enabled ? "disable" : "enable"
        } ${disableUser.data?.firstName} ${disableUser.data?.lastName}`}
        closeFn={() => toggleDisableUser(false, null)}
        confirmFn={confirmDisableAction}
      />
      {openCreate.open && (
        <UserInformation
          id={openCreate.data}
          changePass={openCreate.isChangePassword}
          title={`${userInformationText}${
            openCreate.subTitle ? ` - ${openCreate.subTitle}` : ""
          }`}
          create={openCreate.data === null && !openCreate.isChangePassword}
          handleCloseCard={() =>
            setOpenCreate({
              open: false,
              data: null,
              isChangePassword: false,
              subTitle: null,
            })
          }
        />
      )}
      <Paper elevation={3}>
        <CardHeader title="Users" />
        <Box padding={3}>
          <Grid container marginBottom={2} alignItems="center">
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${userManagementRoute}${usersRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  disabled={openCreate.open}
                  onClick={(): void =>
                    setOpenCreate((prev) => ({
                      ...prev,
                      open: true,
                      subTitle: createUserText,
                    }))
                  }
                  sx={{
                    width: "160px",
                  }}
                >
                  {createUserText}
                </Button>
                <DownloadOptionsList endpoint="/user/generate" />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${userManagementRoute}${usersRoute}`}
                querySearch={(entry: string) =>
                  `?page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "primary.dark",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    User Id
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                        // hide last border
                        // "&:last-child td, &:last-child th": {
                        //   border: 0,
                        // },
                      }}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                      <TableCell>{item.role?.name ?? ""}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>
                        <Box
                          sx={(theme) => ({
                            width: "80px",
                            padding: "3px 8px",
                            borderRadius: "4px",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: ".8rem",
                            fontWeight: 500,
                            backgroundColor:
                              item.status === StatusEnum.enabled
                                ? theme.palette.success.dark
                                : theme.palette.error.dark,
                          })}
                        >
                          {item.status}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiAccountLockOutline}
                            iconColor={theme.palette.info.main}
                            tooltipTitle="Reset Password"
                            onClick={() =>
                              setOpenCreate((prev) => ({
                                ...prev,
                                data: item.id,
                                open: true,
                                isChangePassword: true,
                                subTitle: "Change Password",
                              }))
                            }
                          />
                          <IconButtonMdi
                            mdiIcon={mdiAccountEditOutline}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="Edit User"
                            onClick={() =>
                              setOpenCreate((prev) => ({
                                ...prev,
                                data: item.id,
                                open: true,
                                subTitle: "Edit User",
                              }))
                            }
                          />

                          <IconButtonMdi
                            mdiIcon={
                              item.status === StatusEnum.disabled
                                ? mdiAccountCheckOutline
                                : mdiAccountCancelOutline
                            }
                            iconColor={
                              item.status === StatusEnum.disabled
                                ? theme.palette.success.main
                                : theme.palette.error.main
                            }
                            tooltipTitle={
                              item.status === StatusEnum.disabled
                                ? "Enabel User"
                                : "Disable User"
                            }
                            onClick={() => toggleDisableUser(true, item)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${userManagementRoute}${usersRoute}`}
            querySearch={(page) =>
              `?page=${page}&entries=${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Users;
