import React from "react";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  useFieldArray,
  useWatch,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import IconButtonMdi from "../../common/IconButtonMdi";
import PhoneNumberField from "../../common/textfields/PhoneNumberField";

import { FormType } from "./LeadForm";

type Props = {
  control: Control<FormType>;
  clearErrors: UseFormClearErrors<FormType>;
  setValue: UseFormSetValue<FormType>;
  errors: FieldErrors<FormType>;
};

const LeadFormContacts = ({
  control,
  clearErrors,
  setValue,
  errors,
}: Props) => {
  const theme = useTheme();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contactNumber",
  });

  const contactNumberWatch = useWatch({
    control,
    name: "contactNumber",
  });

  const handleAddRemoveContact = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        value: "",
      });
    }
  };

  return (
    <div>
      <Typography variant="textfieldLabel">Contact Numbers</Typography>
      {fields.map((item, key) => (
        <Stack
          direction="row"
          spacing={1}
          key={key}
          paddingTop={key === 0 ? 1 : 2}
        >
          <IconButtonMdi
            mdiIcon={
              key + 1 === contactNumberWatch.length
                ? mdiPlusCircleOutline
                : mdiMinusCircleOutline
            }
            iconColor={
              key + 1 === contactNumberWatch.length
                ? theme.palette.info.main
                : theme.palette.error.main
            }
            onClick={() =>
              handleAddRemoveContact(
                key + 1 === contactNumberWatch.length ? null : key
              )
            }
          />
          <Box width="100%">
            <PhoneNumberField
              name={`contactNumber.${key}.value`}
              setValue={setValue}
              clearErrors={clearErrors}
              mobileValue={item.value}
              errorMsg={
                errors?.contactNumber
                  ? errors.contactNumber[key]?.value?.message
                  : ""
              }
            />
          </Box>
        </Stack>
      ))}
    </div>
  );
};

export default LeadFormContacts;
