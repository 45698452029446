import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";
import {
  BuyerInformationBodyModel,
  BuyerInformationDtoModel,
  UploadPicDtoModel,
} from "../../../../model/buyerModel";

export const buyerInformationEndpoints = {
  "upload-pic": "/buyer/upload/picture",
  "add-buyer-information": "/buyer/information/add",
  "get-buyer-information": (id: number) => `/buyer/information/get/${id}`,
  "update-buyer-information": (id: number) => `/buyer/information/update/${id}`,
};

export const buyerInformationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadPic: builder.mutation<UploadPicDtoModel, FileList>({
      query: (param) => {
        const formDataBody = new FormData();

        formDataBody.append("picture", param[0]);

        return {
          url: buyerInformationEndpoints["upload-pic"],
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
    addBuyerInfo: builder.mutation<
      {
        success: string;
        data: {
          id: number;
          referenceNumber: string;
        };
        msg: string;
      },
      BuyerInformationBodyModel
    >({
      query: (body) => ({
        url: buyerInformationEndpoints["add-buyer-information"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getBuyerInformation: builder.query<BuyerInformationDtoModel, number>({
      query: (param) => ({
        url: buyerInformationEndpoints["get-buyer-information"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        { type: "buyer-information", id: arg },
      ],
    }),
    updateBuyerInfo: builder.mutation<
      void,
      {
        queryParam: number;
        bodyParam: Partial<BuyerInformationBodyModel>;
      }
    >({
      query: (param) => ({
        url: buyerInformationEndpoints["update-buyer-information"](
          param.queryParam
        ),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        { type: "buyer-information", id: arg.queryParam },
      ],
    }),
  }),
});

export const {
  useUploadPicMutation,
  useAddBuyerInfoMutation,
  useGetBuyerInformationQuery,
  useUpdateBuyerInfoMutation,
} = buyerInformationSlice;
