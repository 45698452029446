import React from "react";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Controller,
  useFieldArray,
  useWatch,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import IconButtonMdi from "../common/IconButtonMdi";
import PhoneNumberField from "../common/textfields/PhoneNumberField";
import { thisFieldRequired } from "../../utils/constants";

import { FormType } from "./CompanyProfile";

interface Props {
  control: Control<FormType>;
  clearErrors: UseFormClearErrors<FormType>;
  setValue: UseFormSetValue<FormType>;
  errors: FieldErrors<FormType>;
}

const ContactPersons = ({ control, clearErrors, setValue, errors }: Props) => {
  const theme = useTheme();

  const {
    fields: contactPersonsFields,
    append: contactPersonsAppend,
    remove: contactPersonsRemove,
  } = useFieldArray({
    control,
    name: "contactPersons",
  });

  const contactPersonsWatch = useWatch({
    name: "contactPersons",
    control,
  });

  const handleAddRemoveContactPerson = (index: number | null) => {
    if (typeof index === "number") {
      contactPersonsRemove(index);
    } else {
      contactPersonsAppend({
        id: null,
        phoneNumber: "",
        name: "",
      });
    }
  };

  const handleNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setValue(`contactPersons.${index}.name`, e.target.value);
    clearErrors(`contactPersons.${index}.name`);
  };

  return (
    <Box>
      <Typography variant="textfieldLabel">Contact Persons</Typography>

      {contactPersonsFields.map((item, key) => (
        <Stack
          direction="row"
          spacing={1}
          key={key}
          paddingTop={key === 0 ? 1 : 2}
        >
          <IconButtonMdi
            mdiIcon={
              key + 1 === contactPersonsWatch.length
                ? mdiPlusCircleOutline
                : mdiMinusCircleOutline
            }
            iconColor={
              key + 1 === contactPersonsWatch.length
                ? theme.palette.info.main
                : theme.palette.error.main
            }
            onClick={() =>
              handleAddRemoveContactPerson(
                key + 1 === contactPersonsWatch.length ? null : key
              )
            }
          />
          <Box width="100%">
            <Controller
              name={`contactPersons.${key}.name`}
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: thisFieldRequired,
              //   },
              // }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={contactPersonsWatch[key]?.name ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleNameInputChange(e, key)
                  }
                  placeholder="Name"
                  error={
                    errors?.contactPersons
                      ? errors.contactPersons[key]?.name?.message
                        ? true
                        : false
                      : false
                  }
                  helperText={
                    errors?.contactPersons &&
                    errors?.contactPersons[key]?.name?.message
                      ? errors.contactPersons[key]?.name?.message
                      : ""
                  }
                  sx={{
                    width: "100%",
                    marginBottom: 1,
                  }}
                />
              )}
            />

            <PhoneNumberField
              name={`contactPersons.${key}.phoneNumber`}
              setValue={setValue}
              clearErrors={clearErrors}
              mobileValue={item.phoneNumber}
              errorMsg={
                errors?.contactPersons &&
                errors.contactPersons[key]?.phoneNumber?.message
                  ? errors.contactPersons[key]?.phoneNumber?.message
                  : ""
              }
            />
          </Box>
        </Stack>
      ))}
    </Box>
  );
};

export default ContactPersons;
