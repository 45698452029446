import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import {
  useAddPriceListMutation,
  useUpdatePriceListMutation,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import IconButtonMdi from "../../common/IconButtonMdi";
import PhoneNumberField from "../../common/textfields/PhoneNumberField";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import useGetChartAccountsConfig from "../../../hooks/chart-accounts/useGetChartAccountsConfig";

interface PriceListFormType {
  id?: number | undefined;
  code: string;
  name: string;
  enable: boolean;
  email: string;
  address: string;
  tin: string;
  contactNumber: {
    id: null | number;
    value: string;
    name: string;
  }[];
  canEditName: number;
  paymentTerm: string;
  shippingMethod: string;
  discount: string;
  paymentMethod: any;
  chartAccount: any;
  subChartAccount: any;
  receivable: boolean;
  remarks: string;
}

type Props = {
  title: string;
  data: PriceListModel | null;
  closeFn: () => void;
};

const SuppliersForm = ({ title, data, closeFn }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const form = useForm<PriceListFormType>({
    defaultValues: {
      id: undefined,
      code: "",
      name: "",
      enable: true,
      email: "",
      address: "",
      tin: "",
      contactNumber: [
        {
          id: null,
          value: "",
          name: "",
        },
      ],
      canEditName: 1,
      paymentTerm: "",
      shippingMethod: "",
      discount: "",
      paymentMethod: null,
      chartAccount: null,
      subChartAccount: null,
      receivable: true,
      remarks: "",
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "contactNumber",
  });

  const [
    getSubAccountTrigger,
    { chartAccountIsLoading, chartAccountList },
    { subAccountIsLoading, subAccountList },
  ] = useGetChartAccountsConfig();

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddPriceListMutation();
  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdatePriceListMutation();

  const saveLoading = addIsLoading || updateIsLoading;

  const handleAddRemoveContact = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        value: "",
        name: "",
      });
    }
  };

  const handleChartAccountChange = (value: any) => {
    const accountNumber = value?.value?.slice(
      value.value.indexOf("-") + 1,
      value.value.length
    );
    getSubAccountTrigger(Number(accountNumber));
    setValue("chartAccount", value);
    setValue("subChartAccount", null);
  };

  const onError = () => {};

  const onSubmit: SubmitHandler<PriceListFormType> = (dataFields) => {
    // const { name, unit_type, perishable, description } = dataFields;

    console.log(dataFields);

    // if (data) {
    //   updateMutation({
    //     queryParams: {
    //       type: serviceType.toUpperCase(),
    //       id: data.id,
    //     },
    //     bodyParams: {
    //       name,
    //       unit_type,
    //       perishable,
    //       description,
    //     },
    //   });
    // } else {
    //   addMutation({
    //     queryParams: serviceType.toUpperCase(),
    //     bodyParams: {
    //       name,
    //       unit_type,
    //       perishable,
    //       description,
    //     },
    //   });
    // }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-rental-services-price-list",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `New category successfully added!`,
      toastId: "add-rental-services-price-list",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: `Category successfully updated!`,
      toastId: "update-rental-services-price-list",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-rental-services-price-list",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        id,
        name,
        regular,
        holiday,
        sp,
        spHoliday,
        inclusions,
        canEditName,
      } = data;

      setValue("id", id);
      setValue("name", name);
      // setValue("regular", regular);
      // setValue("holiday", holiday);
      // setValue("sp", sp);
      // setValue("spHoliday", spHoliday);
      // setValue("inclusions", inclusions);
      // setValue("canEditName", canEditName);
    }
  }, [data]);

  return (
    <Box
      marginBottom={3}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Typography variant="textfieldLabel">Supplier Code:</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <Box>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <div style={{ width: "-webkit-fill-available" }}>
                      <Typography variant="textfieldLabel">
                        Supplier Name
                      </Typography>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            placeholder="Supplier Name"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            disabled={form.watch("canEditName") === 0}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginTop: 26, width: 130 }}>
                      <Controller
                        name="enable"
                        control={control}
                        render={({ field }) => (
                          <Switch checked={field.value} {...field} />
                        )}
                      />
                      <Typography variant="textfieldLabel">Enable</Typography>
                    </div>
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Email Address
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Email Address"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Contact Person
                  </Typography>
                  {fields.map((item, key) => (
                    <Stack
                      direction="row"
                      spacing={1}
                      key={key}
                      paddingTop={key === 0 ? 1 : 2}
                    >
                      <IconButtonMdi
                        mdiIcon={
                          key + 1 === form.watch("contactNumber").length
                            ? mdiPlusCircleOutline
                            : mdiMinusCircleOutline
                        }
                        iconColor={
                          key + 1 === form.watch("contactNumber").length
                            ? theme.palette.info.main
                            : theme.palette.error.main
                        }
                        onClick={() =>
                          handleAddRemoveContact(
                            key + 1 === form.watch("contactNumber").length
                              ? null
                              : key
                          )
                        }
                      />
                      <Box width="100%">
                        <Controller
                          name={`contactNumber.${key}.name`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </Box>
                      <Box width="100%">
                        <PhoneNumberField
                          name={`contactNumber.${key}.value`}
                          setValue={setValue}
                          clearErrors={form.clearErrors}
                          mobileValue={item.value}
                          errorMsg={
                            form.formState?.errors?.contactNumber
                              ? form.formState?.errors.contactNumber[key]?.value
                                  ?.message
                              : ""
                          }
                        />
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">Address</Typography>
                  <Controller
                    name="address"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Address"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        multiline={true}
                        rows={5}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">TIN</Typography>
                  <Controller
                    name="tin"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="TIN"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">
                    Payment Terms
                  </Typography>
                  <Controller
                    name="paymentTerm"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Payment Terms"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Shipping Method
                  </Typography>
                  <Controller
                    name="shippingMethod"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Shipping Method"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">
                    Default Discount
                  </Typography>
                  <Controller
                    name="discount"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Default Discount"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Default Payment Method
                  </Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="paymentMethod"
                    // options={chartAccountList}
                    options={[]}
                    isRequired
                    placeholder="Default Payment Method"
                    // customOnchange={handleChartAccountChange}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">
                    Chart of Account
                  </Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="chartAccount"
                    // options={chartAccountList}
                    options={[]}
                    isRequired
                    placeholder="Chart of Account"
                    // customOnchange={handleChartAccountChange}
                  />
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">
                    Chart of Account (Sub)
                  </Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="subChartAccount"
                    options={subAccountList}
                    isRequired
                    isDisabled={
                      _.isEmpty(form.watch("chartAccount")?.value) ||
                      subAccountIsLoading
                    }
                    placeholder="Chart of Account (Sub)"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="textfieldLabel">Payable</Typography>
                  <Controller
                    name="receivable"
                    control={control}
                    render={({ field }) => (
                      <Switch checked={field.value} {...field} />
                    )}
                  />
                  <Typography variant="textfieldLabel">Receivable</Typography>
                </Box>
                <Box>
                  <Typography variant="textfieldLabel">Remarks</Typography>
                  <Controller
                    name="remarks"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Remarks"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        // multiline={true}
                        // rows={}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default SuppliersForm;
