export const hasSpecialCharacter = (string: string): boolean => {
  const FindCharacter = new RegExp(/[_~`$&+,:;=?@#|'<>.^*(){}%!-]/);
  return FindCharacter.test(string);
};

export const containsNumber = (string: string): boolean => {
  const Given = new RegExp("(?=.*[0-9])");
  return Given.test(string);
};

export const containsUpperCase = (string: string): boolean => {
  const Given = new RegExp("(?=.*[A-Z])");
  return Given.test(string);
};
