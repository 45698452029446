import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import StepperTabMenu, { StepModel } from "../../common/StepperTabMenu";

import {
  inventoryConfigurationRoute,
  inventoryManagementRoute,
} from "../../../routes/routeKeys";
import CardHeader from "../../common/CardHeader";
import Categories from "./Categories";
import AssetMaterial from "./AssetMaterial";
import Locations from "./Locations";
import UnusableAssets from "./UnusableAssets";

export type UnitModel = {
  type: string;
  unitTypeId: number;
  id?: null | number;
  unit: any;
};

interface Props {
  serviceType: string;
}

const InventoryConfiguration = () => {
  // const { state: unitState } = useLocation();
  const { step, id } = useParams();
  const [steps] = useState<StepModel[]>([
    {
      key: "categories",
      label: "Categories",
      withPaging: true,
    },
    {
      key: "assets",
      label: "Assets/Materials",
      withPaging: true,
    },
    {
      key: "locations",
      label: "Locations",
      withPaging: true,
    },
    {
      key: "unusable-assets",
      label: "Unusable Assets",
      withPaging: true,
    },
  ]);
  const form = useForm<any>({
    defaultValues: {
      id: undefined,
      buyerPaymentId: undefined,
      paymentId: undefined,
      servicePaymentTermId: undefined,
      date: new Date(),
      time: new Date(),
      owner: "",
      unit: "",
      encodedBy: "",
      deceasedId: "",
      assistedBy: "",
      coordinatedBy: "",
      price: "",
      rateType: {
        label: "Regular Rate",
        value: "REGULAR",
      },
      paymentType: "",
      paymentTerms: "",
      paymentTypeAmount: "",
      fullPaymentAmount: "",
      downPaymentAmount: "",
      bankName: "",
      chequeNumber: "",
      chequeDate: null,
      serviceDetails: {
        price: "",
        payment: "",
        discounts: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        promo: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        balance: "",
      },
      receipt: "",
    },
  });

  return (
    <Paper elevation={3}>
      {/* <CardHeader title={`${_.capitalize("interment")}`} /> */}
      <Box paddingTop={4} paddingBottom={2}>
        <StepperTabMenu<UnitModel>
          disabled={false}
          steps={steps}
          path={`${inventoryManagementRoute}${inventoryConfigurationRoute}`}
          // state={unitState}
        />
      </Box>
      <Box paddingY={2} paddingX={3}>
        {step === "categories" && (
          <Categories serviceType={"interment"} scheduleForm={form} />
        )}
        {step === "assets" && (
          <AssetMaterial serviceType={"interment"} scheduleForm={form} />
        )}
        {step === "locations" && (
          <Locations route={`${inventoryConfigurationRoute}/locations`} />
        )}
        {step === "unusable-assets" && (
          <UnusableAssets serviceType={"interment"} scheduleForm={form} />
        )}
      </Box>
    </Paper>
  );
};

export default InventoryConfiguration;
