import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "@loadable/component";
import { ToastContainer } from "react-toastify";
import { getFromStorage } from "./utils/helpers/storageHelper";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import PrivateRoute from "./routes/PrivateRoute";
import {
  profileRoute,
  fiveOORoute,
  fourOFourRoute,
  fourOOneRoute,
  loginRoute,
  rolesRoute,
  userManagementRoute,
  usersRoute,
  salesPersonelRoute,
  systemConfigRoute,
  generalRoute,
  chartAccountsRoute,
  paymentsTypesRoute,
  expensesTypesRoute,
  discountsPromosRoute,
  commissionSchemeRoute,
  lotVaultCryptRoute,
  lawnLotRoute,
  cryptRoute,
  vaultRoute,
  buyersRoute,
  clientManagementRoute,
  intermentRoute,
  rentalAndServicesRoute,
  leadsRoute,
  disintermentRoute,
  inurnmentRoute,
  exhumationRoute,
  cremationRoute,
  accountManagementRoute,
  officialReceiptRoute,
  paymentRoute,
  otherPaymentRoute,
  inventoryManagementRoute,
  inventoryConfigurationRoute,
  expensesRoute,
  assetLocationRoute,
  suppliersRoute,
  trackingRoute,
  checkVoucherRoute,
  cashVoucherRoute,
  acknowledgementReceiptRoute,
  provisionalReceiptRoute,
  priceListConfigRoute,
} from "./routes/routeKeys";
import { MainMenuModel } from "./model/menuModel";
import { gg_menu_key } from "./utils/constants";
import {
  replaceAllSpaceWithHyphen,
  replaceAllSlashWithHyphen,
} from "./utils/helpers/stringManipulate";

import Login from "./components/auth/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/profile/Profile";
import Users from "./components/user-management/users/Users";
import Roles from "./components/user-management/role/Roles";
import FourOOne from "./routes/FourOOne";
import FourOFour from "./routes/FourOFour";
import FiveOO from "./routes/FiveOO";
import ComingSoon from "./components/common/ComingSoon";
import SalesPersonel from "./components/user-management/sales-personel/SalesPersonel";

import General from "./components/system-config/general/General";
import ChartAccounts from "./components/system-config/chart-of-accounts/ChartAccounts";
import PaymentsTypes from "./components/system-config/payments-charts/PaymentsCharts";
import ExpensesTypes from "./components/system-config/expenses-types/ExpensesTypes";
import DiscountPromos from "./components/system-config/discount-promos/DiscountPromos";
import CommissionScheme from "./components/system-config/commission-scheme/CommissionScheme";

import LawnLot from "./components/lot-vault-crypt-management/lot/LawnLot";
import Crypt from "./components/lot-vault-crypt-management/crypt/Crypt";
import Vault from "./components/lot-vault-crypt-management/vault/Vault";

import Buyers from "./components/client-management/buyers/Buyers";
import ActiveBuyerStepper from "./components/client-management/buyers/active-buyer-form/ActiveBuyerStepper";
import Leads from "./components/client-management/leads/Leads";

import RentalAndServices from "./components/rental-and-services/RentalAndServices";
import OfficialReceipt from "./components/account-management/official-receipt/OfficialReceipt";
import Payment from "./components/account-management/payment/Payment";
import OtherPayment from "./components/account-management/other-payment/OtherPayment";
import InventoryConfiguration from "./components/inventory-management/inventory-configuration/InventoryConfiguration";
import AssetLocation from "./components/inventory-management/asset-location/AssetLocation";
import Expenses from "./components/account-management/expenses/Expenses";
import Suppliers from "./components/inventory-management/suppliers/Suppliers";
import CheckVoucher from "./components/account-management/voucher/CheckVoucher";
import CashVoucher from "./components/account-management/voucher/CashVoucher";
import AcknowledgementReceipt from "./components/account-management/acknowledgement-receipt/AcknowledgementReceipt";
import ProvisionalReceipt from "./components/account-management/provisional-receipt/ProvisionalReceipt";
import Tracking from "./components/inventory-management/tracking/Tracking";
import PriceListConfig from "./components/system-config/price-list/PriceListConfig";

function menuComponents(name: string): React.ReactNode {
  const menuName: string = replaceAllSlashWithHyphen(
    replaceAllSpaceWithHyphen(name)
  )
    .replace(".", "")
    .toLowerCase();
  switch (`/${menuName}`) {
    case profileRoute:
      return <Profile />;
    case usersRoute:
      return <Users />;
    case rolesRoute:
      return <Roles />;
    case salesPersonelRoute:
      return <SalesPersonel />;
    case generalRoute:
      return <General />;
    case chartAccountsRoute:
      return <ChartAccounts />;
    case paymentsTypesRoute:
      return <PaymentsTypes />;
    case expensesTypesRoute:
      return <ExpensesTypes />;
    case discountsPromosRoute:
      return <DiscountPromos />;
    case commissionSchemeRoute:
      return <CommissionScheme />;
    case lawnLotRoute:
      return <LawnLot />;
    case cryptRoute:
      return <Crypt />;
    case vaultRoute:
      return <Vault />;
    case buyersRoute:
      return <Buyers />;
    case leadsRoute:
      return <Leads />;
    case intermentRoute:
      return (
        <Navigate to={`${rentalAndServicesRoute}${intermentRoute}/calendar`} />
      );
    case disintermentRoute:
      return (
        <Navigate
          to={`${rentalAndServicesRoute}${disintermentRoute}/calendar`}
        />
      );
    case inurnmentRoute:
      return (
        <Navigate to={`${rentalAndServicesRoute}${inurnmentRoute}/calendar`} />
      );
    case exhumationRoute:
      return (
        <Navigate to={`${rentalAndServicesRoute}${exhumationRoute}/calendar`} />
      );
    case cremationRoute:
      return (
        <Navigate to={`${rentalAndServicesRoute}${cremationRoute}/calendar`} />
      );
    case officialReceiptRoute:
      return <OfficialReceipt />;
    case paymentRoute:
      return <Payment />;
    case otherPaymentRoute:
      return <OtherPayment />;
    case inventoryConfigurationRoute:
      return (
        <Navigate
          to={`${inventoryManagementRoute}${inventoryConfigurationRoute}/categories`}
        />
      );
    case assetLocationRoute:
      return <AssetLocation />;
    case suppliersRoute:
      return <Suppliers />;
    case trackingRoute:
      return <Tracking />;
    case expensesRoute:
      return <Expenses />;
    case checkVoucherRoute:
      return <CheckVoucher />;
    case cashVoucherRoute:
      return <CashVoucher />;
    case acknowledgementReceiptRoute:
      return <AcknowledgementReceipt />;
    case provisionalReceiptRoute:
      return <ProvisionalReceipt />;
    case priceListConfigRoute:
      return <PriceListConfig />;
    case "/dashboard":
      return <Dashboard />;
    default:
      return <ComingSoon />;
  }
}

function menuPath(name: string): string {
  const menuName: string = replaceAllSlashWithHyphen(
    replaceAllSpaceWithHyphen(name)
  )
    .replace(".", "")
    .toLowerCase();
  switch (`/${menuName}`) {
    case profileRoute:
      return profileRoute;
    case userManagementRoute:
      return userManagementRoute;
    case systemConfigRoute:
      return systemConfigRoute;
    case lotVaultCryptRoute:
      return lotVaultCryptRoute;
    case clientManagementRoute:
      return clientManagementRoute;
    case rentalAndServicesRoute:
      return rentalAndServicesRoute;
    case accountManagementRoute:
      return accountManagementRoute;
    case inventoryManagementRoute:
      return inventoryManagementRoute;
    case "/dashboard":
      return "/";
    default:
      // return "/";
      return `/${menuName}`;
  }
}

function subMenuPath(nameMenu: string, nameSubMenu: string): string {
  const subMenuName: string =
    replaceAllSpaceWithHyphen(nameSubMenu).toLowerCase();
  const menuName: string = replaceAllSlashWithHyphen(
    replaceAllSpaceWithHyphen(nameMenu)
  )
    .replace(".", "")
    .toLowerCase();

  switch (`/${subMenuName}`) {
    case usersRoute:
      return `${userManagementRoute}${usersRoute}`;
    case generalRoute:
      return `${systemConfigRoute}${generalRoute}`;
    case chartAccountsRoute:
      return `${systemConfigRoute}${chartAccountsRoute}`;
    case paymentsTypesRoute:
      return `${systemConfigRoute}${paymentsTypesRoute}`;
    case expensesTypesRoute:
      return `${systemConfigRoute}${expensesTypesRoute}`;
    case discountsPromosRoute:
      return `${systemConfigRoute}${discountsPromosRoute}`;
    case commissionSchemeRoute:
      return `${systemConfigRoute}${commissionSchemeRoute}`;
    case lawnLotRoute:
      return `${lotVaultCryptRoute}${lawnLotRoute}`;
    case cryptRoute:
      return `${lotVaultCryptRoute}${cryptRoute}`;
    case vaultRoute:
      return `${lotVaultCryptRoute}${vaultRoute}`;
    case buyersRoute:
      return `${clientManagementRoute}${buyersRoute}`;
    case leadsRoute:
      return `${clientManagementRoute}${leadsRoute}`;
    case intermentRoute:
      return `${rentalAndServicesRoute}${intermentRoute}`;
    case officialReceiptRoute:
      return `${accountManagementRoute}${officialReceiptRoute}`;
    case paymentRoute:
      return `${accountManagementRoute}${paymentRoute}`;
    case otherPaymentRoute:
      return `${accountManagementRoute}${otherPaymentRoute}`;
    case inventoryConfigurationRoute:
      return `${inventoryManagementRoute}${inventoryConfigurationRoute}`;
    case assetLocationRoute:
      return `${inventoryManagementRoute}${assetLocationRoute}`;
    case suppliersRoute:
      return `${inventoryManagementRoute}${suppliersRoute}`;
    case trackingRoute:
      return `${inventoryManagementRoute}${trackingRoute}`;
    case expensesRoute:
      return `${accountManagementRoute}${expensesRoute}`;
    case checkVoucherRoute:
      return `${accountManagementRoute}${checkVoucherRoute}`;
    case cashVoucherRoute:
      return `${accountManagementRoute}${cashVoucherRoute}`;
    case acknowledgementReceiptRoute:
      return `${accountManagementRoute}${acknowledgementReceiptRoute}`;
    case provisionalReceiptRoute:
      return `${accountManagementRoute}${provisionalReceiptRoute}`;
    case priceListConfigRoute:
      return `${systemConfigRoute}${priceListConfigRoute}`;
    default:
      return `/${menuName}/${subMenuName}`;
  }
}

function App() {
  const menu: MainMenuModel[] | null =
    getFromStorage<MainMenuModel[]>(gg_menu_key);

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path={loginRoute} element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          {/* <Route path="/" element={<Dashboard />} /> */}
          {menu &&
            menu.map((item) => {
              if (item.subMenu.length > 0) {
                return (
                  <Route path={menuPath(item.name)} key={item.name}>
                    {item.subMenu.map((item2) => {
                      return (
                        <Route
                          path={subMenuPath(item.name, item2.name)}
                          element={menuComponents(item2.name)}
                          key={item2.name}
                        />
                      );
                    })}
                  </Route>
                );
              }

              return (
                <Route
                  path={menuPath(item.name)}
                  element={menuComponents(item.name)}
                  key={item.name}
                />
              );
            })}
          <Route
            path={`${clientManagementRoute}${buyersRoute}/add/:step`}
            element={<ActiveBuyerStepper />}
          />
          <Route
            path={`${accountManagementRoute}${otherPaymentRoute}`}
            element={<OtherPayment />}
          />
          <Route
            path={`${clientManagementRoute}${buyersRoute}/edit/:id/:step`}
            element={<ActiveBuyerStepper />}
          />
          <Route
            path={`${rentalAndServicesRoute}${intermentRoute}/:step`}
            element={<RentalAndServices serviceType="INTERMENT" />}
          />
          <Route
            path={`${rentalAndServicesRoute}${disintermentRoute}/:step`}
            element={<RentalAndServices serviceType="DISINTERMENT" />}
          />
          <Route
            path={`${rentalAndServicesRoute}${inurnmentRoute}/:step`}
            element={<RentalAndServices serviceType="INURNMENT" />}
          />
          <Route
            path={`${rentalAndServicesRoute}${exhumationRoute}/:step`}
            element={<RentalAndServices serviceType="EXHUMATION" />}
          />
          <Route
            path={`${rentalAndServicesRoute}${cremationRoute}/:step`}
            element={<RentalAndServices serviceType="CREMATION" />}
          />
          <Route
            path={`${inventoryManagementRoute}${inventoryConfigurationRoute}/:step`}
            element={<InventoryConfiguration />}
          />
        </Route>

        <Route path={fourOOneRoute} element={<FourOOne />} />
        <Route path={fourOFourRoute} element={<FourOFour />} />
        <Route path={fiveOORoute} element={<FiveOO />} />
      </Routes>
    </div>
  );
}

export default App;
