import { ListFetchQueryType } from "../../../model/global";
import {
  PaymentTypesBodyModel,
  PaymentTypesConfigDtoModel,
  PaymentTypesDtoModel,
} from "../../../model/paymentsTypesModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const paymentsTypesEndpoints = {
  "add-payment": "/configuration/payment/add",
  "payments-list": (page: number, entries: number, search: string) =>
    `/configuration/payment/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "delete-payment": (id: number): string =>
    `/configuration/payment/delete/${id}`,
  "update-payment": (id: number) => `/configuration/payment/update/${id}`,
  "payments-list-config": () => "/configuration/payment/list",
};

export const paymentsTypesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentsList: builder.query<PaymentTypesDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: paymentsTypesEndpoints["payments-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["payments-types-list"],
    }),
    getPaymentsListConfig: builder.query<PaymentTypesConfigDtoModel, void>({
      query: () => ({
        url: paymentsTypesEndpoints["payments-list-config"](),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["payments-types-list-config"],
    }),
    addPayment: builder.mutation<void, PaymentTypesBodyModel>({
      query: (body) => ({
        url: paymentsTypesEndpoints["add-payment"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["payments-types-list", "payments-types-list-config"],
    }),
    updatePayment: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: PaymentTypesBodyModel;
      }
    >({
      query: (param) => ({
        url: paymentsTypesEndpoints["update-payment"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["payments-types-list", "payments-types-list-config"],
    }),
    deletePayment: builder.mutation<void, number>({
      query: (param) => ({
        url: paymentsTypesEndpoints["delete-payment"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["payments-types-list", "payments-types-list-config"],
    }),
  }),
});

export const {
  useAddPaymentMutation,
  useGetPaymentsListQuery,
  useDeletePaymentMutation,
  useUpdatePaymentMutation,
  useLazyGetPaymentsListConfigQuery,
  useGetPaymentsListConfigQuery,
} = paymentsTypesSlice;
