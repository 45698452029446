import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { saveAs } from "file-saver";
import {
  mdiDeleteOutline,
  mdiCloudDownloadOutline,
  mdiCardSearchOutline,
} from "@mdi/js";
import { domainLink } from "../../../utils/constants";

import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import {
  useGetListQuery,
  useDeletePriceMutation,
} from "../../../service/slice/system-configuration/priceListSlice";
import { priceListType } from "../../../utils/enums";
import PriceListUpload from "./PriceListUpload";
import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import ConfirmModal from "../../common/ConfirmModal";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../common/FileViewModal";

import useTableParameters from "../../../hooks/useTableParameters";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import {
  systemConfigRoute,
  priceListConfigRoute,
} from "../../../routes/routeKeys";
import { PriceListGetModel } from "../../../model/priceListModel";

const unitType = [
  priceListType.lot,
  priceListType.columbarium,
  priceListType.crypt,
  priceListType.other,
];

const addFormTitle = ["Lot", "Columbarium", "Crypt", "Other Services"];

const headerLabels: string[] = ["Date Uploaded", "Uploaded By", "Filename"];

type Props = {
  tabIndex: number;
};

const PriceListComp = ({ tabIndex }: Props) => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const [openCreate, handleToggleCreate] = useOpenCreateForm<undefined>();
  const [deleteItem, handleToggleDelete] =
    useDeleteTableItem<PriceListGetModel>();
  const [viewDoc, handleToggleViewDoc] = useFileView();
  const theme = useTheme();
  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePriceMutation();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetListQuery({
    type: unitType[tabIndex],
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const count = listData?.data.count ?? 0;

  const handleDownload = (file: string) =>
    saveAs(file.includes(domainLink) ? file : `${domainLink}${file}`);

  const confirmDelete = () => {
    deleteMutate({
      id: deleteItem.data?.id as number,
      type: unitType[tabIndex],
    });
  };

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "price-list",
    });
  }

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.fileName} successfully deleted!`,
      toastId: "delete-file",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-file",
    });

    deleteReset();
  }

  return (
    <>
      {viewDoc.open && (
        <FileViewModal
          open={viewDoc.open}
          path={viewDoc.path}
          title={
            viewDoc?.filename
              ? viewDoc.filename
              : viewDoc.path.substring(
                  viewDoc.path.lastIndexOf("/") + 1,
                  viewDoc.path.length
                )
          }
          onClose={() => handleToggleViewDoc(false, "", "")}
        />
      )}
      {deleteItem.open && (
        <ConfirmModal
          isLoading={deleteIsLoading}
          open={deleteItem.open}
          text={`Are you sure to delete ${deleteItem.data?.fileName}?`}
          closeFn={() => handleToggleDelete(false, null)}
          confirmFn={confirmDelete}
        />
      )}
      {openCreate.open && (
        <PriceListUpload
          title={openCreate.title}
          priceUnitType={unitType[tabIndex]}
          closeFn={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${systemConfigRoute}${priceListConfigRoute}`}
              querySearch={(value: string) =>
                `?tab=${
                  tabIndex + 1
                }&page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
            <Button
              variant="button-secondary"
              sx={{
                width:
                  tabIndex === 0 ? "180px" : tabIndex === 2 ? "200px" : "280px",
              }}
              onClick={() =>
                handleToggleCreate(
                  true,
                  undefined,
                  `New ${addFormTitle[tabIndex]} Price File`
                )
              }
            >{`ADD ${addFormTitle[tabIndex].toUpperCase()} PRICE`}</Button>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${systemConfigRoute}${priceListConfigRoute}`}
            querySearch={(entry: string) =>
              `?tab=${
                tabIndex + 1
              }&page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.uploadedAt}</TableCell>
                  <TableCell>
                    {item.uploadedByUser
                      ? `${item.uploadedByUser.firstName} ${item.uploadedByUser.lastName}`
                      : ""}
                  </TableCell>
                  <TableCell>{item.fileName}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiCloudDownloadOutline}
                        iconColor={theme.palette.success.main}
                        tooltipTitle="Download File"
                        onClick={() => handleDownload(item.path)}
                      />
                      <IconButtonMdi
                        mdiIcon={mdiCardSearchOutline}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="View File"
                        onClick={() =>
                          handleToggleViewDoc(true, item.path, item.fileName)
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiDeleteOutline}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delete File"
                        onClick={() => handleToggleDelete(true, item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${systemConfigRoute}${priceListConfigRoute}`}
        querySearch={(page) =>
          `?tab=${
            tabIndex + 1
          }&page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={count}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default PriceListComp;
