import {
  BuyerIdentificationBodyModel,
  BuyerIndentificationDtoModel,
} from "../../../../model/buyerModel";
import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const identificationEndpoints = {
  "upload-identification": "/buyer/upload/identification",
  "add-identification": "/buyer/identification/add",
  "identification-list": (
    id: number,
    page: number,
    entries: number,
    search: string
  ) =>
    `/buyer/identification/list/${id}?perPage=${entries}&currentPage=${page}&search=${search}`,
  "delete-identification": (id: number) => `/buyer/identification/delete/${id}`,
};

interface IdentificationListQuery extends ListFetchQueryType {
  id: number;
}

export const buyerIdentificationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addIdentification: builder.mutation<void, BuyerIdentificationBodyModel>({
      query: (body) => ({
        url: identificationEndpoints["add-identification"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["buyer-identification-list"],
    }),
    getIdentificationList: builder.query<
      BuyerIndentificationDtoModel,
      IdentificationListQuery
    >({
      query: (param) => ({
        url: identificationEndpoints["identification-list"](
          param.id,
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["buyer-identification-list"],
    }),
    deleteIdentification: builder.mutation<void, number>({
      query: (param) => ({
        url: identificationEndpoints["delete-identification"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["buyer-identification-list"],
    }),
  }),
});

export const {
  useAddIdentificationMutation,
  useGetIdentificationListQuery,
  useDeleteIdentificationMutation,
} = buyerIdentificationSlice;
