import React, { useEffect } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import {
  useAddCryptColumnMutation,
  useGetCryptLevelConfigListQuery,
  useUpdateCryptColumnMutation,
} from "../../../service/slice/lot-vault-crypt/cryptSlice";
import { CryptColumnModel } from "../../../model/cryptModel";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";

type ColumnForm = {
  name: string;
  description: string;
  level: {
    label: string;
    value: number | string;
  };
};

type Props = {
  title: string;
  data: CryptColumnModel | null;
  closeFn: () => void;
};
const CryptColumnForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<ColumnForm>({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const levelWatch = useWatch({
    control,
    name: "level",
  });

  const {
    data: levelList,
    isLoading: levelIsLoading,
    error: levelError,
  } = useGetCryptLevelConfigListQuery();

  const [
    addMutation,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddCryptColumnMutation();
  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateCryptColumnMutation();

  const onError = () => {
    if (!_.isNumber(levelWatch.value)) {
      setError("level", {
        type: "required",
        message: thisFieldRequired,
      });
    }
  };

  const onSubmit: SubmitHandler<ColumnForm> = (dataFields) => {
    if (!_.isNumber(levelWatch.value)) {
      setError("level", {
        type: "required",
        message: thisFieldRequired,
      });

      return;
    }

    if (data) {
      updateMutations({
        queryParams: data.id,
        bodyParams: {
          name: dataFields.name,
          description: dataFields.description,
          levelId: dataFields.level.value as number,
        },
      });
    } else {
      addMutation({
        name: dataFields.name,
        description: dataFields.description,
        levelId: dataFields.level.value as number,
      });
    }
  };

  const saveLoading = addisLoading || updateIsLoading;

  const levelConfigOptios = levelList
    ? levelList.data.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  if (levelError) {
    errorDisplayOrNavigate({
      error: levelError,
      toastId: "level-list-config",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-crypt-column",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Crypt Column created!",
      toastId: "add-crypt-column",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Crypt Column updated!",
      toastId: "update-crypt-column",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-crypt-column",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("description", data.description as string);
      setValue("level", {
        label: data.cryptLevel.name,
        value: data.cryptLevel.id,
      });
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant="textfieldLabel">Column Name</Typography>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Column Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="textfieldLabel">Level</Typography>
              <CustomReactSelectJs
                control={control}
                name="level"
                options={levelConfigOptios}
                isRequired
                placeholder="Level"
                isDisabled={levelIsLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="textfieldLabel">Description</Typography>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Description"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default CryptColumnForm;
