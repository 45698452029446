import { useState } from "react";
import _ from "lodash";
import { useDebounce } from "use-debounce";

import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { mdiMapSearch } from "@mdi/js";

import useTableParameters from "../../../hooks/useTableParameters";
import {
  inventoryManagementRoute,
  trackingRoute,
} from "../../../routes/routeKeys";
import TableSearchField from "../../common/textfields/TableSearchField";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import { ItemsProps } from "../../common/CustomTabMenu";

const headerLabels: string[] = [
  "SKU",
  "Asset Name",
  "Category",
  "Current Location",
];

const headerLabelsView: string[] = ["Date Moved", "Location", "Date Returned"];

const tabItems: ItemsProps[] = [
  {
    label: "Dispatch",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Tracker",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const Tracker = () => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const theme = useTheme();
  const [openView, handleToggleView] = useOpenCreateForm<PriceListModel>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [debounceSearch] = useDebounce(searchValue, 500);

  // const {
  //   data: listData,
  //   isLoading: listIsLoading,
  //   error: listError,
  //   isFetching: listIsFetching,
  // } = useGetPriceListQuery({
  //   type: serviceType.toUpperCase(),
  //   entries: entriesParam,
  //   page: pageParam - 1,
  //   search: searchParam ?? "",
  // });

  const listData: any = { data: { rows: [] } };
  const listIsLoading = false;
  const listError = false;
  const listIsFetching = false;

  const loading = listIsLoading || listIsFetching;
  // const list = listData?.data?.rows ?? [];
  const list = [{}];
  const totalData = listData?.data?.count ?? 0;

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "rental-services-price-list",
    });
  }

  return (
    <>
      <Box>
        <Grid container alignItems="center" marginBottom={2}>
          <Grid item xs={12} lg={8}>
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${inventoryManagementRoute}${trackingRoute}`}
              querySearch={(value: string) =>
                `?tab=2&page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <RowsPerPageComp
              entries={entriesParam}
              pathname={`${inventoryManagementRoute}${trackingRoute}`}
              querySearch={(entry: string) =>
                `?tab=2&page=${pageParam}&entries=${entry}&search=${searchParam}`
              }
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headerLabels.map((item, key) => (
                  <TableCell key={key} variant="head">
                    {item}
                  </TableCell>
                ))}
                <TableCell
                  variant="head"
                  sx={{
                    position: "sticky",
                    right: 0,
                    zIndex: 800,
                    width: "auto",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress
                      color="primary"
                      style={{ margin: "20px 0" }}
                    />
                  </TableCell>
                </TableRow>
              ) : list && list.length > 0 ? (
                list.map((item: any, key: any) => (
                  <TableRow
                    key={key}
                    sx={{
                      "&:nth-of-type(even)": {
                        backgroundColor: "grey.100",

                        td: {
                          backgroundColor: "grey.100",
                        },
                      },
                      "&:nth-of-type(odd) td": {
                        backgroundColor: "common.white",
                      },
                    }}
                  >
                    <TableCell>0000001</TableCell>
                    <TableCell>{item.regular}</TableCell>
                    <TableCell>{item.holiday}</TableCell>
                    <TableCell>{item.sp}</TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        right: 0,
                        zIndex: 800,
                        width: "auto",
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <IconButtonMdi
                          mdiIcon={mdiMapSearch}
                          iconColor={theme.palette.primary.main}
                          tooltipTitle="View"
                          onClick={() =>
                            handleToggleView(true, item, `Update Supplier`)
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    NO DATA FOUND
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          pathname={`${inventoryManagementRoute}${trackingRoute}`}
          querySearch={(page) =>
            `?tab=2&page=${page}&entries=${entriesParam}&search=${searchParam}`
          }
          totalCount={totalData}
          currentPage={pageParam}
          pageSize={entriesParam}
        />
        {openView.open && (
          <>
            <Typography variant="textfieldLabel" mt={6}>
              Tracking Location: SKU 0000001
            </Typography>
            <div>
              <Typography variant="textfieldLabel" mb={2}>
                Asset Name:
              </Typography>
            </div>
            <Grid container alignItems="center" marginBottom={2}>
              <Grid item xs={12} lg={8}>
                <TableSearchField
                  searchValue={debounceSearch ?? ""}
                  pathname={``}
                  querySearch={(value: string) => ""}
                  handleSearchSetState={(e) => setSearchValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <RowsPerPageComp
                  entries={entries}
                  pathname=""
                  querySearch={(entry) => ""}
                  changeEntry={(e) => setEntries(Number(e.target.value))}
                />
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headerLabelsView.map((item, key) => (
                      <TableCell key={key} variant="head">
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <CircularProgress
                          color="primary"
                          style={{ margin: "20px 0" }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : list && list.length > 0 ? (
                    list.map((item: any, key: any) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:nth-of-type(even)": {
                            backgroundColor: "grey.100",

                            td: {
                              backgroundColor: "grey.100",
                            },
                          },
                          "&:nth-of-type(odd) td": {
                            backgroundColor: "common.white",
                          },
                        }}
                      >
                        <TableCell>01/01/2024</TableCell>
                        <TableCell>{item.regular}</TableCell>
                        <TableCell>{item.holiday}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        NO DATA FOUND
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination
              pathname=""
              querySearch={(page) => ""}
              totalCount={totalData ?? 0}
              currentPage={page}
              pageSize={entries}
              nextPageSetState={() => setPage((state) => state + 1)}
              prevPageSetState={() => setPage((state) => state - 1)}
              selectPageSetState={(page) => setPage(page)}
            />
            <Stack width="100%" alignItems="center" mt={2}>
              <Button
                variant="button-secondary"
                onClick={() => handleToggleView(false, null, "")}
                sx={{ width: 150 }}
              >
                Close
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default Tracker;
