import React from "react";
import _ from "lodash";
import { useParams, useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { useGetBuyerInformationQuery } from "../../../../../service/slice/client-management/buyer/buyerInformationSlice";
import CustomReactSelectJs from "../../../../common/textfields/CustomReactSelectJs";
import { linkWithDomain } from "../../../../../utils/helpers/stringManipulate";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import CustomFileUploader from "../../../../common/textfields/CustomFileUploader";
import { StepModel, StepperActionCta } from "../../../../common/StepperTabMenu";
import { LoaderWithText } from "../../../../common/Loaders";
import { identificationEndpoints } from "../../../../../service/slice/client-management/buyer/identificationSlice";
import IdentificationList from "./IdentificationList";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../../routes/routeKeys";
import {
  identificationOpt,
  thisFieldRequired,
} from "../../../../../utils/constants";

import { useAddIdentificationMutation } from "../../../../../service/slice/client-management/buyer/identificationSlice";
import { UnitModel } from "../unit-type/UnitType";
import { StepperGenericModel } from "../../Buyers";

export interface IdentificationForm {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
  typeDocument: {
    label: string | null;
    value: string | null;
  };
  number: string;
}

type Props = {
  steps: StepModel[];
};

const Identification = ({ steps }: Props) => {
  const { id } = useParams();
  const { state: locState } = useLocation();
  const buyerId = id ? Number(id) : null;

  const form = useForm<IdentificationForm>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
      typeDocument: {
        label: null,
        value: null,
      },
      number: "",
    },
  });

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddIdentificationMutation();

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    reset: fieldReset,
  } = form;

  const documentWatch = useWatch({
    control,
    name: "document",
  });

  const typeWatch = useWatch({
    control,
    name: "typeDocument",
  });

  const loading = buyerInfoIsLoading;
  const saveLoading = addIsLoading;

  const onAddIdentification: SubmitHandler<IdentificationForm> = (data) =>
    addMutate({
      buyerId: buyerId as number,
      format: data.document[0].format,
      name: data.typeDocument.value as string,
      path: data.document[0].path,
      number: data.number,
    });

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Identification added!",
      toastId: "identification-add",
    });

    addReset();
    fieldReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "identification-add",
    });

    addReset();
  }

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting identifications" />
    </Box>
  ) : (
    <>
      <Box marginBottom={4}>
        {buyerId ? (
          <Alert severity="info" icon={false}>
            <Typography variant="body1" marginBottom={1}>
              <Typography component="span" marginRight={1} variant="textSm">
                Buyer:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {`${buyerInfoData?.data.firstName} ${buyerInfoData?.data.lastName}`}
              </Typography>
            </Typography>
            <Typography variant="body1">
              <Typography component="span" marginRight={1} variant="textSm">
                Ref #:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {buyerInfoData?.data.referenceNumber}
              </Typography>
            </Typography>
          </Alert>
        ) : (
          <Alert severity="warning">
            Please add a buyer information or select a buyer to fill up this
            form.
          </Alert>
        )}
      </Box>
      <FormProvider {...form}>
        <Grid container columnSpacing={6} rowSpacing={2}>
          <Grid item xs={12} lg={4} height="100%">
            <Typography variant="textfieldLabel">
              Identification File
            </Typography>
            <CustomFileUploader
              name="document"
              setValue={setValue}
              clearErrors={clearErrors}
              errorMsg={errors.document?.message}
              endpoint={identificationEndpoints["upload-identification"]}
              getValues={getValues}
              isMultiple={false}
              isDisabled={buyerId ? false : true}
            />
            <Box marginTop={2}>
              <Typography variant="textfieldLabel">
                Identification Type
              </Typography>
              <CustomReactSelectJs
                control={control}
                options={identificationOpt}
                name="typeDocument"
                placeholder="Identification type"
                isDisabled={buyerId ? false : true}
              />
            </Box>
            <Box marginTop={2}>
              <Typography variant="textfieldLabel">
                Identification Number
              </Typography>
              <Controller
                name="number"
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Identification Number"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Box>
            <Button
              variant="button-primary"
              sx={{
                width: 150,
                marginTop: 2,
              }}
              disabled={
                _.isEmpty(documentWatch[0].path) ||
                _.isEmpty(typeWatch.value) ||
                buyerId
                  ? false
                  : true
              }
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={
                saveLoading ? () => {} : handleSubmit(onAddIdentification)
              }
            >
              Upload
            </Button>
            {!_.isEmpty(documentWatch[0].path) && (
              <>
                <Box marginTop={2} />
                <DocViewer
                  documents={[
                    {
                      uri: linkWithDomain(documentWatch[0].path),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box
              sx={{
                height: "25px",
              }}
            />
            <IdentificationList />
          </Grid>
        </Grid>
        <Divider
          sx={(theme) => ({
            marginY: theme.spacing(3),
          })}
        />
        <StepperActionCta<StepperGenericModel>
          disabled={false}
          path={
            buyerId
              ? `${clientManagementRoute}${buyersRoute}/edit/${buyerId}`
              : `${clientManagementRoute}${buyersRoute}/add`
          }
          steps={steps}
          state={locState}
        />
      </FormProvider>
    </>
  );
};

export default Identification;
