import { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { mdiAccountEditOutline, mdiDeleteOutline } from "@mdi/js";

import CardHeader from "../../common/CardHeader";

import {
  useGetRoleListQuery,
  useDeleteRoleMutation,
} from "../../../service/slice/usersSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import TableSearchField from "../../common/textfields/TableSearchField";
import { rolesRoute, userManagementRoute } from "../../../routes/routeKeys";
import { addRole, roleInformation } from "../../../utils/constants";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import CustomPagination from "../../common/CustomPagination";
import IconButtonMdi from "../../common/IconButtonMdi";
import RoleInformation from "./RoleInformation";
import ConfirmModal from "../../common/ConfirmModal";
import { RoleModel } from "../../../model/usersModel";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";

const Roles = () => {
  const location = useLocation();
  const theme = useTheme();
  const [deleteRole, handleToggleDeleteModal] = useDeleteTableItem<RoleModel>();
  const [createRole, handleToggleCreate] = useOpenCreateForm<number>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const [
    deleteRoleMutate,
    {
      error: deleteRoleError,
      isLoading: deleteRoleIsLoading,
      reset: deleteRoleReset,
      isSuccess: deleteRoleIsSuccess,
    },
  ] = useDeleteRoleMutation();

  const {
    data: roleList,
    error: roleListError,
    isLoading: roleListIsLoading,
    isFetching: roleListIsFetching,
    refetch: roleListRefetch,
  } = useGetRoleListQuery({
    entries: entriesParam,
    page: _.isEmpty(searchParam) ? pageParam - 1 : 0,
    search: searchParam ?? "",
  });

  const error = roleListError;
  const isLoading = roleListIsLoading || roleListIsFetching;
  const list = roleList?.data.rows ?? [];
  const totalData = roleList?.data.count ?? 0;
  // const list = roleList?.data ?? [];
  // const totalData = roleList?.data?.length ?? 0;

  const handleConfirmDelete = () => {
    deleteRoleMutate(deleteRole.data?.id as number);
  };

  if (deleteRoleIsSuccess) {
    showToastSuccess({
      text: `${deleteRole.data?.name} successfully deleted.`,
      toastId: "delete-role",
    });

    deleteRoleReset();
    handleToggleDeleteModal(false, null);
  }

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "role-list",
    });
  }

  if (deleteRoleError) {
    errorDisplayOrNavigate({
      error: deleteRoleError,
      toastId: "delete-role",
    });

    deleteRoleReset();
  }

  useEffect(() => {
    roleListRefetch();
  }, [pageParam, entriesParam, searchParam]);

  return (
    <>
      <ConfirmModal
        isLoading={deleteRoleIsLoading}
        open={deleteRole.open}
        text={`Are you sure to delete ${deleteRole.data?.name}?`}
        closeFn={() => handleToggleDeleteModal(false, null)}
        confirmFn={handleConfirmDelete}
      />
      {createRole.open && (
        <RoleInformation
          title={`${roleInformation}${
            createRole.open ? ` - ${createRole.title}` : ""
          }`}
          id={createRole.data}
          handleCloseCard={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title="Roles and Permission" />
        <Box padding={3}>
          <Grid container marginBottom={2} alignItems="center">
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${userManagementRoute}${rolesRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  sx={{
                    width: "140px",
                  }}
                  disabled={createRole.open}
                  onClick={() => handleToggleCreate(true, null, "Create Role")}
                >
                  {addRole}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${userManagementRoute}${rolesRoute}`}
                querySearch={(entry: string) =>
                  `?page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "primary.dark",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Role Title
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "common.white",
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                      }}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiAccountEditOutline}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="Edit Role"
                            onClick={() =>
                              handleToggleCreate(true, item.id, "Update Role")
                            }
                          />
                          <IconButtonMdi
                            mdiIcon={mdiDeleteOutline}
                            iconColor={theme.palette.error.main}
                            tooltipTitle="Delete Role"
                            onClick={() => handleToggleDeleteModal(true, item)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${userManagementRoute}${rolesRoute}`}
            querySearch={(page) =>
              `?page=${page}&entries=${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Roles;
