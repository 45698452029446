import { Navigate, Outlet } from "react-router-dom";
import _ from "lodash";
import { getUserCredential } from "../utils/helpers/storageHelper";

import WithMenu from "../components/layout/WithMenu";

import { loginRoute } from "./routeKeys";

const PrivateRoute = () => {
  const credential = getUserCredential();

  if (_.isNull(credential)) {
    return <Navigate to={loginRoute} />;
  }

  return (
    <WithMenu>
      <Outlet />
    </WithMenu>
  );
};

export default PrivateRoute;
