import React, { useState } from "react";
import _ from "lodash";
import Icon from "@mdi/react";

import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";

import { mdiClose } from "@mdi/js";

import ConfirmModal from "../../common/ConfirmModal";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import {
  useDeletePriceListMutation,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import { LoaderWithText } from "../../common/Loaders";

const headerLabels: string[] = ["SKU", "Asset Name", "Total Quantity"];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  onClose: () => void;
};

const AssetLocationModal = ({ open, onClose }: Props) => {
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<PriceListModel>();
  const [moveAll, setMoveAll] = useState(false);

  // const {
  //   data: listData,
  //   isLoading: listIsLoading,
  //   error: listError,
  //   isFetching: listIsFetching,
  // } = useGetPriceListQuery({
  //   type: serviceType.toUpperCase(),
  //   entries: entriesParam,
  //   page: pageParam - 1,
  //   search: searchParam ?? "",
  // });

  const listData: any = { data: { rows: [{}] } };
  const listIsLoading = false;
  const listError = false;
  const listIsFetching = false;

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePriceListMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data?.rows ?? [];
  const totalData = listData?.data?.count ?? 0;

  const confirmDelete = () => deleteMutate(deleteItem.data?.id as number);

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} successfully deleted`,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
  }

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "rental-services-price-list",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={(theme) => ({
          "& .MuiDialog-paper": {
            width: "1000px",
            maxWidth: "90vw",
            borderRadius: 2,
          },
        })}
      >
        {loading ? (
          <Box paddingX={1} height="90vh">
            <Stack alignItems="center" justifyContent="center" height="90vh">
              <LoaderWithText text="Get Additional Details.." />
            </Stack>
          </Box>
        ) : (
          <>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">
                  <Typography component="span" marginRight={1}>
                    Asset Location - LOT 23
                  </Typography>
                </Typography>
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "24px",
                  }}
                  onClick={onClose}
                >
                  <Icon path={mdiClose} size={1} />
                </Box>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Stack
                direction="row"
                width="100%"
                justifyContent="flex-end"
                pt={3}
              >
                <FormControlLabel
                  sx={{
                    label: {
                      fontSize: ".9rem",
                    },
                  }}
                  label="Move All"
                  control={
                    <Checkbox
                      checked={moveAll}
                      onChange={(e) => setMoveAll(e.target.checked)}
                    />
                  }
                />
                <TextField
                  select
                  label="Move To"
                  disabled={!moveAll}
                  sx={{
                    width: "200px",
                  }}
                >
                  <MenuItem disabled value="interment">
                    No options
                  </MenuItem>
                </TextField>
              </Stack>
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headerLabels.map((item, key) => (
                        <TableCell key={key} variant="head">
                          {item}
                        </TableCell>
                      ))}
                      <TableCell
                        variant="head"
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress
                            color="primary"
                            style={{ margin: "20px 0" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : list && list.length > 0 ? (
                      list.map((item: any, key: any) => (
                        <TableRow
                          key={key}
                          sx={{
                            "&:nth-of-type(even)": {
                              backgroundColor: "grey.100",

                              td: {
                                backgroundColor: "grey.100",
                              },
                            },
                            "&:nth-of-type(odd) td": {
                              backgroundColor: "common.white",
                            },
                          }}
                        >
                          <TableCell>Test</TableCell>
                          <TableCell>{item.regular}</TableCell>
                          <TableCell>{item.holiday}</TableCell>
                          <TableCell sx={{ maxWidth: "170px" }}>
                            <Stack
                              direction="row"
                              spacing={1}
                              width="fit-content"
                            >
                              <TextField
                                type="number"
                                placeholder="Quantity"
                                disabled={moveAll}
                                sx={{
                                  width: "100px",
                                }}
                              />
                              <TextField
                                select
                                label="Move To"
                                disabled={moveAll}
                                sx={{
                                  width: "200px",
                                }}
                              >
                                <MenuItem disabled value="interment">
                                  No options
                                </MenuItem>
                              </TextField>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          NO DATA FOUND
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
                paddingX={2}
                paddingY={1}
                justifyContent="center"
              >
                <Button
                  variant="button-primary"
                  onClick={loading ? () => {} : () => {}}
                  sx={{ width: 150 }}
                >
                  Move Now
                </Button>
                <Button
                  variant="button-secondary"
                  onClick={onClose}
                  sx={{ width: 150 }}
                >
                  Close
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default AssetLocationModal;
