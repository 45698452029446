import React, { useEffect } from "react";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  useFieldArray,
  useWatch,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import IconButtonMdi from "../../../../common/IconButtonMdi";
import PhoneNumberField from "../../../../common/textfields/PhoneNumberField";

import { OtherForm } from "./OtherInfo";
import { enterValidMobileNum } from "../../../../../utils/constants";

type Props = {
  parentIndex: number;
  control: Control<OtherForm>;
  clearErrors: UseFormClearErrors<OtherForm>;
  setValue: UseFormSetValue<OtherForm>;
  errors: FieldErrors<OtherForm>;
  disabled: boolean;
};

const AuthorizedContacts = ({
  parentIndex,
  control,
  clearErrors,
  setValue,
  errors,
  disabled,
}: Props) => {
  const theme = useTheme();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `authorized.${parentIndex}.contact`,
  });

  const contactNumberWatch = useWatch({
    control,
    name: `authorized.${parentIndex}.contact`,
  });

  const handleAddRemoveContact = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        value: "",
      });
    }
  };

  const checkValueError = (index: number): string => {
    const mobNumber = contactNumberWatch[index].value;
    if (!_.isEmpty(mobNumber)) {
      console.log(mobNumber);
      const formatVal = mobNumber.replaceAll(" ", "").replace("+63", "");
      if (formatVal.length > 10 || formatVal.length < 10) {
        return enterValidMobileNum;
      }
    }

    return "";
  };

  useEffect(() => {
    if (parentIndex === 0 && fields.length !== contactNumberWatch.length) {
      replace(contactNumberWatch);
    }
  }, [contactNumberWatch]);

  return (
    <div>
      <Typography variant="textfieldLabel">Contact Numbers</Typography>
      {fields.map((item, key) => (
        <Stack
          direction="row"
          spacing={1}
          key={key}
          paddingTop={key === 0 ? 1 : 2}
        >
          <IconButtonMdi
            mdiIcon={
              key + 1 === contactNumberWatch.length
                ? mdiPlusCircleOutline
                : mdiMinusCircleOutline
            }
            iconColor={
              key + 1 === contactNumberWatch.length
                ? theme.palette.info.main
                : theme.palette.error.main
            }
            onClick={
              disabled
                ? () => {}
                : () =>
                    handleAddRemoveContact(
                      key + 1 === contactNumberWatch.length ? null : key
                    )
            }
          />
          <Box width="100%">
            <Box>{item.value}</Box>
            <PhoneNumberField
              name={`authorized.${parentIndex}.contact.${key}.value`}
              setValue={setValue}
              clearErrors={clearErrors}
              // mobileValue={item.value}
              mobileValue={contactNumberWatch[key]?.value ?? ""}
              isDisabled={disabled}
              // errorMsg={checkValueError(key)}
              errorMsg={
                errors && errors.authorized
                  ? errors.authorized[parentIndex]
                    ? errors.authorized[parentIndex]?.contact
                      ? // @ts-ignore: Object is possibly 'undefined'.
                        errors.authorized[parentIndex]?.contact[key]
                        ? // @ts-ignore: Object is possibly 'undefined'.
                          errors.authorized[parentIndex]?.contact[key]?.value
                          ? // @ts-ignore: Object is possibly 'undefined'.
                            errors.authorized[parentIndex]?.contact[key]?.value
                              ?.message
                          : ""
                        : ""
                      : ""
                    : ""
                  : ""
              }
            />
          </Box>
        </Stack>
      ))}
    </div>
  );
};

export default AuthorizedContacts;
