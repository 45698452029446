import React from "react";
import ReactQuill from "react-quill";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import {
  Path,
  UseFormClearErrors,
  UseFormSetValue,
  FieldValues,
} from "react-hook-form";
import { thisFieldRequired } from "../../../utils/constants";
import "react-quill/dist/quill.snow.css";

interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  setValue: any;
  clearErrors: UseFormClearErrors<TFieldValues>;
  errorMsg?: string;
  value: string;
  isDisabled?: boolean;
}

const RichTextEditor = <TFieldValues extends FieldValues>({
  name,
  setValue,
  clearErrors,
  errorMsg,
  value,
  isDisabled = false,
}: Props<TFieldValues>) => {
  const theme = useTheme();
  const handleChangeEvent = (html: string) => {
    setValue(name, html);
    clearErrors([name]);
  };

  return (
    <Box
      sx={{
        "& .ql-toolbar": {
          borderRadius: "8px 8px 0 0",
          backgroundColor: theme.palette.grey[100],
        },
        "& .ql-container": {
          borderRadius: "0 0 8px 8px",
        },
      }}
    >
      <ReactQuill
        readOnly={isDisabled ?? false}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "video",
          "align",
        ]}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image", "video"],
            ["clean"],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
          ],
        }}
        theme="snow"
        value={value}
        onChange={handleChangeEvent}
      />
      {errorMsg && <FormHelperText error>{thisFieldRequired}</FormHelperText>}
    </Box>
  );
};

export default RichTextEditor;
