import {
  LotCategoryBodyModel,
  LotCategoryDtoModel,
  LotCategoryConfigDtoModel,
  LotTypeBodyModel,
  LotTypeDtoModel,
  LotTypeConfigDtoModel,
  LotStatusBodyModel,
  LotStatusDtoModel,
  LotStatusConfigDtoModel,
  UploadLotImageDtoModel,
  LotInformationBodyModel,
  LotInformationDtoModel,
  LotInformationConfigDtoModel,
} from "../../../model/lotModel";
import { ListFetchQueryType } from "../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const lotCategoryEndpoints = {
  "add-lot-category": "/lot/category/add",
  "lot-category-list": (page: number, entries: number, search: string) =>
    `/lot/category/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "lot-category-list-config": "/lot/category/list",
  "update-lot-category": (id: number) => `/lot/category/update/${id}`,
  "delete-lot-category": (id: number) => `/lot/category/delete/${id}`,
};

export const lotTypeEndpoints = {
  "add-lot-type": "/lot/type/add",
  "lot-type-list": (page: number, entries: number, search: string) =>
    `/lot/type/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "lot-type-list-config": "/lot/type/list",
  "update-lot-type": (id: number) => `/lot/type/update/${id}`,
  "delete-lot-type": (id: number) => `/lot/type/delete/${id}`,
};

export const lotStatusEndpoints = {
  "add-lot-status": "/lot/status/add",
  "lot-status-list": (page: number, entries: number, search: string) =>
    `/lot/status/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "lot-status-list-config": "/lot/status/list",
  "update-lot-status": (id: number) => `/lot/status/update/${id}`,
  "delete-lot-status": (id: number) => `/lot/status/delete/${id}`,
};

export const lotInformationEndpoints = {
  "upload-lot-image": "/lot/upload/picture",
  "add-lot-information": "/lot/add",
  "lot-information-list": (page: number, entries: number, search: string) =>
    `/lot/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "update-lot-information": (id: number) => `/lot/update/${id}`,
  "delete-lot-information": (id: number) => `/lot/delete/${id}`,
  "lot-information-list-config": "/lot/list",
};

export const lotCategorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLotCategory: builder.mutation<void, LotCategoryBodyModel>({
      query: (body) => ({
        url: lotCategoryEndpoints["add-lot-category"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-category-list", "lot-category-list-config"],
    }),
    getLotCategoryList: builder.query<LotCategoryDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: lotCategoryEndpoints["lot-category-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-category-list"],
    }),
    getLotCategoryConfigList: builder.query<LotCategoryConfigDtoModel, void>({
      query: (param) => ({
        url: lotCategoryEndpoints["lot-category-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-category-list-config"],
    }),
    updateLotCategory: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: LotCategoryBodyModel;
      }
    >({
      query: (param) => ({
        url: lotCategoryEndpoints["update-lot-category"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-category-list", "lot-category-list-config"],
    }),
    deleteLotCategory: builder.mutation<void, number>({
      query: (param) => ({
        url: lotCategoryEndpoints["delete-lot-category"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-category-list", "lot-category-list-config"],
    }),
  }),
});

export const lotTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLotType: builder.mutation<void, LotTypeBodyModel>({
      query: (body) => ({
        url: lotTypeEndpoints["add-lot-type"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-type-list", "lot-type-list-config"],
    }),
    getLotTypeList: builder.query<LotTypeDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: lotTypeEndpoints["lot-type-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-type-list"],
    }),
    getLotTypeConfigList: builder.query<LotTypeConfigDtoModel, void>({
      query: (param) => ({
        url: lotTypeEndpoints["lot-type-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-type-list-config"],
    }),
    updateLotType: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: LotTypeBodyModel;
      }
    >({
      query: (param) => ({
        url: lotTypeEndpoints["update-lot-type"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-type-list", "lot-type-list-config"],
    }),
    deleteLotType: builder.mutation<void, number>({
      query: (param) => ({
        url: lotTypeEndpoints["delete-lot-type"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-type-list", "lot-type-list-config"],
    }),
  }),
});

export const lotStatusSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLotStatus: builder.mutation<void, LotStatusBodyModel>({
      query: (body) => ({
        url: lotStatusEndpoints["add-lot-status"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-status-list", "lot-status-list-config"],
    }),
    getLotStatusList: builder.query<LotStatusDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: lotStatusEndpoints["lot-status-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-status-list"],
    }),
    getLotStatusConfigList: builder.query<LotStatusConfigDtoModel, void>({
      query: (param) => ({
        url: lotStatusEndpoints["lot-status-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-type-list-config"],
    }),
    updateLotStatus: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: LotStatusBodyModel;
      }
    >({
      query: (param) => ({
        url: lotStatusEndpoints["update-lot-status"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-status-list", "lot-status-list-config"],
    }),
    deleteLotStatus: builder.mutation<void, number>({
      query: (param) => ({
        url: lotStatusEndpoints["delete-lot-status"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-status-list", "lot-status-list-config"],
    }),
  }),
});

export const lotInformationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadLotImage: builder.mutation<
      UploadLotImageDtoModel,
      {
        file: FileList;
      }
    >({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append("picture", param.file[0]);
        }

        return {
          url: lotInformationEndpoints["upload-lot-image"],
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
    addLotInformation: builder.mutation<void, LotInformationBodyModel>({
      query: (body) => ({
        url: lotInformationEndpoints["add-lot-information"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-information-list", "lot-information-list-config"],
    }),
    getLotInformationList: builder.query<
      LotInformationDtoModel,
      ListFetchQueryType
    >({
      query: (param) => ({
        url: lotInformationEndpoints["lot-information-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-information-list"],
    }),
    getLotInformationListConfig: builder.query<
      LotInformationConfigDtoModel,
      void
    >({
      query: () => ({
        url: lotInformationEndpoints["lot-information-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lot-information-list-config"],
    }),
    deleteLotInformation: builder.mutation<void, number>({
      query: (param) => ({
        url: lotInformationEndpoints["delete-lot-information"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-information-list", "lot-information-list-config"],
    }),
    updateLotInformation: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: LotInformationBodyModel;
      }
    >({
      query: (param) => ({
        url: lotInformationEndpoints["update-lot-information"](
          param.queryParams
        ),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lot-information-list", "lot-information-list-config"],
    }),
  }),
});

export const {
  useUploadLotImageMutation,
  useAddLotInformationMutation,
  useGetLotInformationListQuery,
  useLazyGetLotInformationListQuery,
  useLazyGetLotInformationListConfigQuery,
  useDeleteLotInformationMutation,
  useUpdateLotInformationMutation,
} = lotInformationSlice;

export const {
  useAddLotStatusMutation,
  useGetLotStatusConfigListQuery,
  useGetLotStatusListQuery,
  useUpdateLotStatusMutation,
  useDeleteLotStatusMutation,
} = lotStatusSlice;

export const {
  useAddLotTypeMutation,
  useGetLotTypeConfigListQuery,
  useGetLotTypeListQuery,
  useUpdateLotTypeMutation,
  useDeleteLotTypeMutation,
} = lotTypeSlice;

export const {
  useAddLotCategoryMutation,
  useGetLotCategoryConfigListQuery,
  useGetLotCategoryListQuery,
  useUpdateLotCategoryMutation,
  useDeleteLotCategoryMutation,
} = lotCategorySlice;
