import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

type Props = {
  title: string;
  handleCloseCard?: () => void;
};

const CardHeader = ({ title, handleCloseCard }: Props) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          paddingX: theme.spacing(3),
          paddingY: theme.spacing(1),
        })}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>{title}</Typography>
          {handleCloseCard && (
            <Box
              onClick={handleCloseCard}
              sx={{
                cursor: "pointer",
              }}
            >
              <Icon path={mdiClose} size={1} />
            </Box>
          )}
        </Stack>
      </Box>
      <Divider />
    </>
  );
};

export default CardHeader;
