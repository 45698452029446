import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Divider from "@mui/material/Divider";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";

import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { LoaderWithText } from "../../common/Loaders";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";
import {
  domainLink,
  enterValidMobileNum,
  thisFieldRequired,
} from "../../../utils/constants";
import LeadFormContacts from "./LeadFormContacts";
import { LeadInformationModel } from "../../../model/leadModel";
import {
  BuyerInformationModel,
  UploadPicDtoModel,
} from "../../../model/buyerModel";

import { useUploadPicMutation } from "../../../service/slice/client-management/buyer/buyerInformationSlice";
import { useGetAgentListConfigQuery } from "../../../service/slice/usersSlice";
import {
  useAddLeadMutation,
  useGetLeadInformationQuery,
  useUpdateLeadMutation,
} from "../../../service/slice/client-management/lead/leadSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import moment from "moment";
import { RoleTypeEnum } from "../../../utils/enums";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import { skipToken } from "@reduxjs/toolkit/query";
import ConfirmModal from "../../common/ConfirmModal";
import { buyersRoute, clientManagementRoute } from "../../../routes/routeKeys";

interface AddressModel {
  address: string;
  country: string;
  region: string;
  municipality: string;
  city: string;
  postalCode: string;
}

export interface FormType {
  lastName: string;
  firstName: string;
  middleName: string;
  contactNumber: {
    id: null | number;
    value: string;
  }[];
  email: string;
  birthDate: Date | null;
  homeAddress: AddressModel;
  mailingAddress: AddressModel;
  sameHomeAddress: boolean;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
  agent: {
    value: null | number | string;
    label: null | string;
  };
  leadType: "LOT" | "VAULT" | "CRYPT" | "";
}

type Props = {
  title: string;
  handleCloseCard: () => void;
  data: null | { id: number; name: string };
};

const LeadForm = ({ title, handleCloseCard, data }: Props) => {
  const navigate = useNavigate();
  const [toBuyerItem, handleShowBuyerConfirmBox] =
    useOpenCreateForm<LeadInformationModel>();

  const form = useForm<FormType>({
    defaultValues: {
      lastName: "",
      firstName: "",
      middleName: "",
      email: "",
      birthDate: null,
      contactNumber: [
        {
          id: null,
          value: "",
        },
      ],
      homeAddress: {
        address: "",
        city: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
      },
      mailingAddress: {
        address: "",
        city: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
      },
      sameHomeAddress: false,
      picture: {
        file: null,
        filetype: null,
        originalName: null,
        rawFile: null,
      },
      agent: {
        value: null,
        label: null,
      },
      leadType: "",
    },
  });

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
    setError,
    reset: fieldReset,
    getValues,
    handleSubmit,
  } = form;

  const {
    data: agentListData,
    isLoading: agentListLoading,
    error: agentListError,
  } = useGetAgentListConfigQuery();

  const {
    data: leadInfo,
    isLoading: leadInfoIsLoading,
    isFetching: leadInfoIsFetching,
    error: leadInfoError,
  } = useGetLeadInformationQuery(data?.id ?? skipToken);

  const [
    uploadPicMutate,
    {
      isLoading: uploadPicIsLoading,
      error: uploadPicError,
      reset: uploadPicReset,
    },
  ] = useUploadPicMutation();

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddLeadMutation();

  const [
    updateMutate,
    {
      error: updateError,
      isLoading: updateIsLoading,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateLeadMutation();

  const pictureWatch = useWatch({
    control,
    name: "picture",
  });

  const sameHomeAddressWatch = useWatch({
    control,
    name: "sameHomeAddress",
  });

  const leadTypeWatch = useWatch({
    control,
    name: "leadType",
  });

  const mobileNumWatch = useWatch({
    control,
    name: "contactNumber",
  });

  const homeAddressWatch = useWatch({
    control,
    name: "homeAddress",
  });

  const personnelOpt = useMemo(() => {
    return {
      agent:
        agentListData?.data
          .filter((item) => item.type === RoleTypeEnum.agent)
          .map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          })) ?? [],
    };
  }, [agentListData]);

  const initLoading =
    agentListLoading || leadInfoIsLoading || leadInfoIsFetching;
  const saveLoading = uploadPicIsLoading || addIsLoading || updateIsLoading;

  const handleConfirmNavigate = () => {
    const buyerInfo: BuyerInformationModel = {
      gender: toBuyerItem.data?.gender ?? null,
      birthDate: toBuyerItem.data?.birthDate ?? "",
      buyerContacts: toBuyerItem.data?.buyerContacts ?? [],
      email: toBuyerItem.data?.email ?? "",
      firstName: toBuyerItem.data?.firstName ?? "",
      homeAddress: toBuyerItem.data?.homeAddress ?? "",
      homeCity: toBuyerItem.data?.homeCity ?? "",
      homeCountry: toBuyerItem.data?.homeCountry ?? "",
      homeMunicipality: toBuyerItem.data?.homeMunicipality ?? "",
      homePostalCode: toBuyerItem.data?.homePostalCode ?? "",
      homeRegion: toBuyerItem.data?.homeRegion ?? "",
      lastName: toBuyerItem.data?.lastName ?? "",
      mailingAddress: toBuyerItem.data?.mailingAddress ?? "",
      mailingCity: toBuyerItem.data?.mailingCity ?? "",
      mailingCountry: toBuyerItem.data?.mailingCountry ?? "",
      mailingMunicipality: toBuyerItem.data?.mailingMunicipality ?? "",
      mailingPostalCode: toBuyerItem.data?.mailingPostalCode ?? "",
      mailingRegion: toBuyerItem.data?.mailingRegion ?? "",
      middleName: toBuyerItem.data?.middleName ?? "",
      picture: toBuyerItem.data?.picture ?? "",
      referenceNumber: toBuyerItem.data?.referenceNumber ?? "",
      sameAddress: toBuyerItem.data?.sameAddress ?? false,
    };

    navigate(`${clientManagementRoute}${buyersRoute}/add/buyer-information`, {
      state: {
        buyerInfo,
        unitType: toBuyerItem.data?.leadType,
      },
    });
  };

  const validateOtherFields = (): number[] => {
    const errorsArr = [];

    if (_.isEmpty(mobileNumWatch[0].value)) {
      setError(`contactNumber.${0}.value`, {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    } else {
      mobileNumWatch.forEach((item, key) => {
        if (!_.isEmpty(item.value)) {
          const formatVal = item.value.replaceAll(" ", "").replace("+63", "");
          if (formatVal.length > 10 || formatVal.length < 10) {
            setError(`contactNumber.${key}.value`, {
              type: "required",
              message: enterValidMobileNum,
            });

            errorsArr.push(1);
          }
        }
      });
    }

    return errorsArr;
  };

  const onError = () => validateOtherFields();

  const onSubmit: SubmitHandler<FormType> = async (dataFields) => {
    const otherErrors = validateOtherFields();

    if (otherErrors.length > 0) {
      return;
    }

    let dataPic: UploadPicDtoModel | null = null;

    if (dataFields.picture.rawFile) {
      dataPic = await uploadPicMutate(dataFields.picture.rawFile).unwrap();
    }

    if (data?.id) {
      await updateMutate({
        queryParam: data.id,
        bodyParam: {
          agentId: dataFields.agent.value
            ? (dataFields.agent.value as number)
            : null,
          birthDate: dataFields.birthDate
            ? moment(dataFields.birthDate).format("YYYY-MM-DD")
            : null,
          contact: dataFields.contactNumber
            .map((item) => ({
              number: item.value,
              id: item.id,
            }))
            .filter((item) => !_.isEmpty(item.number)),
          email: dataFields.email,
          firstName: dataFields.firstName,
          lastName: dataFields.lastName,
          homeAddress: dataFields.homeAddress.address,
          homeCity: dataFields.homeAddress.city,
          homeCountry: dataFields.homeAddress.country,
          homeMunicipality: dataFields.homeAddress.municipality,
          homePostalCode: dataFields.homeAddress.postalCode,
          homeRegion: dataFields.homeAddress.region,
          mailingAddress: dataFields.mailingAddress.address,
          mailingCity: dataFields.mailingAddress.city,
          mailingCountry: dataFields.mailingAddress.country,
          mailingMunicipality: dataFields.mailingAddress.municipality,
          mailingPostalCode: dataFields.mailingAddress.postalCode,
          mailingRegion: dataFields.mailingAddress.region,
          sameAddress: dataFields.sameHomeAddress ? 1 : 0,
          leadType: _.isEmpty(dataFields.leadType) ? null : dataFields.leadType,
          middleName: dataFields.middleName,
          picture: dataFields.picture.rawFile
            ? (dataPic?.data.link as string)
            : dataFields.picture.file
            ? dataFields.picture.file?.replace(domainLink, "")
            : dataPic
            ? dataPic?.data.link
            : null,
        },
      });
    } else {
      await addMutate({
        agentId: dataFields.agent.value
          ? (dataFields.agent.value as number)
          : null,
        birthDate: dataFields.birthDate
          ? moment(dataFields.birthDate).format("YYYY-MM-DD")
          : null,
        contact: dataFields.contactNumber.map((item) => ({
          number: item.value,
        })),
        email: dataFields.email,
        firstName: dataFields.firstName,
        lastName: dataFields.lastName,
        homeAddress: dataFields.homeAddress.address,
        homeCity: dataFields.homeAddress.city,
        homeCountry: dataFields.homeAddress.country,
        homeMunicipality: dataFields.homeAddress.municipality,
        homePostalCode: dataFields.homeAddress.postalCode,
        homeRegion: dataFields.homeAddress.region,
        mailingAddress: dataFields.mailingAddress.address,
        mailingCity: dataFields.mailingAddress.city,
        mailingCountry: dataFields.mailingAddress.country,
        mailingMunicipality: dataFields.mailingAddress.municipality,
        mailingPostalCode: dataFields.mailingAddress.postalCode,
        mailingRegion: dataFields.mailingAddress.region,
        sameAddress: dataFields.sameHomeAddress ? 1 : 0,
        leadType: _.isEmpty(dataFields.leadType) ? null : dataFields.leadType,
        middleName: dataFields.middleName,
        picture: dataPic ? dataPic.data.link : null,
      });
    }
  };

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-lead",
    });

    updateReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Lead information updated!",
      toastId: "update-lead",
    });

    updateReset();
  }

  if (leadInfoError) {
    errorDisplayOrNavigate({
      error: leadInfoError,
      toastId: "lead-info",
    });
  }

  if (agentListError) {
    errorDisplayOrNavigate({
      error: agentListError,
      toastId: "agent-list",
    });
  }

  if (uploadPicError) {
    errorDisplayOrNavigate({
      error: uploadPicError,
      toastId: "upload-pic",
    });

    uploadPicReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-lead",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New lead added",
      toastId: "add-lead",
    });

    addReset();
    fieldReset();
    setValue("contactNumber", [
      {
        id: null,
        value: "",
      },
    ]);
  }

  useEffect(() => {
    if (leadInfo) {
      const {
        agentId,
        birthDate,
        buyerContacts,
        email,
        firstName,
        lastName,
        middleName,
        leadType,
        picture,
        homeAddress,
        homeCity,
        homeCountry,
        homeMunicipality,
        homePostalCode,
        homeRegion,
        mailingAddress,
        mailingCity,
        mailingCountry,
        mailingMunicipality,
        mailingPostalCode,
        mailingRegion,
        sameAddress,
      } = leadInfo.data;
      const agentValue = agentId
        ? agentListData?.data.find((item) => item.id === agentId)
        : null;
      setValue("email", email);
      setValue("agent", {
        label: agentValue
          ? `${agentValue.firstName} ${agentValue.lastName}`
          : "",
        value: agentValue ? agentValue.id : null,
      });
      setValue("birthDate", birthDate ? new Date(birthDate) : null);
      setValue(
        "contactNumber",
        buyerContacts.map((item) => ({
          id: item.id,
          value: item.number,
        }))
      );
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("middleName", middleName);
      setValue("leadType", leadType ?? "");
      setValue(
        "picture",
        picture
          ? {
              file: picture.includes(domainLink)
                ? picture
                : `${domainLink}/${picture}`,
              originalName: "",
              filetype: "",
              rawFile: null,
            }
          : {
              file: null,
              originalName: "",
              filetype: "",
              rawFile: null,
            }
      );
      setValue("homeAddress.address", homeAddress);
      setValue("homeAddress.city", homeCity);
      setValue("homeAddress.country", homeCountry);
      setValue("homeAddress.municipality", homeMunicipality);
      setValue("homeAddress.postalCode", homePostalCode);
      setValue("homeAddress.region", homeRegion);
      setValue("mailingAddress.address", mailingAddress);
      setValue("mailingAddress.city", mailingCity);
      setValue("mailingAddress.country", mailingCountry);
      setValue("mailingAddress.municipality", mailingMunicipality);
      setValue("mailingAddress.postalCode", mailingPostalCode);
      setValue("mailingAddress.region", mailingRegion);
      setValue("sameHomeAddress", sameAddress);
    }
  }, [leadInfo]);

  useEffect(() => {
    const { homeAddress, mailingAddress } = getValues();
    if (sameHomeAddressWatch) {
      setValue("mailingAddress.address", homeAddress.address);
      setValue("mailingAddress.city", homeAddress.city);
      setValue("mailingAddress.country", homeAddress.country);
      setValue("mailingAddress.municipality", homeAddress.municipality);
      setValue("mailingAddress.postalCode", homeAddress.postalCode);
      setValue("mailingAddress.region", homeAddress.region);
      clearErrors([
        "mailingAddress.address",
        "mailingAddress.city",
        "mailingAddress.country",
        "mailingAddress.municipality",
        "mailingAddress.postalCode",
        "mailingAddress.region",
      ]);
    } else {
      setValue("mailingAddress.address", mailingAddress.address);
      setValue("mailingAddress.city", mailingAddress.city);
      setValue("mailingAddress.country", mailingAddress.country);
      setValue("mailingAddress.municipality", mailingAddress.municipality);
      setValue("mailingAddress.postalCode", mailingAddress.postalCode);
      setValue("mailingAddress.region", mailingAddress.region);
    }
  }, [sameHomeAddressWatch, homeAddressWatch]);

  return (
    <>
      <ConfirmModal
        isLoading={false}
        open={toBuyerItem.open}
        text={`Are you sure to set ${toBuyerItem.data?.firstName} ${toBuyerItem.data?.lastName} (${toBuyerItem.data?.referenceNumber}) as buyer?`}
        closeFn={() => handleShowBuyerConfirmBox(false, null, "")}
        confirmFn={handleConfirmNavigate}
      />
      <Box paddingBottom={2}>
        <Paper elevation={3}>
          <CardHeader title={title} handleCloseCard={handleCloseCard} />
          <Box padding={3}>
            {initLoading ? (
              <LoaderWithText text="Getting lead information" />
            ) : (
              <FormProvider {...form}>
                <Grid
                  container
                  spacing={3}
                  direction={{ xs: "column", md: "row-reverse" }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="textfieldLabel">Photo</Typography>
                    <UploadPhotoField
                      name="picture"
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // pictureValue={allFieldsWatch[1]}
                      pictureValue={pictureWatch}
                      errorMsg={errors.picture?.message}
                      changeText="Change photo"
                      chooseText="Select photo"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Last name
                        </Typography>
                        <Controller
                          name="lastName"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Last Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          First name
                        </Typography>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="First Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Middle name
                        </Typography>
                        <Controller
                          name="middleName"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Middle Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Email address
                        </Typography>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              type="email"
                              placeholder="Email Address"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Date of Birth
                        </Typography>
                        <Controller
                          name="birthDate"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    error: fieldState.error?.message
                                      ? true
                                      : false,
                                    helperText: fieldState.error?.message,
                                    fullWidth: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                      <LeadFormContacts
                        clearErrors={clearErrors}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        width: "100%",
                        height: 40,
                      }}
                    />
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Home address
                        </Typography>
                        <Controller
                          name="homeAddress.address"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Home Address"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Country
                        </Typography>
                        <Controller
                          name="homeAddress.country"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Country"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">Region</Typography>
                        <Controller
                          name="homeAddress.region"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Region"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Municipality
                        </Typography>
                        <Controller
                          name="homeAddress.municipality"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Municipality"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">City</Typography>
                        <Controller
                          name="homeAddress.city"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="City"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Postal code
                        </Typography>
                        <Controller
                          name="homeAddress.postalCode"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Postal code"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="sameHomeAddress"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={{
                            label: {
                              fontSize: ".9rem",
                            },
                          }}
                          label="Same as home address"
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                        />
                      )}
                    />
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Mailing address
                        </Typography>
                        <Controller
                          name="mailingAddress.address"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Mailing Address"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Country
                        </Typography>
                        <Controller
                          name="mailingAddress.country"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Country"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">Region</Typography>
                        <Controller
                          name="mailingAddress.region"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Region"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Municipality
                        </Typography>
                        <Controller
                          name="mailingAddress.municipality"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Municipality"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">City</Typography>
                        <Controller
                          name="mailingAddress.city"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="City"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Postal code
                        </Typography>
                        <Controller
                          name="mailingAddress.postalCode"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Postal code"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <Typography variant="textfieldLabel">Agent</Typography>
                      <CustomReactSelectJs
                        control={control}
                        options={personnelOpt.agent}
                        name="agent"
                        placeholder="Agent"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <Typography variant="textfieldLabel">
                        Unit Type
                      </Typography>
                      <Controller
                        name="leadType"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <RadioGroup {...field} value={leadTypeWatch} row>
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="LOT"
                                label="Lot"
                                control={<Radio />}
                              />
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="VAULT"
                                label="Vault"
                                control={<Radio />}
                              />
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="CRYPT"
                                label="Crypt"
                                control={<Radio />}
                              />
                            </RadioGroup>
                            {error && (
                              <FormHelperText error>
                                {error.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Divider
                  sx={(theme) => ({
                    marginY: theme.spacing(3),
                  })}
                />
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="button-primary"
                    sx={{
                      width: 150,
                    }}
                    startIcon={
                      saveLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    {data ? "Update" : "Save"}
                  </Button>
                  <Button
                    variant="button-secondary"
                    disabled={data ? false : true}
                    sx={{
                      width: 180,
                    }}
                    onClick={() =>
                      handleShowBuyerConfirmBox(
                        true,
                        leadInfo?.data as LeadInformationModel,
                        ""
                      )
                    }
                  >
                    Set as Buyer
                  </Button>
                </Stack>
              </FormProvider>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default LeadForm;
