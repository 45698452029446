import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TransitionProps } from "@mui/material/transitions";
import Stack from "@mui/material/Stack";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import { LoaderWithText } from "../../common/Loaders";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { thisFieldRequired } from "../../../utils/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  onClose: () => void;
};

export interface FormType {
  sku: string;
  asset_name: string;
  description: string;
  quantity: string;
  available: string;
  location: {
    label: string | null;
    value: string | null;
  } | null;
  unusable: boolean;
  date_required: Date | null;
}

const MoveAssetMaterialForm = ({ open, onClose }: Props) => {
  const [openUpload, handleToggleUpload] = useOpenCreateForm<number>();

  const loading = false;

  const form = useForm<FormType>({
    defaultValues: {
      sku: "0000001",
      asset_name: "Sample",
      description: "Lorem ipsum",
      quantity: "",
      available: "",
      location: null,
      unusable: false,
      date_required: null,
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    handleSubmit,
    reset: fieldReset,
  } = form;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: "600px",
          maxWidth: "90vw",
          borderRadius: 2,
        },
      })}
    >
      {loading ? (
        <Box paddingX={1} height="90vh">
          <Stack alignItems="center" justifyContent="center" height="90vh">
            <LoaderWithText text="Get Additional Details.." />
          </Stack>
        </Box>
      ) : (
        <>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1">
                <Typography component="span" marginRight={1}>
                  Move Assets
                </Typography>
              </Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "24px",
                }}
                onClick={onClose}
              >
                <Icon path={mdiClose} size={1} />
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box padding={1} paddingTop={1}>
              <FormProvider {...form}>
                <Box mb={3} mt={1}>
                  <Stack direction="column" spacing={2}>
                    <Box>
                      <Typography variant="textfieldLabel">SKU</Typography>
                      <Controller
                        name="sku"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="SKU"
                            error={errors.sku ? true : false}
                            helperText={errors.sku?.message}
                            inputProps={{ readOnly: true }}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Asset Name
                      </Typography>
                      <Controller
                        name="asset_name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Asset Name"
                            error={errors.asset_name ? true : false}
                            helperText={errors.asset_name?.message}
                            inputProps={{ readOnly: true }}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Description
                      </Typography>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            placeholder="Description"
                            error={errors.description ? true : false}
                            helperText={errors.description?.message}
                            multiline={true}
                            rows={3}
                            inputProps={{ readOnly: true }}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Stack direction="row" spacing={3}>
                      <Box width="100%">
                        <Typography variant="textfieldLabel">
                          Quantity
                        </Typography>
                        <Controller
                          name="quantity"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              placeholder="Quantity"
                              error={errors.quantity ? true : false}
                              helperText={errors.quantity?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </Box>
                      <Box width="100%">
                        <Typography variant="textfieldLabel">
                          Available
                        </Typography>
                        <Controller
                          name="available"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                            validate: (value) => Number(value) <= Number(watch("quantity")) || "Available should not exceed quantity."
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              placeholder="Available"
                              error={errors.available ? true : false}
                              helperText={errors.available?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <Box width="100%">
                        <Typography variant="textfieldLabel">
                          Location
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={[]}
                          name="location"
                          placeholder="Location"
                          isRequired
                        />
                      </Box>
                      <Box width="215px" mt="25px !important">
                        <Controller
                          name="unusable"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              sx={{
                                label: {
                                  fontSize: ".9rem",
                                },
                              }}
                              label="Set Unusable"
                              control={
                                <Checkbox {...field} checked={field.value} />
                              }
                            />
                          )}
                        />
                      </Box>
                    </Stack>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Date Required
                      </Typography>
                      <Controller
                        name="date_required"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: fieldState.error?.message
                                    ? true
                                    : false,
                                  helperText: fieldState.error?.message,
                                  fullWidth: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Box>
                  </Stack>
                </Box>
              </FormProvider>
            </Box>
          </DialogContent>
          {/* {openUpload.open && ( */}
          <DialogActions>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              width="100%"
              paddingX={2}
              paddingY={1}
              justifyContent="center"
            >
              <Button
                variant="button-primary"
                onClick={loading ? () => {} : handleSubmit(() => {})}
                sx={{ width: 150 }}
              >
                Move Now
              </Button>
              <Button
                variant="button-secondary"
                onClick={onClose}
                sx={{ width: 150 }}
              >
                Close
              </Button>
            </Stack>
          </DialogActions>
          {/* )} */}
        </>
      )}
    </Dialog>
  );
};

export default MoveAssetMaterialForm;
