import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import StepperTabMenu, { StepModel } from "../../../common/StepperTabMenu";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../routes/routeKeys";
import CardHeader from "../../../common/CardHeader";
import BuyerInformation from "./buyer-information/BuyerInformation";
import OtherInfo from "./other-information/OtherInfo";
import Identification from "./identification/Identification";
import UnitType, { UnitModel } from "./unit-type/UnitType";
import Payment from "./payment/Payment";
import Guidelines from "./guidelines/Guidelines";

const ActiveBuyerStepper = () => {
  const navigate = useNavigate();
  const { state: unitState } = useLocation();
  const { step, id } = useParams();
  const [steps] = useState<StepModel[]>([
    {
      key: "buyer-information",
      label: "Buyer Information",
      withPaging: false,
    },
    {
      key: "other-information",
      label: "Other Information",
      withPaging: false,
    },
    {
      key: "identification",
      label: "Identification",
      withPaging: true,
    },
    {
      key: "unit-type",
      label: "Unit Type",
      withPaging: false,
    },
    {
      key: "payment",
      label: "Payment",
      withPaging: false,
    },
    {
      key: "guidelines",
      label: "Guidelines",
      withPaging: false,
    },
  ]);

  return (
    <Paper elevation={3}>
      <Box padding={2} paddingTop={1}>
        <CardHeader
          title="Add Buyer"
          handleCloseCard={() =>
            navigate(
              `${clientManagementRoute}${buyersRoute}?tab=1&page=1&entries=10&search=`
            )
          }
        />
        <Box paddingTop={4} paddingBottom={2}>
          <StepperTabMenu<UnitModel>
            // disabled={id ? false : true}
            disabled={false}
            steps={steps}
            path={
              id
                ? `${clientManagementRoute}${buyersRoute}/edit/${id}`
                : `${clientManagementRoute}${buyersRoute}/add`
            }
            state={unitState}
          />
        </Box>
        <Box paddingTop={2} paddingX={3}>
          {step === "buyer-information" && <BuyerInformation steps={steps} />}
          {step === "other-information" && <OtherInfo steps={steps} />}
          {step === "identification" && <Identification steps={steps} />}
          {step === "unit-type" && <UnitType steps={steps} />}
          {step === "payment" && <Payment steps={steps} />}
          {step === "guidelines" && <Guidelines steps={steps} />}
        </Box>
      </Box>
    </Paper>
  );
};

export default ActiveBuyerStepper;
