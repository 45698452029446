import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import {
  accountManagementRoute,
  paymentRoute,
} from "../../../routes/routeKeys";
import UnitPayment from "./UnitPayment";
import ServicePayment from "./ServicePayment";

const tabItems: ItemsProps[] = [
  {
    label: "Units (Lot / Columbarium / Crypt)",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Other Services",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const Payment = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${accountManagementRoute}${paymentRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && <UnitPayment />}
          {tabValue === 1 && <ServicePayment />}
        </Box>
      </Box>
    </Paper>
  );
};

export default Payment;
