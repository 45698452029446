import { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import CardHeader from "../../common/CardHeader";
import LeadList from "./LeadList";
import LeadSendEmail from "./LeadSendEmail";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import { LeadInformationModel } from "../../../model/leadModel";
import LeadForm from "./LeadForm";

type Props = {};

export interface LeadIdType {
  id: number;
  refNum: string;
}

const Leads = (props: Props) => {
  const [leadIds, setLeadIds] = useState<LeadIdType[]>([]);
  const [openCreate, handleToggleCreate] = useOpenCreateForm<{
    id: number;
    name: string;
  }>();

  const handleCheckSelect = (
    lead: LeadIdType,
    isChecked: boolean,
    clearLead?: boolean
  ) => {
    if (clearLead) {
      setLeadIds([]);
      return;
    }

    if (isChecked) {
      setLeadIds((prev) => [...prev, lead]);
    } else {
      setLeadIds((prev) => prev.filter((item) => item.id !== lead.id));
    }
  };
  return (
    <>
      {openCreate.open && (
        <LeadForm
          title={openCreate.title}
          data={openCreate.data}
          handleCloseCard={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title="Leads" />
        <Box padding={3}>
          <LeadList
            leadIds={leadIds}
            isOpenCreate={openCreate.open}
            handleOpenCreate={(data, title) =>
              handleToggleCreate(true, data, title)
            }
            handleCheckSelect={handleCheckSelect}
          />
          <LeadSendEmail
            leadIds={leadIds}
            handleCheckSelect={handleCheckSelect}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Leads;
