import { useState } from "react";

interface FileViewType {
  open: boolean;
  filename?: string;
  path: string;
}

const useFileView = () => {
  const [viewDoc, setViewDoc] = useState<FileViewType>({
    open: false,
    filename: "",
    path: "",
  });

  const handleToggleViewDoc = (
    open: boolean,
    path: string,
    filename?: string
  ) =>
    setViewDoc({
      open,
      path,
      filename: filename ?? "",
    });

  return [viewDoc, handleToggleViewDoc] as const;
};

export default useFileView;
