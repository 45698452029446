import { ListFetchQueryType } from "../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";
import {
  CryptColumnBodyModel,
  CryptColumnConfigDtoModel,
  CryptColumnDtoModel,
  CryptInformationBodyModel,
  CryptInformationDtoModel,
  CryptLevelBodyModel,
  CryptLevelConfigDtoModel,
  CryptLevelDtoModel,
  CryptStatusBodyModel,
  CryptStatusConfigDtoModel,
  CryptStatusDtoModel,
  UploadCryptImageDtoModel,
  CryptInformationConfigDtoModel,
} from "../../../model/cryptModel";

export const cryptLevelEndpoints = {
  "add-crypt-level": "/crypt/level/add",
  "crypt-level-list": (page: number, entries: number, search: string) =>
    `/crypt/level/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "crypt-level-list-config": "/crypt/level/list",
  "update-crypt-level": (id: number) => `/crypt/level/update/${id}`,
  "delete-crypt-level": (id: number) => `/crypt/level/delete/${id}`,
};

export const cryptColumnEndpoints = {
  "add-crypt-column": "/crypt/column/add",
  "crypt-column-list": (page: number, entries: number, search: string) =>
    `/crypt/column/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "crypt-column-list-config": "/crypt/column/list",
  "update-crypt-column": (id: number) => `/crypt/column/update/${id}`,
  "delete-crypt-column": (id: number) => `/crypt/column/delete/${id}`,
};

export const cryptStatusEndpoints = {
  "add-crypt-status": "/crypt/status/add",
  "crypt-status-list": (page: number, entries: number, search: string) =>
    `/crypt/status/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "crypt-status-list-config": "/crypt/status/list",
  "update-crypt-status": (id: number) => `/crypt/status/update/${id}`,
  "delete-crypt-status": (id: number) => `/crypt/status/delete/${id}`,
};

export const cryptInformationEndpoints = {
  "upload-lot-image": "/crypt/upload/picture",
  "add-crypt-information": "/crypt/add",
  "crypt-information-list": (page: number, entries: number, search: string) =>
    `/crypt/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "crypt-information-list-config": "/crypt/list",
  "update-crypt-information": (id: number) => `/crypt/update/${id}`,
  "delete-crypt-information": (id: number) => `/crypt/delete/${id}`,
};

export const cryptLevelSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCryptLevel: builder.mutation<void, CryptLevelBodyModel>({
      query: (body) => ({
        url: cryptLevelEndpoints["add-crypt-level"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-level-list", "crypt-level-list-config"],
    }),
    getCryptLevelList: builder.query<CryptLevelDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: cryptLevelEndpoints["crypt-level-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-level-list"],
    }),
    getCryptLevelConfigList: builder.query<CryptLevelConfigDtoModel, void>({
      query: () => ({
        url: cryptLevelEndpoints["crypt-level-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-level-list-config"],
    }),
    updateCryptLevel: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: CryptLevelBodyModel;
      }
    >({
      query: (param) => ({
        url: cryptLevelEndpoints["update-crypt-level"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-level-list", "crypt-level-list-config"],
    }),
    deleteCryptLevel: builder.mutation<void, number>({
      query: (param) => ({
        url: cryptLevelEndpoints["delete-crypt-level"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-level-list", "crypt-level-list-config"],
    }),
  }),
});

export const cryptColumnSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCryptColumn: builder.mutation<void, CryptColumnBodyModel>({
      query: (body) => ({
        url: cryptColumnEndpoints["add-crypt-column"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-column-list", "crypt-column-list-config"],
    }),
    getCryptColumnList: builder.query<CryptColumnDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: cryptColumnEndpoints["crypt-column-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-column-list"],
    }),
    getCryptColumnConfigList: builder.query<CryptColumnConfigDtoModel, void>({
      query: () => ({
        url: cryptColumnEndpoints["crypt-column-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-column-list-config"],
    }),
    updateCryptColumn: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: CryptColumnBodyModel;
      }
    >({
      query: (param) => ({
        url: cryptColumnEndpoints["update-crypt-column"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-column-list", "crypt-column-list-config"],
    }),
    deleteCryptColumn: builder.mutation<void, number>({
      query: (param) => ({
        url: cryptColumnEndpoints["delete-crypt-column"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-column-list", "crypt-column-list-config"],
    }),
  }),
});

export const cryptStatusSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCryptStatus: builder.mutation<void, CryptStatusBodyModel>({
      query: (body) => ({
        url: cryptStatusEndpoints["add-crypt-status"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-status-list", "crypt-status-list-config"],
    }),
    getCryptStatusList: builder.query<CryptStatusDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: cryptStatusEndpoints["crypt-status-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-status-list"],
    }),
    getCryptStatusConfigList: builder.query<CryptStatusConfigDtoModel, void>({
      query: () => ({
        url: cryptStatusEndpoints["crypt-status-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-status-list-config"],
    }),
    updateCryptStatus: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: CryptStatusBodyModel;
      }
    >({
      query: (param) => ({
        url: cryptStatusEndpoints["update-crypt-status"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-status-list", "crypt-status-list-config"],
    }),
    deleteCryptStatus: builder.mutation<void, number>({
      query: (param) => ({
        url: cryptStatusEndpoints["delete-crypt-status"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["crypt-status-list", "crypt-status-list-config"],
    }),
  }),
});

export const cryptInformationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadCryptImage: builder.mutation<
      UploadCryptImageDtoModel,
      {
        file: FileList;
      }
    >({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append("picture", param.file[0]);
        }

        return {
          url: cryptInformationEndpoints["upload-lot-image"],
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
    addCryptInformation: builder.mutation<void, CryptInformationBodyModel>({
      query: (body) => ({
        url: cryptInformationEndpoints["add-crypt-information"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: [
        "crypt-information-list",
        "crypt-information-list-config",
      ],
    }),
    getCryptInformationList: builder.query<
      CryptInformationDtoModel,
      ListFetchQueryType
    >({
      query: (param) => ({
        url: cryptInformationEndpoints["crypt-information-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-information-list"],
    }),
    getCryptInformationListConfig: builder.query<
      CryptInformationConfigDtoModel,
      void
    >({
      query: () => ({
        url: cryptInformationEndpoints["crypt-information-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["crypt-information-list-config"],
    }),
    deleteCryptInformation: builder.mutation<void, number>({
      query: (param) => ({
        url: cryptInformationEndpoints["delete-crypt-information"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: [
        "crypt-information-list",
        "crypt-information-list-config",
      ],
    }),
    updateCryptInformation: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: CryptInformationBodyModel;
      }
    >({
      query: (param) => ({
        url: cryptInformationEndpoints["update-crypt-information"](
          param.queryParams
        ),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: [
        "crypt-information-list",
        "crypt-information-list-config",
      ],
    }),
  }),
});

export const {
  useUploadCryptImageMutation,
  useAddCryptInformationMutation,
  useGetCryptInformationListQuery,
  useLazyGetCryptInformationListQuery,
  useDeleteCryptInformationMutation,
  useUpdateCryptInformationMutation,
  useLazyGetCryptInformationListConfigQuery,
} = cryptInformationSlice;

export const {
  useAddCryptStatusMutation,
  useDeleteCryptStatusMutation,
  useGetCryptStatusConfigListQuery,
  useGetCryptStatusListQuery,
  useUpdateCryptStatusMutation,
} = cryptStatusSlice;

export const {
  useAddCryptColumnMutation,
  useDeleteCryptColumnMutation,
  useGetCryptColumnConfigListQuery,
  useGetCryptColumnListQuery,
  useUpdateCryptColumnMutation,
} = cryptColumnSlice;

export const {
  useAddCryptLevelMutation,
  useGetCryptLevelConfigListQuery,
  useGetCryptLevelListQuery,
  useUpdateCryptLevelMutation,
  useDeleteCryptLevelMutation,
} = cryptLevelSlice;
