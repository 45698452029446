import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BuyerForm } from "../../../../components/client-management/buyers/active-buyer-form/buyer-information/BuyerInformation";

interface BuyerState {
  [buyerInformation: string]: Partial<BuyerForm> | null;
}

interface PayloadState {
  key: string;
  value: Partial<BuyerForm>;
}

const initialState: BuyerState = {
  buyerInformation: null,
};

export const buyerStepSlice = createSlice({
  name: "buyerStep",
  initialState,
  reducers: {
    saveFormState: (state, action: PayloadAction<PayloadState>) => {
      console.log(action.payload);
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { saveFormState } = buyerStepSlice.actions;

export default buyerStepSlice.reducer;
