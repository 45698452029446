import {
  BuyerUnitTypeBodyModel,
  BuyerUnitTypeBodyUpdateModel,
  BuyerUnitTypeDtoModel,
} from "../../../../model/buyerModel";
import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const unitTypeEndpoints = {
  "add-unit-type": "/buyer/unit-type/add",
  "get-unit-type": (id: number) => `/buyer/unit-type/get/${id}`,
  "update-unit-type": (id: number) => `/buyer/unit-type/update/${id}`,
};

export const unitTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addUnitType: builder.mutation<void, BuyerUnitTypeBodyModel>({
      query: (body) => ({
        url: unitTypeEndpoints["add-unit-type"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "buyer-unit-type",
          id: arg.buyerId,
        },
      ],
    }),
    updateUnitType: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: BuyerUnitTypeBodyUpdateModel;
      }
    >({
      query: (param) => ({
        url: unitTypeEndpoints["update-unit-type"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "buyer-unit-type",
          id: arg.queryParams,
        },
      ],
    }),
    getUnitType: builder.query<BuyerUnitTypeDtoModel, number>({
      query: (param) => ({
        url: unitTypeEndpoints["get-unit-type"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "buyer-unit-type",
          id: arg,
        },
      ],
    }),
  }),
});

export const {
  useAddUnitTypeMutation,
  useGetUnitTypeQuery,
  useUpdateUnitTypeMutation,
} = unitTypeSlice;
