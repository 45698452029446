import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import StepperTabMenu, { StepModel } from "../common/StepperTabMenu";

import { rentalAndServicesRoute } from "../../routes/routeKeys";
import CardHeader from "../common/CardHeader";
import ScheduleCalendar from "./calendar/ScheduleCalendar";
import "./index.css";
import PriceList from "./price-list/PriceList";
import ScheduleAndPayment from "./schedule&Payment/ScheduleAndPayment";
import Inventory from "./inventory/Inventory";
import InventoryTemp from "./inventory/InventoryTemp";
import Requirements from "./requirements/Requirements";
import Payment from "./payment/Payment";

export type UnitModel = {
  type: string;
  unitTypeId: number;
  id?: null | number;
  unit: any;
};

interface Props {
  serviceType: string;
}

const RentalAndServices = ({ serviceType }: Props) => {
  // const { state: unitState } = useLocation();
  const { step, id } = useParams();
  const [steps] = useState<StepModel[]>([
    {
      key: "calendar",
      label: "Calendar",
      withPaging: false,
    },
    {
      key: "price-list",
      label: "Price List",
      withPaging: true,
    },
    {
      key: "schedule",
      label: "Schedule",
      withPaging: false,
    },
    {
      key: "inventory",
      label: "Inventory",
      withPaging: true,
    },
    {
      key: "requirements",
      label: "Requirements/Documentations",
      withPaging: false,
    },
    {
      key: "payment",
      label: "Payment",
      withPaging: false,
    },
  ]);
  const form = useForm<any>({
    defaultValues: {
      id: undefined,
      buyerPaymentId: undefined,
      paymentId: undefined,
      servicePaymentTermId: undefined,
      date: new Date(),
      time: new Date(),
      owner: "",
      unit: "",
      encodedBy: "",
      deceasedId: "",
      assistedBy: "",
      coordinatedBy: "",
      price: "",
      rateType: {
        label: "Regular Rate",
        value: "REGULAR",
      },
      paymentType: "",
      paymentTerms: "",
      paymentTypeAmount: "",
      fullPaymentAmount: "",
      downPaymentAmount: "",
      bankName: "",
      chequeNumber: "",
      chequeDate: null,
      serviceDetails: {
        price: "",
        payment: "",
        discounts: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        promo: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        balance: "",
      },
      receipt: "",
      funeralService: undefined,
      placeOfWake: "",
      casket: "Standard",
      casketHeight: "",
      casketWidth: "",
      casketLength: ""
    },
  });

  return (
    <Paper elevation={3}>
      <CardHeader title={`${_.capitalize(serviceType)}`} />
      <Box paddingTop={4} paddingBottom={2}>
        <StepperTabMenu<UnitModel>
          disabled={false}
          steps={steps}
          path={`${rentalAndServicesRoute}/${serviceType.toLowerCase()}`}
          // state={unitState}
        />
      </Box>
      <Box paddingY={2} paddingX={3}>
        {step === "calendar" && (
          <ScheduleCalendar serviceType={serviceType} scheduleForm={form} />
        )}
        {step === "price-list" && (
          <PriceList serviceType={serviceType} scheduleForm={form} />
        )}
        {step === "requirements" && (
          <Requirements serviceType={serviceType} scheduleForm={form} />
        )}
        {step === "schedule" && (
          <ScheduleAndPayment serviceType={serviceType} form={form} />
        )}
        {step === "inventory" && (
          <InventoryTemp serviceType={serviceType} scheduleForm={form} />
        )}
        {step === "payment" && (
          <Payment serviceType={serviceType} form={form} />
        )}
      </Box>
    </Paper>
  );
};

export default RentalAndServices;
