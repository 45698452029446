import {
  AuthModel,
  LoginFormRequestType,
  AuthDtoModel,
} from "../../model/authModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../apiSlice";
import { saveToStorage } from "../../utils/helpers/storageHelper";
import {
  gg_menu_key,
  gg_user_cred_key,
  refresh_token_key,
  token_key,
} from "../../utils/constants";
import { MainMenuModel } from "../../model/menuModel";

export const authEndPoints = {
  login: "/login",
  "forgot-password": "/user/forgot/password",
  "refresh-token": "/refresh-token",
};

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<Partial<AuthDtoModel>, LoginFormRequestType>({
      query: (body) => ({
        url: authEndPoints.login,
        method: "POST",
        body,
      }),
      transformResponse: (response: {
        data: {
          user: AuthModel;
          token: string;
          menus: MainMenuModel[];
          refreshToken: string;
        };
        msg?: string;
        success?: boolean;
      }) => {
        if (!response.success) {
          return {
            message: response.msg,
            success: response.success,
          };
        }
        saveToStorage<string>(token_key, response.data.token);
        saveToStorage<AuthModel>(gg_user_cred_key, response.data.user);
        saveToStorage<MainMenuModel[]>(gg_menu_key, response.data.menus);
        saveToStorage<string>(refresh_token_key, response.data.refreshToken);
        return {
          user: response.data.user,
          menu: response.data.menus,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    forgotPassword: builder.mutation<
      void,
      {
        email: string;
      }
    >({
      query: (body) => ({
        url: authEndPoints["forgot-password"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useLoginUserMutation, useForgotPasswordMutation } = authSlice;
