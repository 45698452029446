import {
  ListFetchQueryType,
  ListFetchDtoType,
  DocLinkFetchDtoType,
  FetchDtoType,
} from "../../../../model/global";
import {
  AddVoucherReturnType,
  GetVoucherModel,
  VoucherBody,
  VoucherModel,
} from "../../../../model/voucherModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

interface VoucherListQuery extends ListFetchQueryType {
  type: "CASH" | "CHECK";
}

export const endpoints = {
  "add-voucher": (type: "CASH" | "CHECK") =>
    `/account-management/payment/voucher/${type}/add`,
  "print-voucher": (id: number) =>
    `/account-management/payment/voucher/print/${id}`,
  "voucher-list": (
    type: "CASH" | "CHECK",
    page: number,
    entries: number,
    search: string
  ) =>
    `/account-management/payment/voucher/${type}/list?currentPage=${page}&perPage=${entries}&search=${search}`,
  "voucher-details": (id: number) =>
    `/account-management/payment/voucher/get/${id}`,
  "update-voucher": (id: number) =>
    `/account-management/payment/voucher/update/${id}`,
};

export const vouchersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVoucher: builder.mutation<
      AddVoucherReturnType,
      { body: VoucherBody; type: "CASH" | "CHECK" }
    >({
      query: (body) => ({
        url: endpoints["add-voucher"](body.type),
        method: "POST",
        body: body.body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        { type: "voucher-list", id: args.type },
      ],
    }),
    getVoucherDoc: builder.query<
      DocLinkFetchDtoType,
      { id: number; type: "CASH" | "CHECK" }
    >({
      query: (param) => ({
        url: endpoints["print-voucher"](param.id),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "voucher-doc", id: `${args.type}-${args.id}` },
      ],
    }),
    getVoucherList: builder.query<
      ListFetchDtoType<VoucherModel[]>,
      VoucherListQuery
    >({
      query: (params) => ({
        url: endpoints["voucher-list"](
          params.type,
          params.page,
          params.entries,
          params.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "voucher-list", id: args.type },
      ],
    }),
    getVoucherDetails: builder.query<
      FetchDtoType<GetVoucherModel>,
      { id: number; type: "CASH" | "CHECK" }
    >({
      query: (param) => ({
        url: endpoints["voucher-details"](param.id),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "voucher-details", id: `${args.type}-${args.id}` },
      ],
    }),
    updateVoucher: builder.mutation<
      void,
      { id: number; type: "CASH" | "CHECK"; body: VoucherBody }
    >({
      query: (body) => ({
        url: endpoints["update-voucher"](body.id),
        method: "PUT",
        body: body.body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        { type: "voucher-details", id: `${args.type}-${args.id}` },
        { type: "voucher-list", id: args.type },
        { type: "voucher-doc", id: `${args.type}-${args.id}` },
      ],
    }),
  }),
});

export const {
  useAddVoucherMutation,
  useLazyGetVoucherDocQuery,
  useGetVoucherListQuery,
  useGetVoucherDetailsQuery,
  useUpdateVoucherMutation,
} = vouchersSlice;
