import React, { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  changePhotoText,
  thisFieldRequired,
  uploadPhotoText,
} from "../../../utils/constants";
import { getBase64 } from "../../../utils/helpers/getBase64";
import {
  Path,
  UseFormClearErrors,
  UseFormSetValue,
  FieldValues,
  FieldPath,
} from "react-hook-form";

interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  setValue: any;
  clearErrors: UseFormClearErrors<TFieldValues>;
  pictureValue: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
  };
  errorMsg?: string;
  isDisabled?: boolean;
  chooseText?: string;
  changeText?: string;
  width?: string;
  height?: string;
}

const UploadPhotoField = <TFieldValues extends FieldValues>({
  setValue,
  name,
  clearErrors,
  pictureValue,
  errorMsg,
  isDisabled = false,
  changeText = changePhotoText,
  chooseText = uploadPhotoText,
  width = "250px",
  height = "250px",
}: Props<TFieldValues>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const muiTheme = useTheme();

  const handlePictureChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      const file = await getBase64(e.target.files[0]);
      setValue(name, {
        file: file ? file.toString() : "",
        filetype: "",
        originalName: "",
        rawFile: e.target.files,
      });
      clearErrors([name]);
    }
  };

  return (
    <div>
      <Box
        sx={(theme) => ({
          border: `1px solid ${
            errorMsg ? theme.palette.error.main : theme.palette.primary.main
          }`,
          borderRadius: theme.spacing(1),
          width,
          height,
          padding: theme.spacing(1),
          backgroundColor: theme.palette.grey[100],
        })}
      >
        {pictureValue?.file && (
          <img
            src={pictureValue.file as string}
            alt="pic"
            style={{
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
              borderRadius: muiTheme.spacing(0.7),
            }}
          />
        )}
      </Box>
      <form encType="multipart/form-data">
        <input
          accept="image/*"
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handlePictureChange}
        />
      </form>
      {errorMsg && <FormHelperText error>{thisFieldRequired}</FormHelperText>}
      <Button
        variant="button-secondary"
        sx={(theme) => ({ width, marginTop: theme.spacing(1) })}
        onClick={() => inputRef.current?.click()}
        disabled={isDisabled}
      >
        {pictureValue?.file ? changeText : chooseText}
      </Button>
    </div>
  );
};

export default UploadPhotoField;
