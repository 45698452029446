import _ from "lodash";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import RichTextEditor from "../../common/textfields/RichTextEditor";
import { LeadIdType } from "./Leads";
import SelectedLead from "./SelectedLead";
import { useSendEmailLeadMutation } from "../../../service/slice/client-management/lead/leadSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { thisFieldRequired } from "../../../utils/constants";
import { fireEvent } from "@testing-library/react";

interface FormType {
  subject: string;
  message: string;
}

type Props = {
  leadIds: LeadIdType[];
  handleCheckSelect: (
    lead: LeadIdType,
    isChecked: boolean,
    clearLead?: boolean
  ) => void;
};

const LeadSendEmail = ({ leadIds, handleCheckSelect }: Props) => {
  const form = useForm<FormType>({
    defaultValues: {
      subject: "",
      message: "",
    },
  });

  const {
    control,
    setValue,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors },
    reset: fieldReset,
  } = form;

  const [
    sendMutate,
    {
      isLoading: sendIsLoading,
      error: sendError,
      reset: sendReset,
      isSuccess: sendIsSuccess,
    },
  ] = useSendEmailLeadMutation();

  const messageWatch = useWatch({
    control,
    name: "message",
  });

  const onError = () => {
    if (_.isEmpty(messageWatch)) {
      setError("message", {
        type: "required",
        message: thisFieldRequired,
      });
    }
  };

  const onSubmit: SubmitHandler<FormType> = (dataField) => {
    if (_.isEmpty(messageWatch)) {
      setError("message", {
        type: "required",
        message: thisFieldRequired,
      });

      return;
    }

    sendMutate({
      leadIds: leadIds.map((item) => item.id),
      message: dataField.message,
      subject: dataField.subject,
    });
  };

  if (sendError) {
    errorDisplayOrNavigate({
      error: sendError,
      toastId: "send-email",
    });

    sendReset();
  }

  if (sendIsSuccess) {
    showToastSuccess({
      text: "Email sent!",
      toastId: "send-email",
    });

    sendReset();
    fieldReset();
    setValue("message", "");
    handleCheckSelect(
      {
        id: 0,
        refNum: "",
      },
      false,
      true
    );
  }

  return (
    <>
      <Divider
        sx={(theme) => ({
          marginY: theme.spacing(4),
        })}
      />
      {leadIds.length > 0 ? (
        <SelectedLead
          leads={leadIds}
          handleCheckSelect={handleCheckSelect}
          disabled={sendIsLoading}
        />
      ) : (
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.grey[400],
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "1rem",
            paddingBottom: 4,
          })}
        >
          Select a lead
        </Typography>
      )}
      <FormProvider {...form}>
        <Stack spacing={2}>
          <div>
            <Typography variant="textfieldLabel">Subject</Typography>
            <Controller
              name="subject"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: thisFieldRequired,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="Subject"
                  error={error?.message ? true : false}
                  helperText={error?.message}
                  disabled={leadIds.length === 0}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            />
          </div>
          <div>
            <Typography variant="textfieldLabel">Message</Typography>
            <RichTextEditor
              name="message"
              setValue={setValue}
              clearErrors={clearErrors}
              value={messageWatch}
              isDisabled={leadIds.length === 0}
              errorMsg={errors.message?.message}
            />
          </div>
          <Button
            variant="button-primary"
            disabled={leadIds.length === 0}
            sx={{
              width: 150,
            }}
            startIcon={
              sendIsLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={handleSubmit(onSubmit, onError)}
          >
            Send Email
          </Button>
        </Stack>
      </FormProvider>
    </>
  );
};

export default LeadSendEmail;
