import React, { useEffect, useState } from "react";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";

import CardHeader from "../common/CardHeader";
import {
  domainLink,
  emailLabel,
  enterValidMobileNum,
  thisFieldRequired,
} from "../../utils/constants";
import ContactPersons from "./ContactPersons";
import UploadPhotoField from "../common/textfields/UploadPhotoField";
import CustomFileUploader from "../common/textfields/CustomFileUploader";
import Divider from "@mui/material/Divider";
import RichTextEditor from "../common/textfields/RichTextEditor";
import {
  profileEndPoints,
  useGetCompanyProfileQuery,
  useUploadLogoMutation,
  useUpdateCompanyProfileMutation,
} from "../../service/slice/profileSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../utils/notificationToast";
import { LoaderWithText } from "../common/Loaders";
import {
  CompanyProfileBodyModel,
  UploadLogoDtoModel,
} from "../../model/companyProfileModel";
import DocumentItem from "./DocumentItem";
import FileViewModal from "../common/FileViewModal";

export interface FormType {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  contactPersons: {
    id: number | null;
    phoneNumber: string;
    name: string;
  }[];
  documents: {
    id: number | null;
    path: string;
  }[];
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
  address: string;
  district: string;
  city: string;
  region: string;
  country: string;
  zipCode: string;
  header: string;
  footer: string;
}

const CompanyProfile = () => {
  const [viewDoc, setViewDoc] = useState<{
    open: boolean;
    path: string;
  }>({
    open: false,
    path: "",
  });
  const form = useForm<FormType>({
    defaultValues: {
      companyName: "",
      firstName: "",
      lastName: "",
      address: "",
      district: "",
      city: "",
      region: "",
      country: "",
      zipCode: "",
      email: "",
      contactPersons: [
        {
          phoneNumber: "",
          name: "",
        },
      ],
      picture: {
        file: null,
        filetype: null,
        originalName: null,
        rawFile: null,
      },
      documents: [],
      header: "",
      footer: "",
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
    handleSubmit,
    reset: fieldReset,
    setError,
  } = form;

  const {
    data: companyProfileData,
    isLoading: companyProfileIsLoading,
    error: companyProfileError,
  } = useGetCompanyProfileQuery();

  const [
    uploadLogoMutate,
    {
      isLoading: uploadLogoIsLoading,
      error: uploadLogoError,
      reset: uploadLogoReset,
    },
  ] = useUploadLogoMutation();

  const [
    updateCompanyMutate,
    {
      isLoading: updateCompanyIsLoading,
      error: updateCompanyError,
      reset: updateCompanyReset,
      isSuccess: updateCompanyIsSuccess,
    },
  ] = useUpdateCompanyProfileMutation();

  const initLoading = companyProfileIsLoading;
  const updateLoading = uploadLogoIsLoading || updateCompanyIsLoading;

  const contactPersonsWatch = useWatch({
    control,
    name: "contactPersons",
  });

  const pictureWatch = useWatch({
    control,
    name: "picture",
  });

  const headerWatch = useWatch({
    control,
    name: "header",
  });

  const footerWatch = useWatch({
    control,
    name: "footer",
  });

  const documentsWatch = useWatch({
    control,
    name: "documents",
  });

  const handleToggleViewDoc = (open: boolean, path: string) =>
    setViewDoc({
      open,
      path,
    });

  const handleDeleteDoc = (index: number) => {
    const newDocs = [...documentsWatch].filter((item, i) => i !== index);
    setValue("documents", newDocs);
  };

  const validateOtherFields = (): number[] => {
    const errorsArr: number[] = [];

    contactPersonsWatch.forEach((item, key) => {
      if (!_.isEmpty(item.phoneNumber)) {
        const formatVal = item.phoneNumber
          .replaceAll(" ", "")
          .replace("+63", "");
        if (formatVal.length > 10 || formatVal.length < 10) {
          setError(`contactPersons.${key}.phoneNumber`, {
            type: "required",
            message: enterValidMobileNum,
          });

          errorsArr.push(1);
        }
      }
    });

    return errorsArr;
  };

  const submitUpdate: SubmitHandler<FormType> = async (data) => {
    const otherErrors = validateOtherFields();

    if (otherErrors.length > 0) {
      return;
    }

    let dataPic: null | UploadLogoDtoModel = null;
    const newBody: Partial<CompanyProfileBodyModel> = {
      name: data.companyName,
      address: data.address,
      city: data.city,
      contact: data.contactPersons.map((item) => {
        const contactData = item.id
          ? {
              id: item.id,
              name: item.name,
              telephone: item.phoneNumber,
            }
          : {
              name: item.name,
              telephone: item.phoneNumber,
            };

        return contactData;
      }),
      country: data.country,
      district: data.district,
      footer: data.footer,
      header: data.header,
      document: data.documents.map((item) => {
        const docData = item.id
          ? {
              id: item.id,
              path: item.path.includes(domainLink)
                ? item.path.replace(domainLink, "")
                : item.path,
            }
          : {
              path: item.path.includes(domainLink)
                ? item.path.replace(domainLink, "")
                : item.path,
            };

        return docData;
      }),
      region: data.region,
      zipCode: data.zipCode,
    };

    if (data.picture.rawFile) {
      dataPic = await uploadLogoMutate(data.picture.rawFile).unwrap();
    }

    await updateCompanyMutate({
      queryParam: (companyProfileData?.data.id as number) ?? 1,
      bodyParam: {
        ...newBody,
        logo: data.picture.rawFile
          ? (dataPic?.data.link as string)
          : (data.picture.file?.replace(domainLink, "") as string),
      },
    });
  };

  if (updateCompanyIsSuccess) {
    showToastSuccess({
      text: "Company profile successfully updated.",
      toastId: "company-profile",
    });

    fieldReset();
    updateCompanyReset();
  }

  if (companyProfileError) {
    errorDisplayOrNavigate({
      error: companyProfileError,
      toastId: "company-profile",
    });
  }

  if (uploadLogoError) {
    errorDisplayOrNavigate({
      error: uploadLogoError,
      toastId: "upload-logo",
    });

    uploadLogoReset();
  }

  if (updateCompanyError) {
    errorDisplayOrNavigate({
      error: updateCompanyError,
      toastId: "update-company",
    });

    updateCompanyReset();
  }

  useEffect(() => {
    if (companyProfileData) {
      const {
        name,
        address,
        district,
        city,
        region,
        country,
        zipCode,
        logo,
        header,
        footer,
        companyContacts,
        companyDocuments,
      } = companyProfileData.data;
      setValue("companyName", name);
      setValue("address", address);
      setValue("district", district);
      setValue("city", city);
      setValue("region", region);
      setValue("country", country);
      setValue("zipCode", zipCode);
      setValue("picture", {
        file: logo,
        originalName: "",
        filetype: "",
        rawFile: null,
      });
      setValue("header", header ?? "");
      setValue("footer", footer ?? "");
      setValue(
        "contactPersons",
        companyContacts.length > 0
          ? companyContacts.map((item) => ({
              id: item.id,
              name: item.name,
              phoneNumber: item.telephone,
            }))
          : [
              {
                id: null,
                phoneNumber: "",
                name: "",
              },
            ]
      );
      setValue(
        "documents",
        companyDocuments.length > 0
          ? companyDocuments.map((item) => ({
              id: item.id,
              path: item.path,
            }))
          : []
      );
    }
  }, [companyProfileData]);

  return (
    <>
      <FileViewModal
        open={viewDoc.open}
        path={viewDoc.path}
        title={viewDoc.path.substring(
          viewDoc.path.lastIndexOf("/") + 1,
          viewDoc.path.length
        )}
        onClose={() => handleToggleViewDoc(false, "")}
      />
      <Paper elevation={3}>
        <CardHeader title="Company Information" />
        <Box padding={3}>
          {initLoading ? (
            <LoaderWithText text="Getting profile details" />
          ) : (
            <FormProvider {...form}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={4}>
                  <Stack direction="column" spacing={2}>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Company Name
                      </Typography>
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Company Name"
                            error={errors.companyName ? true : false}
                            helperText={errors.companyName?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">
                        {emailLabel}
                      </Typography>
                      <Controller
                        name="email"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="email"
                            placeholder={emailLabel}
                            error={errors.email ? true : false}
                            helperText={errors.email?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <ContactPersons
                      control={control}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      errors={errors}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                  <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Company Logo
                      </Typography>
                      <UploadPhotoField
                        name="picture"
                        setValue={setValue}
                        clearErrors={clearErrors}
                        pictureValue={pictureWatch}
                        errorMsg={errors.picture?.message}
                        changeText="Change logo"
                        chooseText="Select logo"
                      />
                    </Box>
                    <Box width="100%">
                      <Typography variant="textfieldLabel">
                        Company Documents
                      </Typography>
                      <CustomFileUploader
                        name="documents"
                        setValue={setValue}
                        clearErrors={clearErrors}
                        // fileValues={documentsWatch.file}
                        errorMsg={errors.documents?.message}
                        endpoint={profileEndPoints["upload-doc"]}
                        getValues={getValues}
                      />
                      <Divider
                        sx={{
                          paddingY: 2,
                          "& span": {
                            fontSize: ".75rem",
                          },
                        }}
                      >
                        Documents
                      </Divider>
                      {documentsWatch.length === 0 ? (
                        <Typography
                          textAlign="center"
                          fontWeight={500}
                          color="grey.400"
                        >
                          No Uploaded Documents
                        </Typography>
                      ) : (
                        <Grid container spacing={2}>
                          {documentsWatch.map((doc, key) => (
                            <Grid item xs={6} key={key}>
                              <DocumentItem
                                path={doc.path}
                                onClickEvent={() =>
                                  handleToggleViewDoc(true, doc.path)
                                }
                                handleDelete={handleDeleteDoc}
                                index={key}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography variant="textfieldLabel">District</Typography>
                      <Controller
                        name="district"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="District"
                            error={errors.district ? true : false}
                            helperText={errors.district?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography variant="textfieldLabel">City</Typography>
                      <Controller
                        name="city"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="City"
                            error={errors.city ? true : false}
                            helperText={errors.city?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography variant="textfieldLabel">Region</Typography>
                      <Controller
                        name="region"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Region"
                            error={errors.region ? true : false}
                            helperText={errors.region?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography variant="textfieldLabel">Country</Typography>
                      <Controller
                        name="country"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Country"
                            error={errors.country ? true : false}
                            helperText={errors.country?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Typography variant="textfieldLabel">Address</Typography>
                      <Controller
                        name="address"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            // multiline
                            // rows={2}
                            placeholder="Address"
                            error={errors.address ? true : false}
                            helperText={errors.address?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Typography variant="textfieldLabel">Zip Code</Typography>
                      <Controller
                        name="zipCode"
                        control={control}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            placeholder="Zip Code"
                            error={errors.zipCode ? true : false}
                            helperText={errors.zipCode?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography variant="textfieldLabel">Header</Typography>
                      <RichTextEditor
                        name="header"
                        setValue={setValue}
                        clearErrors={clearErrors}
                        value={headerWatch}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography variant="textfieldLabel">Footer</Typography>
                      <RichTextEditor
                        name="footer"
                        setValue={setValue}
                        clearErrors={clearErrors}
                        value={footerWatch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                sx={(theme) => ({
                  marginY: theme.spacing(3),
                })}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="button-primary"
                  sx={{
                    width: 150,
                  }}
                  startIcon={
                    updateLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={
                    updateLoading ? () => {} : handleSubmit(submitUpdate)
                  }
                >
                  Update
                </Button>
              </Stack>
            </FormProvider>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default CompanyProfile;
