import React from "react";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import {
  Controller,
  useFieldArray,
  useWatch,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import IconButtonMdi from "../../common/IconButtonMdi";
import { thisFieldRequired } from "../../../utils/constants";

import { PaymentForm } from "./PaymentsChartsForm";

type Props = {
  control: Control<PaymentForm>;
  clearErrors: UseFormClearErrors<PaymentForm>;
  setValue: UseFormSetValue<PaymentForm>;
};

const RequiredEntries = ({ control, clearErrors, setValue }: Props) => {
  const theme = useTheme();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "requiredFields",
  });

  const requiredFieldsWatch = useWatch({
    control,
    name: "requiredFields",
  });

  const handleAddRemoveRequired = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        value: "",
      });
    }
  };

  return (
    <>
      {fields.map((item, key) => (
        <Stack
          direction="row"
          spacing={1}
          key={key}
          paddingTop={key === 0 ? 0 : 3}
        >
          <IconButtonMdi
            mdiIcon={
              key + 1 === requiredFieldsWatch.length
                ? mdiPlusCircleOutline
                : mdiMinusCircleOutline
            }
            iconColor={
              key + 1 === requiredFieldsWatch.length
                ? theme.palette.info.main
                : theme.palette.error.main
            }
            onClick={() =>
              handleAddRemoveRequired(
                key + 1 === requiredFieldsWatch.length ? null : key
              )
            }
          />
          <Box width="100%">
            <Stack spacing={2}>
              <Controller
                name={`requiredFields.${key}.value`}
                control={control}
                rules={{
                  required: {
                    value: _.isEmpty(requiredFieldsWatch[0].value),
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    value={requiredFieldsWatch[key]?.value ?? ""}
                    // placeholder="Cheque Number"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
              {/* <Controller
                name={`requiredFields.${key}.bank`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    value={requiredFieldsWatch[key]?.bank ?? ""}
                    placeholder="Bank"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
              <Controller
                name={`requiredFields.${key}.chequeDate`}
                control={control}
                disabled={false}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      value={requiredFieldsWatch[key]?.chequeDate}
                      slotProps={{
                        textField: {
                          placeholder: "Cheque Date",
                          variant: "outlined",
                          error: fieldState.error ? true : false,
                          helperText: fieldState.error?.message,
                        },
                      }}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name={`requiredFields.${key}.amount`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    value={requiredFieldsWatch[key]?.amount ?? ""}
                    placeholder="Amount"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              /> */}
            </Stack>
          </Box>
        </Stack>
      ))}
    </>
  );
};

export default RequiredEntries;
