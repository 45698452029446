import React from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import OneAv from "../../../asset/avatars/1.png";
import TwoAv from "../../../asset/avatars/2.png";
import ThreeAv from "../../../asset/avatars/3.png";

type Props = {};

interface tableType {
  taskId: string;
  serviceDate: string;
  client: string;
  clientEmail: string;
  avatar: string;
  taskName: string;
}

const tableData: tableType[] = [
  {
    taskId: "#2798",
    serviceDate: "Dec 2, 2023",
    client: "Joseph Wheeler",
    clientEmail: "nutoani@icpair.com",
    avatar: OneAv,
    taskName: "",
  },
  {
    taskId: "#1304",
    serviceDate: "Dec 5, 2023",
    client: "May Lloyd",
    clientEmail: "jeju@ma.co.uk",
    avatar: TwoAv,
    taskName: "",
  },
  {
    taskId: "#7900",
    serviceDate: "Dec 10, 2023",
    client: "William Mckinney",
    clientEmail: "cidagehe@nonaldo.com",
    avatar: ThreeAv,
    taskName: "",
  },
];

const TaskDashboard = (props: Props) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Task ID
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Client / Desceased
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Task Name
              </TableCell>
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(even)": {
                    backgroundColor: "grey.100",

                    td: {
                      backgroundColor: "grey.100",
                    },
                  },
                  "&:nth-of-type(odd) td": {
                    backgroundColor: "common.white",
                  },
                }}
              >
                <TableCell>{item.taskId}</TableCell>
                <TableCell>{item.serviceDate}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <img
                      src={item.avatar}
                      alt={item.client}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "100px",
                      }}
                    />
                    <Box>
                      <Typography
                        sx={(theme) => ({
                          fontSize: ".95rem",
                          fontWeight: 500,
                        })}
                      >
                        {item.client}
                      </Typography>
                      <Typography
                        sx={(theme) => ({
                          fontSize: ".7rem",
                          color: theme.palette.grey[500],
                        })}
                      >
                        {item.clientEmail}
                      </Typography>
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell>{item.taskName}</TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    right: 0,
                    zIndex: 800,
                    width: "auto",
                  }}
                >
                  <TextField
                    select
                    defaultValue="New"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <MenuItem value="New">New</MenuItem>
                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                    <MenuItem value="Done">Done</MenuItem>
                  </TextField>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TaskDashboard;
