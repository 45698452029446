import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const CustomTab = styled(Tab)(({ theme }) => ({
  // width: "100%",
  color: theme.palette.common.white,
  fontWeight: 600,
  fontSize: ".85rem",
  backgroundColor: theme.palette.primary.main,
  borderRadius: 4,
  padding: "8px 16px",
  minHeight: "100%",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(255,255,255,.2)",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.common.white,
  },
}));

export interface ItemsProps {
  hasOtherQuery?: boolean;
  label: string;
  additionalQuery?: string;
}

const CustomTabMenu = ({
  path,
  items,
}: {
  path: string;
  items: ItemsProps[];
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const item = items[newValue];
    navigate(
      item?.hasOtherQuery === true
        ? `${path}${newValue + 1}${item.additionalQuery}`
        : `${path}${newValue + 1}`
    );
  };

  return (
    <Tabs
      value={tabValue === -1 ? 0 : tabValue}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        borderRadius: 1,
        padding: 1,
        "& .MuiTabs-flexContainer": {
          gap: 1,
        },
        "& .MuiTabs-scrollButtons": {
          color: theme.palette.common.white,
        },
      })}
    >
      {items.map((item, key) => (
        <CustomTab
          key={key}
          label={item.label}
          {...a11yProps(key)}
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default CustomTabMenu;
