import _ from "lodash";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import ConfirmModal from "../../common/ConfirmModal";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import {
  useDeletePriceListMutation,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import CardHeader from "../../common/CardHeader";
import Locations from "../inventory-configuration/Locations";

const headerLabels: string[] = ["SKU", "Asset Name", "Total Quantity"];

const AssetLocation = () => {
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<PriceListModel>();

  // const {
  //   data: listData,
  //   isLoading: listIsLoading,
  //   error: listError,
  //   isFetching: listIsFetching,
  // } = useGetPriceListQuery({
  //   type: serviceType.toUpperCase(),
  //   entries: entriesParam,
  //   page: pageParam - 1,
  //   search: searchParam ?? "",
  // });

  const listData: any = { data: { rows: [{}] } };
  const listIsLoading = false;
  const listError = false;
  const listIsFetching = false;

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePriceListMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data?.rows ?? [];
  const totalData = listData?.data?.count ?? 0;

  const confirmDelete = () => deleteMutate(deleteItem.data?.id as number);

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} successfully deleted`,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
  }

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "rental-services-price-list",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      <Paper elevation={3}>
        <CardHeader title="Asset Location" />
        <Box padding={3}>
          <Locations route="/asset-location" />
        </Box>
      </Paper>
    </>
  );
};

export default AssetLocation;
