import { useState } from "react";

interface DeleteType<T> {
  open: boolean;
  data: T | null;
}

const useDeleteTableItem = <T>() => {
  const [deleteItem, setDeleteItem] = useState<DeleteType<T>>({
    open: false,
    data: null,
  });

  const handleToggleDelete = (open: boolean, data: T | null) =>
    setDeleteItem({
      open,
      data,
    });

  return [deleteItem, handleToggleDelete] as const;
};

export default useDeleteTableItem;
