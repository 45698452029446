import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import { TransitionProps } from "@mui/material/transitions";
import Stack from "@mui/material/Stack";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import {
  ServicePaymentModel,
  UnitPaymentModel,
} from "../../../model/accountManagementPaymentModel";
import { LoaderWithText } from "../../common/Loaders";
import PaymentForm from "./PaymentForm";
import PaymentSchedule from "./PaymentSchedule";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import PayModalUpload from "./PayModalUpload";
import { useGetPaymentDetailsUnitQuery } from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import { errorDisplayOrNavigate } from "../../../utils/notificationToast";
import { commaFormatted } from "../../../utils/helpers/stringManipulate";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  onClose: () => void;
  unitPaymentData?: UnitPaymentModel | null;
  servicePaymentData?: ServicePaymentModel | null;
  type: "unit" | "service";
};

const unitDetails = (unit: any) => {
  if (unit?.lotCategory) {
    return (
      <>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Unit Type:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {`${unit.lotCategory.name} - ${unit.lotType.name}`}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Phase:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.phase}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Block:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.block}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Section:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.section}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Lot No.:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.size}
          </Typography>
        </Typography>
      </>
    );
  } else if (unit.vaultColumn) {
    return (
      <>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Unit Type:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {`${unit.vaultLevel.name} - ${unit.vaultColumn.name}`}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Column:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.vaultColumn.name}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Level:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.vaultLevel.name}
          </Typography>
        </Typography>
      </>
    );
  } else if (unit.cryptColumn) {
    return (
      <>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Unit Type:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {`${unit.cryptLevel.name} - ${unit.cryptColumn.name}`}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Column:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.cryptColumn.name}
          </Typography>
        </Typography>
        <Typography variant="body1" marginBottom={0.5}>
          <Typography component="span" marginRight={1} variant="textSm">
            Level:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
            })}
          >
            {unit.cryptLevel.name}
          </Typography>
        </Typography>
      </>
    );
  } else {
    return "";
  }
};

const PayModal = ({
  open,
  onClose,
  unitPaymentData = null,
  servicePaymentData = null,
  type,
}: Props) => {
  const [openUpload, handleToggleUpload] = useOpenCreateForm<number>();

  const {
    data: detailsData,
    isLoading: detailsIsLoading,
    isFetching: detailsIsFetching,
    error: detailsError,
    refetch: detailsRefetch,
  } = useGetPaymentDetailsUnitQuery({
    id: unitPaymentData
      ? unitPaymentData?.id
      : servicePaymentData
      ? servicePaymentData?.id
      : 0,
    type,
  });

  const loading = detailsIsLoading || detailsIsFetching;

  if (detailsError) {
    errorDisplayOrNavigate({
      error: detailsError,
      toastId: "payment-details",
    });
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: "90vw",
          maxWidth: "90vw",
          height: "90vh",
          borderRadius: 2,
        },
      })}
    >
      {loading ? (
        <Box paddingX={2} height="90vh">
          <Stack alignItems="center" justifyContent="center" height="90vh">
            <LoaderWithText text="Get Additional Details.." />
          </Stack>
        </Box>
      ) : (
        <>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1">
                <Typography component="span" marginRight={1}>
                  Pay - Reference No.:
                </Typography>
                <Typography
                  component="span"
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  })}
                >
                  {unitPaymentData?.referenceNumber ??
                    servicePaymentData?.referenceNumber}
                </Typography>
              </Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "24px",
                }}
                onClick={onClose}
              >
                <Icon path={mdiClose} size={1} />
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box padding={2} paddingTop={1}>
              {openUpload.open ? (
                <PayModalUpload
                  handleToggleUpload={() => handleToggleUpload(false, null, "")}
                  id={openUpload.data ?? 0}
                  type={type}
                />
              ) : (
                <>
                  <Box marginBottom={4}>
                    <Alert severity="info" icon={false}>
                      <Typography variant="body1" marginBottom={2}>
                        <Typography
                          component="span"
                          marginRight={1}
                          variant="textSm"
                        >
                          Buyer:
                        </Typography>
                        <Typography
                          component="span"
                          variant="textSm"
                          sx={(theme) => ({
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          })}
                        >
                          {detailsData?.data.name ?? ""}
                        </Typography>
                      </Typography>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={{ xs: 2, md: 6, lg: 8 }}
                      >
                        <div>{unitDetails(detailsData?.data.unit)}</div>
                        <div>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Lot Price:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {commaFormatted(detailsData?.data.price ?? "")}
                            </Typography>
                          </Typography>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              PCF:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {commaFormatted(detailsData?.data.pcf ?? "")}
                            </Typography>
                          </Typography>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Total:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {detailsData?.data.total
                                ? commaFormatted(
                                    detailsData.data.total.toString()
                                  )
                                : ""}
                            </Typography>
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Reservation:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {commaFormatted(
                                detailsData?.data.reservation ?? ""
                              )}
                            </Typography>
                          </Typography>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Downpayment:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {commaFormatted(
                                detailsData?.data.downpayment ?? ""
                              )}
                            </Typography>
                          </Typography>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Balance:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {detailsData?.data.balanceWithPayment
                                ? commaFormatted(
                                    detailsData.data.balanceWithPayment.toString()
                                  )
                                : ""}
                            </Typography>
                          </Typography>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Terms of Payment:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {detailsData?.data.term ?? ""}
                            </Typography>
                          </Typography>
                          <Typography variant="body1" marginBottom={0.5}>
                            <Typography
                              component="span"
                              marginRight={1}
                              variant="textSm"
                            >
                              Interest Rate:
                            </Typography>
                            <Typography
                              component="span"
                              variant="textSm"
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              })}
                            >
                              {detailsData?.data.term
                                ? `${detailsData?.data.interest}%`
                                : ""}
                            </Typography>
                          </Typography>
                        </div>
                      </Stack>
                    </Alert>
                  </Box>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        fontWeight={600}
                        color={"primary"}
                        marginBottom={1}
                      >
                        Payment
                      </Typography>
                      <PaymentForm
                        id={
                          unitPaymentData
                            ? unitPaymentData.id
                            : servicePaymentData
                            ? servicePaymentData.id
                            : 0
                        }
                        type={type}
                        detailsData={detailsData}
                        detailsRefetch={detailsRefetch}
                      />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <Typography
                        fontWeight={600}
                        color={"primary"}
                        marginBottom={1}
                      >
                        Payment Schedule
                      </Typography>
                      <PaymentSchedule
                        handleToggleUpload={() =>
                          handleToggleUpload(
                            true,
                            detailsData?.data.id ?? 0,
                            ""
                          )
                        }
                        schedules={detailsData?.data.payments ?? []}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </DialogContent>
          {openUpload.open && (
            <DialogActions>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
                paddingX={2}
                paddingY={1}
                justifyContent={{
                  xs: "flex-start",
                }}
              >
                <Button
                  variant="button-secondary"
                  onClick={() => handleToggleUpload(false, null, "")}
                  sx={{ width: 150 }}
                >
                  Back
                </Button>
              </Stack>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default PayModal;
