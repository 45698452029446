import {
  AddBuyerPaymentDtoModel,
  AddPaymentDocBody,
  BuyerPaymentBody,
  BuyerPaymentDtoModel,
  BuyerPaymentReceiptDtoModel,
  PaymentDocDtoModel,
} from "../../../../model/buyerModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const buyerPaymentEndpoints = {
  "add-payment": "/buyer/payment/add",
  "get-payment": (id: number) => `/buyer/payment/get/${id}`,
  "get-receipt": (id: number, receiptType: string) =>
    `/buyer/receipt/print/${id}/${receiptType}`,
  "upload-payment-doc": "/buyer/upload/payment",
  "add-payment-doc": "/buyer/payment/file/add",
  "get-payment-doc-list": (id: number) => `/buyer/payment/file/get/${id}`,
  "delete-payment-doc": (id: number) => `/buyer/payment/file/delete/${id}`,
};

export const buyerPaymentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addBuyerPayment: builder.mutation<
      AddBuyerPaymentDtoModel,
      BuyerPaymentBody
    >({
      query: (body) => ({
        url: buyerPaymentEndpoints["add-payment"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        "buyer-unit-payment-receipt",
        {
          type: "buyer-unit-payment",
          id: arg.buyerUnitTypeId,
        },
      ],
    }),
    getPayment: builder.query<BuyerPaymentDtoModel, number>({
      query: (param) => ({
        url: buyerPaymentEndpoints["get-payment"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "buyer-unit-payment",
          id: arg,
        },
      ],
    }),
    getReceipt: builder.query<
      BuyerPaymentReceiptDtoModel,
      {
        id: number;
        receiptType: string;
      }
    >({
      query: (param) => ({
        url: buyerPaymentEndpoints["get-receipt"](param.id, param.receiptType),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "buyer-unit-payment-receipt",
          id: arg.id,
        },
      ],
    }),
    addPaymentDoc: builder.mutation<void, AddPaymentDocBody>({
      query: (body) => ({
        url: buyerPaymentEndpoints["add-payment-doc"],
        body,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "buyer-payment-doc-list",
          id: arg.buyerPaymentId,
        },
      ],
    }),
    deletePaymentDoc: builder.mutation<
      void,
      {
        id: number;
        buyerPaymentId: number;
      }
    >({
      query: (param) => ({
        url: buyerPaymentEndpoints["delete-payment-doc"](param.id),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "buyer-payment-doc-list",
          id: arg.buyerPaymentId,
        },
      ],
    }),
    getPaymentDocs: builder.query<PaymentDocDtoModel, number>({
      query: (param) => ({
        url: buyerPaymentEndpoints["get-payment-doc-list"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "buyer-payment-doc-list",
          id: arg,
        },
      ],
    }),
  }),
});

export const {
  useAddBuyerPaymentMutation,
  useGetPaymentQuery,
  useLazyGetReceiptQuery,
  useAddPaymentDocMutation,
  useGetPaymentDocsQuery,
  useDeletePaymentDocMutation,
} = buyerPaymentSlice;
