import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import { lotVaultCryptRoute, cryptRoute } from "../../../routes/routeKeys";
import CryptLevel from "./CryptLevel";
import CryptColumn from "./CryptColumn";
import CryptStatus from "./CryptStatus";
import CryptInformation from "./CryptInformation";

const tabItems: ItemsProps[] = [
  {
    label: "Crypt Information",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Crypt Level",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Crypt Column",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Crypt Statuses",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const Crypt = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${lotVaultCryptRoute}${cryptRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && <CryptInformation />}
          {tabValue === 1 && <CryptLevel />}
          {tabValue === 2 && <CryptColumn />}
          {tabValue === 3 && <CryptStatus />}
        </Box>
      </Box>
    </Paper>
  );
};

export default Crypt;
