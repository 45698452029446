import React from "react";
import Icon from "@mdi/react";
import { mdiBriefcaseVariantOutline } from "@mdi/js";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  count: number;
  loading: boolean;
  route: string;
};

const CounterDashboard = ({ title, count, loading, route }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(route);

  return (
    <Paper elevation={3}>
      {loading ? (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" style={{ margin: "20px 0" }} />
        </Stack>
      ) : (
        <Box paddingY={2} paddingX={3}>
          <Stack direction="column" spacing={1}>
            <Box
              sx={(theme) => ({
                borderRadius: "50px",
                width: "40px",
                height: "40px",
                backgroundColor: theme.palette.warning.main,
                paddingLeft: "5.5px",
                paddingTop: "4px",
                cursor: "pointer",
              })}
              onClick={handleNavigate}
            >
              <Icon path={mdiBriefcaseVariantOutline} size={1.2} color="#fff" />
            </Box>
            <Typography
              sx={(theme) => ({
                color: theme.palette.error.main,
                fontSize: "1.1rem",
                fontWeight: 500,
              })}
            >
              {title}
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: "1.1rem",
                fontWeight: 600,
              })}
            >
              {count}
            </Typography>
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default CounterDashboard;
