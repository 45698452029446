import Grid from "@mui/material/Grid";
import RequirementsUploader from "./RequirementsUploader";

interface Props {
  scheduleForm: any;
  serviceType: string;
}

const Requirements = ({ serviceType, scheduleForm }: Props) => {
  return (
    <Grid container marginTop={4} spacing={3}>
      {serviceType !== "DISINTERMENT" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Death Certificate"
            uploadTitle="Upload Death Certificate"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "EXHUMATION" &&
        serviceType !== "CREMATION" &&
        serviceType !== "DISINTERMENT" && (
          <Grid item xs={12}>
            <RequirementsUploader
              title="Burial Permit (Antipolo City Health Office)"
              uploadTitle="Upload Burial Permit (Antipolo City Health Office)"
              type="GDL"
            />
          </Grid>
        )}
      {serviceType !== "EXHUMATION" &&
        serviceType !== "CREMATION" &&
        serviceType !== "DISINTERMENT" && (
          <Grid item xs={12}>
            <RequirementsUploader
              title="Transfer Permit"
              uploadTitle="Transfer Permit"
              type="GDL"
            />
          </Grid>
        )}
      <Grid item xs={12}>
        <RequirementsUploader
          title={`${
            serviceType === "INURNMENT" ? "Cremation" : "Burial"
          } Certificate (${
            serviceType === "DISINTERMENT"
              ? "(Gethsemane-Admin Office)"
              : "(Previous Cemetery)"
          }`}
          uploadTitle={`${
            serviceType === "INURNMENT" ? "Cremation" : "Burial"
          } Certificate ${
            serviceType === "DISINTERMENT"
              ? "(Gethsemane-Admin Office)"
              : "(Previous Cemetery)"
          }`}
          type="GDL"
        />
      </Grid>
      {serviceType === "CREMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Cremation Permit"
            uploadTitle="Cremation Permit"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "INURNMENT" && serviceType !== "CREMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Exhumation Permit"
            uploadTitle="Exhumation Permit"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "EXHUMATION" && serviceType !== "DISINTERMENT" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Senior Citizen ID/PWD ID"
            uploadTitle="Senior Citizen ID/PWD ID"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType === "CREMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Cremation Dispatch"
            uploadTitle="Cremation Dispatch"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType === "CREMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Authority Cremate of Remains"
            uploadTitle="Authority Cremate of Remains"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "CREMATION" && serviceType !== "DISINTERMENT" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Certificate of Ownership of Lot Owner/Receipt of Full Payment"
            uploadTitle="Certificate of Ownership of Lot Owner/Receipt of Full Payment"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "DISINTERMENT" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Authorization Letter"
            uploadTitle="Authorization Letter"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "DISINTERMENT" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="2 Valid ID's w/ Specimen Signature of Lot Owner"
            uploadTitle="2 Valid ID's w/ Specimen Signature of Lot Owner"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "DISINTERMENT" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="2 Valid ID's of Authorized Representative"
            uploadTitle="2 Valid ID's of Authorized Representative"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType !== "EXHUMATION" &&
        serviceType !== "CREMATION" &&
        serviceType !== "DISINTERMENT" && (
          <Grid item xs={12}>
            <RequirementsUploader
              title="Signed Guidelines"
              uploadTitle="Signed Guidelines"
              type="GDL"
            />
          </Grid>
        )}
      {serviceType !== "EXHUMATION" &&
        serviceType !== "CREMATION" &&
        serviceType !== "DISINTERMENT" && (
          <Grid item xs={12}>
            <RequirementsUploader
              title="Tombstone Marker Form"
              uploadTitle="Tombstone Marker Form"
              type="GDL"
            />
          </Grid>
        )}
      {serviceType !== "EXHUMATION" &&
        serviceType !== "CREMATION" &&
        serviceType !== "DISINTERMENT" && (
          <Grid item xs={12}>
            <RequirementsUploader
              title="Interment Form"
              uploadTitle="Interment Form"
              type="GDL"
            />
          </Grid>
        )}
      {serviceType !== "EXHUMATION" &&
        serviceType !== "CREMATION" &&
        serviceType !== "DISINTERMENT" && (
          <Grid item xs={12}>
            <RequirementsUploader
              title="Dispatch Form"
              uploadTitle="Dispatch Form"
              type="GDL"
            />
          </Grid>
        )}
      {serviceType === "EXHUMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Exhumation Form"
            uploadTitle="Exhumation Form"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType === "EXHUMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Exhumation Waiver"
            uploadTitle="Exhumation Waiver"
            type="GDL"
          />
        </Grid>
      )}
      {serviceType === "EXHUMATION" && (
        <Grid item xs={12}>
          <RequirementsUploader
            title="Exhumation Dispatch Form"
            uploadTitle="Exhumation Dispatch Form"
            type="GDL"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Requirements;
