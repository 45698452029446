import { number } from "yup";
import {
  FetchDtoType,
  ListFetchDtoType,
  ListFetchQueryType,
} from "../../../model/global";
import {
  PriceListAddBody,
  PriceListGetModel,
  PriceListUnitType,
} from "../../../model/priceListModel";
import { priceListType } from "../../../utils/enums";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

interface PriceListQuery extends ListFetchQueryType {
  type: PriceListUnitType;
}

export const endpoints = {
  add: `/configuration/price-list/add`,
  get: (id: number) => `/configuration/price-list/get/${id}`,
  upload: "/configuration/price-list/upload",
  list: (
    type: PriceListUnitType,
    page: number,
    entries: number,
    search: string
  ) =>
    `/configuration/price-list/list/${type}?currentPage=${page}&perPage=${entries}&search=${search}`,
  delete: (id: number) => `/configuration/price-list/delete/${id}`,
};

export const priceListSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.query<
      ListFetchDtoType<PriceListGetModel[]>,
      PriceListQuery
    >({
      query: (param) => ({
        url: endpoints.list(
          param.type,
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        {
          type: "price-list-config",
          id: args.type,
        },
      ],
    }),
    getItem: builder.query<
      FetchDtoType<PriceListGetModel>,
      { type: PriceListUnitType; id: number }
    >({
      query: (param) => ({
        url: endpoints.get(param.id),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "price-list-config", id: `${args.type}-${args.id}` },
      ],
    }),
    addPrice: builder.mutation<void, PriceListAddBody>({
      query: (body) => ({
        url: endpoints.add,
        body,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "price-list-config",
          id: arg.type,
        },
      ],
    }),
    deletePrice: builder.mutation<
      void,
      { id: number; type: PriceListUnitType }
    >({
      query: (param) => ({
        url: endpoints.delete(param.id),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        {
          type: "price-list-config",
          id: args.type,
        },
        {
          type: "price-list-config",
          id: `${args.type}-${args.id}`,
        },
      ],
    }),
  }),
});

export const {
  useGetListQuery,
  useAddPriceMutation,
  useDeletePriceMutation,
  useGetItemQuery,
} = priceListSlice;
