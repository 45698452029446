import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Icon from "@mdi/react";
import { mdiMinusCircleOutline } from "@mdi/js";
import { LeadIdType } from "./Leads";

type Props = {
  leads: LeadIdType[];
  handleCheckSelect: (lead: LeadIdType, isChecked: boolean) => void;
  disabled: boolean;
};

const SelectedLead = ({ leads, handleCheckSelect, disabled }: Props) => {
  const theme = useTheme();

  return (
    <Box width="100%" marginBottom={3}>
      <Divider
        sx={{
          paddingY: 2,
          "& span": {
            fontSize: ".75rem",
          },
        }}
      >
        Selected leads
      </Divider>
      <Grid container spacing={2}>
        {leads.map((item, key) => (
          <Grid item xs={12} md={3} lg={2} key={key}>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.grey[100],
                borderRadius: 1,
                boxShadow: theme.shadows[3],
                padding: 1,
              })}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: ".85rem",
                    color: theme.palette.primary.main,
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  })}
                >
                  {item.refNum}
                </Typography>
                <div
                  onClick={
                    disabled ? () => {} : () => handleCheckSelect(item, false)
                  }
                >
                  <Icon
                    path={mdiMinusCircleOutline}
                    size={1}
                    color={theme.palette.error.main}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SelectedLead;
