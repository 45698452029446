import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Icon from "@mdi/react";
import { useTheme } from "@mui/material/styles";
import { mdiClose } from "@mdi/js";
import OtpInput from "react-otp-input";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  otpCode: string;
  open: boolean;
  text: string;
  digits?: number;
  loading?: boolean;
  resendLoading?: boolean;
  handleOtpChange: (val: string) => void;
  submit?: () => void;
  resend?: () => void;
  closeFn: () => void;
};

const OtpModal = ({
  otpCode,
  handleOtpChange,
  open,
  text,
  closeFn,
  digits = 6,
  loading = false,
  submit,
  resend,
  resendLoading,
}: Props) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: 400,
          borderRadius: 2,
        },
      })}
    >
      <DialogContent>
        <Stack direction="row" justifyContent="flex-end">
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              closeFn();
            }}
          >
            <Icon path={mdiClose} size={1} />
          </Box>
        </Stack>
        <Box
          sx={{
            paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              fontSize: "1rem",
              marginBottom: 2,
            }}
          >
            {text}
          </Typography>
          <Stack alignItems={"center"} justifyContent={"center"} gap={4}>
            <OtpInput
              value={otpCode}
              onChange={handleOtpChange}
              numInputs={digits}
              renderSeparator={<div style={{ width: "5px" }} />}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
              inputStyle={{
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "5px",
                width: "50px",
                height: "60px",
                fontSize: "1.5rem",
                fontWeight: 600,
                color: theme.palette.primary.dark,
              }}
            />

            <Button
              onClick={submit}
              variant="button-confirm"
              sx={{
                width: 150,
              }}
              startIcon={
                loading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
            >
              Submit
            </Button>

            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontSize: "1rem",
                marginBottom: 2,
              }}
            >
              Did not receive?{" "}
              <Typography
                onClick={resendLoading ? () => {} : resend}
                component={"span"}
                fontWeight={600}
                sx={{
                  cursor: "pointer",
                }}
              >
                {resendLoading ? "Sending" : "Re-send"}
              </Typography>
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OtpModal;
