import React from "react";

import CompanyProfile from "../company-profile/CompanyProfile";
import UserProfile from "./UserProfile";
import { getUserCredential } from "../../utils/helpers/storageHelper";

type Props = {};

const Profile = (props: Props) => {
  const user = getUserCredential();

  return user?.roleId === 1 ? <CompanyProfile /> : <UserProfile />;
};

export default Profile;
