import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
import {
  useGetPaymentScheduleQuery,
  useAddPaymentScheduleMutation,
  useSendApprovalCodeMutation,
} from "../../../../service/slice/system-configuration/generalSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../utils/notificationToast";
import { LoaderWithText } from "../../../common/Loaders";
import OtpModal from "../../../common/OtpModal";

interface PaymentConfigType {
  overdue: string;
  overduePenalty: string;
  cancellationFee: string;
  nonPaymentTermination: string;
}

const PaymentScheduleConfig = () => {
  const [openOtp, setOpenOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const form = useForm<PaymentConfigType>({
    defaultValues: {
      overdue: "",
      overduePenalty: "",
      cancellationFee: "",
      nonPaymentTermination: "",
    },
  });

  const { control, setValue, handleSubmit, getValues } = form;

  const {
    data: paymentData,
    isLoading: paymentDataIsLoading,
    error: paymentDataError,
    refetch: paymentDataRefetch,
  } = useGetPaymentScheduleQuery();

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddPaymentScheduleMutation();

  const [
    sendApprovalMutation,
    {
      isLoading: sendApprovalLoading,
      error: sendApprovalError,
      reset: sendApprovalReset,
      isSuccess: sendApprovalSuccess,
    },
  ] = useSendApprovalCodeMutation();

  const onSubmit = () => {
    const data = getValues();
    addMutation({
      code: otp,
      cancellationFee: Number(data.cancellationFee),
      daysToOverdue: Number(data.overdue),
      nonPayment: Number(data.nonPaymentTermination),
      overduePenalty: Number(data.overduePenalty),
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "service-add",
    });

    addReset();
  }

  if (sendApprovalError) {
    errorDisplayOrNavigate({
      error: sendApprovalError,
      toastId: "send-approval",
    });

    sendApprovalReset();
  }

  if (paymentDataError) {
    errorDisplayOrNavigate({
      error: paymentDataError,
      toastId: "payment-get",
    });
  }

  useEffect(() => {
    if (sendApprovalSuccess) {
      showToastSuccess({
        text: "Please check your email for the approval code!",
        toastId: "approval",
      });
      setOpenOtp(true);
      sendApprovalReset();
    }
  }, [sendApprovalSuccess]);

  useEffect(() => {
    if (addIsSuccess) {
      showToastSuccess({
        text: "Payment schedule configuration successfully saved!",
        toastId: "add",
      });
      paymentDataRefetch();
      setOpenOtp(false);
      setOtp("");
      addReset();
    }
  }, [addIsSuccess]);

  useEffect(() => {
    if (paymentData) {
      const { cancellationFee, daysToOverdue, nonPayment, overduePenalty } =
        paymentData.data;
      setValue("cancellationFee", cancellationFee);
      setValue("nonPaymentTermination", nonPayment.toString());
      setValue("overdue", daysToOverdue.toString());
      setValue("overduePenalty", overduePenalty);
    }
  }, [paymentData]);

  return paymentDataIsLoading ? (
    <LoaderWithText text="Getting payment schedule configuration" />
  ) : (
    <FormProvider {...form}>
      <OtpModal
        open={openOtp}
        text="Approval Code"
        closeFn={() => setOpenOtp(false)}
        otpCode={otp}
        handleOtpChange={(val) => setOtp(val)}
        loading={addIsLoading}
        submit={onSubmit}
        resend={() => sendApprovalMutation("PS")}
        resendLoading={sendApprovalLoading}
      />
      <Grid
        container
        spacing={4}
        marginTop={4}
        sx={{
          width: { xs: "100%", lg: "70%" },
        }}
      >
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5.3}>
              <Typography variant="textSm">Days set as overdue</Typography>
            </Grid>
            <Grid item xs={6.7}>
              <Controller
                control={control}
                name="overdue"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5.3}>
              <Typography variant="textSm">Overdue Penalty (%)</Typography>
            </Grid>
            <Grid item xs={6.7}>
              <Controller
                control={control}
                name="overduePenalty"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5.3}>
              <Typography variant="textSm">Cancellation Fee</Typography>
            </Grid>
            <Grid item xs={6.7}>
              <Controller
                control={control}
                name="cancellationFee"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5.3}>
              <Typography variant="textSm">
                Days non-payment for Contract Termination
              </Typography>
            </Grid>
            <Grid item xs={6.7}>
              <Controller
                control={control}
                name="nonPaymentTermination"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="button-primary"
            startIcon={
              sendApprovalLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            sx={{
              width: 150,
              marginTop: 2,
            }}
            onClick={
              sendApprovalLoading ? () => {} : () => sendApprovalMutation("PS")
            }
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default PaymentScheduleConfig;
