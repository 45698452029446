import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useParams, useLocation } from "react-router-dom";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { LoaderWithText } from "../../../../common/Loaders";
import { useGetBuyerInformationQuery } from "../../../../../service/slice/client-management/buyer/buyerInformationSlice";
import { StepModel, StepperActionCta } from "../../../../common/StepperTabMenu";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import {
  salesPersonnelOpt,
  thisFieldRequired,
} from "../../../../../utils/constants";
import CustomReactSelectJs from "../../../../common/textfields/CustomReactSelectJs";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../../routes/routeKeys";
import { useLazyGetAgentListConfigQuery } from "../../../../../service/slice/usersSlice";
import {
  useAddUnitTypeMutation,
  useGetUnitTypeQuery,
  useUpdateUnitTypeMutation,
} from "../../../../../service/slice/client-management/buyer/unitTypeSlice";
import { RoleTypeEnum } from "../../../../../utils/enums";
import UnitListLawnLot from "./UnitListLawnLot";
import UnitListCrypt from "./UnitListCrypt";
import UnitListVault from "./UnitListVault";
import { LotInformationModel } from "../../../../../model/lotModel";
import { VaultInformationModel } from "../../../../../model/vaultModel";
import { CryptInformationModel } from "../../../../../model/cryptModel";
import SelectedUnitsList from "./SelectedUnitsList";
import { StepperGenericModel } from "../../Buyers";

export type UnitModel = {
  type: string;
  unitTypeId?: number;
  id?: null | number;
  unit: any;
};

interface UnitTypeForm {
  lawnLot: boolean;
  vault: boolean;
  crypt: boolean;
  locatedBy: {
    value: string | null;
    label: string | null;
  };
  agent: {
    value: number | null;
    label: string | null;
  };
  cashier: {
    value: number | null;
    label: string | null;
  };
  locator: {
    value: number | null;
    label: string | null;
  };
  asd: {
    value: number | null;
    label: string | null;
  };
  marketingHead: {
    value: number | null;
    label: string | null;
  };
  noted: string;
  encodedBuyer: string;
}

type Props = {
  steps: StepModel[];
};

const UnitType = ({ steps }: Props) => {
  const { id } = useParams();
  const { state: locState } = useLocation();
  const buyerId = id ? Number(id) : null;

  const [units, setUnits] = useState<UnitModel[]>(
    locState?.unit ? [locState.unit] : []
  );

  const [selectUnitError, setSelectedUnitError] = useState<string>("");
  const form = useForm<UnitTypeForm>({
    defaultValues: {
      lawnLot: locState?.unit
        ? locState?.unit?.type === "LOT"
          ? true
          : false
        : locState?.unitType && locState.unitType === "LOT"
        ? true
        : false,
      vault: locState?.unit
        ? locState?.unit?.type === "VAULT"
          ? true
          : false
        : locState?.unitType && locState.unitType === "VAULT"
        ? true
        : false,
      crypt: locState?.unit
        ? locState?.unit?.type === "CRYPT"
          ? true
          : false
        : locState?.unitType && locState.unitType === "CRYPT"
        ? true
        : false,
      locatedBy: {
        value: null,
        label: null,
      },
      agent: {
        value: null,
        label: null,
      },
      asd: {
        value: null,
        label: null,
      },
      marketingHead: {
        value: null,
        label: null,
      },
      locator: {
        value: null,
        label: null,
      },
      cashier: {
        value: null,
        label: null,
      },
      noted: "",
      encodedBuyer: "",
    },
  });

  const {
    control,
    setError,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors: formErrors },
  } = form;

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const {
    data: unitTypeData,
    isLoading: unitTypeIsLoading,
    error: unitTypeError,
    isFetching: unitTypeIsFetching,
  } = useGetUnitTypeQuery(buyerId ?? skipToken);

  const [
    agentListQueryTrigger,
    { data: agentListData, isLoading: agentListLoading, error: agentListError },
  ] = useLazyGetAgentListConfigQuery();

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddUnitTypeMutation();

  const [
    updateMutate,
    {
      isLoading: updateIsLoading,
      error: updateError,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateUnitTypeMutation();

  const loading =
    buyerInfoIsLoading ||
    agentListLoading ||
    unitTypeIsFetching ||
    unitTypeIsLoading;
  const saveLoading = addIsLoading || updateIsLoading;

  const personnelOpt = useMemo(() => {
    return {
      areaDirector:
        agentListData?.data
          .filter((item) => item.type === RoleTypeEnum.areaDirector)
          .map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          })) ?? [],
      marketingHead:
        agentListData?.data
          .filter((item) => item.type === RoleTypeEnum.marketingHead)
          .map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          })) ?? [],
      agent:
        agentListData?.data
          .filter((item) => item.type === RoleTypeEnum.agent)
          .map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          })) ?? [],
      cashier:
        agentListData?.data
          .filter((item) => item.type === RoleTypeEnum.cashier)
          .map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          })) ?? [],
      locator:
        agentListData?.data
          .filter((item) => item.type === RoleTypeEnum.locator)
          .map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          })) ?? [],
    };
  }, [agentListData]);

  const lawnLotWatch = useWatch({
    control,
    name: "lawnLot",
  });

  const cryptWatch = useWatch({
    control,
    name: "crypt",
  });

  const vaultWatch = useWatch({
    control,
    name: "vault",
  });

  const locatedByValueWatch = useWatch({
    control,
    name: "locatedBy.value",
  });

  const handleToggleUnits = (
    isAdd: boolean,
    type: string,
    unitTypeId: number,
    unit:
      | (LotInformationModel & VaultInformationModel & CryptInformationModel)
      | null
  ) => {
    if (isAdd) {
      if (type === "LOT") {
        clearErrors("lawnLot");
      } else if (type === "CRYPT") {
        clearErrors("crypt");
      } else if (type === "VAULT") {
        clearErrors("vault");
      }

      setUnits((state) => [
        ...state,
        {
          type,
          unitTypeId,
          unit,
        },
      ]);
    } else {
      setUnits((state) =>
        state.filter((item) => item.unitTypeId !== unitTypeId)
      );
    }
  };

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (!dataFields.lawnLot && !dataFields.crypt && !dataFields.vault) {
      setSelectedUnitError("Please select at least 1 unit type");
      errors.push(1);
    }

    if (dataFields.lawnLot && units.length === 0) {
      setError("lawnLot", {
        type: "required",
        message: "Please assign a unit from the list.",
      });
      errors.push(1);
    }

    if (dataFields.crypt && units.length === 0) {
      setError("crypt", {
        type: "required",
        message: "Please assign a unit from the list.",
      });
      errors.push(1);
    }

    if (dataFields.vault && units.length === 0) {
      setError("vault", {
        type: "required",
        message: "Please assign a unit from the list.",
      });
      errors.push(1);
    }

    // if (dataFields.asd.value === null) {
    //   setError("asd", {
    //     type: "required",
    //     message: thisFieldRequired,
    //   });
    //   errors.push(1);
    // }

    // if (dataFields.marketingHead.value === null) {
    //   setError("marketingHead", {
    //     type: "required",
    //     message: thisFieldRequired,
    //   });
    //   errors.push(1);
    // }

    return errors;
  };

  const getLocatedById = (form: UnitTypeForm): number | null => {
    const { value } = form.locatedBy;
    if (value === RoleTypeEnum.agent) {
      return form.agent.value;
    } else if (value === RoleTypeEnum.areaDirector) {
      return form.asd.value;
    } else if (value === RoleTypeEnum.marketingHead) {
      return form.marketingHead.value;
    } else if (value === RoleTypeEnum.cashier) {
      return form.cashier.value;
    } else if (value === RoleTypeEnum.locator) {
      return form.locator.value;
    } else {
      return null;
    }
  };

  const onSubmit: SubmitHandler<UnitTypeForm> = (dataField) => {
    if (checkErrors().length > 0) {
      return;
    }

    const locId = getLocatedById(dataField);

    if (unitTypeData?.data?.assignee) {
      updateMutate({
        queryParams: buyerId as number,
        bodyParams: {
          agentId: dataField.agent.value,
          asdId: dataField.asd.value,
          buyerId: buyerId as number,
          locatedBy:
            dataField.locatedBy.value === RoleTypeEnum.areaDirector
              ? "AREA-DIRECTOR"
              : dataField.locatedBy.value,
          locatedById: locId,
          marketingHeadId: dataField.marketingHead.value,
          cashierId: dataField.cashier.value,
          locatorId: dataField.locator.value,
          unitType: units.map((item) => ({
            type: item.type,
            unitTypeId: item.unitTypeId as number,
            id: item.id,
          })),
        },
      });
    } else {
      addMutate({
        agentId: dataField.agent.value,
        asdId: dataField.asd.value,
        cashierId: dataField.cashier.value,
        locatorId: dataField.locator.value,
        buyerId: buyerId as number,
        locatedBy:
          dataField.locatedBy.value === RoleTypeEnum.areaDirector
            ? "AREA-DIRECTOR"
            : dataField.locatedBy.value,
        locatedById: locId,
        marketingHeadId: dataField.marketingHead.value,
        unitType: units.map((item) => ({
          type: item.type,
          unitTypeId: item.unitTypeId as number,
        })),
      });
    }
  };

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (agentListError) {
    errorDisplayOrNavigate({
      error: agentListError,
      toastId: "agent-list-config",
    });
  }

  if (unitTypeError) {
    errorDisplayOrNavigate({
      error: unitTypeError,
      toastId: "unit-type-data",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-unit-type",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Unit type created",
      toastId: "add-unit-type",
    });

    addReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-unit-type",
    });

    updateReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Unit type updated!",
      toastId: "update-unit-type",
    });

    updateReset();
  }

  useEffect(() => {
    if (lawnLotWatch || cryptWatch || vaultWatch) {
      setSelectedUnitError("");
    }

    if (!lawnLotWatch) {
      setUnits((state) => state.filter((item) => item.type !== "LOT"));
    } else if (!cryptWatch) {
      setUnits((state) => state.filter((item) => item.type !== "CRYPT"));
    } else if (!vaultWatch) {
      setUnits((state) => state.filter((item) => item.type !== "VAULT"));
    }
  }, [lawnLotWatch, cryptWatch, vaultWatch]);

  useEffect(() => {
    if (buyerId) {
      agentListQueryTrigger();
    }
  }, [buyerId]);

  useEffect(() => {
    if (unitTypeData?.data?.assignee) {
      const { assignee, unitType } = unitTypeData.data;
      const hasLot = unitType.find((item) => item.type === "LOT")
        ? true
        : false;
      const hasVault = unitType.find((item) => item.type === "VAULT")
        ? true
        : false;
      const hasCrypt = unitType.find((item) => item.type === "CRYPT")
        ? true
        : false;
      const locatedByVal = assignee.locatedBy
        ? {
            value: assignee.locatedBy,
            label:
              salesPersonnelOpt.find(
                (item) => item.value === assignee.locatedBy
              )?.label ?? null,
          }
        : {
            label: null,
            value: null,
          };
      setValue(
        "noted",
        assignee.noted
          ? `${assignee.noted.firstName} ${assignee.noted.lastName}`
          : ""
      );
      setValue(
        "encodedBuyer",
        assignee.encodedBuyer
          ? `${assignee.encodedBuyer.firstName} ${assignee.encodedBuyer.lastName}`
          : ""
      );
      setValue("lawnLot", hasLot);
      setValue("crypt", hasCrypt);
      setValue("vault", hasVault);
      setValue("agent", {
        label: assignee.agent
          ? `${assignee.agent.firstName} ${assignee.agent.lastName}`
          : null,
        value: assignee.agent?.id ?? null,
      });
      setValue("locatedBy", locatedByVal);
      setValue("asd", {
        label: assignee.ASD
          ? `${assignee.ASD.firstName} ${assignee.ASD.lastName}`
          : null,
        value: assignee.ASD?.id ?? null,
      });
      setValue("cashier", {
        label: assignee.Cashier
          ? `${assignee.Cashier.firstName} ${assignee.Cashier.lastName}`
          : null,
        value: assignee.Cashier?.id ?? null,
      });
      setValue("locator", {
        label: assignee.Locator
          ? `${assignee.Locator.firstName} ${assignee.Locator.lastName}`
          : null,
        value: assignee.Locator?.id ?? null,
      });
      setValue("marketingHead", {
        label: assignee.MarketingHead
          ? `${assignee.MarketingHead.firstName} ${assignee.MarketingHead.lastName}`
          : null,
        value: assignee.MarketingHead?.id ?? null,
      });
      setUnits(
        unitType.map((item) => ({
          type: item.type,
          unitTypeId: item.unitTypeId,
          id: item.id,
          unit: item.unit,
        }))
      );
    }
  }, [unitTypeData]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting unit type information.." />
    </Box>
  ) : (
    <>
      <Box marginBottom={4}>
        {buyerId ? (
          <Alert severity="info" icon={false}>
            <Typography variant="body1" marginBottom={1}>
              <Typography component="span" marginRight={1} variant="textSm">
                Buyer:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {`${buyerInfoData?.data.firstName} ${buyerInfoData?.data.lastName}`}
              </Typography>
            </Typography>
            <Typography variant="body1">
              <Typography component="span" marginRight={1} variant="textSm">
                Ref #:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {buyerInfoData?.data.referenceNumber}
              </Typography>
            </Typography>
          </Alert>
        ) : (
          <Alert severity="warning">
            Please add a buyer information or select a buyer to fill up this
            form.
          </Alert>
        )}
      </Box>
      <FormProvider {...form}>
        <Grid container columnSpacing={3} rowSpacing={6}>
          <Grid item xs={12} lg={2}>
            <Typography variant="textfieldLabel">Unit Type</Typography>
            {!_.isEmpty(selectUnitError) && (
              <FormHelperText error>{selectUnitError}</FormHelperText>
            )}
            <FormGroup>
              <Controller
                name="lawnLot"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      label: {
                        fontSize: ".9rem",
                      },
                    }}
                    label="Lawn Lot"
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                name="vault"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      label: {
                        fontSize: ".9rem",
                      },
                    }}
                    label="Columbarium"
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                name="crypt"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      label: {
                        fontSize: ".9rem",
                      },
                    }}
                    label="Crypt"
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={3.3}>
            <Stack spacing={2}>
              <div>
                <Typography variant="textfieldLabel">Located By</Typography>
                <CustomReactSelectJs
                  control={control}
                  options={salesPersonnelOpt}
                  name="locatedBy"
                  placeholder="Located By"
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Agent</Typography>
                <CustomReactSelectJs
                  control={control}
                  options={personnelOpt.agent}
                  name="agent"
                  placeholder="Agent"
                />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3.3}>
            <Stack spacing={2}>
              <div>
                <Typography variant="textfieldLabel">ASD</Typography>
                <CustomReactSelectJs
                  control={control}
                  options={personnelOpt.areaDirector}
                  name="asd"
                  placeholder="Area Director"
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Marketing Head</Typography>
                <CustomReactSelectJs
                  control={control}
                  options={personnelOpt.marketingHead}
                  name="marketingHead"
                  placeholder="Marketing Head"
                />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3.3}>
            <Stack spacing={2}>
              <div>
                <Typography variant="textfieldLabel">Cashier</Typography>
                <CustomReactSelectJs
                  control={control}
                  options={personnelOpt.cashier}
                  name="cashier"
                  placeholder="Cashier"
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Locator</Typography>
                <CustomReactSelectJs
                  control={control}
                  options={personnelOpt.locator}
                  name="locator"
                  placeholder="Locator"
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Encoded By</Typography>
                <Controller
                  name="encodedBuyer"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Noted By</Typography>
                <Controller
                  name="noted"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
            </Stack>
          </Grid>
          {!lawnLotWatch && !cryptWatch && !vaultWatch && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={(theme) => ({
                  color: theme.palette.grey[400],
                  textTransform: "uppercase",
                  textAlign: "center",
                  fontSize: "1rem",
                })}
              >
                Select a unit type
              </Typography>
            </Grid>
          )}
          {units.length > 0 && (
            <SelectedUnitsList
              units={units}
              handleToggleUnit={handleToggleUnits}
            />
          )}
          {lawnLotWatch && (
            <Grid item xs={6}>
              {formErrors?.lawnLot && (
                <FormHelperText error>
                  {formErrors?.lawnLot.message}
                </FormHelperText>
              )}
              <UnitListLawnLot
                units={units}
                handleToggleUnit={handleToggleUnits}
              />
            </Grid>
          )}
          {cryptWatch && (
            <Grid item xs={6}>
              {formErrors?.crypt && (
                <FormHelperText error>
                  {formErrors?.crypt.message}
                </FormHelperText>
              )}
              <UnitListCrypt
                units={units}
                handleToggleUnit={handleToggleUnits}
              />
            </Grid>
          )}
          {vaultWatch && (
            <Grid item xs={6}>
              {formErrors?.vault && (
                <FormHelperText error>
                  {formErrors?.vault.message}
                </FormHelperText>
              )}
              <UnitListVault
                units={units}
                handleToggleUnit={handleToggleUnits}
              />
            </Grid>
          )}
        </Grid>
        <Divider
          sx={(theme) => ({
            marginY: theme.spacing(3),
          })}
        />
        <StepperActionCta<StepperGenericModel>
          steps={steps}
          disabled={false}
          path={
            buyerId
              ? `${clientManagementRoute}${buyersRoute}/edit/${buyerId}`
              : `${clientManagementRoute}${buyersRoute}/add`
          }
          state={locState}
        >
          <Button
            variant="button-primary"
            disabled={buyerId ? false : true}
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={handleSubmit(onSubmit)}
          >
            {unitTypeData?.data?.assignee ? "Update" : "Save"}
          </Button>
        </StepperActionCta>
      </FormProvider>
    </>
  );
};

export default UnitType;
