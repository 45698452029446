import React from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { mdiDeleteOutline, mdiSquareEditOutline } from "@mdi/js";

import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import { cryptRoute, lotVaultCryptRoute } from "../../../routes/routeKeys";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import { CryptColumnModel } from "../../../model/cryptModel";
import {
  useGetCryptColumnListQuery,
  useDeleteCryptColumnMutation,
} from "../../../service/slice/lot-vault-crypt/cryptSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import ConfirmModal from "../../common/ConfirmModal";
import CryptColumnForm from "./CryptColumnForm";

const headerLabels: string[] = ["Column", "Level", "Description"];

const CryptColumn = () => {
  const [openCreate, handleToggleCreate] =
    useOpenCreateForm<CryptColumnModel>();
  const [deleteItem, handleToggleDelete] =
    useDeleteTableItem<CryptColumnModel>();
  const location = useLocation();
  const theme = useTheme();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const {
    data: dataList,
    isLoading: dataIsLoading,
    error: dataError,
    isFetching: dataIsFetching,
  } = useGetCryptColumnListQuery({
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam as string,
  });

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeleteCryptColumnMutation();

  const loading = dataIsLoading || dataIsFetching;
  const list = dataList?.data?.rows ?? [];
  const totalData = dataList?.data?.count ?? 0;

  const confirmDelete = () => {
    deleteMutate(deleteItem.data?.id as number);
  };

  if (dataError) {
    errorDisplayOrNavigate({
      error: dataError,
      toastId: "crypt-column-list",
    });
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-crypt-column",
    });

    deleteReset();
  }

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} deleted`,
      toastId: "delete-crypt-column",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      {openCreate.open && (
        <CryptColumnForm
          title={openCreate.title}
          data={openCreate.data}
          closeFn={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Grid container alignItems="center" marginBottom={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${lotVaultCryptRoute}${cryptRoute}`}
              querySearch={(value: string) =>
                `?tab=3&page=${pageParam}&entries=${entriesParam}&search=${value}`
              }
            />
            <Button
              variant="button-secondary"
              disabled={openCreate.open}
              onClick={() =>
                handleToggleCreate(true, null, "Create Crypt Column")
              }
              sx={{
                width: "180px",
              }}
            >
              Add Column
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${lotVaultCryptRoute}${cryptRoute}`}
            querySearch={(entry: string) =>
              `?tab=3&page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.cryptLevel.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiSquareEditOutline}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Edit Column"
                        onClick={() =>
                          handleToggleCreate(true, item, "Edit Crypt Column")
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiDeleteOutline}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delete Column"
                        onClick={() => handleToggleDelete(true, item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${lotVaultCryptRoute}${cryptRoute}`}
        querySearch={(page) =>
          `?tab=3&page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default CryptColumn;
