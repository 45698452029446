import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import Discounts from "./Discounts";
import Promos from "./Promos";
import {
  discountsPromosRoute,
  systemConfigRoute,
} from "../../../routes/routeKeys";

const tabItems: ItemsProps[] = [
  {
    label: "Discounts",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Promos",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const DiscountPromos = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={1}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${systemConfigRoute}${discountsPromosRoute}?tab=`}
        />
        {(tabValue === 0 || tabValue === -1) && <Discounts />}
        {tabValue === 1 && <Promos />}
      </Box>
    </Paper>
  );
};

export default DiscountPromos;
