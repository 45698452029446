import React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import TriangleImage from "../../../asset/dashboard/triangle-light.png";
import TrophyImage from "../../../asset/dashboard/trophy.png";
import { reportsRoute, salesSummaryRoute } from "../../../routes/routeKeys";
import { MostSaleModel } from "../../../model/dashboardModel";
import { commaFormatted } from "../../../utils/helpers/stringManipulate";

type Props = { loading: boolean; data: MostSaleModel | undefined };

const TriangleImg = styled("img")({
  right: 0,
  bottom: 0,
  height: 170,
  position: "absolute",
});

// Styled component for the trophy image
const TrophyImg = styled("img")({
  right: 36,
  bottom: 20,
  height: 98,
  position: "absolute",
});

const Trophy = ({ loading, data }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = () => navigate(`${reportsRoute}${salesSummaryRoute}`);

  return (
    <Card sx={{ position: "relative", height: "100%" }}>
      {loading ? (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" style={{ margin: "20px 0" }} />
        </Stack>
      ) : (
        <CardContent>
          {data ? (
            <>
              <Typography variant="h6">{`Congratulations ${data.agent.firstName} ${data.agent.lastName}! 🥳`}</Typography>
              <Typography variant="body2" sx={{ letterSpacing: "0.25px" }}>
                Best seller of the month
              </Typography>
              <Typography variant="h5" sx={{ my: 4, color: "primary.main" }}>
                {commaFormatted(data.amount)}
              </Typography>
              <Button size="small" variant="contained" onClick={handleNavigate}>
                View Sales
              </Button>
              <TriangleImg alt="triangle background" src={TriangleImage} />
              <TrophyImg alt="trophy" src={TrophyImage} />
            </>
          ) : (
            <>
              <Typography variant="h6">No Sales</Typography>
              <TriangleImg alt="triangle background" src={TriangleImage} />
              <TrophyImg alt="trophy" src={TrophyImage} />
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default Trophy;
