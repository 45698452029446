import React from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { mdiDeleteOutline, mdiReceiptTextEditOutline } from "@mdi/js";

import TableSearchField from "../../common/textfields/TableSearchField";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import CustomPagination from "../../common/CustomPagination";
import {
  discountsPromosRoute,
  systemConfigRoute,
} from "../../../routes/routeKeys";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import PromoForm from "./PromoForm";
import {
  useGetPromoListQuery,
  useDeletePromoMutation,
} from "../../../service/slice/system-configuration/discountPromoSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import IconButtonMdi from "../../common/IconButtonMdi";
import { PromoModel } from "../../../model/discountPromoModel";
import ConfirmModal from "../../common/ConfirmModal";

const tableLabels = [
  "Promo Code",
  "Promo Name",
  "Promo Description",
  "Amount",
  "Percent",
  "Validity",
  "Chart of Account",
];

const Promos = () => {
  const theme = useTheme();
  const location = useLocation();
  const [openCreate, handleToggleCreate] = useOpenCreateForm<PromoModel>();
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<PromoModel>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const {
    data: dataList,
    isLoading: dataIsLoading,
    error: dataError,
    isFetching: dataIsFetching,
  } = useGetPromoListQuery({
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam as string,
  });

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePromoMutation();

  const loading = dataIsLoading || dataIsFetching;
  const list = dataList?.data?.rows ?? [];
  const totalData = dataList?.data?.count ?? 0;

  const confirmDelete = () => deleteMutate(deleteItem.data?.id as number);

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} successfully deleted`,
      toastId: "delete-promo",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-promo",
    });

    deleteReset();
  }

  if (dataError) {
    errorDisplayOrNavigate({
      error: dataError,
      toastId: "promo-list",
    });
  }

  return (
    <Box marginTop={4}>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      {openCreate.open && (
        <PromoForm
          closeFn={() => handleToggleCreate(false, null, "")}
          data={openCreate.data as null}
          title={openCreate.title}
        />
      )}
      <Grid container marginBottom={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${systemConfigRoute}${discountsPromosRoute}`}
              querySearch={(value) =>
                `?tab=2&page=${1}&entries=${entriesParam}&search=${value}`
              }
            />
            <Button
              variant="button-secondary"
              disabled={openCreate.open}
              onClick={() => handleToggleCreate(true, null, "New Promo")}
              sx={{
                width: "180px",
              }}
            >
              Add Promo
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${systemConfigRoute}${discountsPromosRoute}`}
            querySearch={(entry) =>
              `?tab=2&page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableLabels.map((item, key) => (
                <TableCell key={key} variant="head">
                  {item}
                </TableCell>
              ))}
              <TableCell
                variant="head"
                sx={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    {item.type === "AMOUNT" ? item.value : 0}
                  </TableCell>
                  <TableCell>
                    {item.type === "PERCENTAGE" ? item.value : 0}
                  </TableCell>
                  <TableCell>{`${moment(new Date(item.from)).format(
                    "ll"
                  )} - ${moment(new Date(item.to)).format("ll")}`}</TableCell>
                  <TableCell>{item.chartAccount.accountName}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiReceiptTextEditOutline}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Edit Promo"
                        onClick={() =>
                          handleToggleCreate(true, item, "Update Discount")
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiDeleteOutline}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delete Promo"
                        onClick={() => handleToggleDelete(true, item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${systemConfigRoute}${discountsPromosRoute}`}
        querySearch={(page) =>
          `?tab=2&page${page}&${entriesParam}&search=${searchParam}`
        }
        totalCount={totalData}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </Box>
  );
};

export default Promos;
