import React, { useState } from "react";
import _ from "lodash";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { resetPasswordText, thisFieldRequired } from "../../../utils/constants";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useForgotPasswordMutation } from "../../../service/slice/authSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open?: boolean;
  closeFn: () => void;
};

const ForgotPasswordModal = ({ open = false, closeFn }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const [forgotPasswordMutate, { isLoading, error, isSuccess, reset }] =
    useForgotPasswordMutation();

  const handleClose = () => {
    setEmail("");
    setEmailError("");
    closeFn();
  };

  const handleConfirm = () => {
    if (_.isEmpty(email)) {
      setEmailError(thisFieldRequired);
      return;
    }

    forgotPasswordMutate({
      email,
    });
  };

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "forgot-password",
    });
    reset();
  }

  if (isSuccess) {
    reset();
    showToastSuccess({
      text: "Please check your email for auto generated password",
      toastId: "forgot-password",
    });
    handleClose();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: 400,
          borderRadius: 2,
        },
      })}
    >
      <DialogContent>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Stack direction="row" justifyContent="flex-end" marginBottom={2}>
            <Box
              onClick={handleClose}
              sx={{
                cursor: "pointer",
              }}
            >
              <Icon path={mdiClose} size={1} />
            </Box>
          </Stack>
          <Stack direction="column" alignItems="flex-start" spacing={2}>
            <Typography
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: "1.3rem",
                marginBottom: 2,
              })}
            >
              {resetPasswordText}
            </Typography>
            <TextField
              type="email"
              placeholder="Please enter your valid email"
              error={!_.isEmpty(emailError)}
              helperText={emailError}
              sx={(theme) => ({
                width: "100%",
                // "& .MuiOutlinedInput-root": {
                //   backgroundColor: theme.palette.grey[100],
                // },
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
            />
            <Box
              sx={{
                paddingTop: 3,
              }}
            >
              <Button
                variant="button-primary"
                onClick={handleConfirm}
                disabled={isLoading || _.isEmpty(email)}
                sx={{
                  width: "150px",
                }}
                startIcon={
                  isLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
              >
                RESET
              </Button>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordModal;
