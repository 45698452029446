import React from "react";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useFieldArray,
  useWatch,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  FieldErrors,
  Controller,
} from "react-hook-form";

import IconButtonMdi from "../../../../common/IconButtonMdi";

import { OtherForm } from "./OtherInfo";
import { thisFieldRequired } from "../../../../../utils/constants";
import CustomReactSelectJs from "../../../../common/textfields/CustomReactSelectJs";

type Props = {
  control: Control<OtherForm>;
  clearErrors: UseFormClearErrors<OtherForm>;
  setValue: UseFormSetValue<OtherForm>;
  errors: FieldErrors<OtherForm>;
};

const DeceasedInfo = ({ control, clearErrors, setValue, errors }: Props) => {
  const theme = useTheme();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "deceasedInfo",
  });

  const deceasedInfoWatch = useWatch({
    control,
    name: "deceasedInfo",
  });

  const handleAddDecease = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        birthDate: null,
        deathDate: null,
        firstName: "",
        lastName: "",
        middleName: "",
        nickname: "",
        religion: "",
        placeOfBirth: "",
      });
    }
  };

  return (
    <div>
      {fields.map((item, key) => (
        <Stack
          direction="row"
          spacing={1}
          key={key}
          paddingTop={key === 0 ? 0 : 3}
        >
          <Box paddingTop="28px">
            <IconButtonMdi
              mdiIcon={
                key + 1 === deceasedInfoWatch.length
                  ? mdiPlusCircleOutline
                  : mdiMinusCircleOutline
              }
              iconColor={
                key + 1 === deceasedInfoWatch.length
                  ? theme.palette.info.main
                  : theme.palette.error.main
              }
              onClick={() =>
                handleAddDecease(
                  key + 1 === deceasedInfoWatch.length ? null : key
                )
              }
            />
          </Box>
          <Box width={"100%"}>
            <Stack spacing={2}>
              <div>
                <Typography variant="textfieldLabel">First Name</Typography>
                <Controller
                  name={`deceasedInfo.${key}.firstName`}
                  control={control}
                  rules={{
                    required: {
                      value: _.isEmpty(deceasedInfoWatch[0].firstName)
                        ? true
                        : key === 0
                        ? true
                        : false,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="First Name"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={deceasedInfoWatch[key]?.firstName ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Last Name</Typography>
                <Controller
                  name={`deceasedInfo.${key}.lastName`}
                  control={control}
                  rules={{
                    required: {
                      value: _.isEmpty(deceasedInfoWatch[0].lastName)
                        ? true
                        : key === 0
                        ? true
                        : false,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Last Name"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={deceasedInfoWatch[key]?.lastName ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Middle Name</Typography>
                <Controller
                  name={`deceasedInfo.${key}.middleName`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Middle Name"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={deceasedInfoWatch[key]?.middleName ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Nickname</Typography>
                <Controller
                  name={`deceasedInfo.${key}.nickname`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Nickname"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={deceasedInfoWatch[key]?.nickname ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Religion</Typography>
                <Controller
                  name={`deceasedInfo.${key}.religion`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Religion"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={deceasedInfoWatch[key]?.religion ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Place of Birth</Typography>
                <Controller
                  name={`deceasedInfo.${key}.placeOfBirth`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Place of Birth"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      value={deceasedInfoWatch[key]?.placeOfBirth ?? ""}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Date of Birth</Typography>
                <Controller
                  name={`deceasedInfo.${key}.birthDate`}
                  control={control}
                  rules={{
                    required: {
                      value:
                        deceasedInfoWatch[0].birthDate === null
                          ? true
                          : key === 0
                          ? true
                          : false,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        value={deceasedInfoWatch[key]?.birthDate ?? null}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error: fieldState.error?.message ? true : false,
                            helperText: fieldState.error?.message,
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <div>
                <Typography variant="textfieldLabel">Date of Death</Typography>
                <Controller
                  name={`deceasedInfo.${key}.deathDate`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        value={deceasedInfoWatch[key]?.deathDate ?? null}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error: fieldState.error?.message ? true : false,
                            helperText: fieldState.error?.message,
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </Stack>
          </Box>
        </Stack>
      ))}
    </div>
  );
};

export default DeceasedInfo;
