import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import Icon from "@mdi/react";
import {
  mdiHomeOutline,
  mdiAccountCogOutline,
  mdiBallotOutline,
  mdiGraveStone,
  mdiLibraryShelves,
  mdiFileDocumentEditOutline,
  mdiChartBar,
  mdiCreditCardSettingsOutline,
  mdiCogOutline,
  mdiAccountGroupOutline,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";

import CompanyLogo from "../../asset/logo.png";
import { getFromStorage } from "../../utils/helpers/storageHelper";
import { gg_menu_key } from "../../utils/constants";
import { MainMenuModel, SubMenuModel } from "../../model/menuModel";
import {
  replaceAllSlashWithHyphen,
  replaceAllSpaceWithHyphen,
} from "../../utils/helpers/stringManipulate";
import {
  accountManagementRoute,
  acknowledgementReceiptRoute,
  buyersRoute,
  cashVoucherRoute,
  chartAccountsRoute,
  checkVoucherRoute,
  clientManagementRoute,
  commissionSchemeRoute,
  cryptRoute,
  discountsPromosRoute,
  expensesRoute,
  expensesTypesRoute,
  generalRoute,
  lawnLotRoute,
  leadsRoute,
  lotVaultCryptRoute,
  officialReceiptRoute,
  paymentRoute,
  paymentsTypesRoute,
  priceListConfigRoute,
  provisionalReceiptRoute,
  rolesRoute,
  salesPersonelRoute,
  systemConfigRoute,
  userManagementRoute,
  usersRoute,
  vaultRoute,
} from "../../routes/routeKeys";

interface CustomListItemButtonProps extends ListItemButtonProps {
  iscurrent: number;
  issubmenu?: number;
}

interface CustomListItemTextProps extends ListItemTextProps {
  iscurrent?: number;
  issubmenu?: number;
}

export function menuIcons(name: string) {
  switch (name.toLowerCase()) {
    case "dashboard":
      return mdiHomeOutline;
    case "company profile":
      return mdiAccountCogOutline;
    case "lot management":
      return mdiBallotOutline;
    case "rental and services":
      return mdiGraveStone;
    case "inventory management":
      return mdiLibraryShelves;
    case "contract management":
      return mdiFileDocumentEditOutline;
    case "reports":
      return mdiChartBar;
    case "account management":
      return mdiCreditCardSettingsOutline;
    case "system configuration":
      return mdiCogOutline;
    case "user management":
      return mdiAccountGroupOutline;
    default:
      return mdiHomeOutline;
  }
}

const CustomListItemButton = styled(ListItemButton)<CustomListItemButtonProps>(
  ({ theme, iscurrent, issubmenu }) => ({
    position: "relative",
    zIndex: 2,
    marginBottom: "5px",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "95%",
      height: "100%",
      zIndex: -1,
      backgroundImage: iscurrent
        ? issubmenu
          ? `linear-gradient(98deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark} 94%)`
          : `linear-gradient(98deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark} 94%)`
        : "none",
      borderRadius: "0 100px 100px 0",
    },
    "&:hover": {
      backgroundColor: "transparent",
      "&::after": {
        content: '""',
        position: "absolute",
        zIndex: -1,
        top: 0,
        left: 0,
        width: "95%",
        height: "100%",
        backgroundColor: "rgba(58, 53, 65, 0.04)",
        borderRadius: "0 100px 100px 0",
      },
    },
  })
);

const CustomListItemText = styled(ListItemText)<CustomListItemTextProps>(
  ({ theme, iscurrent, issubmenu }) => ({
    ".MuiListItemText-primary": {
      fontSize: issubmenu ? ".8rem" : ".85rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: iscurrent ? 700 : 500,
      color: iscurrent
        ? theme.palette.common.white
        : issubmenu
        ? theme.palette.secondary.light
        : theme.palette.text.primary,
    },
  })
);

const DrawerComponent = () => {
  const menu: MainMenuModel[] | null =
    getFromStorage<MainMenuModel[]>(gg_menu_key);
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mainOpen, setMainOpen] = useState<number[]>([]);

  function checkIfMainOpen(id: number): boolean {
    return mainOpen.includes(id);
  }

  function checkCurrentActiveRoute(
    menuName: string,
    subMenuName?: string
  ): number {
    const pathnameSplit = pathname.split("/");
    const menu = replaceAllSlashWithHyphen(replaceAllSpaceWithHyphen(menuName))
      .replace(".", "")
      .toLowerCase();
    if (subMenuName) {
      const subMenu = replaceAllSpaceWithHyphen(subMenuName).toLowerCase();

      return pathnameSplit[2] === subMenu && pathname.includes(menu) ? 1 : 0;
    } else {
      if (menu === "dashboard") {
        return pathname === "/" ? 1 : 0;
      }

      return pathname.includes(menu) ? 1 : 0;
    }
  }

  function handleMainClick(menuItem: MainMenuModel): void {
    if (menuItem.subMenu.length > 0) {
      if (checkIfMainOpen(menuItem.id)) {
        setMainOpen((prev) => prev.filter((item) => item !== menuItem.id));
      } else {
        setMainOpen((prev) => [...prev, menuItem.id]);
      }
    } else {
      const menuName = replaceAllSlashWithHyphen(
        replaceAllSpaceWithHyphen(menuItem.name)
      )
        .replace(".", "")
        .toLowerCase();

      navigate(menuName === "dashboard" ? "/" : `/${menuName}`);
    }
  }

  function handleSubClick(
    menuItem: MainMenuModel,
    subMenuItem: SubMenuModel
  ): void {
    const menuName = replaceAllSlashWithHyphen(
      replaceAllSpaceWithHyphen(menuItem.name)
    ).toLowerCase();
    const subMenuName = replaceAllSlashWithHyphen(
      replaceAllSpaceWithHyphen(subMenuItem.name)
    ).toLowerCase();
    switch (`/${subMenuName}`) {
      case usersRoute:
        navigate(
          `${userManagementRoute}${usersRoute}?page=1&entries=10&search=`
        );
        break;
      case rolesRoute:
        navigate(
          `${userManagementRoute}${rolesRoute}?page=1&entries=10&search=`
        );
        break;
      case salesPersonelRoute:
        navigate(
          `${userManagementRoute}${salesPersonelRoute}?page=1&entries=10&search=`
        );
        break;
      case generalRoute:
        navigate(
          `${systemConfigRoute}${generalRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case chartAccountsRoute:
        navigate(
          `${systemConfigRoute}${chartAccountsRoute}?page=1&entries=10&search=`
        );
        break;
      case paymentsTypesRoute:
        navigate(
          `${systemConfigRoute}${paymentsTypesRoute}?page=1&entries=10&search=`
        );
        break;
      case expensesTypesRoute:
        navigate(
          `${systemConfigRoute}${expensesTypesRoute}?page=1&entries=10&search=`
        );
        break;
      case discountsPromosRoute:
        navigate(
          `${systemConfigRoute}${discountsPromosRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case commissionSchemeRoute:
        navigate(`${systemConfigRoute}${commissionSchemeRoute}`);
        break;
      case lawnLotRoute:
        navigate(
          `${lotVaultCryptRoute}${lawnLotRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case cryptRoute:
        navigate(
          `${lotVaultCryptRoute}${cryptRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case vaultRoute:
        navigate(
          `${lotVaultCryptRoute}${vaultRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case buyersRoute:
        navigate(
          `${clientManagementRoute}${buyersRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case leadsRoute:
        navigate(
          `${clientManagementRoute}${leadsRoute}?page=1&entries=10&search=`
        );
        break;
      case officialReceiptRoute:
        navigate(
          `${accountManagementRoute}${officialReceiptRoute}?page=1&entries=10&search=`
        );
        break;
      case paymentRoute:
        navigate(
          `${accountManagementRoute}${paymentRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      case expensesRoute:
        navigate(
          `${accountManagementRoute}${expensesRoute}?page=1&entries=10&search=`
        );
        break;
      case checkVoucherRoute:
        navigate(
          `${accountManagementRoute}${checkVoucherRoute}?page=1&entries=10&search=`
        );
        break;
      case cashVoucherRoute:
        navigate(
          `${accountManagementRoute}${cashVoucherRoute}?page=1&entries=10&search=`
        );
        break;
      case acknowledgementReceiptRoute:
        navigate(
          `${accountManagementRoute}${acknowledgementReceiptRoute}?page=1&entries=10&search=`
        );
        break;
      case provisionalReceiptRoute:
        navigate(
          `${accountManagementRoute}${provisionalReceiptRoute}?page=1&entries=10&search=`
        );
        break;
      case priceListConfigRoute:
        navigate(
          `${systemConfigRoute}${priceListConfigRoute}?tab=1&page=1&entries=10&search=`
        );
        break;
      default:
        navigate(`/${menuName}/${subMenuName}`);
        break;
    }
  }

  // useEffect(() => {
  //   if (menu) {
  //     setMainOpen(
  //       menu.filter((item) => item.subMenu.length > 0).map((item) => item.id)
  //     );
  //   }
  // }, []);

  return (
    <div>
      <img
        src={CompanyLogo}
        alt="Garden of Gethsemany"
        style={{
          maxWidth: 200,
          marginLeft: 30,
        }}
      />
      <Divider />
      <List>
        {menu &&
          menu.map((item) => (
            <React.Fragment key={`${item.name}-${item.id}`}>
              <Tooltip title={item.name} placement="top-end">
                <ListItem disablePadding>
                  <CustomListItemButton
                    onClick={() => handleMainClick(item)}
                    iscurrent={checkCurrentActiveRoute(item.name)}
                    issubmenu={0}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      <Icon
                        path={menuIcons(item.name)}
                        size={1}
                        color={
                          checkCurrentActiveRoute(item.name)
                            ? theme.palette.common.white
                            : theme.palette.text.primary
                        }
                      />
                    </ListItemIcon>
                    <CustomListItemText
                      primary={item.name}
                      iscurrent={checkCurrentActiveRoute(item.name)}
                      issubmenu={0}
                    />
                    {item.subMenu.length > 0 &&
                      (checkIfMainOpen(item.id) ? (
                        <Icon
                          path={mdiChevronUp}
                          size={1}
                          color={
                            checkCurrentActiveRoute(item.name)
                              ? theme.palette.common.white
                              : theme.palette.text.primary
                          }
                        />
                      ) : (
                        <Icon
                          path={mdiChevronDown}
                          size={1}
                          color={
                            checkCurrentActiveRoute(item.name)
                              ? theme.palette.common.white
                              : theme.palette.text.primary
                          }
                        />
                      ))}
                  </CustomListItemButton>
                </ListItem>
              </Tooltip>
              {item.subMenu.length > 0 && (
                <Collapse
                  in={checkIfMainOpen(item.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List>
                    {item.subMenu.map((item2) => (
                      <React.Fragment key={`${item2.name}-${item2.id}`}>
                        <Tooltip title={item2.name} placement="top-end">
                          <ListItem disablePadding>
                            <CustomListItemButton
                              iscurrent={checkCurrentActiveRoute(
                                item.name,
                                item2.name
                              )}
                              issubmenu={1}
                              onClick={() => handleSubClick(item, item2)}
                            >
                              <ListItemIcon>{/* <MailIcon /> */}</ListItemIcon>
                              <CustomListItemText
                                primary={item2.name}
                                iscurrent={checkCurrentActiveRoute(
                                  item.name,
                                  item2.name
                                )}
                                issubmenu={1}
                              />
                            </CustomListItemButton>
                          </ListItem>
                        </Tooltip>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
      </List>
    </div>
  );
};

export default DrawerComponent;
