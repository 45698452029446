import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from "react-hook-form";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Icon from "@mdi/react";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";

import CardHeader from "../../common/CardHeader";

import { useGetMenuListQuery } from "../../../service/slice/usersSlice";
import { getUserCredential } from "../../../utils/helpers/storageHelper";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";
import { menuIcons } from "../../layout/DrawerComponent";
import {
  descriptionLabel,
  nameLabel,
  seleactAtleastOne,
  thisFieldRequired,
} from "../../../utils/constants";
import {
  useAddRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
} from "../../../service/slice/usersSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { RoleModel } from "../../../model/usersModel";
import { MainMenuModel } from "../../../model/menuModel";

type Props = {
  id: number | null;
  title: string;
  handleCloseCard: () => void;
};

interface RoleForm {
  name: string;
  description: string;
}

const RoleInformation = ({ id, handleCloseCard, title }: Props) => {
  const userCred = getUserCredential();
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState<number[]>([]);
  const [permissionForm, setPermissionForm] = useState<number[]>([]);
  const [permissionError, setPermissionError] = useState<string>("");
  const form = useForm<RoleForm>({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset: fieldReset,
  } = form;

  const {
    data: menuList,
    isLoading: menuListIsLoading,
    error: menuListError,
  } = useGetMenuListQuery(1);

  const {
    data: roleData,
    isLoading: roleDataIsLoading,
    error: roleDataError,
    isSuccess: roleDataIsSuccess,
  } = useGetRoleQuery(id ?? skipToken);

  const [
    addRoleMutate,
    {
      error: addRoleError,
      isLoading: addRoleIsLoading,
      reset: addRoleReset,
      isSuccess: addRoleIsSuccess,
    },
  ] = useAddRoleMutation();

  const [
    updateRoleMutate,
    {
      error: updateRoleError,
      isLoading: updateRoleIsLoading,
      reset: updateRoleReset,
      isSuccess: updateRoleIsSuccess,
    },
  ] = useUpdateRoleMutation();

  const list = menuList?.data ?? [];
  const isLoading = menuListIsLoading || roleDataIsLoading;
  const saveLoading = addRoleIsLoading || updateRoleIsLoading;

  function handleTogglePermission(id: number, menuItem?: MainMenuModel) {
    if (menuItem) {
      if (permissionForm.includes(menuItem.id)) {
        const menuItemSubMenus = menuItem.subMenu
          .map((sub) => sub.id)
          .filter((item) => item !== id);
        if (permissionForm.includes(id)) {
          let test = false;
          for (let i = 0; i < menuItemSubMenus.length; i++) {
            if (permissionForm.includes(menuItemSubMenus[i])) {
              test = true;
            }
          }

          if (test) {
            setPermissionForm((prev) => prev.filter((item) => item !== id));
          } else {
            const indexOfMainMenu = permissionForm.indexOf(menuItem.id);
            const indexOfSubMenu = permissionForm.indexOf(id);
            const permissionFormInstance = [...permissionForm];
            permissionFormInstance.splice(indexOfMainMenu, 1);
            setPermissionForm(
              permissionFormInstance.filter((item) => item !== id)
            );
          }
        } else {
          setPermissionForm((prev) => [...prev, id]);
        }
      } else {
        setPermissionForm((prev) => [...prev, menuItem.id, id]);
      }
    } else {
      if (permissionForm.includes(id)) {
        setPermissionForm((prev) => prev.filter((item) => item !== id));
      } else {
        setPermissionForm((prev) => [...prev, id]);
      }
    }
    setPermissionError("");
  }

  function checkIfIdIncluded(id: number): boolean {
    return openMenu.includes(id);
  }

  function handleMenuClick(id: number): void {
    if (checkIfIdIncluded(id)) {
      setOpenMenu((prev) => prev.filter((item) => item !== id));
    } else {
      setOpenMenu((prev) => [...prev, id]);
    }
  }

  const onError = () => {
    if (permissionForm.length === 0) {
      setPermissionError(seleactAtleastOne);
    }
  };

  const onSubmitSave: SubmitHandler<RoleForm> = async (data) => {
    if (permissionForm.length === 0) {
      setPermissionError(seleactAtleastOne);
      return;
    }

    if (id) {
      updateRoleMutate({
        queryParam: id,
        bodyParam: {
          name: data.name,
          description: data.description,
          menuAccess: permissionForm,
        },
      });
    } else {
      addRoleMutate({
        name: data.name,
        description: data.description,
        menuAccess: permissionForm,
      });
    }
  };

  if (addRoleIsSuccess) {
    showToastSuccess({
      text: "Role successfully created.",
      toastId: "role-list",
    });

    addRoleReset();
    fieldReset();
    setPermissionError("");
    handleCloseCard();
  }

  if (addRoleError) {
    errorDisplayOrNavigate({
      error: addRoleError,
      toastId: "add-role",
    });

    addRoleReset();
  }

  if (menuListError) {
    errorDisplayOrNavigate({
      error: menuListError,
      toastId: "menu-list",
    });
  }

  if (roleDataError) {
    errorDisplayOrNavigate({
      error: roleDataError,
      toastId: `role-view-${id}`,
    });
  }

  if (updateRoleError) {
    errorDisplayOrNavigate({
      error: updateRoleError,
      toastId: `role-view-${id}`,
    });

    updateRoleReset();
  }

  if (updateRoleIsSuccess) {
    showToastSuccess({
      text: "Role successfully updated!",
      toastId: "role-view",
    });

    updateRoleReset();
    fieldReset();
    setPermissionError("");
    handleCloseCard();
  }

  useEffect(() => {
    if (list.length > 0) {
      setOpenMenu(
        list.filter((item) => item.subMenu.length > 0).map((item) => item.id)
      );
    }
  }, [list]);

  useEffect(() => {
    if (roleDataIsSuccess) {
      const { name, description } = roleData.data.role;
      const menuArr: number[] = [];

      roleData.data.menus.forEach((item1) => {
        if (item1.subMenu.length > 0) {
          item1.subMenu.forEach((item2) => {
            menuArr.push(item2.id);
          });
        }
        menuArr.push(item1.id);
      });

      setValue("name", name ?? "");
      setValue("description", description ?? "");
      setPermissionForm(menuArr);
    }
  }, [roleDataIsSuccess]);

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={handleCloseCard} />
        <Box padding={3}>
          {isLoading ? (
            <LoaderWithText text="Getting menu list" />
          ) : (
            <>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">
                      {nameLabel}
                    </Typography>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder={nameLabel}
                          error={errors.name ? true : false}
                          helperText={errors.name?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="textfieldLabel">
                      {descriptionLabel}
                    </Typography>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder={descriptionLabel}
                          error={errors.description ? true : false}
                          helperText={errors.description?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    marginY: 3,
                  }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box width="50%">
                    <Typography variant="textfieldLabel">
                      Permissions
                    </Typography>
                    {!_.isEmpty(permissionError) && (
                      <FormHelperText error>{permissionError}</FormHelperText>
                    )}
                  </Box>
                  <Box width="50%">
                    <Typography variant="textfieldLabel">Allow</Typography>
                  </Box>
                </Stack>
                {list.map((menuItem, menuKey) => {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      paddingTop={2}
                      key={menuKey}
                    >
                      <Box
                        width={menuItem.subMenu.length === 0 ? "50%" : "100%"}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          spacing={2}
                          onClick={
                            menuItem.subMenu.length > 0
                              ? () => handleMenuClick(menuItem.id)
                              : () => {}
                          }
                          sx={{
                            cursor:
                              menuItem.subMenu.length > 0
                                ? "pointer"
                                : "default",
                          }}
                        >
                          <Icon
                            path={menuIcons(menuItem.name)}
                            size={1}
                            color={theme.palette.primary.main}
                          />
                          <Typography
                            sx={(theme) => ({
                              fontSize: ".9rem",
                              color: theme.palette.grey[600],
                            })}
                          >
                            {menuItem.name}
                          </Typography>
                          {menuItem.subMenu.length > 0 && (
                            <Icon
                              path={
                                checkIfIdIncluded(menuItem.id)
                                  ? mdiChevronUp
                                  : mdiChevronDown
                              }
                              size={1}
                              color={theme.palette.common.black}
                            />
                          )}
                        </Stack>
                        {menuItem.subMenu.length > 0 && (
                          <Collapse
                            in={checkIfIdIncluded(menuItem.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Stack
                              direction="column"
                              paddingTop={2}
                              width="100%"
                            >
                              <Box width="100%">
                                {menuItem.subMenu.map(
                                  (subMenuItem, subMenuKey) => {
                                    return (
                                      <Stack direction="row" key={subMenuKey}>
                                        <Box width="50%">
                                          <Typography
                                            sx={(theme) => ({
                                              fontSize: ".9rem",
                                              color: theme.palette.grey[600],
                                              marginLeft: "40px",
                                            })}
                                          >
                                            {subMenuItem.name}
                                          </Typography>
                                        </Box>
                                        <Box width="50%">
                                          <Checkbox
                                            disableRipple
                                            checked={permissionForm.includes(
                                              subMenuItem.id
                                            )}
                                            onChange={() =>
                                              handleTogglePermission(
                                                subMenuItem.id,
                                                menuItem
                                              )
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                    );
                                  }
                                )}
                              </Box>
                            </Stack>
                          </Collapse>
                        )}
                      </Box>
                      {menuItem.subMenu.length === 0 && (
                        <Box width="50%">
                          <Checkbox
                            disableRipple
                            checked={permissionForm.includes(menuItem.id)}
                            onChange={() => handleTogglePermission(menuItem.id)}
                          />
                        </Box>
                      )}
                    </Stack>
                  );
                })}
                <Divider
                  sx={(theme) => ({
                    marginY: theme.spacing(3),
                  })}
                />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="button-primary"
                    sx={{
                      width: 150,
                    }}
                    startIcon={
                      saveLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={
                      saveLoading
                        ? () => {}
                        : handleSubmit(onSubmitSave, onError)
                    }
                  >
                    {id ? "Update" : "Save"}
                  </Button>
                </Stack>
              </FormProvider>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default RoleInformation;
