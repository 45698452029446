import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { linkWithDomain } from "../../../../../utils/helpers/stringManipulate";
import { useLazyDownloadBuyerGuidelineQuery } from "../../../../../service/slice/client-management/buyer/buyerGuidelineSlice";
import { useGetPaymentQuery } from "../../../../../service/slice/client-management/buyer/buyerPaymentSlice";
import { errorDisplayOrNavigate } from "../../../../../utils/notificationToast";
import { skipToken } from "@reduxjs/toolkit/query";
import { LoaderWithText } from "../../../../common/Loaders";

import "./index.css";

type Props = {
  title: string;
  type: "RR" | "TC" | "GDL" | "PS";
  unitId?: number;
};

const GuidelineViewPs = ({ title, type, unitId }: Props) => {
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;

  const [
    downloadTrigger,
    {
      data: guidelineData,
      isLoading: guidelineIsLoading,
      error: guidelineError,
    },
  ] = useLazyDownloadBuyerGuidelineQuery();

  const {
    data: paymentData,
    isLoading: paymentDataIsLoading,
    error: paymentDataError,
  } = useGetPaymentQuery(unitId ?? skipToken);

  const loading = guidelineIsLoading || paymentDataIsLoading;

  if (guidelineError) {
    errorDisplayOrNavigate({
      error: guidelineError,
      toastId: "guideline",
    });
  }

  if (paymentDataError) {
    errorDisplayOrNavigate({
      error: paymentDataError,
      toastId: "payment-data",
    });
  }

  useEffect(() => {
    if (paymentData?.data.id && paymentData.data.type === "SCHEDULED") {
      downloadTrigger({
        buyerId: buyerId as number,
        buyerUnitTypeId: paymentData.data.buyerUnitTypeId,
        type,
      });
    }
  }, [paymentData]);

  return loading ? (
    <LoaderWithText text={`Getting ${title.toLowerCase()} data`} />
  ) : paymentData?.data?.id && paymentData.data.type === "SCHEDULED" ? (
    <Grid item xs={12} lg={6}>
      <Box height={600} width={"100%"} marginTop={{ xs: 3, lg: 6 }}>
        <Typography variant="textfieldLabel">{title}</Typography>
        <DocViewer
          documents={[
            {
              uri: linkWithDomain(
                guidelineData?.data ? guidelineData?.data.link : ""
              ),
            },
          ]}
          pluginRenderers={DocViewerRenderers}
        />
      </Box>
    </Grid>
  ) : null;
};

export default GuidelineViewPs;
