import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export const LoaderWithText = ({
  loaderColor,
  text,
}: {
  loaderColor?: string;
  text?: string;
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <CircularProgress
        size={20}
        sx={{
          color: loaderColor ?? "primary.main",
        }}
      />
      {text && (
        <Typography
          sx={{
            fontWeight: 500,
            color: loaderColor ?? "primary.main",
          }}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
};
