import React from "react";
import Grid from "@mui/material/Grid";

import Trophy from "./sub/Trophy";
import StatisticsCard from "./sub/StatisticsCard";
import TaskDashboard from "./sub/TaskDashboard";
import CounterDashboard from "./sub/CounterDashboard";
import FooterPanel from "../common/FooterPanel";
import { useGetDashboardDataQuery } from "../../service/slice/dashboardSlice";
import { errorDisplayOrNavigate } from "../../utils/notificationToast";
import {
  buyersRoute,
  clientManagementRoute,
  leadsRoute,
  reportsListDeliquentAcc,
  reportsListForfeitedAcc,
  reportsRoute,
} from "../../routes/routeKeys";

type Props = {};

const Dashboard = (props: Props) => {
  const { data, isLoading, error, isFetching } = useGetDashboardDataQuery();

  const loading = isLoading || isFetching;

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "dashboard-data",
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Trophy
          loading={loading}
          data={
            data
              ? data.data.mostSale.agent
                ? data.data.mostSale
                : undefined
              : undefined
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <StatisticsCard loading={loading} data={data?.data.statistics} />
      </Grid>
      <Grid item xs={12} md={7}>
        <TaskDashboard />
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CounterDashboard
              title="New Client"
              count={data?.data.otherStatistics.newClient ?? 0}
              loading={loading}
              route={`${clientManagementRoute}${leadsRoute}?page=1&entries=10&search=`}
            />
          </Grid>
          <Grid item xs={6}>
            <CounterDashboard
              title="Receivables"
              count={
                data ? Math.round(data.data.otherStatistics.recievables) : 0
              }
              loading={loading}
              route={`${clientManagementRoute}${buyersRoute}?page=1&entries=10&search=`}
            />
          </Grid>
          <Grid item xs={6}>
            <CounterDashboard
              title="Delinquent"
              count={data?.data.otherStatistics.delinquent ?? 0}
              loading={loading}
              route={`${reportsRoute}${reportsListDeliquentAcc}`}
            />
          </Grid>
          <Grid item xs={6}>
            <CounterDashboard
              title="Forfeited"
              count={data?.data.otherStatistics.forfeited ?? 0}
              loading={loading}
              route={`${reportsRoute}${reportsListForfeitedAcc}`}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <FooterPanel />
      </Grid> */}
    </Grid>
  );
};

export default Dashboard;
