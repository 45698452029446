import React, { useEffect } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import {
  useAddLotTypeMutation,
  useUpdateLotTypeMutation,
  useGetLotCategoryConfigListQuery,
} from "../../../service/slice/lot-vault-crypt/lotSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LotCategoryModel, LotTypeModel } from "../../../model/lotModel";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";

type TypeForm = {
  name: string;
  category: {
    label: string;
    value: number | string;
  };
  description: string;
};

type Props = {
  title: string;
  data: LotTypeModel | null;
  closeFn: () => void;
};

const LotTypeForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<TypeForm>({
    defaultValues: {
      name: "",
      category: {
        label: "",
        value: "",
      },
      description: "",
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const {
    data: categoryList,
    isLoading: categoryIsLoading,
    error: categoryError,
  } = useGetLotCategoryConfigListQuery();

  const [
    addMutation,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddLotTypeMutation();
  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateLotTypeMutation();

  const categoryOptions = categoryList
    ? categoryList.data.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  const categoryWatch = useWatch({
    control,
    name: "category",
  });

  const onError = () => {
    if (!_.isNumber(categoryWatch.value)) {
      setError("category", {
        type: "required",
        message: thisFieldRequired,
      });
    }
  };

  const onSubmit: SubmitHandler<TypeForm> = (dataFields) => {
    if (!_.isNumber(categoryWatch.value)) {
      setError("category", {
        type: "required",
        message: thisFieldRequired,
      });

      return;
    }

    if (data) {
      updateMutations({
        queryParams: data.id,
        bodyParams: {
          categoryId: dataFields.category.value as number,
          description: dataFields.description,
          name: dataFields.name,
        },
      });
    } else {
      addMutation({
        categoryId: dataFields.category.value as number,
        description: dataFields.description,
        name: dataFields.name,
      });
    }
  };

  const saveLoading = addisLoading || updateIsLoading;

  if (categoryError) {
    errorDisplayOrNavigate({
      error: categoryError,
      toastId: "category-list",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-lot-type",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Type successfully created!",
      toastId: "add-lot-type",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Lot Type successfully updated!",
      toastId: "update-lot-type",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-lot-type",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("description", data.description);
      setValue("category", {
        label: data.lotCategory.name,
        value: data.lotCategory.id,
      });
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant="textfieldLabel">Type Name</Typography>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Category Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="textfieldLabel">Category</Typography>
              <CustomReactSelectJs
                control={control}
                name="category"
                options={categoryOptions}
                isRequired
                placeholder="Category"
                isDisabled={categoryIsLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="textfieldLabel">Description</Typography>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Description"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default LotTypeForm;
