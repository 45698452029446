import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { TransitionProps } from "@mui/material/transitions";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import { VaultInformationModel } from "../../../../../model/vaultModel";
import ConfirmModal from "../../../../common/ConfirmModal";
import useDeleteTableItem from "../../../../../hooks/useDeleteTableItem";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  unit: VaultInformationModel | null;
  handleToggleView: (open: boolean, unit: VaultInformationModel | null) => void;
  handleToggleUnit: (
    isAdd: boolean,
    type: string,
    unitTypeId: number,
    unit: any
  ) => void;
  isSelected?: boolean;
};

const VaultDetails = ({
  open,
  unit,
  handleToggleView,
  handleToggleUnit,
  isSelected,
}: Props) => {
  const [unassignItem, handleUnassignItem] =
    useDeleteTableItem<VaultInformationModel>();

  return (
    <>
      <ConfirmModal
        isLoading={false}
        open={unassignItem.open}
        text={`Are you sure to unassign ${unassignItem.data?.vaultLevel.name} - ${unassignItem.data?.vaultColumn.name}`}
        closeFn={() => handleUnassignItem(false, null)}
        confirmFn={() => {
          handleToggleUnit(
            isSelected ? false : true,
            "VAULT",
            unit?.id as number,
            unit as VaultInformationModel
          );
          handleUnassignItem(false, null);
          handleToggleView(false, null);
        }}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={(theme) => ({
          "& .MuiDialog-paper": {
            width: "70vw",
            maxWidth: "70vw",
            height: "90vh",
            borderRadius: 2,

            "& #file-name": {
              display: "none",
            },
          },
        })}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{`${unit?.vaultLevel.name} - ${unit?.vaultColumn.name}`}</Typography>
            <Box
              sx={{
                cursor: "pointer",
                height: "24px",
              }}
              onClick={() => handleToggleView(false, null)}
            >
              <Icon path={mdiClose} size={1} />
            </Box>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <div>
                  <Typography variant="textfieldLabel">
                    Columbarium Level
                  </Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={unit?.vaultLevel.name}
                    sx={{
                      width: "100%",
                    }}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">
                    Columbarium Column
                  </Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={unit?.vaultColumn.name}
                    sx={{
                      width: "100%",
                    }}
                  />
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <div>
                  <Typography variant="textfieldLabel">Amount</Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={unit?.amountPreview ?? unit?.amount}
                    sx={{
                      width: "100%",
                    }}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">At Need</Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={unit?.amountPreview ?? unit?.atNeed}
                    sx={{
                      width: "100%",
                    }}
                  />
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="textfieldLabel">
                Columbarium Image
              </Typography>
              <Box sx={{ width: "100%" }}>
                <img
                  src={unit?.picture}
                  alt="pic"
                  style={{
                    backgroundSize: "cover",
                    width: "100%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            justifyContent: "center",
            paddingTop: 2,
            paddingBottom: 2,
            backgroundColor: theme.palette.grey[100],
          })}
        >
          {unit?.vaultStatus.name === "Available" && (
            <Button
              variant={isSelected ? "button-cancel" : "button-confirm"}
              sx={{
                width: 150,
              }}
              onClick={
                isSelected
                  ? () => {
                      handleUnassignItem(true, unit);
                    }
                  : () => {
                      handleToggleUnit(
                        true,
                        "VAULT",
                        unit?.id as number,
                        unit as VaultInformationModel
                      );
                      handleToggleView(false, null);
                    }
              }
            >
              {isSelected ? "Unassign" : "Assign"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VaultDetails;
