import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from "react-hook-form";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import BGImage from "../../../asset/lg-bg.jpg";
import CompanyLogo from "../../../asset/logo.png";
// models
import { AuthModel, LoginFormDataType } from "../../../model/authModel";
import { useLoginUserMutation } from "../../../service/slice/authSlice";
import {
  showToastError,
  showToastSuccess,
} from "../../../utils/notificationToast";
import {
  emailLabel,
  forgotPasswordLabel,
  loginBtnLabel,
  passwordLabel,
  pleaseSignInText,
  thisFieldRequired,
  welcomeText,
} from "../../../utils/constants";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { replaceAllSpaceWithHyphen } from "../../../utils/helpers/stringManipulate";
import FooterPanel from "../../common/FooterPanel";
import HeaderPanel from "../../common/HeaderPanel";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [resetPassModal, setResetPassModal] = useState<boolean>(false);
  const form = useForm<LoginFormDataType>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const [loginMutate, { isLoading, error, reset, isSuccess, data }] =
    useLoginUserMutation();

  const onSubmit: SubmitHandler<LoginFormDataType> = (data) => {
    loginMutate({
      username: data.email,
      password: data.password,
    });
  };

  if (error) {
    showToastError({ error, toastId: "auth" });
    reset();
  }

  useEffect(() => {
    if (isSuccess) {
      if (data?.user?.success === false) {
        showToastError({
          text: data?.user.message,
          toastId: "auth",
        });
        return;
      }
      showToastSuccess({
        text: `Authenticated as ${data?.user?.email}`,
        toastId: "auth",
      });

      if (data?.menu) {
        navigate(
          data?.menu![0].name === "Dashboard"
            ? "/"
            : `/${replaceAllSpaceWithHyphen(data?.menu![0].name as string)}`
        );
      } else {
        navigate("/");
      }
    }
  }, [isSuccess]);

  return (
    <>
      <HeaderPanel />
      <ForgotPasswordModal
        open={resetPassModal}
        closeFn={() => setResetPassModal(false)}
      />
      <Box
        sx={(theme) => ({
          // backgroundImage: `url(${BGImage})`,
          backgroundColor: theme.palette.secondary.main,
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
        })}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          minHeight={"100vh"}
        >
          <Paper
            elevation={2}
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              padding: theme.spacing(5),
              maxWidth: 450,
              width: 450,
              marginTop: 13,
            })}
          >
            <Stack direction="row" justifyContent="center" marginBottom={4}>
              <img
                src={CompanyLogo}
                alt="Garden of Gethsemany"
                style={{
                  maxWidth: 200,
                }}
              />
            </Stack>
            <Box marginBottom={4}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                {welcomeText}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: ".9rem",
                }}
              >
                {pleaseSignInText}
              </Typography>
            </Box>
            <FormProvider {...form}>
              <Stack spacing={3} marginY={2} width={"100%"}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      type={"text"}
                      label={emailLabel}
                      placeholder={emailLabel}
                      error={errors.email ? true : false}
                      helperText={errors.email?.message}
                      sx={{
                        width: "100%",
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label={passwordLabel}
                      placeholder={passwordLabel}
                      error={errors.password ? true : false}
                      helperText={errors.password?.message}
                      sx={{
                        width: "100%",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityOutlinedIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                onClick={() => setShowPassword(true)}
                                sx={{
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
              </Stack>

              <Button
                variant="button-primary"
                onClick={isLoading ? () => {} : handleSubmit(onSubmit)}
                startIcon={
                  isLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
              >
                {loginBtnLabel}
              </Button>
              <Typography
                onClick={() => setResetPassModal(true)}
                marginTop={4}
                sx={(theme) => ({
                  textAlign: "right",
                  fontSize: ".8rem",
                  color: theme.palette.primary.dark,
                  cursor: "pointer",
                })}
              >{`${forgotPasswordLabel}?`}</Typography>
            </FormProvider>
          </Paper>
        </Stack>
      </Box>
      <FooterPanel />
    </>
  );
};

export default Login;
