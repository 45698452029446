import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Icon from "@mdi/react";
import { mdiFileFind, mdiDeleteOutline } from "@mdi/js";
import { useTheme } from "@mui/material/styles";
import FileViewModal from "../common/FileViewModal";

type Props = {
  path: string;
  onClickEvent: () => void;
  handleDelete: (index: number) => void;
  index: number;
};

const DocumentItem = ({ path, onClickEvent, handleDelete, index }: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: 1,
        boxShadow: theme.shadows[3],
        padding: 1,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Tooltip title={path.substring(path.lastIndexOf("/") + 1, path.length)}>
          <Typography
            sx={(theme) => ({
              fontSize: ".85rem",
              color: theme.palette.primary.main,
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            })}
          >
            {path.substring(path.lastIndexOf("/") + 1, path.length)}
          </Typography>
        </Tooltip>
        <Stack direction="row" spacing={1}>
          <div onClick={onClickEvent}>
            <Icon
              path={mdiFileFind}
              size={1}
              color={theme.palette.info.main}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div onClick={() => handleDelete(index)}>
            <Icon
              path={mdiDeleteOutline}
              size={1}
              color={theme.palette.error.main}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </Stack>
      </Stack>
    </Box>
  );
};

export default DocumentItem;
