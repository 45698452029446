import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import {
  useAddLotCategoryMutation,
  useUpdateLotCategoryMutation,
} from "../../../service/slice/lot-vault-crypt/lotSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LotCategoryModel } from "../../../model/lotModel";

type CategoryForm = {
  name: string;
  description: string;
};

type Props = {
  title: string;
  data: LotCategoryModel | null;
  closeFn: () => void;
};

const LotCategoryForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<CategoryForm>({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const { control, setValue, reset: fieldReset, handleSubmit } = form;

  const [
    addMutation,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddLotCategoryMutation();
  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateLotCategoryMutation();

  const onSubmit: SubmitHandler<CategoryForm> = (dataFields) => {
    if (data) {
      updateMutations({
        queryParams: data.id,
        bodyParams: dataFields,
      });
    } else {
      addMutation(dataFields);
    }
  };

  const saveLoading = addisLoading || updateIsLoading;

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-lot-category",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Category successfully created!",
      toastId: "add-lot-category",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Lot Category successfully updated!",
      toastId: "update-lot-category",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-lot-category",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("description", data.description);
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        <FormProvider {...form}>
          <Stack spacing={2}>
            <Box
              sx={{
                width: { lg: "40%", xs: "100%" },
              }}
            >
              <Typography variant="textfieldLabel">Category Name</Typography>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Category Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography variant="textfieldLabel">Description</Typography>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Description"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Divider
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          />
          <Button
            variant="button-primary"
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={saveLoading ? () => {} : handleSubmit(onSubmit)}
          >
            {data ? "Update" : "Save"}
          </Button>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default LotCategoryForm;
