import {
  BuyerGuidelineBody,
  BuyerGuidelineQuery,
  BuyerGuidlineDtoModel,
} from "../../../../model/buyerModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const buyerGuidelineEndpoints = {
  "upload-guideline": "/buyer/upload/agreement",
  "add-guideline": "/buyer/agreement/add",
  "download-guidelines": (param: BuyerGuidelineQuery) =>
    `/buyer/agreement/download?buyerId=${param.buyerId}&type=${param.type}&buyerUnitTypeId=${param.buyerUnitTypeId}`,
};

export const buyerGuidelineSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addBuyerGuideline: builder.mutation<void, BuyerGuidelineBody>({
      query: (body) => ({
        url: buyerGuidelineEndpoints["add-guideline"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "buyer-guideline",
          id: `${arg.buyerId}-${arg.type}`,
        },
      ],
    }),
    downloadBuyerGuideline: builder.query<
      BuyerGuidlineDtoModel,
      BuyerGuidelineQuery
    >({
      query: (param) => ({
        url: buyerGuidelineEndpoints["download-guidelines"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "buyer-guideline",
          id: `${arg.buyerId}-${arg.type}`,
        },
      ],
    }),
  }),
});

export const {
  useAddBuyerGuidelineMutation,
  useDownloadBuyerGuidelineQuery,
  useLazyDownloadBuyerGuidelineQuery,
} = buyerGuidelineSlice;
