import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type Props = {};

const ComingSoon = (props: Props) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="85vh"
      sx={{
        backgroundColor: "secondary.dark",
      }}
    >
      <Typography
        sx={{
          color: "common.white",
          fontSize: "3rem",
          fontWeight: 600,
        }}
      >
        Coming Soon
      </Typography>
    </Stack>
  );
};

export default ComingSoon;
