import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import { clientManagementRoute, buyersRoute } from "../../../routes/routeKeys";

import ActiveBuyers from "./ActiveBuyers";
import ForfeitedBuyers from "./ForfeitedBuyers";
import DelinquentBuyers from "./DelinquentBuyers";
import { UnitModel } from "./active-buyer-form/unit-type/UnitType";
import { BuyerInformationModel } from "../../../model/buyerModel";

export interface StepperGenericModel {
  unit?: UnitModel;
  unitType?: "LOT" | "VAULT" | "CRYPT";
  buyerInfo?: BuyerInformationModel;
}

const tabItems: ItemsProps[] = [
  {
    label: "Active Buyers",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Forfeited Buyers",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Delinquent Buyers",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const Buyers = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${clientManagementRoute}${buyersRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && <ActiveBuyers />}
          {tabValue === 1 && <ForfeitedBuyers />}
          {tabValue === 2 && <DelinquentBuyers />}
        </Box>
      </Box>
    </Paper>
  );
};

export default Buyers;
