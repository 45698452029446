import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { generalRoute, systemConfigRoute } from "../../../routes/routeKeys";
import GeneralConfig from "./general-config/GeneralConfig";
import LotsVaultsConfig from "./lots-vaults-config/LotsVaultsConfig";
import ServicesConfig from "./service-config/ServicesConfig";
import PaymentScheduleConfig from "./payment-config/PaymentScheduleConfig";
import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";

const tabItems: ItemsProps[] = [
  {
    label: "General",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Lots/Vaults/Crypt",
  },
  {
    label: "Services",
  },
  {
    label: "Payment Schedule",
  },
];

const General = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${systemConfigRoute}${generalRoute}?tab=`}
        />
        {(tabValue === 0 || tabValue === -1) && <GeneralConfig />}
        {tabValue === 1 && <LotsVaultsConfig />}
        {tabValue === 2 && <ServicesConfig />}
        {tabValue === 3 && <PaymentScheduleConfig />}
      </Box>
    </Paper>
  );
};

export default General;
