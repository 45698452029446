import React, { useState, useEffect } from "react";
import { mdiFilePdfBox, mdiMicrosoftExcel } from "@mdi/js";
import { useLazyGenerateFileQuery } from "../../service/apiSlice";
import { saveAs } from "file-saver";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import IconButtonMdi from "./IconButtonMdi";
import { errorDisplayOrNavigate } from "../../utils/notificationToast";

type Props = {
  endpoint: string;
};

const DownloadOptionsList = ({ endpoint }: Props) => {
  const theme = useTheme();

  const [
    generateFileTrigger,
    {
      isLoading: fileDataIsLoading,
      isFetching: fileDataIsFetching,
      error: fileDataError,
      originalArgs,
    },
  ] = useLazyGenerateFileQuery();

  const isLoading = fileDataIsLoading || fileDataIsFetching;

  const handleDownload = async (type: string) => {
    const file = await generateFileTrigger({
      endpoint,
      type,
    }).unwrap();

    const filename = file.data.link.substring(
      file.data.link.lastIndexOf("/") + 1,
      file.data.link.length
    );

    saveAs(file.data.link, filename);
  };

  if (fileDataError) {
    errorDisplayOrNavigate({
      error: fileDataError,
      toastId: "file-download",
    });
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1} paddingLeft={2}>
      <IconButtonMdi
        mdiIcon={mdiFilePdfBox}
        iconColor={theme.palette.error.dark}
        tooltipTitle="Download PDF File"
        isLoading={isLoading && originalArgs?.type === "pdf"}
        onClick={() => handleDownload("pdf")}
      />
      <IconButtonMdi
        mdiIcon={mdiMicrosoftExcel}
        iconColor={theme.palette.success.dark}
        tooltipTitle="Download EXCEL File"
        isLoading={isLoading && originalArgs?.type === "excel"}
        onClick={() => handleDownload("excel")}
      />
    </Stack>
  );
};

export default DownloadOptionsList;
