import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mdi/react";
import { mdiClose, mdiMinusCircleOutline, mdiPlusCircleOutline } from "@mdi/js";
import Stack from "@mui/material/Stack";
import StepperTabMenu, { StepModel } from "../../common/StepperTabMenu";
import { domainLink, thisFieldRequired } from "../../../utils/constants";
import { LoaderWithText } from "../../common/Loaders";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetBuyerInformationQuery,
  useUpdateBuyerInfoMutation,
  useUploadPicMutation,
} from "../../../service/slice/client-management/buyer/buyerInformationSlice";
import { useAddBuyerInfoMutation } from "../../../service/slice/rental-and-services/rentalServicesSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { UploadPicDtoModel } from "../../../model/usersModel";
import { BuyerInformationBodyModel } from "../../../model/buyerModel";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import PhoneNumberField from "../../common/textfields/PhoneNumberField";
import IconButtonMdi from "../../common/IconButtonMdi";
import { useTheme } from "@mui/material/styles";
import {
  useGetOtherInfoQuery,
  useUpdateOtherInfoMutation,
} from "../../../service/slice/client-management/buyer/otherInformationSlice";
import SpouseContacts from "../../client-management/buyers/active-buyer-form/other-information/SpouseContacts";
import DeceasedInfo from "../../client-management/buyers/active-buyer-form/other-information/DeceasedInfo";
import AuthorizedInfo from "../../client-management/buyers/active-buyer-form/other-information/AuthorizedInfo";
import { OtherForm } from "../../client-management/buyers/active-buyer-form/other-information/OtherInfo";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  title: string;
  buyerPaymentId: number | null;
  onClose: () => void;
};

interface AddressModel {
  address: string;
  country: string;
  region: string;
  municipality: string;
  city: string;
  postalCode: string;
}

export interface BuyerForm {
  lastName: string;
  firstName: string;
  middleName: string;
  contactNumber: {
    id: null | number;
    value: string;
  }[];
  email: string;
  birthDate: Date | null;
  homeAddress: AddressModel;
  mailingAddress: AddressModel;
  sameHomeAddress: boolean;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
}

const AddBuyerModal = ({ open, title, onClose, buyerPaymentId }: Props) => {
  const [step, setStep] = useState(0);
  const [steps] = useState<StepModel[]>([
    {
      key: "buyer-information",
      label: "Buyer Information",
      withPaging: false,
    },
    {
      key: "other-information",
      label: "Other Information",
      withPaging: false,
    },
  ]);
  const [buyerId, setBuyerId] = useState<any>();

  const theme = useTheme();
  const { state: locState } = useLocation();

  const form = useForm<BuyerForm>({
    defaultValues: {
      lastName: "",
      firstName: "",
      middleName: "",
      email: "",
      birthDate: null,
      contactNumber: [
        {
          id: null,
          value: "",
        },
      ],
      homeAddress: {
        address: "",
        city: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
      },
      mailingAddress: {
        address: "",
        city: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
      },
      sameHomeAddress: false,
      picture: {
        file: null,
        filetype: null,
        originalName: null,
        rawFile: null,
      },
    },
  });

  const otherForm = useForm<OtherForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      contactNumber: [
        {
          id: null,
          value: "",
        },
      ],
      email: "",
      married: false,
      deceasedInfo: [
        {
          id: null,
          birthDate: null,
          deathDate: null,
          firstName: "",
          lastName: "",
          middleName: "",
          nickname: "",
          religion: "",
          placeOfBirth: "",
        },
      ],
      authorized: [
        {
          id: null,
          firstName: "",
          lastName: "",
          middleName: "",
          email: "",
          contact: [
            {
              id: null,
              value: "",
            },
          ],
        },
      ],
    },
  });

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const {
    data: otherInfoData,
    isLoading: otherInfoIsLoading,
    error: otherInfoError,
  } = useGetOtherInfoQuery(buyerId ?? skipToken);

  const [
    uploadPicMutate,
    {
      isLoading: uploadPicIsLoading,
      error: uploadPicError,
      reset: uploadPicReset,
    },
  ] = useUploadPicMutation();

  const [
    addMutate,
    {
      data: addData,
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddBuyerInfoMutation();

  const [
    updateMutate,
    {
      error: updateError,
      isLoading: updateIsLoading,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateBuyerInfoMutation();

  const [
    updateOtherMutate,
    {
      error: updateOtherError,
      isLoading: updateOtherIsLoading,
      reset: updateOtherReset,
      isSuccess: updateOtherIsSuccess,
    },
  ] = useUpdateOtherInfoMutation();

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
    clearErrors,
  } = form;

  const {
    control: otherControl,
    setValue: setOtherValue,
    handleSubmit: handleOtherSubmit,
    formState: { errors: otherErrors },
    clearErrors: clearOtherErrors,
  } = otherForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contactNumber",
  });

  const pictureWatch = useWatch({
    control,
    name: "picture",
  });

  const mobileNumWatch = useWatch({
    control,
    name: "contactNumber",
  });

  const sameHomeAddressWatch = useWatch({
    control,
    name: "sameHomeAddress",
  });

  const homeAddressWatch = useWatch({
    control,
    name: "homeAddress",
  });

  const contactNumberWatch = useWatch({
    control,
    name: "contactNumber",
  });

  const marriedWatch = useWatch({
    control: otherControl,
    name: "married",
  });

  // const allFieldsWatch = watch(["picture"]);

  const saveLoading =
    uploadPicIsLoading ||
    addIsLoading ||
    updateIsLoading ||
    updateOtherIsLoading;
  const loading = buyerInfoIsLoading || otherInfoIsLoading;

  const validateOtherFields = (): number[] => {
    const errorsArr = [];

    if (_.isEmpty(mobileNumWatch[0].value)) {
      setError(`contactNumber.${0}.value`, {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    return errorsArr;
  };

  const onError = () => {
    validateOtherFields();
  };

  const onOtherError = () => {
    // validateOtherFields();
  };

  const onSubmit: SubmitHandler<BuyerForm> = async (dataFields) => {
    const otherErrors = validateOtherFields();

    if (otherErrors.length > 0) {
      return;
    }

    const finalDateBirth: string = moment(dataFields.birthDate).format(
      "YYYY-MM-DD"
    );

    let dataPic: UploadPicDtoModel | null = null;
    if (buyerId) {
      const newBody: Partial<BuyerInformationBodyModel> = {
        birthDate: finalDateBirth,
        contact: dataFields.contactNumber
          .map((item) => ({
            number: item.value,
            id: item.id,
          }))
          .filter((item) => !_.isEmpty(item.number)),
        email: dataFields.email,
        firstName: dataFields.firstName,
        lastName: dataFields.lastName,
        middleName: dataFields.middleName,
        homeAddress: dataFields.homeAddress.address,
        homeCity: dataFields.homeAddress.city,
        homeCountry: dataFields.homeAddress.country,
        homeMunicipality: dataFields.homeAddress.municipality,
        homePostalCode: dataFields.homeAddress.postalCode,
        homeRegion: dataFields.homeAddress.region,
        mailingAddress: dataFields.mailingAddress.address,
        mailingCity: dataFields.mailingAddress.city,
        mailingCountry: dataFields.mailingAddress.country,
        mailingMunicipality: dataFields.mailingAddress.municipality,
        mailingPostalCode: dataFields.mailingAddress.postalCode,
        mailingRegion: dataFields.mailingAddress.region,
        sameAddress: dataFields.sameHomeAddress ? 1 : 0,
      };

      if (dataFields.picture.rawFile) {
        dataPic = await uploadPicMutate(dataFields.picture.rawFile).unwrap();
      }

      await updateMutate({
        queryParam: buyerId,
        bodyParam: {
          ...newBody,
          picture: dataFields.picture.rawFile
            ? (dataPic?.data.link as string)
            : dataFields.picture.file
            ? dataFields.picture.file?.replace(domainLink, "")
            : dataPic
            ? dataPic?.data.link
            : null,
        },
      });
    } else {
      if (dataFields.picture.rawFile) {
        dataPic = await uploadPicMutate(dataFields.picture.rawFile).unwrap();
      }

      await addMutate({
        type: "SERVICE",
        birthDate: finalDateBirth,
        contact: dataFields.contactNumber.map((item) => ({
          number: item.value,
          id: null,
        })),
        email: dataFields.email,
        firstName: dataFields.firstName,
        lastName: dataFields.lastName,
        middleName: dataFields.middleName,
        picture: dataPic ? dataPic?.data.link : null,
        homeAddress: dataFields.homeAddress.address,
        homeCity: dataFields.homeAddress.city,
        homeCountry: dataFields.homeAddress.country,
        homeMunicipality: dataFields.homeAddress.municipality,
        homePostalCode: dataFields.homeAddress.postalCode,
        homeRegion: dataFields.homeAddress.region,
        mailingAddress: dataFields.mailingAddress.address,
        mailingCity: dataFields.mailingAddress.city,
        mailingCountry: dataFields.mailingAddress.country,
        mailingMunicipality: dataFields.mailingAddress.municipality,
        mailingPostalCode: dataFields.mailingAddress.postalCode,
        mailingRegion: dataFields.mailingAddress.region,
        sameAddress: dataFields.sameHomeAddress ? 1 : 0,
      });
    }
  };

  const onOtherSubmit: SubmitHandler<OtherForm> = (dataFields) => {
    // const otherErrors = validateOtherFields();

    // if (otherErrors.length > 0) {
    //   return;
    // }

    updateOtherMutate({
      queryParam: buyerId as number,
      bodyParam: {
        married: dataFields.married ? 1 : 0,
        spouseEmail: dataFields.email,
        spouseFirstName: dataFields.firstName,
        spouseLastName: dataFields.lastName,
        spouseMiddleName: dataFields.middleName,
        contact: dataFields.contactNumber
          .map((item) => ({
            id: item.id,
            number: item.value,
          }))
          .filter((item) => !_.isEmpty(item.number)),
        deceased: dataFields.deceasedInfo
          .map((item) => ({
            birthDate: moment(item.birthDate).format("YYYY-MM-DD"),
            deathDate: item.deathDate
              ? moment(item.deathDate).format("YYYY-MM-DD")
              : "",
            firstName: item.firstName,
            id: item.id,
            lastName: item.lastName,
            middleName: item.middleName,
          }))
          .filter(
            (item) =>
              !_.isEmpty(item.firstName) &&
              !_.isEmpty(item.lastName) &&
              item.birthDate !== null
          ),
        authorized: dataFields.authorized.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          middleName: item.middleName,
          id: item.id,
          email: item.email,
          contact: item.contact
            .map((cont) => ({
              id: cont.id,
              number: cont.value,
            }))
            .filter((cont) => !_.isEmpty(cont.number)),
        })),
      },
    });
  };

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (uploadPicError) {
    errorDisplayOrNavigate({
      error: uploadPicError,
      toastId: "upload-pic",
    });

    uploadPicReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New buyer added.",
      toastId: "add",
    });

    addReset();
    setBuyerId(addData?.data?.id);
    setStep(1);
    // fieldReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-info",
    });

    updateReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Buyer information updated!",
      toastId: "update-info",
    });

    updateReset();
  }

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (otherInfoError) {
    errorDisplayOrNavigate({
      error: otherInfoError,
      toastId: "other-info",
    });
  }

  if (updateOtherIsSuccess) {
    showToastSuccess({
      text: otherInfoData?.data
        ? "Other information updated!"
        : "Other information saved!",
      toastId: "update-infor",
    });

    if (otherInfoData?.data) {
      updateOtherReset();
    } else {
    }
  }

  if (updateOtherError) {
    errorDisplayOrNavigate({
      error: updateOtherError,
      toastId: "update-info",
    });

    updateOtherReset();
  }

  const handleAddRemoveContact = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        id: null,
        value: "",
      });
    }
  };

  useEffect(() => {
    const { homeAddress, mailingAddress } = getValues();
    if (sameHomeAddressWatch) {
      setValue("mailingAddress.address", homeAddress.address);
      setValue("mailingAddress.city", homeAddress.city);
      setValue("mailingAddress.country", homeAddress.country);
      setValue("mailingAddress.municipality", homeAddress.municipality);
      setValue("mailingAddress.postalCode", homeAddress.postalCode);
      setValue("mailingAddress.region", homeAddress.region);
      clearErrors([
        "mailingAddress.address",
        "mailingAddress.city",
        "mailingAddress.country",
        "mailingAddress.municipality",
        "mailingAddress.postalCode",
        "mailingAddress.region",
      ]);
    } else {
      setValue("mailingAddress.address", mailingAddress.address);
      setValue("mailingAddress.city", mailingAddress.city);
      setValue("mailingAddress.country", mailingAddress.country);
      setValue("mailingAddress.municipality", mailingAddress.municipality);
      setValue("mailingAddress.postalCode", mailingAddress.postalCode);
      setValue("mailingAddress.region", mailingAddress.region);
    }
  }, [sameHomeAddressWatch, homeAddressWatch]);

  useEffect(() => {
    // if (dataFromStorage) {
    //   setValue("lastName", dataFromStorage.lastName);
    // }
    if (buyerInfoData || locState?.buyerInfo) {
      const {
        firstName,
        lastName,
        birthDate,
        buyerContacts,
        email,
        homeAddress,
        homeCity,
        homeCountry,
        homeMunicipality,
        homePostalCode,
        homeRegion,
        mailingAddress,
        mailingCity,
        mailingCountry,
        mailingMunicipality,
        mailingPostalCode,
        mailingRegion,
        middleName,
        picture,
        sameAddress,
      } = buyerInfoData?.data ? buyerInfoData.data : locState.buyerInfo;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("middleName", middleName);
      setValue("email", email);
      setValue("birthDate", new Date(birthDate));
      setValue(
        "contactNumber",
        buyerContacts.map((item: { id: number | null; number: string }) => ({
          id: locState?.buyerInfo ? null : item.id,
          value: item.number,
        }))
      );
      setValue("homeAddress.address", homeAddress);
      setValue("homeAddress.city", homeCity);
      setValue("homeAddress.country", homeCountry);
      setValue("homeAddress.municipality", homeMunicipality);
      setValue("homeAddress.postalCode", homePostalCode);
      setValue("homeAddress.region", homeRegion);
      setValue("mailingAddress.address", mailingAddress);
      setValue("mailingAddress.city", mailingCity);
      setValue("mailingAddress.country", mailingCountry);
      setValue("mailingAddress.municipality", mailingMunicipality);
      setValue("mailingAddress.postalCode", mailingPostalCode);
      setValue("mailingAddress.region", mailingRegion);
      setValue(
        "picture",
        picture
          ? {
              file: picture.includes(domainLink)
                ? picture
                : `${domainLink}/${picture}`,
              originalName: "",
              filetype: "",
              rawFile: null,
            }
          : {
              file: null,
              originalName: "",
              filetype: "",
              rawFile: null,
            }
      );
      setValue("sameHomeAddress", sameAddress);
    }
  }, [buyerInfoData, locState?.buerInfo]);

  useEffect(() => {
    if (otherInfoData?.data) {
      const {
        buyerContacts,
        buyerDeceaseds,
        married,
        spouseEmail,
        spouseFirstName,
        spouseLastName,
        spouseMiddleName,
      } = otherInfoData.data;
      setOtherValue("married", married);
      setOtherValue("email", spouseEmail);
      setOtherValue("firstName", spouseFirstName);
      setOtherValue("lastName", spouseLastName);
      setOtherValue("middleName", spouseMiddleName);
      setOtherValue(
        "contactNumber",
        buyerContacts.length > 0
          ? buyerContacts.map((item: { id: number; number: string }) => ({
              id: item.id,
              value: item.number,
            }))
          : [
              {
                id: null,
                value: "",
              },
            ]
      );

      setOtherValue(
        "deceasedInfo",
        buyerDeceaseds.length > 0
          ? buyerDeceaseds.map((item) => ({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              middleName: item.middleName,
              birthDate: new Date(item.birthDate),
              deathDate: new Date(item.deathDate),
              nickname: "",
              religion: "",
              placeOfBirth: "",
            }))
          : [
              {
                id: null,
                birthDate: null,
                deathDate: null,
                firstName: "",
                lastName: "",
                middleName: "",
                nickname: "",
                religion: "",
                placeOfBirth: "",
              },
            ]
      );
    }
  }, [otherInfoData]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: "90vw",
          maxWidth: "90vw",
          height: "90vh",
          borderRadius: 2,

          "& #file-name": {
            display: "none",
          },
        },
      })}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>{title}</Typography>
          <Box
            sx={{
              cursor: "pointer",
              height: "24px",
            }}
            onClick={onClose}
          >
            <Icon path={mdiClose} size={1} />
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box padding={2} paddingTop={1}>
          <Box paddingTop={2} paddingX={3}>
            {step === 0 && (
              <FormProvider {...form}>
                <Grid
                  container
                  spacing={3}
                  direction={{ xs: "column", md: "row-reverse" }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="textfieldLabel">Photo</Typography>
                    <UploadPhotoField
                      name="picture"
                      setValue={setValue}
                      clearErrors={clearErrors}
                      // pictureValue={allFieldsWatch[1]}
                      pictureValue={pictureWatch}
                      errorMsg={errors.picture?.message}
                      changeText="Change photo"
                      chooseText="Select photo"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Last name
                        </Typography>
                        <Controller
                          name="lastName"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Last Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          First name
                        </Typography>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="First Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Middle name
                        </Typography>
                        <Controller
                          name="middleName"
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Middle Name"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Email address
                        </Typography>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              type="email"
                              placeholder="Email Address"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Contact Numbers
                        </Typography>
                        {fields.map((item, key) => (
                          <Stack
                            direction="row"
                            spacing={1}
                            key={key}
                            paddingTop={key === 0 ? 1 : 2}
                          >
                            <IconButtonMdi
                              mdiIcon={
                                key + 1 === contactNumberWatch.length
                                  ? mdiPlusCircleOutline
                                  : mdiMinusCircleOutline
                              }
                              iconColor={
                                key + 1 === contactNumberWatch.length
                                  ? theme.palette.info.main
                                  : theme.palette.error.main
                              }
                              onClick={() =>
                                handleAddRemoveContact(
                                  key + 1 === contactNumberWatch.length
                                    ? null
                                    : key
                                )
                              }
                            />
                            <Box width="100%">
                              <PhoneNumberField
                                name={`contactNumber.${key}.value`}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                mobileValue={item.value}
                                errorMsg={
                                  errors?.contactNumber
                                    ? errors.contactNumber[key]?.value?.message
                                    : ""
                                }
                              />
                            </Box>
                          </Stack>
                        ))}
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Date of Birth
                        </Typography>
                        <Controller
                          name="birthDate"
                          control={control}
                          render={({ field, fieldState }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    error: fieldState.error?.message
                                      ? true
                                      : false,
                                    helperText: fieldState.error?.message,
                                    fullWidth: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        width: "100%",
                        height: 40,
                      }}
                    />
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Home address
                        </Typography>
                        <Controller
                          name="homeAddress.address"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Home Address"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Country
                        </Typography>
                        <Controller
                          name="homeAddress.country"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Country"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">Region</Typography>
                        <Controller
                          name="homeAddress.region"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Region"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Municipality
                        </Typography>
                        <Controller
                          name="homeAddress.municipality"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Municipality"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">City</Typography>
                        <Controller
                          name="homeAddress.city"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="City"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Postal code
                        </Typography>
                        <Controller
                          name="homeAddress.postalCode"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Postal code"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="sameHomeAddress"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={{
                            label: {
                              fontSize: ".9rem",
                            },
                          }}
                          label="Same as home address"
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                        />
                      )}
                    />
                    <Stack spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">
                          Mailing address
                        </Typography>
                        <Controller
                          name="mailingAddress.address"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Mailing Address"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Country
                        </Typography>
                        <Controller
                          name="mailingAddress.country"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Country"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">Region</Typography>
                        <Controller
                          name="mailingAddress.region"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Region"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Municipality
                        </Typography>
                        <Controller
                          name="mailingAddress.municipality"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Municipality"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">City</Typography>
                        <Controller
                          name="mailingAddress.city"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="City"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          Postal code
                        </Typography>
                        <Controller
                          name="mailingAddress.postalCode"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: thisFieldRequired,
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={sameHomeAddressWatch}
                              placeholder="Postal code"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider
                  sx={(theme) => ({
                    marginY: theme.spacing(3),
                  })}
                />
              </FormProvider>
            )}
            {step === 1 && (
              <>
                <Box marginBottom={4}>
                  {buyerId ? (
                    <Alert severity="info" icon={false}>
                      <Typography variant="body1" marginBottom={1}>
                        <Typography
                          component="span"
                          marginRight={1}
                          variant="textSm"
                        >
                          Buyer:
                        </Typography>
                        <Typography
                          component="span"
                          variant="textSm"
                          sx={(theme) => ({
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          })}
                        >
                          {`${buyerInfoData?.data.firstName} ${buyerInfoData?.data.lastName}`}
                        </Typography>
                      </Typography>
                      <Typography variant="body1">
                        <Typography
                          component="span"
                          marginRight={1}
                          variant="textSm"
                        >
                          Ref #:
                        </Typography>
                        <Typography
                          component="span"
                          variant="textSm"
                          sx={(theme) => ({
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          })}
                        >
                          {buyerInfoData?.data.referenceNumber}
                        </Typography>
                      </Typography>
                    </Alert>
                  ) : (
                    <Alert severity="warning">
                      Please add a buyer information or select a buyer to fill
                      up this form.
                    </Alert>
                  )}
                </Box>
                <FormProvider {...otherForm}>
                  <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12}>
                      <div>
                        <Typography variant="textfieldLabel" marginRight={2}>
                          Civil Status
                        </Typography>
                        <Controller
                          name="married"
                          control={otherControl}
                          render={({ field }) => (
                            <FormControlLabel
                              sx={{
                                label: {
                                  fontSize: ".9rem",
                                },
                              }}
                              label="Married"
                              control={
                                <Checkbox {...field} checked={field.value} />
                              }
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography
                        variant="textSm"
                        marginBottom={2}
                        marginLeft={0.5}
                        fontWeight={600}
                      >
                        Spouse Information
                      </Typography>
                      <Stack spacing={2}>
                        <div>
                          <Typography variant="textfieldLabel">
                            First Name
                          </Typography>
                          <Controller
                            name="firstName"
                            control={otherControl}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                disabled={!marriedWatch}
                                placeholder="First Name"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <Typography variant="textfieldLabel">
                            Last Name
                          </Typography>
                          <Controller
                            name="lastName"
                            control={otherControl}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                disabled={!marriedWatch}
                                placeholder="Last Name"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <Typography variant="textfieldLabel">
                            Middle Name
                          </Typography>
                          <Controller
                            name="middleName"
                            control={otherControl}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                disabled={!marriedWatch}
                                placeholder="Middle Name"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <Typography variant="textfieldLabel">
                            Email Address
                          </Typography>
                          <Controller
                            name="email"
                            control={otherControl}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                disabled={!marriedWatch}
                                type="email"
                                placeholder="Email Address"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                        <SpouseContacts
                          clearErrors={clearOtherErrors}
                          control={otherControl}
                          errors={otherErrors}
                          setValue={setOtherValue}
                          disabled={!marriedWatch}
                        />
                      </Stack>

                      <div>
                        <Typography
                          variant="textSm"
                          marginBottom={2}
                          marginLeft={0.5}
                          fontWeight={600}
                          mt={3}
                        >
                          Authorized Person
                        </Typography>
                        <AuthorizedInfo
                          clearErrors={clearOtherErrors}
                          control={otherControl}
                          errors={otherErrors}
                          setValue={setOtherValue}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography
                        variant="textSm"
                        marginBottom={2}
                        marginLeft={0.5}
                        fontWeight={600}
                      >
                        Deceased Information
                      </Typography>
                      <DeceasedInfo
                        clearErrors={clearOtherErrors}
                        control={otherControl}
                        errors={otherErrors}
                        setValue={setOtherValue}
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    sx={(theme) => ({
                      marginY: theme.spacing(3),
                    })}
                  />
                </FormProvider>
              </>
            )}

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              width="100%"
              justifyContent={{
                xs: "flex-start",
                md: "space-between",
              }}
            >
              {step !== 0 && (
                <Button
                  disabled={step === 0}
                  variant="button-secondary"
                  sx={{
                    width: 150,
                  }}
                  onClick={() => setStep(0)}
                >
                  Back
                </Button>
              )}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                width="100%"
              >
                <Button
                  variant="button-primary"
                  sx={{
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={
                    step === 0
                      ? handleSubmit(onSubmit, onError)
                      : handleOtherSubmit(onOtherSubmit, onOtherError)
                  }
                >
                  {step === 0
                    ? buyerId
                      ? "Update"
                      : "Save"
                    : otherInfoData?.data
                    ? "Update"
                    : "Save"}
                </Button>
                {step + 1 < steps.length && (
                  <Button
                    disabled={step !== 0}
                    variant="button-secondary"
                    sx={{
                      width: 150,
                    }}
                    onClick={() => setStep(1)}
                  >
                    Next
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddBuyerModal;
