import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { mdiDelete, mdiAccountArrowRight, mdiPencil } from "@mdi/js";
import Checkbox from "@mui/material/Checkbox";

import IconButtonMdi from "../../common/IconButtonMdi";
import TableSearchField from "../../common/textfields/TableSearchField";
import {
  buyersRoute,
  clientManagementRoute,
  leadsRoute,
} from "../../../routes/routeKeys";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import CustomPagination from "../../common/CustomPagination";
import { LeadInformationModel, LeadModel } from "../../../model/leadModel";
import {
  useGetLeadListQuery,
  useDeleteLeadMutation,
  useLazyGetLeadInformationQuery,
} from "../../../service/slice/client-management/lead/leadSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import ConfirmModal from "../../common/ConfirmModal";
import { LeadIdType } from "./Leads";
import { BuyerInformationModel } from "../../../model/buyerModel";

type Props = {
  leadIds: LeadIdType[];
  isOpenCreate: boolean;
  handleOpenCreate: (
    data: null | { id: number; name: string },
    title: string
  ) => void;
  handleCheckSelect: (lead: LeadIdType, isChecked: boolean) => void;
};

const LeadList = ({
  leadIds,
  isOpenCreate,
  handleOpenCreate,
  handleCheckSelect,
}: Props) => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [deleteItem, handelToggleDeleteModal] = useDeleteTableItem<{
    id: number;
    refNumber: string;
  }>();
  const [toBuyerItem, handleShowBuyerConfirmBox] =
    useOpenCreateForm<LeadModel>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      error: deleteError,
      reset: deleteReset,
      isSuccess: deleteIsSuccess,
    },
  ] = useDeleteLeadMutation();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetLeadListQuery({
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const [
    getLeadInfoTrigger,
    { isLoading: leadInfoIsLoading, data: leadInfoData, error: leadInfoError },
  ] = useLazyGetLeadInformationQuery();

  const isLoading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalCount = listData?.data.count ?? 0;

  const handleConfirmDelete = () => {
    deleteMutate(deleteItem.data?.id as number);
  };

  const handleConfirmNavigate = () => {
    const buyerInfo: BuyerInformationModel = {
      gender: leadInfoData?.data.gender ?? null,
      birthDate: leadInfoData?.data?.birthDate ?? "",
      buyerContacts: leadInfoData?.data?.buyerContacts ?? [],
      email: leadInfoData?.data?.email ?? "",
      firstName: leadInfoData?.data?.firstName ?? "",
      homeAddress: leadInfoData?.data?.homeAddress ?? "",
      homeCity: leadInfoData?.data?.homeCity ?? "",
      homeCountry: leadInfoData?.data?.homeCountry ?? "",
      homeMunicipality: leadInfoData?.data?.homeMunicipality ?? "",
      homePostalCode: leadInfoData?.data?.homePostalCode ?? "",
      homeRegion: leadInfoData?.data?.homeRegion ?? "",
      lastName: leadInfoData?.data?.lastName ?? "",
      mailingAddress: leadInfoData?.data?.mailingAddress ?? "",
      mailingCity: leadInfoData?.data?.mailingCity ?? "",
      mailingCountry: leadInfoData?.data?.mailingCountry ?? "",
      mailingMunicipality: leadInfoData?.data?.mailingMunicipality ?? "",
      mailingPostalCode: leadInfoData?.data?.mailingPostalCode ?? "",
      mailingRegion: leadInfoData?.data?.mailingRegion ?? "",
      middleName: leadInfoData?.data?.middleName ?? "",
      picture: leadInfoData?.data?.picture ?? "",
      referenceNumber: leadInfoData?.data?.referenceNumber ?? "",
      sameAddress: leadInfoData?.data?.sameAddress ?? false,
    };
    navigate(`${clientManagementRoute}${buyersRoute}/add/buyer-information`, {
      state: {
        buyerInfo,
        unitType: toBuyerItem.data?.leadType,
      },
    });
  };

  const handleGetLeadInfoData = async (item: LeadModel) => {
    await getLeadInfoTrigger(item.id);

    handleShowBuyerConfirmBox(true, item, "");
  };

  if (leadInfoError) {
    errorDisplayOrNavigate({
      error: leadInfoError,
      toastId: "lead-info",
    });
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-lead",
    });

    deleteReset();
  }

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.refNumber} deleted!`,
      toastId: "delete-lead",
    });

    deleteReset();
    handleCheckSelect(
      {
        id: deleteItem.data?.id as number,
        refNum: deleteItem.data?.refNumber as string,
      },
      false
    );
    handelToggleDeleteModal(false, null);
  }

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "lead-list",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.refNumber}?`}
        closeFn={() => handelToggleDeleteModal(false, null)}
        confirmFn={handleConfirmDelete}
      />
      <ConfirmModal
        isLoading={false}
        open={toBuyerItem.open}
        text={`Are you sure to set ${toBuyerItem.data?.firstName} ${toBuyerItem.data?.lastName} (${toBuyerItem.data?.referenceNumber}) as buyer?`}
        closeFn={() => handleShowBuyerConfirmBox(false, null, "")}
        confirmFn={handleConfirmNavigate}
      />
      <Grid container marginBottom={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableSearchField
              searchValue={searchParam ?? ""}
              pathname={`${clientManagementRoute}${leadsRoute}`}
              querySearch={(val) =>
                `?page=${pageParam}&entries=${entriesParam}&search=${val}`
              }
            />
            <Button
              variant="button-secondary"
              sx={{
                width: "180px",
              }}
              disabled={isOpenCreate}
              onClick={() => handleOpenCreate(null, "Add Lead")}
            >
              Add New Lead
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entriesParam}
            pathname={`${clientManagementRoute}${leadsRoute}`}
            querySearch={(entry) =>
              `?page=${pageParam}&entries=${entry}&search=${searchParam}`
            }
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Select
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Reference Number
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Full Name
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Unit Type
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : list && list.length > 0 ? (
              list.map((item, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>
                    <Checkbox
                      checked={
                        leadIds.find((lead) => lead.id === item.id)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleCheckSelect(
                          {
                            id: item.id,
                            refNum: item.referenceNumber,
                          },
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>{item.referenceNumber}</TableCell>
                  <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                  <TableCell>{item.leadType ? item.leadType : ""}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiPencil}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="Edit"
                        onClick={() =>
                          handleOpenCreate(
                            {
                              id: item.id,
                              name: `${item.firstName} ${item.lastName}`,
                            },
                            "Add Lead"
                          )
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiDelete}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delete"
                        onClick={() =>
                          handelToggleDeleteModal(true, {
                            id: item.id,
                            refNumber: item.referenceNumber,
                          })
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiAccountArrowRight}
                        iconColor={theme.palette.success.main}
                        tooltipTitle="Set as buyer"
                        isLoading={leadInfoIsLoading}
                        onClick={() => handleGetLeadInfoData(item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname={`${clientManagementRoute}${leadsRoute}`}
        querySearch={(page) =>
          `?page=${page}&entries=${entriesParam}&search=${searchParam}`
        }
        totalCount={totalCount}
        currentPage={pageParam}
        pageSize={entriesParam}
      />
    </>
  );
};

export default LeadList;
