import { DashboardDataModel } from "../../model/dashboardModel";
import { FetchDtoType } from "../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../apiSlice";

export const endpoints = {
  getData: "/dashboard/get",
};

export const dashboardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query<FetchDtoType<DashboardDataModel>, void>({
      query: () => ({
        url: endpoints.getData,
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["dashboard-data"],
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardSlice;
