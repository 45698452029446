import React from "react";
import PhoneInput from "react-phone-input-2";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Path,
  UseFormClearErrors,
  UseFormSetValue,
  FieldValues,
} from "react-hook-form";
import { UserForm } from "../../user-management/users/UserInformation";
import { thisFieldRequired } from "../../../utils/constants";
import "react-phone-input-2/lib/style.css";

type FormVal = UserForm;

interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  setValue: any;
  clearErrors: UseFormClearErrors<TFieldValues>;
  errorMsg?: string;
  mobileValue?: string;
  isDisabled?: boolean;
}

const PhoneNumberField = <TFieldValues extends FieldValues>({
  name,
  setValue,
  clearErrors,
  errorMsg,
  mobileValue,
  isDisabled = false,
}: Props<TFieldValues>) => {
  const theme = useTheme();
  const handleChangePhone = (
    value: string,
    data: {},
    e: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setValue(name, formattedValue);
    clearErrors([name]);
  };

  return (
    <Box
      sx={(theme) => ({
        "& .form-control": {
          width: "100%",
          height: "42.7px",
          fontSize: ".9rem",
          borderRadius: theme.spacing(1),
          color: isDisabled
            ? theme.palette.grey[400]
            : theme.palette.common.black,
          backgroundColor: isDisabled ? theme.palette.grey[200] : "#fff",
          border: `1px solid ${
            errorMsg ? theme.palette.error.main : theme.palette.grey[400]
          }`,
          "&:focus-within": {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
        "& .flag-dropdown": {
          backgroundColor: isDisabled ? theme.palette.grey[200] : "transparent",
          borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
          border: `1px solid ${
            errorMsg ? theme.palette.error.main : theme.palette.grey[400]
          }`,
          borderRight: 0,
          "& .selected-flag": {
            borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
          },
        },
      })}
    >
      <PhoneInput
        enableSearch
        country="ph"
        value={mobileValue}
        onChange={handleChangePhone}
        disableDropdown={isDisabled}
        disabled={isDisabled}
      />
      {/* {errorMsg && <FormHelperText error>{thisFieldRequired}</FormHelperText>} */}
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
    </Box>
  );
};

export default PhoneNumberField;
