import { domainLink } from "../constants";

export const replaceAllSpaceWithHyphen = (value: string) =>
  value.replaceAll(" ", "-");
export const replaceAllHyphenWithSpace = (value: string) =>
  value.replaceAll("-", " ");
export const replaceAllSlashWithHyphen = (value: string) =>
  value.replaceAll("/", "-");
export const linkWithDomain = (path: string): string =>
  path.includes(domainLink) ? path : `${domainLink}${path}`;
export const removeSpaces = (value: string): string =>
  value.replaceAll(" ", "");

export const removeNonNumeric = (num: string) =>
  num.toString().replace(/[^0-9-!@#$%*?]/g, "");

export const commaFormatted = (amount: string) => {
  var delimiter = ","; // replace comma if desired
  var a = amount.split(".", 2);
  var d = a[1] ? a[1] : "00";
  var i = parseInt(a[0]);
  if (isNaN(i)) {
    return "";
  }
  var minus = "";
  if (i < 0) {
    minus = "-";
  }
  i = Math.abs(i);
  var n = String(i);
  var a: string[] = [];
  while (n.length > 3) {
    var nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  if (d.length < 1) {
    amount = n;
  } else {
    amount = n + "." + d;
  }
  amount = minus + amount;
  return amount;
};
