import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/query";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";

import { StepModel } from "../../../../common/StepperTabMenu";
import { useGetBuyerInformationQuery } from "../../../../../service/slice/client-management/buyer/buyerInformationSlice";
import {
  useGetOtherInfoQuery,
  useUpdateOtherInfoMutation,
} from "../../../../../service/slice/client-management/buyer/otherInformationSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import { LoaderWithText } from "../../../../common/Loaders";
import {
  enterValidMobileNum,
  thisFieldRequired,
} from "../../../../../utils/constants";
import SpouseContacts from "./SpouseContacts";
import DeceasedInfo from "./DeceasedInfo";
import { StepperActionCta } from "../../../../common/StepperTabMenu";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../../routes/routeKeys";
import { UnitModel } from "../unit-type/UnitType";
import { StepperGenericModel } from "../../Buyers";
import AuthorizedInfo from "./AuthorizedInfo";

export interface OtherForm {
  firstName: string;
  lastName: string;
  middleName: string;
  married: boolean;
  contactNumber: {
    id: null | number;
    value: string;
  }[];
  email: string;
  deceasedInfo: {
    id: number | null;
    firstName: string;
    lastName: string;
    middleName: string;
    nickname: string;
    religion: string;
    birthDate: Date | null;
    deathDate: Date | null;
    placeOfBirth: string;
  }[];
  authorized: {
    id: number | null;
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    contact: {
      id: null | number;
      value: string;
    }[];
  }[];
}

type Props = {
  steps: StepModel[];
};

const OtherInfo = ({ steps }: Props) => {
  const navigate = useNavigate();
  const { state: locState } = useLocation();
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;

  const form = useForm<OtherForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      contactNumber: [
        {
          id: null,
          value: "",
        },
      ],
      email: "",
      married: false,
      deceasedInfo: [
        {
          id: null,
          birthDate: null,
          deathDate: null,
          firstName: "",
          lastName: "",
          middleName: "",
          nickname: "",
          religion: "",
          placeOfBirth: "",
        },
      ],
      authorized: [
        {
          id: null,
          firstName: "",
          lastName: "",
          middleName: "",
          email: "",
          contact: [
            {
              id: null,
              value: "",
            },
          ],
        },
      ],
    },
  });

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const {
    data: otherInfoData,
    isLoading: otherInfoIsLoading,
    error: otherInfoError,
  } = useGetOtherInfoQuery(buyerId ?? skipToken);

  const [
    updateMutate,
    {
      error: updateError,
      isLoading: updateIsLoading,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateOtherInfoMutation();

  const {
    control,
    setValue,
    reset: fieldReset,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
    clearErrors,
    watch,
  } = form;

  const mobileNumWatch = useWatch({
    control,
    name: "contactNumber",
  });

  const marriedWatch = useWatch({
    control,
    name: "married",
  });

  const authorizedWatch = useWatch({ control, name: "authorized" });

  const loading = buyerInfoIsLoading || otherInfoIsLoading;
  const saveLoading = updateIsLoading;

  const validateOtherFields = (): number[] => {
    const errorsArr: number[] = [];

    // if (_.isEmpty(mobileNumWatch[0].value)) {
    //   setError(`contactNumber.${0}.value`, {
    //     type: "required",
    //     message: thisFieldRequired,
    //   });

    //   errorsArr.push(1);
    // }

    authorizedWatch.forEach((item, parentKey) => {
      item.contact.forEach((contactItem, contactKey) => {
        if (!_.isEmpty(contactItem.value)) {
          const formatVal = contactItem.value
            .replaceAll(" ", "")
            .replace("+63", "");
          if (formatVal.length > 10 || formatVal.length < 10) {
            setError(`authorized.${parentKey}.contact.${contactKey}.value`, {
              type: "required",
              message: enterValidMobileNum,
            });
            errorsArr.push(1);
          }
        }
      });
    });

    mobileNumWatch.forEach((item, key) => {
      if (!_.isEmpty(item.value)) {
        const formatVal = item.value.replaceAll(" ", "").replace("+63", "");
        if (formatVal.length > 10 || formatVal.length < 10) {
          setError(`contactNumber.${key}.value`, {
            type: "required",
            message: enterValidMobileNum,
          });

          errorsArr.push(1);
        }
      }
    });

    return errorsArr;
  };

  const onError = () => {
    validateOtherFields();
  };

  const onSubmit: SubmitHandler<OtherForm> = (dataFields) => {
    const otherErrors = validateOtherFields();

    if (otherErrors.length > 0) {
      return;
    }

    updateMutate({
      queryParam: buyerId as number,
      bodyParam: {
        married: dataFields.married ? 1 : 0,
        spouseEmail: dataFields.email,
        spouseFirstName: dataFields.firstName,
        spouseLastName: dataFields.lastName,
        spouseMiddleName: dataFields.middleName,
        contact: dataFields.contactNumber
          .map((item) => ({
            id: item.id,
            number: item.value,
          }))
          .filter((item) => !_.isEmpty(item.number)),
        deceased: dataFields.deceasedInfo
          .map((item) => ({
            birthDate: moment(item.birthDate).format("YYYY-MM-DD"),
            deathDate: item.deathDate
              ? moment(item.deathDate).format("YYYY-MM-DD")
              : "",
            firstName: item.firstName,
            id: item.id,
            lastName: item.lastName,
            middleName: item.middleName,
            nickName: item.nickname,
            religion: item.religion,
            birthPlace: item.placeOfBirth,
          }))
          .filter(
            (item) =>
              !_.isEmpty(item.firstName) &&
              !_.isEmpty(item.lastName) &&
              item.birthDate !== null
          ),
        authorized: dataFields.authorized.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          middleName: item.middleName,
          id: item.id,
          email: item.email,
          contact: item.contact
            .map((cont) => ({
              id: cont.id,
              number: cont.value,
            }))
            .filter((cont) => !_.isEmpty(cont.number)),
        })),
      },
    });
  };

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (otherInfoError) {
    errorDisplayOrNavigate({
      error: otherInfoError,
      toastId: "other-info",
    });
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: otherInfoData?.data
        ? "Other information updated!"
        : "Other information saved!",
      toastId: "update-infor",
    });

    if (otherInfoData?.data) {
      updateReset();
    } else {
      navigate(
        `${clientManagementRoute}${buyersRoute}/edit/${buyerId}/identification?page=1&entries=10&search=`,
        {
          state: locState,
        }
      );
    }
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-info",
    });

    updateReset();
  }

  useEffect(() => {
    if (otherInfoData?.data) {
      const {
        buyerContacts,
        buyerDeceaseds,
        married,
        spouseEmail,
        spouseFirstName,
        spouseLastName,
        spouseMiddleName,
        buyerAuthorizeds,
      } = otherInfoData.data;
      setValue("married", married);
      setValue("email", spouseEmail);
      setValue("firstName", spouseFirstName);
      setValue("lastName", spouseLastName);
      setValue("middleName", spouseMiddleName);
      setValue(
        "contactNumber",
        buyerContacts.length > 0
          ? buyerContacts.map((item: { id: number; number: string }) => ({
              id: item.id,
              value: item.number,
            }))
          : [
              {
                id: null,
                value: "",
              },
            ]
      );

      setValue(
        "deceasedInfo",
        buyerDeceaseds.length > 0
          ? buyerDeceaseds.map((item) => ({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              middleName: item.middleName,
              birthDate: new Date(item.birthDate),
              deathDate: new Date(item.deathDate),
              nickname: item.nickName ?? "",
              placeOfBirth: item.birthPlace ?? "",
              religion: item.religion ?? "",
            }))
          : [
              {
                id: null,
                birthDate: null,
                deathDate: null,
                firstName: "",
                lastName: "",
                middleName: "",
                nickname: "",
                religion: "",
                placeOfBirth: "",
              },
            ]
      );

      setValue(
        "authorized",
        buyerAuthorizeds.length > 0
          ? buyerAuthorizeds.map((item) => ({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              middleName: item.middleName,
              email: item.email,
              contact:
                item.buyerAuthorizedContacts.length > 0
                  ? item.buyerAuthorizedContacts.map((itemCon) => ({
                      id: itemCon.id,
                      value: itemCon.number,
                    }))
                  : [
                      {
                        id: null,
                        value: "",
                      },
                    ],
            }))
          : [
              {
                contact: [{ id: null, value: "" }],
                email: "",
                firstName: "",
                id: null,
                lastName: "",
                middleName: "",
              },
            ]
      );
    }
  }, [otherInfoData]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting other information.." />
    </Box>
  ) : (
    <>
      <Box marginBottom={4}>
        {buyerId ? (
          <Alert severity="info" icon={false}>
            <Typography variant="body1" marginBottom={1}>
              <Typography component="span" marginRight={1} variant="textSm">
                Buyer:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {`${buyerInfoData?.data.firstName} ${buyerInfoData?.data.lastName}`}
              </Typography>
            </Typography>
            <Typography variant="body1">
              <Typography component="span" marginRight={1} variant="textSm">
                Ref #:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {buyerInfoData?.data.referenceNumber}
              </Typography>
            </Typography>
          </Alert>
        ) : (
          <Alert severity="warning">
            Please add a buyer information or select a buyer to fill up this
            form.
          </Alert>
        )}
      </Box>
      <FormProvider {...form}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          <Grid item xs={12}>
            <div>
              <Typography variant="textfieldLabel" marginRight={2}>
                Civil Status
              </Typography>
              <Controller
                name="married"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      label: {
                        fontSize: ".9rem",
                      },
                    }}
                    label="Married"
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box marginBottom={3}>
              <Typography
                variant="textSm"
                marginBottom={2}
                marginLeft={0.5}
                fontWeight={600}
              >
                Spouse Information
              </Typography>
              <Stack spacing={2}>
                <div>
                  <Typography variant="textfieldLabel">First Name</Typography>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={!marriedWatch}
                        placeholder="First Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">Last Name</Typography>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={!marriedWatch}
                        placeholder="Last Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">Middle Name</Typography>
                  <Controller
                    name="middleName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={!marriedWatch}
                        placeholder="Middle Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">
                    Email Address
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={!marriedWatch}
                        type="email"
                        placeholder="Email Address"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <SpouseContacts
                  clearErrors={clearErrors}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  disabled={!marriedWatch}
                />
              </Stack>
            </Box>

            <div>
              <Typography
                variant="textSm"
                marginBottom={2}
                marginLeft={0.5}
                fontWeight={600}
              >
                Authorized Representative
              </Typography>
              <AuthorizedInfo
                clearErrors={clearErrors}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              variant="textSm"
              marginBottom={2}
              marginLeft={0.5}
              fontWeight={600}
            >
              Deceased Information
            </Typography>
            <DeceasedInfo
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid>
        </Grid>
        <Divider
          sx={(theme) => ({
            marginY: theme.spacing(3),
          })}
        />

        <StepperActionCta<StepperGenericModel>
          // disabled={buyerId ? false : true}
          disabled={false}
          path={
            buyerId
              ? `${clientManagementRoute}${buyersRoute}/edit/${buyerId}`
              : `${clientManagementRoute}${buyersRoute}/add`
          }
          steps={steps}
          state={locState}
        >
          <Button
            variant="button-primary"
            disabled={buyerId ? false : true}
            sx={{
              width: 150,
            }}
            startIcon={
              saveLoading && (
                <CircularProgress
                  size={20}
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                />
              )
            }
            onClick={handleSubmit(onSubmit, onError)}
          >
            {otherInfoData?.data ? "Update" : "Save"}
          </Button>
        </StepperActionCta>
      </FormProvider>
    </>
  );
};

export default OtherInfo;
