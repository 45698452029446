import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useForm, FormProvider, SubmitHandler, Path } from "react-hook-form";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import LotsVaultsForm from "./LotsVaultsForm";
import {
  useGetLvcQuery,
  useAddLvcMutation,
  useSendApprovalCodeMutation,
} from "../../../../service/slice/system-configuration/generalSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../utils/notificationToast";
import { LoaderWithText } from "../../../common/Loaders";
import OtpModal from "../../../common/OtpModal";
import { LvcInterestRateModel } from "../../../../model/generalModel";

interface InterestRateBodyType {
  id: number | null;
  years: number;
  interest: number | string;
}

interface RateFormType {
  id: number | null;
  value: number | string;
}
interface InterestRateFormType {
  one: RateFormType;
  two: RateFormType;
  three: RateFormType;
  four: RateFormType;
  five: RateFormType;
  six: RateFormType;
  seven: RateFormType;
  eight: RateFormType;
}
interface LotsConfigFormType {
  lot: {
    reservation: number | string;
    pcf: number | string;
    transfer: number | string;
    daysVoid: number | string;
    interestRate: InterestRateFormType;
  };
  vault: {
    reservation: number | string;
    pcf: number | string;
    transfer: number | string;
    daysVoid: number | string;
    interestRate: InterestRateFormType;
  };
  crypt: {
    reservation: number | string;
    pcf: number | string;
    transfer: number | string;
    daysVoid: number | string;
    interestRate: InterestRateFormType;
  };
}

const errorMessageValid = "Negative values are not allowed.";

const LotsVaultsConfig = () => {
  const [openOtp, setOpenOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const form = useForm<LotsConfigFormType>({
    defaultValues: {
      lot: {
        reservation: "",
        pcf: "",
        transfer: "",
        daysVoid: "",
        interestRate: {
          one: {
            id: null,
            value: "",
          },
          two: {
            id: null,
            value: "",
          },
          three: {
            id: null,
            value: "",
          },
          four: {
            id: null,
            value: "",
          },
          five: {
            id: null,
            value: "",
          },
          six: {
            id: null,
            value: "",
          },
          seven: {
            id: null,
            value: "",
          },
          eight: {
            id: null,
            value: "",
          },
        },
      },
      vault: {
        reservation: "",
        pcf: "",
        transfer: "",
        daysVoid: "",
        interestRate: {
          one: {
            id: null,
            value: "",
          },
          two: {
            id: null,
            value: "",
          },
          three: {
            id: null,
            value: "",
          },
          four: {
            id: null,
            value: "",
          },
          five: {
            id: null,
            value: "",
          },
          six: {
            id: null,
            value: "",
          },
          seven: {
            id: null,
            value: "",
          },
          eight: {
            id: null,
            value: "",
          },
        },
      },
      crypt: {
        reservation: "",
        pcf: "",
        transfer: "",
        daysVoid: "",
        interestRate: {
          one: {
            id: null,
            value: "",
          },
          two: {
            id: null,
            value: "",
          },
          three: {
            id: null,
            value: "",
          },
          four: {
            id: null,
            value: "",
          },
          five: {
            id: null,
            value: "",
          },
          six: {
            id: null,
            value: "",
          },
          seven: {
            id: null,
            value: "",
          },
          eight: {
            id: null,
            value: "",
          },
        },
      },
    },
  });

  const { control, clearErrors, setValue, handleSubmit, getValues, setError } =
    form;

  const { data, isLoading, error, refetch } = useGetLvcQuery();
  const [
    addMutation,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddLvcMutation();
  const [
    sendApprovalMutation,
    {
      isLoading: sendApprovalLoading,
      error: sendApprovalError,
      reset: sendApprovalReset,
      isSuccess: sendApprovalSuccess,
    },
  ] = useSendApprovalCodeMutation();

  const toInterestRateForm = (
    inter: LvcInterestRateModel[]
  ): InterestRateFormType => {
    let val: InterestRateFormType = {
      one: {
        id: null,
        value: "",
      },
      two: {
        id: null,
        value: "",
      },
      three: {
        id: null,
        value: "",
      },
      four: {
        id: null,
        value: "",
      },
      five: {
        id: null,
        value: "",
      },
      six: {
        id: null,
        value: "",
      },
      seven: {
        id: null,
        value: "",
      },
      eight: {
        id: null,
        value: "",
      },
    };

    if (inter.length > 0) {
      const yearOne = inter.find((item) => item.years === 1);
      const yearTwo = inter.find((item) => item.years === 2);
      const yearThree = inter.find((item) => item.years === 3);
      const yearFour = inter.find((item) => item.years === 4);
      const yearFive = inter.find((item) => item.years === 5);
      const yearSix = inter.find((item) => item.years === 6);
      const yearSeven = inter.find((item) => item.years === 7);
      const yearEight = inter.find((item) => item.years === 8);
      val = {
        one: {
          id: yearOne?.id ?? null,
          value: yearOne?.interest ? Number(yearOne?.interest) : "",
        },
        two: {
          id: yearTwo?.id ?? null,
          value: yearTwo?.interest ? Number(yearTwo?.interest) : "",
        },
        three: {
          id: yearThree?.id ?? null,
          value: yearThree?.interest ? Number(yearThree?.interest) : "",
        },
        four: {
          id: yearFour?.id ?? null,
          value: yearFour?.interest ? Number(yearFour?.interest) : "",
        },
        five: {
          id: yearFive?.id ?? null,
          value: yearFive?.interest ? Number(yearFive?.interest) : "",
        },
        six: {
          id: yearSix?.id ?? null,
          value: yearSix?.interest ? Number(yearSix?.interest) : "",
        },
        seven: {
          id: yearSeven?.id ?? null,
          value: yearSeven?.interest ? Number(yearSeven?.interest) : "",
        },
        eight: {
          id: yearEight?.id ?? null,
          value: yearEight?.interest ? Number(yearEight?.interest) : "",
        },
      };
    }

    return val;
  };

  const interestRetVal = (
    interstObj: InterestRateFormType
  ): InterestRateBodyType[] => {
    let interestArr: InterestRateBodyType[] = [];

    if (!_.isEmpty(interstObj.one)) {
      interestArr.push({
        id: interstObj.one.id,
        years: 1,
        interest:
          Number(interstObj.one.value) === 0
            ? interstObj.one.value.toString()
            : interstObj.one.value,
      });
    }

    if (!_.isEmpty(interstObj.two)) {
      interestArr.push({
        id: interstObj.two.id,
        years: 2,
        interest:
          Number(interstObj.two.value) === 0
            ? interstObj.two.value.toString()
            : interstObj.two.value,
      });
    }

    if (!_.isEmpty(interstObj.three)) {
      interestArr.push({
        id: interstObj.three.id,
        years: 3,
        interest:
          Number(interstObj.three.value) === 0
            ? interstObj.three.value.toString()
            : interstObj.three.value,
      });
    }

    if (!_.isEmpty(interstObj.four)) {
      interestArr.push({
        id: interstObj.four.id,
        years: 4,
        interest:
          Number(interstObj.four.value) === 0
            ? interstObj.four.value.toString()
            : interstObj.four.value,
      });
    }

    if (!_.isEmpty(interstObj.five)) {
      interestArr.push({
        id: interstObj.five.id,
        years: 5,
        interest:
          Number(interstObj.five.value) === 0
            ? interstObj.five.value.toString()
            : interstObj.five.value,
      });
    }

    if (!_.isEmpty(interstObj.six)) {
      interestArr.push({
        id: interstObj.six.id,
        years: 6,
        interest:
          Number(interstObj.six.value) === 0
            ? interstObj.six.value.toString()
            : interstObj.six.value,
      });
    }

    if (!_.isEmpty(interstObj.seven)) {
      interestArr.push({
        id: interstObj.seven.id,
        years: 7,
        interest:
          Number(interstObj.seven.value) === 0
            ? interstObj.seven.value.toString()
            : interstObj.seven.value,
      });
    }

    if (!_.isEmpty(interstObj.eight)) {
      interestArr.push({
        id: interstObj.eight.id,
        years: 8,
        interest:
          Number(interstObj.eight.value) === 0
            ? interstObj.eight.value.toString()
            : interstObj.eight.value,
      });
    }

    return interestArr;
  };

  const checkZeroAndNegative = (
    interstObj: InterestRateFormType,
    key: "lot" | "crypt" | "vault"
  ): number[] => {
    const errors: number[] = [];

    if (
      // interstObj.one.value === "0" ||
      // interstObj.one.value === "0.00" ||
      interstObj.one.value.toString().includes("-")
    ) {
      errors.push(1);
      setError(`${key}.interestRate.one.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.two.value === "0" ||
      // interstObj.two.value === "0.00" ||
      interstObj.two.value.toString().includes("-")
    ) {
      errors.push(2);
      setError(`${key}.interestRate.two.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.three.value === "0" ||
      // interstObj.three.value === "0.00" ||
      interstObj.three.value.toString().includes("-")
    ) {
      errors.push(3);
      setError(`${key}.interestRate.three.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.four.value === "0" ||
      // interstObj.four.value === "0.00" ||
      interstObj.four.value.toString().includes("-")
    ) {
      errors.push(4);
      setError(`${key}.interestRate.four.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.five.value === "0" ||
      // interstObj.five.value === "0.00" ||
      interstObj.five.value.toString().includes("-")
    ) {
      errors.push(5);
      setError(`${key}.interestRate.five.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.six.value === "0" ||
      // interstObj.six.value === "0.00" ||
      interstObj.six.value.toString().includes("-")
    ) {
      errors.push(6);
      setError(`${key}.interestRate.six.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.seven.value === "0" ||
      // interstObj.seven.value === "0.00" ||
      interstObj.seven.value.toString().includes("-")
    ) {
      errors.push(7);
      setError(`${key}.interestRate.seven.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    if (
      // interstObj.eight.value === "0" ||
      // interstObj.eight.value === "0.00" ||
      interstObj.eight.value.toString().includes("-")
    ) {
      errors.push(8);
      setError(`${key}.interestRate.eight.value`, {
        type: "validate",
        message: errorMessageValid,
      });
    }

    return errors;
  };

  const onSubmit = () => {
    const data = getValues();
    const lotInterestRate = interestRetVal(data.lot.interestRate);
    const cryptInterestRate = interestRetVal(data.crypt.interestRate);
    const vaultInterestRate = interestRetVal(data.vault.interestRate);

    addMutation({
      code: otp,
      CRYPT: {
        daysToVoid: Number(data.crypt.daysVoid),
        pcf: Number(data.crypt.pcf),
        reservationFee: Number(data.crypt.reservation),
        transferFee: Number(data.crypt.transfer),
        interestRate: cryptInterestRate,
      },
      LOT: {
        daysToVoid: Number(data.lot.daysVoid),
        pcf: Number(data.lot.pcf),
        reservationFee: Number(data.lot.reservation),
        transferFee: Number(data.lot.transfer),
        interestRate: lotInterestRate,
      },
      VAULT: {
        daysToVoid: Number(data.vault.daysVoid),
        pcf: Number(data.vault.pcf),
        reservationFee: Number(data.vault.reservation),
        transferFee: Number(data.vault.transfer),
        interestRate: vaultInterestRate,
      },
    });
  };

  const submitApproval = () => {
    clearErrors();
    const data = getValues();
    const lotInterestCheck = checkZeroAndNegative(data.lot.interestRate, "lot");
    const cryptInterestCheck = checkZeroAndNegative(
      data.crypt.interestRate,
      "crypt"
    );
    const vaultInterestCheck = checkZeroAndNegative(
      data.vault.interestRate,
      "vault"
    );

    if (
      lotInterestCheck.length > 0 ||
      cryptInterestCheck.length > 0 ||
      vaultInterestCheck.length > 0
    ) {
      return;
    }

    sendApprovalMutation("lvc");
  };

  if (sendApprovalError) {
    errorDisplayOrNavigate({
      error: sendApprovalError,
      toastId: "send-approval",
    });

    sendApprovalReset();
  }

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "lvc-get",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "lvc-add",
    });

    addReset();
  }

  useEffect(() => {
    if (addIsSuccess) {
      showToastSuccess({
        text: "Configuration successfully saved!",
        toastId: "add",
      });
      refetch();
      setOpenOtp(false);
      setOtp("");
      addReset();
    }
  }, [addIsSuccess]);

  useEffect(() => {
    if (sendApprovalSuccess) {
      showToastSuccess({
        text: "Please check your email for the approval code!",
        toastId: "approval",
      });
      setOpenOtp(true);
      sendApprovalReset();
    }
  }, [sendApprovalSuccess]);

  useEffect(() => {
    if (data) {
      const config = data.data;

      const lotConfig = config.find((item) => item.type === "LOT");
      const vaultConfig = config.find((item) => item.type === "VAULT");
      const cryptConfig = config.find((item) => item.type === "CRYPT");

      setValue("lot", {
        daysVoid: lotConfig?.daysToVoid ?? "",
        pcf: lotConfig?.pcf ?? "",
        reservation: lotConfig?.reservationFee ?? "",
        transfer: lotConfig?.transferFee ?? "",
        interestRate: toInterestRateForm(lotConfig?.lvcInterestRates ?? []),
      });

      setValue("vault", {
        daysVoid: vaultConfig?.daysToVoid ?? "",
        pcf: vaultConfig?.pcf ?? "",
        reservation: vaultConfig?.reservationFee ?? "",
        transfer: vaultConfig?.transferFee ?? "",
        interestRate: toInterestRateForm(vaultConfig?.lvcInterestRates ?? []),
      });
      setValue("crypt", {
        daysVoid: cryptConfig?.daysToVoid ?? "",
        pcf: cryptConfig?.pcf ?? "",
        reservation: cryptConfig?.reservationFee ?? "",
        transfer: cryptConfig?.transferFee ?? "",
        interestRate: toInterestRateForm(cryptConfig?.lvcInterestRates ?? []),
      });
    }
  }, [data]);

  return (
    <>
      <Box marginTop={4}>
        {isLoading ? (
          <LoaderWithText text="Getting Configurations" />
        ) : (
          <FormProvider {...form}>
            <OtpModal
              open={openOtp}
              text="Approval Code"
              closeFn={() => {
                setOpenOtp(false);
                setOtp("");
              }}
              otpCode={otp}
              handleOtpChange={(val) => setOtp(val)}
              loading={addIsLoading}
              submit={onSubmit}
              resend={() => sendApprovalMutation("lvc")}
              resendLoading={sendApprovalLoading}
            />
            <LotsVaultsForm
              control={control}
              nameReservation="lot.reservation"
              namePcf="lot.pcf"
              nameTransfer="lot.transfer"
              nameDays="lot.daysVoid"
              nameInterestOne="lot.interestRate.one.value"
              nameInterestTwo="lot.interestRate.two.value"
              nameInterestThree="lot.interestRate.three.value"
              nameInterestFour="lot.interestRate.four.value"
              nameInterestFive="lot.interestRate.five.value"
              nameInterestSix="lot.interestRate.six.value"
              nameInterestSeven="lot.interestRate.seven.value"
              nameInterestEight="lot.interestRate.eight.value"
              clearErrors={clearErrors}
              // errors={errors}
              title="Lot Configuration"
            />
            <Divider
              sx={{
                marginY: 4,
              }}
            />
            <LotsVaultsForm
              control={control}
              nameReservation="vault.reservation"
              namePcf="vault.pcf"
              nameTransfer="vault.transfer"
              nameDays="vault.daysVoid"
              nameInterestOne="vault.interestRate.one.value"
              nameInterestTwo="vault.interestRate.two.value"
              nameInterestThree="vault.interestRate.three.value"
              nameInterestFour="vault.interestRate.four.value"
              nameInterestFive="vault.interestRate.five.value"
              nameInterestSix="vault.interestRate.six.value"
              nameInterestSeven="vault.interestRate.seven.value"
              nameInterestEight="vault.interestRate.eight.value"
              clearErrors={clearErrors}
              // errors={errors}
              title="Vault Configuration"
            />
            <Divider
              sx={{
                marginY: 4,
              }}
            />
            <LotsVaultsForm
              control={control}
              nameReservation="crypt.reservation"
              namePcf="crypt.pcf"
              nameTransfer="crypt.transfer"
              nameDays="crypt.daysVoid"
              nameInterestOne="crypt.interestRate.one.value"
              nameInterestTwo="crypt.interestRate.two.value"
              nameInterestThree="crypt.interestRate.three.value"
              nameInterestFour="crypt.interestRate.four.value"
              nameInterestFive="crypt.interestRate.five.value"
              nameInterestSix="crypt.interestRate.six.value"
              nameInterestSeven="crypt.interestRate.seven.value"
              nameInterestEight="crypt.interestRate.eight.value"
              clearErrors={clearErrors}
              // errors={errors}
              title="Crypt Configuration"
            />
            <Divider
              sx={{
                marginY: 4,
              }}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="button-primary"
                startIcon={
                  sendApprovalLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
                sx={{
                  width: 150,
                }}
                onClick={sendApprovalLoading ? () => {} : submitApproval}
                // onClick={onSubmit}
              >
                Save
              </Button>
            </Stack>
          </FormProvider>
        )}
      </Box>
    </>
  );
};

export default LotsVaultsConfig;
