import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import CustomTabMenu, { ItemsProps } from "../../common/CustomTabMenu";
import { lotVaultCryptRoute, lawnLotRoute } from "../../../routes/routeKeys";
import LotInformation from "./LotInformation";
import LotCategory from "./LotCategory";
import LotType from "./LotType";
import LotStatus from "./LotStatus";

const tabItems: ItemsProps[] = [
  {
    label: "Lot Information",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Lot Category",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Lot Types",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
  {
    label: "Lot Statuses",
    hasOtherQuery: true,
    additionalQuery: "&page=1&entries=10&search=",
  },
];

const LawnLot = () => {
  const location = useLocation();
  const tabValue = Number(new URLSearchParams(location.search).get("tab")) - 1;

  return (
    <Paper elevation={3}>
      <Box padding={3}>
        <CustomTabMenu
          items={tabItems}
          path={`${lotVaultCryptRoute}${lawnLotRoute}?tab=`}
        />
        <Box marginTop={4}>
          {(tabValue === 0 || tabValue === -1) && <LotInformation />}
          {tabValue === 1 && <LotCategory />}
          {tabValue === 2 && <LotType />}
          {tabValue === 3 && <LotStatus />}
        </Box>
      </Box>
    </Paper>
  );
};

export default LawnLot;
