import React, { useEffect } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import { LoaderWithText } from "../../common/Loaders";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { thisFieldRequired } from "../../../utils/constants";
import useGetChartAccountsConfig from "../../../hooks/chart-accounts/useGetChartAccountsConfig";
import {
  useAddDiscountMutation,
  useUpdateDiscountMutation,
} from "../../../service/slice/system-configuration/discountPromoSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { DiscountModel } from "../../../model/discountPromoModel";

interface DiscountFormType {
  name: string;
  chartAccount: {
    label: string;
    value: string;
  };
  subChartAccount: {
    label: string;
    value: string | number;
  };
  description: string;
  radioValue: "" | "AMOUNT" | "PERCENTAGE";
  amount: string;
  percentage: string;
}

type Props = {
  title: string;
  data: DiscountModel | null;
  closeFn: () => void;
};

const DiscountForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<DiscountFormType>({
    defaultValues: {
      name: "",
      chartAccount: {
        label: "",
        value: "",
      },
      subChartAccount: {
        label: "",
        value: "",
      },
      description: "",
      radioValue: "",
      amount: "",
      percentage: "",
    },
  });

  const [
    getSubAccountTrigger,
    { chartAccountIsLoading, chartAccountList },
    { subAccountIsLoading, subAccountList },
  ] = useGetChartAccountsConfig();

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddDiscountMutation();
  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateDiscountMutation();

  const { control, setValue, reset: fieldReset, handleSubmit, setError } = form;

  const chartAccountWatch = useWatch({
    control,
    name: "chartAccount",
  });
  const subChartAccountWatch = useWatch({
    control,
    name: "subChartAccount",
  });

  const radioValueWatch = useWatch({
    control,
    name: "radioValue",
  });

  const initLoading = chartAccountIsLoading;
  const saveLoading = addIsLoading || updateIsLoading;

  const handleChartAccountChange = (value: {
    label: string;
    value: string;
  }) => {
    const accountNumber = value.value.slice(
      value.value.indexOf("-") + 1,
      value.value.length
    );
    getSubAccountTrigger(Number(accountNumber));
    setValue("chartAccount", value);
    setValue("subChartAccount", {
      label: "",
      value: "",
    });
  };

  const validateSelectFields = (): number[] => {
    let errors: number[] = [];
    if (_.isEmpty(chartAccountWatch.value)) {
      setError("chartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }

    if (!_.isNumber(subChartAccountWatch.value)) {
      setError("subChartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }
    return errors;
  };

  const onError = () => {
    validateSelectFields();
  };

  const onSubmit: SubmitHandler<DiscountFormType> = (dataFields) => {
    if (validateSelectFields().length > 0) {
      return;
    }

    const {
      chartAccount,
      description,
      name,
      amount,
      percentage,
      radioValue,
      subChartAccount,
    } = dataFields;

    if (data) {
      updateMutation({
        queryParams: data.id,
        bodyParams: {
          chartAccountId: Number(
            chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
          ),
          description,
          name,
          type: radioValue as "AMOUNT" | "PERCENTAGE",
          value:
            radioValue === "AMOUNT" ? parseInt(amount) : parseInt(percentage),
          subChartAccountId: subChartAccount.value as number,
        },
      });
    } else {
      addMutation({
        chartAccountId: Number(
          chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
        ),
        description,
        name,
        type: radioValue as "AMOUNT" | "PERCENTAGE",
        value:
          radioValue === "AMOUNT" ? parseInt(amount) : parseInt(percentage),
        subChartAccountId: subChartAccount.value as number,
      });
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-discount",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New discount successfully added!",
      toastId: "add-discount",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Discount successfully updated!",
      toastId: "update-discount",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-discount",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        name,
        chartAccount,
        description,
        subChartAccountDiscount,
        type,
        value,
      } = data;

      setValue("name", name);
      setValue("amount", type === "AMOUNT" ? value : "");
      setValue("percentage", type === "PERCENTAGE" ? value : "");
      setValue("radioValue", type);
      setValue("chartAccount", {
        label: `${chartAccount.accountName} - ${chartAccount.accountNumber}`,
        value: `${chartAccount.id}-${chartAccount.accountNumber}`,
      });
      setValue("description", description ?? "");
      setValue("subChartAccount", {
        label: subChartAccountDiscount.subAccount,
        value: subChartAccountDiscount.id,
      });
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        {initLoading ? (
          <LoaderWithText text="Getting additional information." />
        ) : (
          <FormProvider {...form}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Discount Name
                    </Typography>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Discount Name"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          // InputProps={{
                          //   readOnly: data ? true : false,
                          // }}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Chart of Account
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="chartAccount"
                      options={chartAccountList}
                      isRequired
                      placeholder="Chart of Account"
                      // @ts-ignore
                      customOnchange={handleChartAccountChange}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Chart of Account (Sub)
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="subChartAccount"
                      options={subAccountList}
                      isRequired
                      isDisabled={
                        _.isEmpty(chartAccountWatch.value) ||
                        subAccountIsLoading
                      }
                      placeholder="Chart of Account (Sub)"
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Description
                    </Typography>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          multiline
                          rows={4}
                          placeholder="Description"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Stack direction="row" spacing={2}>
                  <Box>
                    <Controller
                      name="radioValue"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select a type",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <RadioGroup
                            {...field}

                            // defaultValue={data?.type ?? ""}
                          >
                            <FormControlLabel
                              value="AMOUNT"
                              control={<Radio />}
                              label="Amount"
                              sx={{
                                marginBottom: 1,
                              }}
                            />
                            <FormControlLabel
                              value="PERCENTAGE"
                              control={<Radio />}
                              label="Percentage"
                            />
                          </RadioGroup>
                          {fieldState.error && (
                            <FormHelperText error>
                              {fieldState.error?.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Box>
                  <Stack spacing={1}>
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: {
                          value:
                            _.isEmpty(radioValueWatch) ||
                            radioValueWatch === "PERCENTAGE"
                              ? false
                              : true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          disabled={
                            _.isEmpty(radioValueWatch) ||
                            radioValueWatch === "PERCENTAGE"
                          }
                          placeholder="Amount"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="percentage"
                      control={control}
                      rules={{
                        required: {
                          value:
                            _.isEmpty(radioValueWatch) ||
                            radioValueWatch === "AMOUNT"
                              ? false
                              : true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          disabled={
                            _.isEmpty(radioValueWatch) ||
                            radioValueWatch === "AMOUNT"
                          }
                          placeholder="Percentage"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
            >
              {data ? "Update" : "Save"}
            </Button>
          </FormProvider>
        )}
      </Box>
    </Box>
  );
};

export default DiscountForm;
