import { UserForm } from "../../components/user-management/users/UserInformation";
import { MenuDtoModel } from "../../model/menuModel";
import {
  RoleDtoModel,
  UserListQueryType,
  UsersDtoModel,
  RoleListQueryType,
  AddUserBodyType,
  ViewUserDtoModel,
  UploadPicDtoModel,
  RoleListDtoModel,
  AddRoleBodyType,
  ViewRoleDtoModel,
  AgentDtoModel,
  AgentConfigDtoModel,
  AddAgentBodyModel,
  AgentViewDtoModel,
  UsersConfigDtoModel,
} from "../../model/usersModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../apiSlice";

export const usersEndPoints = {
  "user-list": (page: number = 1, entries: number = 10, search: string = "") =>
    `/user/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "user-list-config": () => "/user/list",
  "view-user": (id: number) => `/user/get/${id}`,
  "role-list": (
    page: number = 1,
    entries: number = 10,
    searchInput: string = ""
  ) => `role/list?perPage=${entries}&currentPage=${page}&search=${searchInput}`,
  "role-list-no-param": "role/list",
  "add-user": "/user/add",
  "update-picture-user": "/user/upload/picture",
  "update-picture-agent": "/user/upload.picture",
  "update-user": (id: number) => `/user/update/${id}`,
  "menu-list": (id: number) => `/role/menu/list/${id}`,
  "add-role": "/role/add",
  "view-role": (id: number) => `/role/get/${id}`,
  "update-role": (id: number) => `/role/update/${id}`,
  "delete-role": (id: number) => `/role/delete/${id}`,
  "agent-list": (
    page: number = 1,
    entries: number = 10,
    searchInput: string = ""
  ) =>
    `/agent/list?perPage=${entries}&currentPage=${page}&search=${searchInput}`,
  "agent-list-config": "/agent/list",
  "add-agent": "/agent/add",
  "view-agent": (id: number) => `/agent/get/${id}`,
  "update-agent": (id: number) => `/agent/update/${id}`,
};

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMenuList: builder.query<MenuDtoModel, number>({
      query: (param) => ({
        url: usersEndPoints["menu-list"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getUserList: builder.query<UsersDtoModel, UserListQueryType>({
      query: (param) => ({
        url: usersEndPoints["user-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["user-list"],
    }),
    getUserListConfig: builder.query<UsersConfigDtoModel, void>({
      query: () => ({
        url: usersEndPoints["user-list-config"](),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["user-list-config"],
    }),
    getUser: builder.query<ViewUserDtoModel, number>({
      query: (param) => ({
        url: usersEndPoints["view-user"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) =>
        result
          ? [{ type: "view-user" as const, id: arg }, "view-user"]
          : ["view-user"],
    }),
    getRoleListConfig: builder.query<RoleDtoModel, undefined>({
      query: (param) => ({
        url: usersEndPoints["role-list-no-param"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      // providesTags: [usersQueryTag["role-list"]],
    }),
    getRoleList: builder.query<RoleListDtoModel, RoleListQueryType>({
      query: (param) => ({
        url: usersEndPoints["role-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["role-list"],
    }),
    addUser: builder.mutation<void, AddUserBodyType>({
      query: (body) => ({
        url: usersEndPoints["add-user"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: ["user-list", "user-list-config"],
    }),
    uploadPicture: builder.mutation<
      UploadPicDtoModel,
      {
        endpoint: string;
        file: FileList;
      }
    >({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append("picture", param.file[0]);
        }

        return {
          url: usersEndPoints["update-picture-user"],
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
    updateUser: builder.mutation<
      ViewUserDtoModel,
      {
        queryParam: number;
        bodyParam: Partial<AddUserBodyType>;
      }
    >({
      query: (param) => ({
        url: usersEndPoints["update-user"](param.queryParam),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: (results, error, arg) => [
        { type: "view-user" as const, id: arg.queryParam },
        "user-list",
      ],
    }),
    addRole: builder.mutation<void, AddRoleBodyType>({
      query: (body) => ({
        url: usersEndPoints["add-role"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: ["role-list"],
    }),
    getRole: builder.query<ViewRoleDtoModel, number>({
      query: (param) => ({
        url: usersEndPoints["view-role"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) =>
        result
          ? [{ type: "view-role" as const, id: arg }, "view-role"]
          : ["view-role"],
    }),
    updateRole: builder.mutation<
      ViewRoleDtoModel,
      {
        queryParam: number;
        bodyParam: AddRoleBodyType;
      }
    >({
      query: (param) => ({
        url: usersEndPoints["update-role"](param.queryParam),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: (results, error, arg) => [
        { type: "view-role" as const, id: arg.queryParam },
        "role-list",
      ],
    }),
    deleteRole: builder.mutation<void, number>({
      query: (param) => ({
        url: usersEndPoints["delete-role"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: ["role-list"],
    }),
    getAgentList: builder.query<AgentDtoModel, UserListQueryType>({
      query: (param) => ({
        url: usersEndPoints["agent-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["agent-list"],
    }),
    getAgentListConfig: builder.query<AgentConfigDtoModel, void>({
      query: (param) => ({
        url: usersEndPoints["agent-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    addAgent: builder.mutation<void, AddAgentBodyModel>({
      query: (body) => ({
        url: usersEndPoints["add-agent"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: ["agent-list"],
    }),
    updateAgent: builder.mutation<
      AgentViewDtoModel,
      {
        queryParam: number;
        bodyParam: Partial<AddAgentBodyModel>;
      }
    >({
      query: (param) => ({
        url: usersEndPoints["update-agent"](param.queryParam),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
      invalidatesTags: (results, error, arg) => [
        { type: "view-agent" as const, id: arg.queryParam },
        "agent-list",
      ],
    }),
    getAgent: builder.query<AgentViewDtoModel, number>({
      query: (param) => ({
        url: usersEndPoints["view-agent"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, err, arg) =>
        result
          ? [{ type: "view-agent" as const, id: arg }, "view-agent"]
          : ["view-agent"],
    }),
  }),
});

export const {
  useGetUserListQuery,
  useGetUserListConfigQuery,
  useLazyGetUserListConfigQuery,
  useAddUserMutation,
  useGetRoleListConfigQuery,
  useGetRoleListQuery,
  useUploadPictureMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetMenuListQuery,
  useAddRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetAgentListQuery,
  useGetAgentListConfigQuery,
  useLazyGetAgentListConfigQuery,
  useAddAgentMutation,
  useGetAgentQuery,
  useUpdateAgentMutation,
} = usersSlice;
