import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import IconButtonMdi from "../../../common/IconButtonMdi";
import {
  useAddServicesMutation,
  useGetServicesQuery,
  useSendApprovalCodeMutation,
} from "../../../../service/slice/system-configuration/generalSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../utils/notificationToast";
import OtpModal from "../../../common/OtpModal";
import { LoaderWithText } from "../../../common/Loaders";

interface ServiceFormType {
  daysPrior: string;
  rushFee: string;
  rescheduleAmount: string;
  cancellationFee: string;
  cremationBond: string;
  holidays: {
    id: number | null;
    name: string;
    date: Date | null;
  }[];
}

const ServicesConfig = () => {
  const theme = useTheme();
  const [openOtp, setOpenOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const form = useForm<ServiceFormType>({
    defaultValues: {
      daysPrior: "",
      rushFee: "",
      rescheduleAmount: "",
      cancellationFee: "",
      cremationBond: "",
      holidays: [
        {
          id: null,
          name: "",
          date: null,
        },
      ],
    },
  });

  const { control, setValue, handleSubmit, getValues } = form;

  const {
    data: serviceData,
    isLoading: serviceIsLoading,
    error: serviceError,
    refetch: serviceRefetch,
  } = useGetServicesQuery();
  const [
    addMutation,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddServicesMutation();

  const [
    sendApprovalMutation,
    {
      isLoading: sendApprovalLoading,
      error: sendApprovalError,
      reset: sendApprovalReset,
      isSuccess: sendApprovalSuccess,
    },
  ] = useSendApprovalCodeMutation();

  const {
    fields: holidayFields,
    append: holidayAppend,
    remove: holidayRemove,
  } = useFieldArray({
    control,
    name: "holidays",
  });

  const holidayWatch = useWatch({
    control,
    name: "holidays",
  });

  const handleAddRemoveHoliday = (index: number | null) => {
    if (typeof index === "number") {
      holidayRemove(index);
    } else {
      holidayAppend({
        id: null,
        name: "",
        date: null,
      });
    }
  };

  const onSubmit = () => {
    const data = getValues();

    addMutation({
      code: otp,
      cancellationFee: Number(data.cancellationFee),
      cashBond: Number(data.cremationBond),
      daysToInterment: Number(data.daysPrior),
      reschedulingAmount: Number(data.rescheduleAmount),
      rushFee: Number(data.rushFee),
      holiday: data.holidays.map((item) => ({
        id: item.id,
        name: item.name,
        date: moment(item.date).format("YYYY-MM-DD"),
      })),
    });
  };

  if (serviceError) {
    errorDisplayOrNavigate({
      error: serviceError,
      toastId: "service-get",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "service-add",
    });

    addReset();
  }

  if (sendApprovalError) {
    errorDisplayOrNavigate({
      error: sendApprovalError,
      toastId: "send-approval",
    });

    sendApprovalReset();
  }

  useEffect(() => {
    if (sendApprovalSuccess) {
      showToastSuccess({
        text: "Please check your email for the approval code!",
        toastId: "approval",
      });
      setOpenOtp(true);
      sendApprovalReset();
    }
  }, [sendApprovalSuccess]);

  useEffect(() => {
    if (addIsSuccess) {
      showToastSuccess({
        text: "Services configuration successfully saved!",
        toastId: "add",
      });
      serviceRefetch();
      setOpenOtp(false);
      setOtp("");
      addReset();
    }
  }, [addIsSuccess]);

  useEffect(() => {
    if (serviceData) {
      const {
        daysToInterment,
        cancellationFee,
        cashBond,
        reschedulingAmount,
        rushFee,
      } = serviceData.data.service;
      const holidayVal = serviceData.data.holiday;

      setValue("daysPrior", daysToInterment.toString());
      setValue("cancellationFee", cancellationFee.toString());
      setValue("cremationBond", cashBond.toString());
      setValue("rescheduleAmount", reschedulingAmount);
      setValue("rushFee", rushFee);
      setValue(
        "holidays",
        holidayVal.length === 0
          ? [
              {
                id: null,
                name: "",
                date: null,
              },
            ]
          : holidayVal.map((item) => ({
              id: item.id,
              name: item.name,
              date: new Date(item.date),
            }))
      );
    }
  }, [serviceData]);

  return serviceIsLoading ? (
    <LoaderWithText text="Getting service configuration" />
  ) : (
    <FormProvider {...form}>
      <OtpModal
        open={openOtp}
        text="Approval Code"
        closeFn={() => setOpenOtp(false)}
        otpCode={otp}
        handleOtpChange={(val) => setOtp(val)}
        loading={addIsLoading}
        submit={onSubmit}
        resend={() => sendApprovalMutation("service")}
        resendLoading={sendApprovalLoading}
      />
      <Box marginTop={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={5}>
            <Typography variant="textSm" marginBottom={2} fontWeight={600}>
              Internment/Cremation/Inurnment/Exhumation/Disinterment
            </Typography>
            <Grid container spacing={2} alignItems="center" marginBottom={4}>
              <Grid item xs={4}>
                <Typography variant="textSm">
                  Days prior to interment
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="daysPrior"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="textSm">Rush Fee</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="rushFee"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="textSm">Re-scheduling Amount</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="rescheduleAmount"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="textSm">Cancellation Fee (%)</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="cancellationFee"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="textSm">Cremation Cash Bond</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="cremationBond"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Typography variant="textSm" marginBottom={2} fontWeight={600}>
              Holiday and Government Declared for year 2023
            </Typography>
            <Grid container spacing={2} alignItems="center">
              {holidayFields.map((item, key) => (
                <React.Fragment key={key}>
                  <Grid item xs={1.4}>
                    <IconButtonMdi
                      mdiIcon={
                        key + 1 === holidayWatch.length
                          ? mdiPlusCircleOutline
                          : mdiMinusCircleOutline
                      }
                      iconColor={
                        key + 1 === holidayWatch.length
                          ? theme.palette.info.main
                          : theme.palette.error.main
                      }
                      onClick={() =>
                        handleAddRemoveHoliday(
                          key + 1 === holidayWatch.length ? null : key
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <Controller
                      name={`holidays.${key}.name`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          value={holidayWatch[key]?.name ?? ""}
                          placeholder="Holiday"
                          error={error ? true : false}
                          helperText={error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5.1}>
                    <Controller
                      name={`holidays.${key}.date`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            value={holidayWatch[key]?.date ?? null}
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: error ? true : false,
                                helperText: error?.message,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="button-primary"
              startIcon={
                sendApprovalLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              sx={{
                width: 150,
              }}
              onClick={
                sendApprovalLoading
                  ? () => {}
                  : () => sendApprovalMutation("service")
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default ServicesConfig;
