import React from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import { thisFieldRequired } from "../../../utils/constants";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import PaymentModalUploadList from "./PaymentModalUploadList";
import { endpoints } from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import {
  useAddPaymentAccountManagDocMutation,
  useGetPaymentAccountManagDocsQuery,
} from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { skipToken } from "@reduxjs/toolkit/query";

interface PaymentUploadForm {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
  filename: string;
  description: string;
}

type Props = {
  handleToggleUpload: () => void;
  id: number;
  type: "unit" | "service";
};

const PayModalUpload = ({ handleToggleUpload, id, type }: Props) => {
  const form = useForm<PaymentUploadForm>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
      filename: "",
      description: "",
    },
  });

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset: fieldReset,
  } = form;

  const {
    data: docList,
    isLoading: docListIsLoading,
    error: docListError,
    isFetching: docListIsFetching,
  } = useGetPaymentAccountManagDocsQuery(
    id !== 0
      ? {
          id,
          type,
        }
      : skipToken
  );

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      reset: addReset,
    },
  ] = useAddPaymentAccountManagDocMutation();

  const documentWatch = useWatch({
    control,
    name: "document",
  });

  const onSubmit: SubmitHandler<PaymentUploadForm> = (dataFields) => {
    addMutate({
      body: {
        buyerPaymentId: id as number,
        description: dataFields.description,
        fileName: dataFields.filename,
        path: dataFields.document[0].path,
      },
      type,
    });
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Payment document addedd!",
      toastId: "add",
    });

    fieldReset();
    addReset();
  }

  if (docListError) {
    errorDisplayOrNavigate({
      error: docListError,
      toastId: "doc-list",
    });
  }

  return (
    <Grid container columnSpacing={6} rowSpacing={2} marginTop={2}>
      <Grid item xs={12} lg={4} height="100%">
        <FormProvider {...form}>
          <Stack spacing={2}>
            <div>
              <Typography variant="textfieldLabel">Upload Files</Typography>
              <CustomFileUploader
                name="document"
                setValue={setValue}
                clearErrors={clearErrors}
                errorMsg={errors.document?.message}
                endpoint={endpoints["upload-payment-doc"]}
                getValues={getValues}
                isMultiple={false}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Filename</Typography>
              <Controller
                name="filename"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Filename"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Description</Typography>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Description"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    multiline={true}
                    rows={2}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <Button
              variant="button-primary"
              sx={{
                width: 150,
                marginTop: 2,
              }}
              disabled={_.isEmpty(documentWatch[0].path)}
              startIcon={
                addIsLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={addIsLoading ? () => {} : handleSubmit(onSubmit)}
            >
              Upload
            </Button>
            {!_.isEmpty(documentWatch[0].path) && (
              <>
                <Box marginTop={2} />
                <DocViewer
                  documents={[
                    {
                      uri: linkWithDomain(documentWatch[0].path),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              </>
            )}
          </Stack>
        </FormProvider>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          sx={{
            height: "25px",
          }}
        />
        <PaymentModalUploadList
          docs={docList?.data ?? []}
          loading={docListIsLoading || docListIsFetching}
          count={docList?.data?.length ?? 0}
          type={type}
        />
      </Grid>
    </Grid>
  );
};

export default PayModalUpload;
