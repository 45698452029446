import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useFieldArray,
} from "react-hook-form";

import { CommissionSchemeModel } from "../../../model/commissionSchemeModel";
import {
  useGetCommissionSchemeQuery,
  useUpdateCommissionMutation,
} from "../../../service/slice/system-configuration/commissionScheme";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CardHeader from "../../common/CardHeader";
import { LoaderWithText } from "../../common/Loaders";
import { thisFieldRequired } from "../../../utils/constants";

const headers: {
  [key: string]: string;
} = {
  "AREA-DIRECTOR": "Area Sales Director - From commission of Marketing Head",
  "MARKETING-HEAD": "Marketing Head",
};

interface CommissionSchemeForm {
  commissions: CommissionSchemeModel[];
}

const CommissionScheme = () => {
  const form = useForm<CommissionSchemeForm>();

  const { setValue, handleSubmit, control } = form;

  const { data, isLoading, error, isFetching } = useGetCommissionSchemeQuery();
  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateCommissionMutation();

  const commissionFields = useFieldArray({
    control,
    name: "commissions",
  });

  const loading = isLoading || isFetching;
  const saveLoading = updateIsLoading;

  const onSubmit: SubmitHandler<CommissionSchemeForm> = async (data) => {
    await Promise.all(
      data.commissions.map((item) =>
        updateMutation({
          queryParam: item.type,
          bodyParam: {
            crypt: parseInt(item.crypt),
            cryptTax: parseInt(item.cryptTax),
            lot: parseInt(item.lot),
            lotTax: parseInt(item.lotTax),
            vault: parseInt(item.vault),
            vaultTax: parseInt(item.vaultTax),
          },
        })
      )
    );
  };

  if (error) {
    errorDisplayOrNavigate({
      error,
      toastId: "commission-scheme",
    });
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Commission Scheme successfully updated!",
      toastId: "update-commission",
    });

    updateReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-commission",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      setValue("commissions", data.data);
    }
  }, [data]);

  return (
    <Paper elevation={3}>
      <CardHeader title="Commission Scheme" />
      <Box padding={3}>
        {loading ? (
          <LoaderWithText text="Getting commission schemr configuration" />
        ) : (
          <FormProvider {...form}>
            <Stack direction="column" spacing={4}>
              {commissionFields.fields.map((item, key) => (
                <Box key={key}>
                  <Typography
                    variant="textSm"
                    marginBottom={1}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {headers[item.type]}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={4}>
                      <Stack direction="column" spacing={1}>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Lot Commission (%)
                          </Typography>
                          <Controller
                            name={`commissions.${key}.lot`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Lot Commission"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Withholding Tax (%)
                          </Typography>
                          <Controller
                            name={`commissions.${key}.lotTax`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Withholding Tax"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Stack direction="column" spacing={1}>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Vault Commission (%)
                          </Typography>
                          <Controller
                            name={`commissions.${key}.vault`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Vault Commission"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Withholding Tax (%)
                          </Typography>
                          <Controller
                            name={`commissions.${key}.vaultTax`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Withholding Tax"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Stack direction="column" spacing={1}>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Crypt Commission (%)
                          </Typography>
                          <Controller
                            name={`commissions.${key}.crypt`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Crypt Commission"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Withholding Tax (%)
                          </Typography>
                          <Controller
                            name={`commissions.${key}.cryptTax`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: thisFieldRequired,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Withholding Tax"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Stack>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={saveLoading ? () => {} : handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </FormProvider>
        )}
      </Box>
    </Paper>
  );
};

export default CommissionScheme;
