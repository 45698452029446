import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PasswordIndicators } from "../../utils/enums";

type Props = {
  activeIndicators: string[];
};

const passwordIndicatorsLabel: {
  toCheck: string;
  label: string;
}[] = [
  {
    toCheck: PasswordIndicators.passwordMinCharacter,
    label: "At least 8 characters.",
  },
  {
    toCheck: PasswordIndicators.passwordSpecialChar,
    label: "Have at least 1 special character.",
  },
  {
    toCheck: PasswordIndicators.passwordNumber,
    label: "Have at least 1 number.",
  },
  {
    toCheck: PasswordIndicators.passwordUppercase,
    label: "Have at least 1 uppercase letter.",
  },
  {
    toCheck: PasswordIndicators.passwordMatch,
    label: "Match to confirm.",
  },
];

const PasswordIndicator = ({ activeIndicators }: Props) => {
  return (
    <Stack
      direction="column"
      spacing={1}
      sx={(theme) => ({
        padding: theme.spacing(1),
      })}
    >
      {passwordIndicatorsLabel.map((item, key) => (
        <Stack
          key={key}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Box
            sx={(theme) => ({
              width: theme.spacing(1),
              height: theme.spacing(1),
              borderRadius: theme.spacing(3),
              backgroundColor: activeIndicators.includes(item.toCheck)
                ? theme.palette.success.main
                : theme.palette.grey[400],
            })}
          />
          <Typography
            sx={(theme) => ({
              color: activeIndicators.includes(item.toCheck)
                ? theme.palette.success.main
                : theme.palette.grey[400],
              fontSize: ".85rem",
            })}
          >
            {item.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default PasswordIndicator;
