import {
  AccountBodyModel,
  AccountListConfigDtoModel,
  AccountListDtoModel,
  SubAccountListConfigDtoModel,
} from "../../../model/chartAccountModel";
import { ListFetchQueryType } from "../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const chartAccountEndpoints = {
  "add-account": "/configuration/chart-account/add",
  "update-account": (id: number): string =>
    `/configuration/chart-account/update/${id}`,
  "account-list": (page: number, entries: number, search: string) =>
    `/configuration/chart-account/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "delete-account": (id: number): string =>
    `/configuration/chart-account/delete/${id}`,
  "account-list-config": "/configuration/chart-account/list",
  "sub-account-list-config": (accountNum: number) =>
    `/configuration/chart-account/sub-account/list?accountNumber=${accountNum}`,
};

export const chartAccountSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAccount: builder.mutation<void, AccountBodyModel>({
      query: (body) => ({
        url: chartAccountEndpoints["add-account"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["bank-account-list", "bank-account-config"],
    }),
    updateAccount: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: AccountBodyModel;
      }
    >({
      query: (param) => ({
        url: chartAccountEndpoints["update-account"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["bank-account-list", "bank-account-config"],
    }),
    getAccountList: builder.query<AccountListDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: chartAccountEndpoints["account-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["bank-account-list"],
    }),
    deleteAccount: builder.mutation<void, number>({
      query: (param) => ({
        url: chartAccountEndpoints["delete-account"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["bank-account-list", "bank-account-config"],
    }),
    getAccountListConfig: builder.query<AccountListConfigDtoModel, void>({
      query: (_) => ({
        url: chartAccountEndpoints["account-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["bank-account-config"],
    }),
    getSubAccountListConfig: builder.query<
      SubAccountListConfigDtoModel,
      number
    >({
      query: (param) => ({
        url: chartAccountEndpoints["sub-account-list-config"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useAddAccountMutation,
  useUpdateAccountMutation,
  useGetAccountListQuery,
  useDeleteAccountMutation,
  useGetAccountListConfigQuery,
  useLazyGetSubAccountListConfigQuery,
} = chartAccountSlice;
