import React from "react";
import {
  useGetAccountListConfigQuery,
  useLazyGetSubAccountListConfigQuery,
} from "../../service/slice/system-configuration/chartAccountsSlice";
import { AccountModel } from "../../model/chartAccountModel";
import { errorDisplayOrNavigate } from "../../utils/notificationToast";

const useGetChartAccountsConfig = () => {
  const {
    data: chartAccountData,
    isLoading: chartAccountIsLoading,
    error: chartAccountError,
  } = useGetAccountListConfigQuery();
  const [
    getSubAccountTrigger,
    {
      data: subAccountData,
      isLoading: subAccountIsLoading,
      error: subAccountError,
    },
  ] = useLazyGetSubAccountListConfigQuery();

  const chartAccountList = chartAccountData?.data
    ? chartAccountData?.data.map((item: Partial<AccountModel>) => ({
        label: `${item.accountName} - ${item.accountNumber}`,
        value: `${item.id}-${item.accountNumber}`,
      }))
    : [];
  const subAccountList = subAccountData?.data
    ? subAccountData.data.map((item: Partial<AccountModel>) => ({
        label: item.subAccount,
        value: item.id,
      }))
    : [];

  if (chartAccountError) {
    errorDisplayOrNavigate({
      error: chartAccountError,
      toastId: "chart-account-config",
    });
  }
  if (subAccountError) {
    errorDisplayOrNavigate({
      error: subAccountError,
      toastId: "sub-account-config",
    });
  }

  return [
    getSubAccountTrigger,
    {
      chartAccountList,
      chartAccountIsLoading,
    },
    {
      subAccountList,
      subAccountIsLoading,
    },
  ] as const;
};

export default useGetChartAccountsConfig;
