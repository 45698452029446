import React from "react";
import Typography from "@mui/material/Typography";

type Props = {};

const FiveOO = (props: Props) => {
  return (
    <div>
      {" "}
      <Typography variant="h1">500</Typography>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "1.5rem !important" }}>
        Internal server error 👨🏻‍💻
      </Typography>
      <Typography variant="body2">Oops, something went wrong!</Typography>
    </div>
  );
};

export default FiveOO;
