import React, { useEffect } from "react";
import _ from "lodash";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Switch from "@mui/material/Switch";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import useGetChartAccountsConfig from "../../../hooks/chart-accounts/useGetChartAccountsConfig";
import { domainLink, thisFieldRequired } from "../../../utils/constants";
import { LoaderWithText } from "../../common/Loaders";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import { discountPromoEndpoints } from "../../../service/slice/system-configuration/discountPromoSlice";
import {
  useAddPromoMutation,
  useUpdatePromoMutation,
} from "../../../service/slice/system-configuration/discountPromoSlice";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import moment from "moment";
import { PromoModel } from "../../../model/discountPromoModel";

interface PromoFormType {
  code: string;
  name: string;
  radioValue: "" | "AMOUNT" | "PERCENTAGE";
  description: string;
  amount: string;
  percentage: string;
  chartAccount: {
    label: string;
    value: string;
  };
  subChartAccount: {
    label: string;
    value: string | number;
  };
  enable: boolean;
  validityFrom: Date | null;
  validityTo: Date | null;
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
}

type Props = {
  title: string;
  data: PromoModel | null;
  closeFn: () => void;
};

const PromoForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<PromoFormType>({
    defaultValues: {
      code: "",
      name: "",
      description: "",
      radioValue: "",
      amount: "",
      percentage: "",
      validityFrom: null,
      validityTo: null,
      enable: false,
      chartAccount: {
        label: "",
        value: "",
      },
      subChartAccount: {
        label: "",
        value: "",
      },
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
    },
  });

  const [
    getSubAccountTrigger,
    { chartAccountIsLoading, chartAccountList },
    { subAccountIsLoading, subAccountList },
  ] = useGetChartAccountsConfig();

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddPromoMutation();

  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdatePromoMutation();

  const {
    control,
    setValue,
    reset: fieldReset,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
  } = form;

  const chartAccountWatch = useWatch({
    control,
    name: "chartAccount",
  });
  const subChartAccountWatch = useWatch({
    control,
    name: "subChartAccount",
  });

  const radioValueWatch = useWatch({
    control,
    name: "radioValue",
  });

  const documentsWatch = useWatch({
    control,
    name: "document",
  });

  const initLoading = chartAccountIsLoading;
  const saveLoading = addIsLoading || updateIsLoading;

  const handleChartAccountChange = (value: {
    label: string;
    value: string;
  }) => {
    const accountNumber = value.value.slice(
      value.value.indexOf("-") + 1,
      value.value.length
    );
    getSubAccountTrigger(Number(accountNumber));
    setValue("chartAccount", value);
    setValue("subChartAccount", {
      label: "",
      value: "",
    });
  };

  const validateSelectFields = (): number[] => {
    let errors: number[] = [];
    if (_.isEmpty(chartAccountWatch.value)) {
      setError("chartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }

    if (!_.isNumber(subChartAccountWatch.value)) {
      setError("subChartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }
    return errors;
  };

  const onError = () => {
    validateSelectFields();
  };

  const onSubmit: SubmitHandler<PromoFormType> = (dataFields) => {
    if (validateSelectFields().length > 0) {
      return;
    }

    const {
      chartAccount,
      subChartAccount,
      amount,
      code,
      description,
      document,
      enable,
      name,
      percentage,
      radioValue,
      validityFrom,
      validityTo,
    } = dataFields;

    if (data) {
      updateMutation({
        queryParams: data.id,
        bodyParams: {
          name,
          banner: document[0].path.includes(domainLink)
            ? document[0].path.replace(domainLink, "")
            : document[0].path,
          chartAccountId: Number(
            chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
          ),
          description,
          code,
          subChartAccountId: subChartAccount.value as number,
          type: radioValue as "AMOUNT" | "PERCENTAGE",
          value:
            radioValue === "AMOUNT" ? parseInt(amount) : parseInt(percentage),
          from: moment(validityFrom).format("YYYY-MM-DD"),
          to: moment(validityTo).format("YYYY-MM-DD"),
          enable: enable ? 1 : 0,
        },
      });
    } else {
      addMutation({
        name,
        banner: document[0].path,
        chartAccountId: Number(
          chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
        ),
        description,
        code,
        subChartAccountId: subChartAccount.value as number,
        type: radioValue as "AMOUNT" | "PERCENTAGE",
        value:
          radioValue === "AMOUNT" ? parseInt(amount) : parseInt(percentage),
        from: moment(validityFrom).format("YYYY-MM-DD"),
        to: moment(validityTo).format("YYYY-MM-DD"),
        enable: enable ? 1 : 0,
      });
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-promo",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New promo successfully added!",
      toastId: "add-promo",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Promo successfully updated!",
      toastId: "update-promo",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-promo",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        code,
        name,
        banner,
        chartAccount,
        description,
        from,
        subChartAccountPromo,
        to,
        type,
        value,
      } = data;

      setValue("code", code);
      setValue("name", name);
      setValue("amount", type === "AMOUNT" ? value : "");
      setValue("percentage", type === "PERCENTAGE" ? value : "");
      setValue("radioValue", type);
      setValue("chartAccount", {
        label: `${chartAccount.accountName} - ${chartAccount.accountNumber}`,
        value: `${chartAccount.id}-${chartAccount.accountNumber}`,
      });
      setValue("description", description ?? "");
      setValue("subChartAccount", {
        label: subChartAccountPromo.subAccount,
        value: subChartAccountPromo.id,
      });
      setValue("validityFrom", new Date(from));
      setValue("validityTo", new Date(to));
      setValue("document", [
        {
          id: null,
          path: banner,
          name: "",
          format: "",
        },
      ]);
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        {initLoading ? (
          <LoaderWithText text="Getting additional information." />
        ) : (
          <FormProvider {...form}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Promo Code</Typography>
                    <Controller
                      name="code"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Promo Code"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Stack direction="row" spacing={2} paddingTop={1}>
                    <Box>
                      <Controller
                        name="radioValue"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please select a type",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <RadioGroup
                              {...field}

                              // defaultValue={data?.type ?? ""}
                            >
                              <FormControlLabel
                                value="AMOUNT"
                                control={<Radio />}
                                label="Amount"
                                sx={{
                                  marginBottom: 1,
                                }}
                              />
                              <FormControlLabel
                                value="PERCENTAGE"
                                control={<Radio />}
                                label="Percentage"
                              />
                            </RadioGroup>
                            {fieldState.error && (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </Box>
                    <Stack spacing={1}>
                      <Controller
                        name="amount"
                        control={control}
                        rules={{
                          required: {
                            value:
                              _.isEmpty(radioValueWatch) ||
                              radioValueWatch === "PERCENTAGE"
                                ? false
                                : true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="number"
                            disabled={
                              _.isEmpty(radioValueWatch) ||
                              radioValueWatch === "PERCENTAGE"
                            }
                            placeholder="Amount"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="percentage"
                        control={control}
                        rules={{
                          required: {
                            value:
                              _.isEmpty(radioValueWatch) ||
                              radioValueWatch === "AMOUNT"
                                ? false
                                : true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="number"
                            disabled={
                              _.isEmpty(radioValueWatch) ||
                              radioValueWatch === "AMOUNT"
                            }
                            placeholder="Percentage"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Chart of Account
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="chartAccount"
                      options={chartAccountList}
                      isRequired
                      placeholder="Chart of Account"
                      // @ts-ignore
                      customOnchange={handleChartAccountChange}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Chart of Account (Sub)
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="subChartAccount"
                      options={subAccountList}
                      isRequired
                      isDisabled={
                        _.isEmpty(chartAccountWatch.value) ||
                        subAccountIsLoading
                      }
                      placeholder="Chart of Account (Sub)"
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Promo Name</Typography>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Promo Name"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Description
                    </Typography>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          multiline
                          rows={4}
                          placeholder="Description"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
                    <Box>
                      <Typography variant="textfieldLabel" width={"100%"}>
                        Validity From
                      </Typography>
                      <Controller
                        name="validityFrom"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              minDate={new Date()}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: error ? true : false,
                                  helperText: error?.message,
                                  sx: {
                                    width: "100%",
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel" width={"100%"}>
                        Validity To
                      </Typography>
                      <Controller
                        name="validityTo"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              minDate={new Date()}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: error ? true : false,
                                  helperText: error?.message,
                                  sx: {
                                    width: "100%",
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="textfieldLabel">Enable</Typography>
                    <Controller
                      name="enable"
                      control={control}
                      render={({ field }) => (
                        <Switch checked={field.value} {...field} />
                      )}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="textfieldLabel">Attach Promo</Typography>
                <CustomFileUploader
                  name="document"
                  setValue={setValue}
                  clearErrors={clearErrors}
                  endpoint={discountPromoEndpoints["upload-banner"]}
                  getValues={getValues}
                  errorMsg={errors.document?.message}
                  isWhiteBg
                  formKey="banner"
                  isMultiple={false}
                />
                {!_.isEmpty(documentsWatch[0].path) && (
                  <Box marginTop={2}>
                    <DocViewer
                      documents={[
                        {
                          uri: linkWithDomain(documentsWatch[0].path),
                        },
                      ]}
                      pluginRenderers={DocViewerRenderers}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
            >
              {data ? "Update" : "Save"}
            </Button>
          </FormProvider>
        )}
      </Box>
    </Box>
  );
};

export default PromoForm;
