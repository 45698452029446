import {
  CommissionSchemeBodyModel,
  CommissionSchemeDtoModel,
  CommissionSchemeTypeModel,
} from "../../../model/commissionSchemeModel";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";

export const commissionSchemeEndpoint = {
  "get-commission": "/configuration/commission-scheme/get",
  "update-commission": (type: string) =>
    `/configuration/commission-scheme/update/${type}`,
};

export const commissionSchemeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommissionScheme: builder.query<CommissionSchemeDtoModel, void>({
      query: () => ({
        url: commissionSchemeEndpoint["get-commission"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["commission-scheme"],
    }),
    updateCommission: builder.mutation<
      void,
      {
        queryParam: string;
        bodyParam: CommissionSchemeBodyModel;
      }
    >({
      query: (param) => ({
        url: commissionSchemeEndpoint["update-commission"](param.queryParam),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["commission-scheme"],
    }),
  }),
});

export const { useGetCommissionSchemeQuery, useUpdateCommissionMutation } =
  commissionSchemeSlice;
