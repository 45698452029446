import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { useGetBuyerInformationQuery } from "../../../../../service/slice/client-management/buyer/buyerInformationSlice";
import { useGetUnitTypeQuery } from "../../../../../service/slice/client-management/buyer/unitTypeSlice";
import { StepModel, StepperActionCta } from "../../../../common/StepperTabMenu";
import { skipToken } from "@reduxjs/toolkit/query";
import { errorDisplayOrNavigate } from "../../../../../utils/notificationToast";
import { LoaderWithText } from "../../../../common/Loaders";
import GuidlineViewUploader from "./GuidlineViewUploader";
import GuidelineView from "./GuidelineView";
import GuidelineViewPs from "./GuidelineViewPs";
import { showName } from "../unit-type/SelectedUnitsList";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../../routes/routeKeys";

type Props = {
  steps: StepModel[];
};

const Guidelines = ({ steps }: Props) => {
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const {
    data: unitTypeData,
    isLoading: unitTypeIsLoading,
    error: unitTypeError,
  } = useGetUnitTypeQuery(buyerId ?? skipToken);

  const loading = buyerInfoIsLoading || unitTypeIsLoading;

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (unitTypeError) {
    errorDisplayOrNavigate({
      error: unitTypeError,
      toastId: "unit-type",
    });
  }

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting other information.." />
    </Box>
  ) : (
    <>
      <Box marginBottom={4}>
        {buyerId ? (
          <Alert severity="info" icon={false}>
            <Typography variant="body1" marginBottom={1}>
              <Typography component="span" marginRight={1} variant="textSm">
                Buyer:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {`${buyerInfoData?.data.firstName} ${buyerInfoData?.data.lastName}`}
              </Typography>
            </Typography>
            <Typography variant="body1">
              <Typography component="span" marginRight={1} variant="textSm">
                Ref #:
              </Typography>
              <Typography
                component="span"
                variant="textSm"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                })}
              >
                {buyerInfoData?.data.referenceNumber}
              </Typography>
            </Typography>
          </Alert>
        ) : (
          <Alert severity="warning">
            Please add a buyer information or select a buyer to fill up this
            form.
          </Alert>
        )}

        <Grid container marginTop={4} spacing={3}>
          <Grid item xs={12}>
            <GuidlineViewUploader
              title="Guideline"
              uploadTitle="Upload Guideline"
              type="GDL"
            />
          </Grid>
          <Grid item xs={12}>
            <GuidlineViewUploader
              title="Rules and Regulations"
              uploadTitle="Upload Rules and Regulations"
              type="RR"
            />
          </Grid>
          <Grid item xs={12}>
            <GuidlineViewUploader
              title="Terms and Conditions"
              uploadTitle="Upload Terms and Conditions"
              type="TC"
            />
          </Grid>
          <Grid item xs={12}>
            <GuidlineViewUploader
              title="Reservation Agreement"
              uploadTitle="Upload Reservation Agreement"
              type="RS"
            />
          </Grid>
          <Grid item xs={12}>
            <GuidlineViewUploader
              title="Buyer Information Sheet"
              uploadTitle="Upload Buyer Information Sheet"
              type="BIS"
            />
          </Grid>
          {unitTypeData &&
            unitTypeData.data?.unitType.map((item, key) => (
              <GuidelineViewPs
                title={`Payment Schedule: ${item.type} ${showName(
                  item.unit,
                  item.type
                )}`}
                type="PS"
                unitId={item.id}
              />
            ))}
        </Grid>

        <Divider
          sx={(theme) => ({
            marginY: theme.spacing(3),
            marginTop: theme.spacing(4),
          })}
        />

        <StepperActionCta
          steps={steps}
          path={
            buyerId
              ? `${clientManagementRoute}${buyersRoute}/edit/${buyerId}`
              : `${clientManagementRoute}${buyersRoute}/add`
          }
        />
      </Box>
    </>
  );
};

export default Guidelines;
