import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import { Icon } from "@mdi/react";
import { mdiAccountOutline, mdiLogoutVariant } from "@mdi/js";

import {
  domainLink,
  gg_menu_key,
  gg_user_cred_key,
  logoutText,
  profileText,
  refresh_token_key,
  searchText,
  token_key,
} from "../../utils/constants";
import { useDebounce } from "@uidotdev/usehooks";
import AltAvatarImage from "../../asset/avatars/1.png";
import { removeFromStorage } from "../../utils/helpers/storageHelper";
import { loginRoute } from "../../routes/routeKeys";
import { getUserCredential } from "../../utils/helpers/storageHelper";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      //   animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  //   "@keyframes ripple": {
  //     "0%": {
  //       transform: "scale(.8)",
  //       opacity: 1,
  //     },
  //     "100%": {
  //       transform: "scale(2.4)",
  //       opacity: 0,
  //     },
  //   },
}));

const HeaderComponent = () => {
  const user = getUserCredential();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const debounceSearch = useDebounce(searchValue, 1000);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeFromStorage(gg_menu_key);
    removeFromStorage(gg_user_cred_key);
    removeFromStorage(token_key);
    removeFromStorage(refresh_token_key);
    setAnchorEl(null);
    navigate(loginRoute);
  };

  return (
    <Stack
      direction="row"
      // justifyContent="space-between"
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
    >
      {/* <TextField
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={searchText}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "100px",
            backgroundColor: "transparent",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  fontSize: 20,
                }}
              />
            </InputAdornment>
          ),
        }}
      /> */}

      <IconButton onClick={handleClick}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            alt="image"
            src={
              user?.picture ? `${domainLink}/${user.picture}` : AltAvatarImage
            }
          />
        </StyledBadge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            width: 230,
          },
        }}
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          sx={{
            margin: "6px 16px 12px",
          }}
        >
          <Avatar
            alt="image"
            src={
              user?.picture ? `${domainLink}/${user.picture}` : AltAvatarImage
            }
          />
        </StyledBadge>
        <Divider />
        <MenuItem onClick={handleClose} sx={{ marginTop: 1 }}>
          <ListItemIcon>
            <Icon path={mdiAccountOutline} size={1} />
          </ListItemIcon>
          {profileText}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Icon path={mdiLogoutVariant} size={1} />
          </ListItemIcon>
          {logoutText}
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default HeaderComponent;
