import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Icon from "@mdi/react";
import { mdiHelpCircleOutline } from "@mdi/js";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open?: boolean;
  text?: string;
  icon?: string;
  isLoading?: boolean;
  closeFn?: () => void;
  confirmFn?: () => void;
};

const ConfirmModal = ({
  open = false,
  text = "Are you sure?",
  icon = mdiHelpCircleOutline,
  isLoading,
  closeFn,
  confirmFn,
}: Props) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: 400,
          borderRadius: 2,
        },
      })}
    >
      <DialogTitle>
        <Stack alignItems="center" justifyContent="center">
          <Icon path={icon} size={3.5} color={theme.palette.warning.main} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            // paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              fontSize: ".95rem",
            }}
          >
            {text}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          justifyContent: "center",
          paddingTop: 2,
          paddingBottom: 2,
          backgroundColor: theme.palette.grey[100],
        })}
      >
        <Button
          variant="button-cancel"
          sx={{
            width: 150,
          }}
          startIcon={
            isLoading && (
              <CircularProgress
                size={20}
                sx={(theme) => ({
                  color: theme.palette.common.white,
                })}
              />
            )
          }
          onClick={closeFn}
        >
          Cancel
        </Button>
        <Button
          variant="button-confirm"
          sx={{
            width: 150,
          }}
          startIcon={
            isLoading && (
              <CircularProgress
                size={20}
                sx={(theme) => ({
                  color: theme.palette.common.white,
                })}
              />
            )
          }
          onClick={confirmFn}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
