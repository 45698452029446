import { ErrorResponseModel, apiSlice, errorHandler } from "../../apiSlice";
import {
  ExpenseTypesBodyModel,
  ExpenseTypesConfigDtoModel,
  ExpenseTypesDtoModel,
  ExpenseTypesModel,
} from "../../../model/expensesTypesModel";
import { ListFetchQueryType } from "../../../model/global";

export const expensesTypesEndpoints = {
  "add-expense": "/configuration/expense/add",
  "expense-list": (page: number, entries: number, search: string) =>
    `/configuration/expense/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "delete-expense": (id: number) => `/configuration/expense/delete/${id}`,
  "update-payment": (id: number) => `/configuration/expense/update/${id}`,
  "expense-list-config": "/configuration/expense/list",
};

export const expensesTypesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseList: builder.query<ExpenseTypesDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: expensesTypesEndpoints["expense-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["expenses-types-list"],
    }),
    getExpenseListConfig: builder.query<ExpenseTypesConfigDtoModel, void>({
      query: (param) => ({
        url: expensesTypesEndpoints["expense-list-config"],
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["expenses-types-list-config"],
    }),
    addExpense: builder.mutation<void, ExpenseTypesBodyModel>({
      query: (body) => ({
        url: expensesTypesEndpoints["add-expense"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["expenses-types-list"],
    }),
    updateExpense: builder.mutation<
      void,
      {
        queryParams: number;
        bodyParams: ExpenseTypesBodyModel;
      }
    >({
      query: (param) => ({
        url: expensesTypesEndpoints["update-payment"](param.queryParams),
        method: "PUT",
        body: param.bodyParams,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["expenses-types-list"],
    }),
    deleteExpense: builder.mutation<void, number>({
      query: (param) => ({
        url: expensesTypesEndpoints["delete-expense"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["expenses-types-list"],
    }),
  }),
});

export const {
  useAddExpenseMutation,
  useGetExpenseListQuery,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
  useGetExpenseListConfigQuery,
} = expensesTypesSlice;
