import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useParams, useNavigate } from "react-router-dom";

export type StepModel = {
  key: string;
  label: string;
  withPaging: boolean;
};

interface StepperProps {
  path: string;
  steps: StepModel[];
  disabled?: boolean;
}

interface StepperCtaProps<T extends unknown> extends StepperProps {
  children?: React.ReactNode;
  state?: T;
}

interface StepperTabProps<T extends unknown> extends StepperProps {
  state?: T;
}

export const StepperActionCta = <T extends unknown>({
  path,
  steps,
  disabled = false,
  children,
  state,
}: StepperCtaProps<T>) => {
  const navigate = useNavigate();
  const { step } = useParams();

  const activeStep: number = steps.findIndex((elem) => elem.key === step);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      width="100%"
      justifyContent={{
        xs: "flex-start",
        md: "space-between",
      }}
    >
      {activeStep !== 0 && (
        <Button
          disabled={disabled}
          variant="button-secondary"
          sx={{
            width: 150,
          }}
          onClick={() => {
            navigate(
              steps[activeStep - 1].withPaging
                ? `${path}/${
                    steps[activeStep - 1].key
                  }?page=1&entries=10&search=`
                : `${path}/${steps[activeStep - 1].key}`,
              {
                state,
              }
            );
          }}
        >
          Back
        </Button>
      )}
      <Stack direction="row" spacing={2} justifyContent="flex-end" width="100%">
        {children && children}
        {activeStep + 1 < steps.length && (
          <Button
            disabled={disabled}
            variant="button-secondary"
            sx={{
              width: 150,
            }}
            onClick={() => {
              navigate(
                steps[activeStep + 1].withPaging
                  ? `${path}/${
                      steps[activeStep + 1].key
                    }?page=1&entries=10&search=`
                  : `${path}/${steps[activeStep + 1].key}`,
                {
                  state,
                }
              );
            }}
          >
            Next
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const StepperTabMenu = <T extends unknown>({
  path,
  steps,
  disabled = false,
  state,
}: StepperTabProps<T>) => {
  const navigate = useNavigate();
  const { step } = useParams();

  const activeStep: number = steps.findIndex((elem) => elem.key === step);

  const handleStep = (step: StepModel) =>
    navigate(
      step.withPaging
        ? `${path}/${step.key}?page=1&entries=10&search=`
        : `${path}/${step.key}`,
      {
        state,
      }
    );

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear alternativeLabel activeStep={activeStep}>
        {steps.map((item) => (
          <Step key={item.key}>
            <StepButton
              onClick={() => handleStep(item)}
              disableRipple
              disabled={disabled}
            >
              {item.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperTabMenu;
