import {
  BuyerListConfigDtoModel,
  BuyerListDtoModel,
} from "../../../../model/buyerModel";
import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const buyerListEndpoints = {
  "buyer-list": (
    status: number,
    page: number,
    entries: number,
    search: string
  ) =>
    `/buyer/list/${status}?perPage=${entries}&currentPage=${page}&search=${search}`,
  "update-buyer-status": (status: number, id: number) =>
    `/buyer/forfeit/${id}/${status}`,
  "buyer-list-config": () => "/service/buyer/list",
  "download-list": (status: number) => `/buyer/generate/${status}`,
  "send-approval-code": "/configuration/send/approval/reinstate",
};

interface BuyerListQuery extends ListFetchQueryType {
  status: number;
}

export const buyerListSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendAppovalCode: builder.mutation<void, void>({
      query: () => ({
        url: buyerListEndpoints["send-approval-code"],
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getBuyerList: builder.query<BuyerListDtoModel, BuyerListQuery>({
      query: (param) => ({
        url: buyerListEndpoints["buyer-list"](
          param.status,
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        {
          type: "buyer-list",
          id: args.status + 1,
        },
      ],
    }),
    getBuyerListConfig: builder.query<BuyerListConfigDtoModel, void>({
      query: () => ({
        url: buyerListEndpoints["buyer-list-config"](),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["buyer-list-config"],
    }),
    updateBuyerStatus: builder.mutation<
      void,
      {
        status: number;
        id: number;
        statusToInvalidate: number;
        code?: string;
      }
    >({
      query: (params) => ({
        url: buyerListEndpoints["update-buyer-status"](
          params.status,
          params.id
        ),
        method: "PUT",
        body: params.code
          ? {
              code: params.code,
            }
          : {},
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        {
          type: "buyer-list",
          id: 1,
        },
        {
          type: "buyer-list",
          id: 2,
        },
      ],
    }),
  }),
});

export const {
  useGetBuyerListQuery,
  useLazyGetBuyerListConfigQuery,
  useGetBuyerListConfigQuery,
  useUpdateBuyerStatusMutation,
  useSendAppovalCodeMutation,
} = buyerListSlice;
