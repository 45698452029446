import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CustomFileUploader from "../../../common/textfields/CustomFileUploader";
import IconButtonMdi from "../../../common/IconButtonMdi";
import PhoneNumberField from "../../../common/textfields/PhoneNumberField";
import { linkWithDomain } from "../../../../utils/helpers/stringManipulate";
import GuidelineList from "./GuidelineList";
import {
  useGetGuidelineListQuery,
  useAddGuidelineMutation,
  useAddNotificationMutation,
} from "../../../../service/slice/system-configuration/generalSlice";
import { generalEndpoints } from "../../../../service/slice/system-configuration/generalSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../utils/notificationToast";
import { SelectChangeEvent } from "@mui/material/Select";
import useDeleteTableItem from "../../../../hooks/useDeleteTableItem";
import ConfirmModal from "../../../common/ConfirmModal";

export interface GeneralConfigType {
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
  emails: {
    id: number | null;
    email: string;
  }[];
  telephones: {
    id: number | null;
    phone: string;
  }[];
}

const GeneralConfig = () => {
  const location = useLocation();
  const theme = useTheme();

  const [deleteItem, handleDeleteItem] = useDeleteTableItem<{
    index: number;
    value: string;
    type: "email" | "phone";
  }>();

  const pageParam = Number(new URLSearchParams(location.search).get("page"));
  const entriesParam = Number(
    new URLSearchParams(location.search).get("entries")
  );
  const searchParam = new URLSearchParams(location.search).get("search");

  const form = useForm<GeneralConfigType>({
    defaultValues: {
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
      emails: [
        {
          id: null,
          email: "",
        },
      ],
      telephones: [
        {
          id: null,
          phone: "",
        },
      ],
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
    handleSubmit,
    reset: fieldReset,
  } = form;

  const {
    fields: emailFields,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    control,
    name: "emails",
  });
  const {
    fields: telephoneFields,
    append: telephoneAppend,
    remove: telephoneRemove,
  } = useFieldArray({
    control,
    name: "telephones",
  });

  const documentWatch = useWatch({
    control,
    name: "document",
  });

  const emailsWatch = useWatch({
    control,
    name: "emails",
  });

  const telephoneWatch = useWatch({
    control,
    name: "telephones",
  });

  const {
    data: guidelineList,
    isLoading: guidelineIsLoading,
    error: guidelineError,
    refetch: guidelineRefetch,
    isFetching: guidelineIsFetching,
  } = useGetGuidelineListQuery({
    page: pageParam - 1,
    entries: entriesParam,
    search: searchParam ?? "",
  });

  const [
    addGuidelineMutation,
    {
      isLoading: addGuidelineIsLoading,
      error: addGuidelineError,
      reset: addGuidelineReset,
      isSuccess: addGuidelineIsSuccess,
    },
  ] = useAddGuidelineMutation();

  const [
    addNotificationMutation,
    {
      isLoading: addNotificationIsLoading,
      error: addNotificationError,
      reset: addNotificationReset,
      isSuccess: addNotificationIsSuccess,
    },
  ] = useAddNotificationMutation();

  const onSubmitGuideline: SubmitHandler<GeneralConfigType> = (data) => {
    addGuidelineMutation({
      format: data.document[0].format,
      name: data.document[0].name,
      path: data.document[0].path,
    });
  };

  const onSubmitNotification: SubmitHandler<GeneralConfigType> = (data) => {
    addNotificationMutation({
      email: data.emails,
      phone: data.telephones,
    });
  };

  const confirmRemoveItem = (index: number, type: "email" | "phone" | "") => {
    if (type === "email") {
      emailRemove(index);
    } else {
      telephoneRemove(index);
    }

    handleDeleteItem(false, null);
  };

  const handleAddRemoveEmail = (index: number | null) => {
    if (typeof index === "number") {
      emailRemove(index);
    } else {
      emailAppend({
        id: null,
        email: "",
      });
    }
  };

  const handleAddRemoveTelephone = (index: number | null) => {
    if (typeof index === "number") {
      telephoneRemove(index);
    } else {
      telephoneAppend({
        id: null,
        phone: "",
      });
    }
  };

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setValue(`emails.${index}.email`, e.target.value);
    clearErrors(`emails.${index}.email`);
  };

  if (addGuidelineIsSuccess) {
    showToastSuccess({
      text: "Guideline successfully added!",
      toastId: "add-guideline",
    });
    addGuidelineReset();
    setValue("document", [
      {
        id: null,
        path: "",
        name: "",
        format: "",
      },
    ]);
  }

  if (addNotificationIsSuccess) {
    showToastSuccess({
      text: "Notification successfully Updated!",
      toastId: "add-notification",
    });

    addNotificationReset();
    setValue("document", [
      {
        id: null,
        path: "",
        name: "",
        format: "",
      },
    ]);
  }

  if (addGuidelineError) {
    errorDisplayOrNavigate({
      error: addGuidelineError,
      toastId: "add-guideline",
    });

    addGuidelineReset();
  }

  if (addNotificationError) {
    errorDisplayOrNavigate({
      error: addNotificationError,
      toastId: "add-notification",
    });

    addNotificationReset();
  }

  if (guidelineError) {
    errorDisplayOrNavigate({
      error: guidelineError,
      toastId: "guideline-list",
    });
  }

  // useEffect(() => {
  //   guidelineRefetch();
  // }, [page, entries, searchDebounce]);

  useEffect(() => {
    if (guidelineList) {
      if (guidelineList.data.emails && guidelineList.data.emails.length > 0) {
        setValue("emails", guidelineList.data.emails);
      }

      if (guidelineList.data.phones && guidelineList.data.emails.length > 0) {
        setValue("telephones", guidelineList.data.phones);
      }
    }
  }, [guidelineList]);

  return (
    <>
      <ConfirmModal
        open={deleteItem.open}
        text={`Are you sure to remove ${deleteItem.data?.value}?`}
        closeFn={() => handleDeleteItem(false, null)}
        confirmFn={() =>
          confirmRemoveItem(
            deleteItem.data?.index ?? 0,
            deleteItem.data?.type ?? ""
          )
        }
      />
      <FormProvider {...form}>
        <Grid container columnSpacing={6} rowSpacing={2} marginTop={2}>
          <Grid item xs={12} lg={4} height="100%">
            <Typography variant="textfieldLabel">Upload Files</Typography>
            <CustomFileUploader
              name="document"
              setValue={setValue}
              clearErrors={clearErrors}
              errorMsg={errors.document?.message}
              endpoint={generalEndpoints["upload-guideline"]}
              getValues={getValues}
              isMultiple={false}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
                marginTop: 2,
              }}
              disabled={_.isEmpty(documentWatch[0].path)}
              startIcon={
                addGuidelineIsLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={
                addGuidelineIsLoading
                  ? () => {}
                  : handleSubmit(onSubmitGuideline)
              }
            >
              Upload
            </Button>
            {!_.isEmpty(documentWatch[0].path) && (
              <>
                <Box marginTop={2} />
                <DocViewer
                  documents={[
                    {
                      uri: linkWithDomain(documentWatch[0].path),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box
              sx={{
                height: "25px",
              }}
            />
            <GuidelineList
              guidelines={guidelineList?.data.guidelines ?? []}
              loading={guidelineIsLoading || guidelineIsFetching}
              count={guidelineList?.data.count ?? 0}
            />
            {guidelineIsLoading ? (
              <Stack alignItems="center" justifyContent="center" marginTop={2}>
                <CircularProgress
                  color="primary"
                  style={{ margin: "20px 0" }}
                />
              </Stack>
            ) : (
              <Stack direction="row" spacing={4} marginTop={2}>
                <Box>
                  <Typography variant="textfieldLabel">
                    Email Notifications
                  </Typography>
                  {emailFields.map((item, key) => (
                    <Stack
                      direction="row"
                      spacing={1}
                      key={key}
                      paddingTop={key === 0 ? 1 : 2}
                    >
                      <IconButtonMdi
                        mdiIcon={
                          key + 1 === emailsWatch.length
                            ? mdiPlusCircleOutline
                            : mdiMinusCircleOutline
                        }
                        iconColor={
                          key + 1 === emailsWatch.length
                            ? theme.palette.info.main
                            : theme.palette.error.main
                        }
                        onClick={() =>
                          key + 1 === emailsWatch.length
                            ? handleAddRemoveEmail(
                                key + 1 === emailsWatch.length ? null : key
                              )
                            : handleDeleteItem(true, {
                                index: key,
                                value: item.email,
                                type: "email",
                              })
                        }
                      />
                      <Box width="100%">
                        <Controller
                          name={`emails.${key}.email`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={emailsWatch[key]?.email ?? ""}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleEmailChange(e, key)}
                              placeholder="Email"
                              error={
                                errors.emails
                                  ? errors.emails[key]
                                    ? true
                                    : false
                                  : false
                              }
                              helperText={
                                errors.emails ? errors.emails[key]?.message : ""
                              }
                              sx={{
                                width: "100%",
                                marginBottom: 1,
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Stack>
                  ))}
                </Box>

                <Box>
                  <Typography variant="textfieldLabel">
                    Phone Numbers
                  </Typography>
                  {telephoneFields.map((item, key) => (
                    <Stack
                      direction="row"
                      spacing={1}
                      key={key}
                      paddingTop={key === 0 ? 1 : 2}
                    >
                      <IconButtonMdi
                        mdiIcon={
                          key + 1 === telephoneWatch.length
                            ? mdiPlusCircleOutline
                            : mdiMinusCircleOutline
                        }
                        iconColor={
                          key + 1 === telephoneWatch.length
                            ? theme.palette.info.main
                            : theme.palette.error.main
                        }
                        onClick={() =>
                          key + 1 === telephoneWatch.length
                            ? handleAddRemoveTelephone(
                                key + 1 === telephoneWatch.length ? null : key
                              )
                            : handleDeleteItem(true, {
                                index: key,
                                value: item.phone,
                                type: "phone",
                              })
                        }
                      />
                      <Box width="100%">
                        <PhoneNumberField
                          name={`telephones.${key}.phone`}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          mobileValue={item.phone}
                        />
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            )}
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="button-primary"
                sx={{
                  width: 150,
                  marginTop: 2,
                }}
                startIcon={
                  addNotificationIsLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
                onClick={
                  addNotificationIsLoading
                    ? () => {}
                    : handleSubmit(onSubmitNotification)
                }
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default GeneralConfig;
