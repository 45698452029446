import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Icon from "@mdi/react";
import { mdiCardSearchOutline, mdiAccountOffOutline } from "@mdi/js";

import { UnitModel } from "./UnitType";
import { LotInformationModel } from "../../../../../model/lotModel";
import { VaultInformationModel } from "../../../../../model/vaultModel";
import { CryptInformationModel } from "../../../../../model/cryptModel";
import LotDetails from "./LotDetails";
import CryptDetails from "./CryptDetails";
import VaultDetails from "./VaultDetails";
import ConfirmModal from "../../../../common/ConfirmModal";
import useDeleteTableItem from "../../../../../hooks/useDeleteTableItem";

type Props = {
  units: UnitModel[];
  handleToggleUnit: (
    isAdd: boolean,
    type: string,
    unitTypeId: number,
    unit: any
  ) => void;
};

export const showName = (unit: any, type: string): string => {
  if (type === "LOT") {
    return `${unit?.lotCategory?.name} - ${unit?.lotType?.name}`;
  } else if (type === "VAULT") {
    return `${unit?.vaultLevel?.name}-${unit?.vaultColumn?.name}`;
  } else if (type === "CRYPT") {
    return `${unit?.cryptLevel?.name}-${unit?.cryptColumn?.name}`;
  } else {
    return "";
  }
};

const SelectedUnitsList = ({ units, handleToggleUnit }: Props) => {
  const theme = useTheme();
  const [view, setView] = useState<{
    open: boolean;
    unit: any;
    type: string;
  }>({
    open: false,
    unit: null,
    type: "",
  });
  const [unassignItem, handleUnassignItem] = useDeleteTableItem<{
    unit: any;
    type: string;
  }>();

  const handleToggleView = (open: boolean, unit: any, type?: string) =>
    setView({
      open,
      unit,
      type: type ?? "",
    });

  return (
    <>
      <ConfirmModal
        isLoading={false}
        open={unassignItem.open}
        text={`Are you sure to unassign ${showName(
          unassignItem.data?.unit,
          unassignItem.data?.type as string
        )}`}
        closeFn={() => handleUnassignItem(false, null)}
        confirmFn={() => {
          handleToggleUnit(
            false,
            "CRYPT",
            unassignItem.data?.unit.id,
            unassignItem.data?.unit
          );
        }}
      />
      {view.type === "LOT" && (
        <LotDetails
          open={view.open}
          unit={view.unit}
          handleToggleUnit={handleToggleUnit}
          handleToggleView={handleToggleView}
          isSelected
        />
      )}
      {view.type === "CRYPT" && (
        <CryptDetails
          open={view.open}
          unit={view.unit}
          handleToggleUnit={handleToggleUnit}
          handleToggleView={handleToggleView}
          isSelected
        />
      )}
      {view.type === "VAULT" && (
        <VaultDetails
          open={view.open}
          unit={view.unit}
          handleToggleUnit={handleToggleUnit}
          handleToggleView={handleToggleView}
          isSelected
        />
      )}
      <Box width="100%">
        <Divider
          sx={{
            paddingY: 2,
            "& span": {
              fontSize: ".75rem",
            },
          }}
        >
          Selected units
        </Divider>
        <Grid container spacing={2}>
          {units.map((item, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: 1,
                    boxShadow: theme.shadows[3],
                    padding: 1,
                  })}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Tooltip title={"Test"}>
                      <div>
                        <Typography
                          sx={(theme) => ({
                            fontSize: ".85rem",
                            color: theme.palette.primary.main,
                            whiteSpace: "nowrap",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          })}
                        >
                          {showName(item.unit, item.type)}
                        </Typography>
                        <Typography
                          sx={(theme) => ({
                            fontSize: ".75rem",
                            color: theme.palette.common.black,
                            marginTop: "2px",
                          })}
                        >
                          {`Unit type: ${
                            item.type === "VAULT" ? "COLUMBARIUM" : item.type
                          }`}
                        </Typography>
                      </div>
                    </Tooltip>
                    <Stack direction="row" spacing={1}>
                      <div
                        onClick={() =>
                          handleToggleView(true, item.unit, item.type)
                        }
                      >
                        <Icon
                          path={mdiCardSearchOutline}
                          size={1}
                          color={theme.palette.primary.main}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div onClick={() => handleUnassignItem(true, item)}>
                        <Icon
                          path={mdiAccountOffOutline}
                          size={1}
                          color={theme.palette.error.main}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default SelectedUnitsList;
