import React from "react";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { mdiDeleteOutline, mdiCardSearchOutline } from "@mdi/js";

import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import ConfirmModal from "../../common/ConfirmModal";

import useTableParameters from "../../../hooks/useTableParameters";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import {
  useDeleteOrMutation,
  useGetOrListQuery,
} from "../../../service/slice/account-management/OR/OrSlice";
import {
  accountManagementRoute,
  officialReceiptRoute,
} from "../../../routes/routeKeys";
import { OrModel } from "../../../model/OrModel";
import OfficialReceiptForm from "./OfficialReceiptForm";
import OfficialReceiptView from "./OfficialReceiptView";

type Props = {};

const headerLabels: string[] = [
  "Booklet Code",
  "OR Start Number",
  "Pages",
  "Used",
  "Left",
];

const OfficialReceipt = (props: Props) => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const [openCreate, handleToggleCreate] = useOpenCreateForm<OrModel>();
  const [openView, handleToggleView] = useOpenCreateForm<OrModel>();
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<OrModel>();
  const theme = useTheme();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetOrListQuery({
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeleteOrMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  const confirmDelete = () => {
    deleteMutate(deleteItem.data?.id as number);
  };

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "or-list",
    });
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-or",
    });

    deleteReset();
  }

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.code} successfully deleted.`,
      toastId: "delete-or",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.code}`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      {openView.open && (
        <OfficialReceiptView
          closeFn={() => handleToggleView(false, null, "")}
          open={openView.open}
          data={openView.data}
        />
      )}
      {openCreate.open && (
        <OfficialReceiptForm
          title={openCreate.title}
          data={openCreate.data}
          closeFn={() => handleToggleCreate(false, null, "")}
        />
      )}
      <Paper elevation={3}>
        <Box padding={3}>
          <Grid container alignItems="center" marginBottom={2}>
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${accountManagementRoute}${officialReceiptRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  onClick={() =>
                    handleToggleCreate(true, null, "Create Booklet")
                  }
                  sx={{
                    width: "180px",
                  }}
                >
                  Add Booklet
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${accountManagementRoute}${officialReceiptRoute}`}
                querySearch={(entry: string) =>
                  `?tab=1&page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headerLabels.map((item, key) => (
                    <TableCell key={key} variant="head">
                      {item}
                    </TableCell>
                  ))}
                  <TableCell
                    variant="head"
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                      }}
                    >
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.start}</TableCell>
                      <TableCell>{item.pages}</TableCell>
                      <TableCell>{item.usedCount}</TableCell>
                      <TableCell>{item.leftCount}</TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiCardSearchOutline}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="View Booklet"
                            onClick={() => handleToggleView(true, item, "")}
                          />
                          {item.leftCount !== 0 && (
                            <IconButtonMdi
                              mdiIcon={mdiDeleteOutline}
                              iconColor={theme.palette.error.main}
                              tooltipTitle="Delete Booklet"
                              onClick={() => handleToggleDelete(true, item)}
                            />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${accountManagementRoute}${officialReceiptRoute}`}
            querySearch={(page) =>
              `?tab=1&page=${page}&entries=${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default OfficialReceipt;
