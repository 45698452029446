import React from "react";
import { useNavigate } from "react-router-dom";
import { usePagination, DOTS } from "../../hooks/usePaginate";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
  pathname: string;
  querySearch: (page: number) => string;
  // onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  nextPageSetState?: () => void;
  prevPageSetState?: () => void;
  selectPageSetState?: (page: number) => void;
};

const CustomPagination = ({
  pathname,
  querySearch,
  // onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  nextPageSetState,
  prevPageSetState,
  selectPageSetState,
}: Props) => {
  const navigate = useNavigate();
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () =>
    nextPageSetState
      ? nextPageSetState()
      : navigate({
          pathname,
          search: querySearch(currentPage + 1),
        });
  const onPrevious = () =>
    prevPageSetState
      ? prevPageSetState()
      : navigate({
          pathname,
          search: querySearch(currentPage - 1),
        });

  let lastPage = paginationRange
    ? paginationRange[paginationRange?.length - 1]
    : currentPage;

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      spacing={2}
    >
      <Box>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={(theme) => ({
              color: "common.black",
              fontWeight: 400,
              padding: theme.spacing(1),
              fontSize: ".9rem",
            })}
          >
            Showing
          </Typography>
          <Typography
            sx={(theme) => ({
              fontWeight: 500,
              color: "primary.main",
              padding: theme.spacing(1),
              fontSize: ".9rem",
            })}
          >
            {currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1}{" "}
            -{" "}
            {currentPage === 1
              ? lastPage === currentPage
                ? currentPage * pageSize - (currentPage * pageSize - totalCount)
                : pageSize
              : lastPage === currentPage
              ? currentPage * pageSize - (currentPage * pageSize - totalCount)
              : currentPage * pageSize}
          </Typography>
          <Typography
            sx={(theme) => ({
              color: "common.black",
              fontWeight: 400,
              padding: theme.spacing(1),
              fontSize: ".9rem",
            })}
          >
            of {totalCount}
          </Typography>
        </Stack>
      </Box>

      <Box>
        <Stack direction="row" spacing={1}>
          <Typography
            sx={(theme) => ({
              color: "common.black",
              fontWeight: 500,
              paddingX: theme.spacing(2),
              paddingY: theme.spacing(1),
              fontSize: ".9rem",
              backgroundColor: "grey.100",
              borderRadius: theme.spacing(1),
              display: currentPage !== 1 ? "initial" : "none",
            })}
            onClick={() => (currentPage !== 1 ? onPrevious() : null)}
          >
            Prev
          </Typography>
          <Stack direction="row" spacing={1}>
            {paginationRange?.map((pageNumber, key) => {
              if (pageNumber === DOTS) {
                return (
                  <Typography
                    key={key}
                    sx={(theme) => ({
                      color: "common.black",
                      fontWeight: 500,
                      paddingX: theme.spacing(2),
                      paddingY: theme.spacing(1),
                      fontSize: ".9rem",
                      backgroundColor: "grey.100",
                      borderRadius: theme.spacing(1),
                    })}
                  >
                    &#8230;
                  </Typography>
                );
              }

              return (
                <Typography
                  key={key}
                  sx={(theme) => ({
                    color:
                      pageNumber !== currentPage
                        ? "common.black"
                        : "common.white",
                    fontWeight: 500,
                    paddingX: theme.spacing(2),
                    paddingY: theme.spacing(1),
                    fontSize: ".9rem",
                    backgroundColor:
                      pageNumber !== currentPage ? "grey.100" : "primary.main",
                    borderRadius: theme.spacing(1),
                  })}
                  onClick={() =>
                    pageNumber !== currentPage
                      ? selectPageSetState
                        ? selectPageSetState(Number(pageNumber))
                        : navigate({
                            pathname,
                            search: querySearch(pageNumber as number),
                          })
                      : null
                  }
                >
                  {pageNumber}
                </Typography>
              );
            })}
          </Stack>
          <Typography
            sx={(theme) => ({
              color: "common.black",
              fontWeight: 500,
              padding: theme.spacing(1),
              fontSize: ".9rem",
              backgroundColor: "grey.100",
              borderRadius: theme.spacing(1),
              display:
                currentPage === lastPage || totalCount === 0
                  ? "none"
                  : "initial",
            })}
            onClick={() =>
              currentPage !== lastPage && totalCount !== 0 ? onNext() : null
            }
          >
            Next
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default CustomPagination;
