import { useState } from "react";

export interface CreateType<T> {
  open: boolean;
  data: T | null;
  title: string;
}

const useOpenCreateForm = <T>() => {
  const [openCreate, setOpenCreate] = useState<CreateType<T>>({
    open: false,
    data: null,
    title: "",
  });

  const handleToggleCreate = (open: boolean, data: T | null, title: string) =>
    setOpenCreate({
      open,
      data,
      title,
    });

  return [openCreate, handleToggleCreate] as const;
};

export default useOpenCreateForm;
