import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Calendar,
  EventProps,
  Views,
  View,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { StepModel } from "../../common/StepperTabMenu";
import { rentalAndServicesRoute } from "../../../routes/routeKeys";
import { useGetRentalServicesCalendarQuery } from "../../../service/slice/rental-and-services/rentalServicesSlice";
import { errorDisplayOrNavigate } from "../../../utils/notificationToast";
import { MyEvent } from "../../../model/rentalServicesModel";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { LoaderWithText } from "../../common/Loaders";

interface Props {
  scheduleForm: any;
  serviceType: string;
}

const localizer = momentLocalizer(moment);

const ScheduleCalendar = ({ serviceType, scheduleForm }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  var date = new Date();
  const [currentRange, setCurrentRange] = useState<{ start: Date; end: Date }>({
    start: new Date(date.getFullYear(), date.getMonth(), 1),
    end: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  });
  const [initialRange, setInitialRange] = useState<{ start: Date; end: Date }>({
    start: new Date(date.getFullYear(), date.getMonth(), 1),
    end: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  });

  const {
    data: calendarList,
    isLoading: calendarIsLoading,
    error: calendarError,
    refetch: calendarRefetch,
    isFetching: calendarIsFetching,
  } = useGetRentalServicesCalendarQuery({
    type: serviceType,
    from: moment(currentRange.start).format("YYYY-MM-DD"),
    to: moment(currentRange.end).format("YYYY-MM-DD"),
  });

  const loading = calendarIsLoading || calendarIsFetching;

  if (calendarError) {
    errorDisplayOrNavigate({
      error: calendarError,
      toastId: "rental-services-calendar-get",
    });
  }

  const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    if (Array.isArray(range)) {
      setCurrentRange({ start: range[0], end: range[range.length - 1] });
    } else {
      setCurrentRange(range);
    }
  };

  const eventPropGetter = (event: MyEvent) => {
    let backgroundColor = "";
    const isPast = moment().isAfter(moment(event.date), "day");
    const paidToday =
      moment().isSame(moment(event.date), "day") && event.status === "PAID";
    const reservation =
      moment().isSameOrBefore(moment(event.date), "day") &&
      event.status === "PARTIALLY-PAID";
    const paidReservation =
      moment(event.date).isAfter(moment(), "day") && event.status === "PAID";

    if (isPast) {
      backgroundColor = theme.palette.grey[400];
    } else if (paidToday) {
      // backgroundColor = theme.palette.success.dark;
      backgroundColor = "limegreen";
    } else if (reservation) {
      // backgroundColor = theme.palette.success.light;
      backgroundColor = "greenyellow";
    } else if (paidReservation) {
      // backgroundColor = theme.palette.warning.light;
      backgroundColor = "limegreen";
    } else {
      backgroundColor = "#fff";
    }

    return {
      style: {
        backgroundColor,
        color: "#000",
      },
    };
  };

  const getEventTooltip = (event: MyEvent): string => {
    return `Date & Time: ${event.date} ${event.time}
    \nUnit owner: ${event.buyer.firstName} ${event.buyer.lastName}
    \nUnit location: ${
      event.unitType === "LOT"
        ? "LOT " +
          event.unit?.lotCategory?.name +
          "-" +
          event.unit?.lotType?.name
        : event.unitType === "VAULT"
        ? "Vault Level " +
          event.unit?.vaultLevel?.name +
          " Column " +
          event.unit?.vaultColumn?.name
        : event.unitType === "CRYPT"
        ? "Crypt Level " +
          event.unit?.cryptLevel?.name +
          "Column " +
          event.unit?.cryptColumn?.name
        : ""
    }
    \nService: ${event.service.type}
    \nType: ${event.service.name}
    \nRate: ${event.service.rate}
    \nName of Deceased: ${event.deceased.firstName} ${event.deceased.lastName}
    \nMaterials: NA
    \nStatus: ${
      event.status.toLowerCase() === "paid"
        ? event.status
        : `Balance ${event.balance}`
    }
    `;
  };

  const handleSelectEvent = (event: MyEvent) => {
    // const isPast = moment().isAfter(moment(event.date), "day");

    // if (isPast) {
    //   scheduleForm.setValue("id", event.id);
    //   navigate(
    //     `${rentalAndServicesRoute}/${serviceType.toLowerCase()}/schedule`
    //   );
    // } else if (event.status === "UNPAID" || event.status === "PARTIALLY-PAID") {
      scheduleForm.setValue("id", event.id);
      navigate(
        `${rentalAndServicesRoute}/${serviceType.toLowerCase()}/schedule`
      );
    // }
  };

  const handleSelectSlot = (slotInfo: {
    start: Date;
    end: Date;
    action: "select" | "click" | "doubleClick";
  }) => {
    if (slotInfo.action === "doubleClick") {
      scheduleForm.reset();
      scheduleForm.setValue("date", slotInfo.start);
      navigate(
        `${rentalAndServicesRoute}/${serviceType.toLowerCase()}/price-list?page=1&entries=10&search=`
      );
    }
  };

  useEffect(() => {
    calendarRefetch();
  }, [currentRange]);

  // return loading ? (
  //   <Box paddingY={3}>
  //     <LoaderWithText text={`Getting ${_.capitalize(serviceType)} events..`} />
  //   </Box>
  // ) :
  return (
    <Box>
      <Calendar
        events={(calendarList ? calendarList.data : []).map((item: MyEvent) => {
          return {
            ...item,
            start: new Date(item.date + " " + item.time),
            end: new Date(item.date + " " + item.time),
            title:
              item.buyer.firstName +
              " " +
              item.buyer.lastName +
              "\n" +
              (item.unitType ? `${
                item.unitType === "LOT"
                  ? "LOT " +
                    item.unit?.lotCategory?.name +
                    "-" +
                    item.unit?.lotType?.name
                  : item.unitType === "VAULT"
                  ? "Vault Level " +
                    item.unit?.vaultLevel?.name +
                    " Column " +
                    item.unit?.vaultColumn?.name
                  : item.unitType === "CRYPT"
                  ? "Crypt Level " +
                    item.unit?.cryptLevel?.name +
                    "Column " +
                    item.unit?.cryptColumn?.name
                  : ""
              }` : "No Unit") +
              // "\nCrypt Level " +
              // item.unit.cryptLevel.name +
              // " Column " +
              // item.unit.cryptColumn.name +
              "\n" +
              item.deceased.firstName +
              " " +
              item.deceased.lastName,
          };
        })}
        localizer={localizer}
        defaultView={Views.MONTH}
        selectable
        style={{
          minHeight: "90vh",
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        views={["month", "week", "day"]}
        onRangeChange={(range) => handleRangeChange(range)}
        eventPropGetter={eventPropGetter}
        onSelectEvent={handleSelectEvent}
        tooltipAccessor={getEventTooltip}
        showAllEvents={true}
        defaultDate={new Date()}
        onSelectSlot={handleSelectSlot}
      />
    </Box>
  );
};

export default ScheduleCalendar;
