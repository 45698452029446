import React, { useEffect, useMemo } from "react";
import _, { last } from "lodash";
import moment from "moment";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import {
  useGetAgentListConfigQuery,
  usersEndPoints,
} from "../../../service/slice/usersSlice";
import CardHeader from "../../common/CardHeader";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";
import { number } from "yup";
import {
  agentTypeOpt,
  domainLink,
  emailLabel,
  enterValidMobileNum,
  salesPersonnelOpt,
  thisFieldRequired,
} from "../../../utils/constants";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";
import PhoneNumberField from "../../common/textfields/PhoneNumberField";
import {
  useUploadPictureMutation,
  useAddAgentMutation,
  useGetAgentQuery,
  useUpdateAgentMutation,
} from "../../../service/slice/usersSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { replaceAllSpaceWithHyphen } from "../../../utils/helpers/stringManipulate";
import { UploadPicDtoModel } from "../../../model/usersModel";
import { RoleTypeEnum } from "../../../utils/enums";

interface AgentForm {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  type: {
    label: string;
    value: string | null;
  };
  age: number | null;
  sex: {
    label: string;
    value: string | null;
  };
  civilStatus: {
    value: string | null;
    label: string;
  };
  dateOfBirth: Date | null;
  phoneNumber: string;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
  reportingTo: {
    label: string;
    value: number | null;
  };
}

type Props = {
  title: string;
  id?: number | null;
  handleClose: () => void;
};

const SalesPersonelInformation = ({ title, handleClose, id }: Props) => {
  const form = useForm<AgentForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      type: {
        label: "",
        value: null,
      },
      age: null,
      sex: {
        label: "",
        value: null,
      },
      civilStatus: {
        value: null,
        label: "",
      },
      dateOfBirth: null,
      picture: {
        file: null,
        originalName: null,
        filetype: null,
        rawFile: null,
      },
      reportingTo: {
        label: "",
        value: null,
      },
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    handleSubmit,
    reset: fieldReset,
    setError,
  } = form;

  const {
    data: agentList,
    isLoading: agentListIsLoading,
    error: agentListError,
  } = useGetAgentListConfigQuery();

  const {
    data: agentData,
    isLoading: agentDataIsLoading,
    error: agentDataError,
    isSuccess: agentDataIsSuccess,
  } = useGetAgentQuery(id ?? skipToken);

  const [
    uploadPicMutate,
    {
      error: uploadPicError,
      isLoading: uploadPicIsLoading,
      reset: uploadPicReset,
      isSuccess: uploadIsSuccess,
    },
  ] = useUploadPictureMutation();

  const [
    addAgentMutate,
    {
      error: addAgentError,
      isLoading: addAgentIsLoading,
      reset: addAgentReset,
      isSuccess: addAgentIsSuccess,
    },
  ] = useAddAgentMutation();

  const [
    updateAgentMutate,
    {
      error: updateAgentError,
      isLoading: updateAgentIsLoading,
      reset: updateAgentReset,
      isSuccess: updateAgentIsSuccess,
    },
  ] = useUpdateAgentMutation();

  const initLoading = agentListIsLoading || agentDataIsLoading;
  const saveLoading =
    uploadPicIsLoading || addAgentIsLoading || updateAgentIsLoading;

  const phoneNumberWatch = useWatch({
    control,
    name: "phoneNumber",
  });

  const typeWatch = useWatch({
    control,
    name: "type",
  });
  const reportingToWatch = useWatch({
    control,
    name: "reportingTo",
  });
  const pictureWatch = useWatch({
    control,
    name: "picture",
  });
  const mobileNumWatch = useWatch({
    control,
    name: "phoneNumber",
  });
  const sexWatch = useWatch({
    control,
    name: "sex",
  });

  const civilStatusWatch = useWatch({
    control,
    name: "civilStatus",
  });

  const agentListOpt = useMemo(() => {
    return agentList?.data
      .filter((item) => {
        if (
          typeWatch.value === RoleTypeEnum.agent &&
          item.type === RoleTypeEnum.areaDirector
        ) {
          return item;
        } else if (
          typeWatch.value === RoleTypeEnum.areaDirector &&
          item.type === RoleTypeEnum.marketingHead
        ) {
          return item;
        } else if (
          typeWatch.value === RoleTypeEnum.locator &&
          item.type === RoleTypeEnum.cashier
        ) {
          console.log(item);
          return item;
        } else {
          return;
        }
      })
      .map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
  }, [agentList, typeWatch]);

  const checkErrors = (): number[] => {
    const errorsArr = [];

    if (!_.isEmpty(phoneNumberWatch)) {
      const formatVal = phoneNumberWatch.replaceAll(" ", "").replace("+63", "");

      if (formatVal.length > 10 || formatVal.length < 10) {
        setError("phoneNumber", {
          type: "required",
          message: enterValidMobileNum,
        });
        errorsArr.push(1);
      }
    }

    if (_.isEmpty(typeWatch.label)) {
      setError("type", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (
      (_.isEmpty(reportingToWatch.label) &&
        typeWatch.value === RoleTypeEnum.agent) ||
      (_.isEmpty(reportingToWatch.label) &&
        typeWatch.value === RoleTypeEnum.areaDirector) ||
      (_.isEmpty(reportingToWatch.label) &&
        typeWatch.value === RoleTypeEnum.locator)
    ) {
      setError("reportingTo", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(sexWatch.value)) {
      setError("sex", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(civilStatusWatch.value)) {
      setError("civilStatus", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(pictureWatch.file)) {
      setError("picture", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(mobileNumWatch)) {
      setError("phoneNumber", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    return errorsArr;
  };

  const onError = () => {
    checkErrors();
  };

  const onSubmitUpdate: SubmitHandler<AgentForm> = async (data) => {
    const finalDate: string = moment(data.dateOfBirth).format("YYYY-MM-DD");
    let dataPic: null | UploadPicDtoModel = null;
    const errorCheck = checkErrors();

    if (errorCheck.length > 0) {
      return;
    }

    if (data.picture.rawFile) {
      dataPic = await uploadPicMutate({
        endpoint: usersEndPoints["update-picture-agent"],
        file: data.picture.rawFile as FileList,
      }).unwrap();
    }

    await updateAgentMutate({
      queryParam: id as number,
      bodyParam: {
        firstName: data.firstName,
        birthDate: finalDate,
        civilStatus: data.civilStatus.value as string,
        email: data.email,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        picture: data.picture.rawFile
          ? (dataPic?.data.link as string)
          : (data.picture.file?.replace(domainLink, "") as string),
        reportingTo: data.reportingTo.value as number,
        sex: data.sex.value as string,
        type: data.type.value as string,
      },
    });
  };

  const onSubmit: SubmitHandler<AgentForm> = async (data) => {
    const errorCheck = checkErrors();

    if (errorCheck.length > 0) {
      return;
    }

    const dataPic = await uploadPicMutate({
      endpoint: usersEndPoints["update-picture-agent"],
      file: data.picture.rawFile as FileList,
    }).unwrap();

    const finalDate: string = moment(data.dateOfBirth).format("YYYY-MM-DD");

    await addAgentMutate({
      firstName: data.firstName,
      birthDate: finalDate,
      civilStatus: data.civilStatus.value as string,
      email: data.email,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      picture: dataPic.data.link,
      reportingTo: data.reportingTo.value as number,
      sex: data.sex.value as string,
      type: data.type.value as string,
    });
  };

  if (agentDataError) {
    errorDisplayOrNavigate({
      error: agentDataError,
      toastId: "view-agent",
    });
  }

  if (updateAgentIsSuccess) {
    showToastSuccess({
      text: "Sales Person successfully updated",
      toastId: "view-agent",
    });

    updateAgentReset();
    fieldReset();
    handleClose();
  }

  if (addAgentIsSuccess) {
    showToastSuccess({
      text: "Sales Person successfully added.",
      toastId: "add-agent",
    });

    addAgentReset();
    fieldReset();
    handleClose();
  }

  if (agentListError) {
    errorDisplayOrNavigate({
      error: agentListError,
      toastId: "agent-list-config",
    });
  }

  if (uploadPicError) {
    errorDisplayOrNavigate({
      error: uploadPicError,
      toastId: "add-agent",
    });

    uploadPicReset();
  }

  if (updateAgentError) {
    errorDisplayOrNavigate({
      error: updateAgentError,
      toastId: "view-agent",
    });

    updateAgentReset();
  }

  if (addAgentError) {
    errorDisplayOrNavigate({
      error: addAgentError,
      toastId: "add-agent",
    });

    addAgentReset();
  }

  useEffect(() => {
    if (agentDataIsSuccess) {
      const {
        email,
        firstName,
        lastName,
        parent,
        status,
        type,
        birthDate,
        civilStatus,
        middleName,
        phoneNumber,
        picture,
        reportingTo,
        sex,
      } = agentData.data;

      setValue("email", email);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("middleName", middleName ?? "");
      setValue("type", {
        label: type,
        value: replaceAllSpaceWithHyphen(type),
      });
      setValue("age", null);
      setValue("sex", {
        label: sex ?? "",
        value: sex ?? null,
      });
      setValue("civilStatus", {
        label: civilStatus ?? "",
        value: civilStatus ?? null,
      });
      setValue("dateOfBirth", new Date(birthDate as string));
      setValue("phoneNumber", phoneNumber ?? "");
      setValue("picture", {
        file: picture ?? "",
        filetype: null,
        originalName: null,
        rawFile: null,
      });
      setValue("reportingTo", {
        label: parent ? `${parent?.firstName} ${parent?.lastName}` : "",
        value: parent ? parent.id : null,
      });
    }
  }, [agentDataIsSuccess]);

  useEffect(() => {
    if (typeWatch.value === RoleTypeEnum.marketingHead) {
      setError("reportingTo", {
        type: "required",
        message: "",
      });
    }
  }, [typeWatch]);

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={handleClose} />
        <Box padding={3}>
          {initLoading ? (
            <LoaderWithText text="Getting user information" />
          ) : (
            <FormProvider {...form}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="textfieldLabel">{emailLabel}</Typography>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="email"
                        placeholder={emailLabel}
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="textfieldLabel">Type</Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="type"
                    options={salesPersonnelOpt}
                    isRequired
                    placeholder="Type"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="textfieldLabel">Reporting to</Typography>
                  <CustomReactSelectJs
                    control={control}
                    name="reportingTo"
                    options={agentListOpt}
                    isRequired={
                      typeWatch.value === RoleTypeEnum.marketingHead
                        ? false
                        : typeWatch.value === RoleTypeEnum.cashier
                        ? false
                        : true
                    }
                    placeholder="Reporting To"
                  />
                </Grid>
              </Grid>
              <Divider
                sx={(theme) => ({
                  marginY: theme.spacing(3),
                })}
              />
              <Stack
                direction={{ xs: "column", lg: "row" }}
                spacing={{ xs: 0, lg: 2 }}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <UploadPhotoField
                  setValue={setValue}
                  name="picture"
                  clearErrors={clearErrors}
                  pictureValue={pictureWatch}
                  errorMsg={errors.picture?.message}
                  isDisabled={false}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">First Name</Typography>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          placeholder={"First Name"}
                          error={errors.firstName ? true : false}
                          helperText={errors.firstName?.message}
                          sx={{
                            width: "100%",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">Last Name</Typography>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          placeholder={"Last Name"}
                          error={errors.lastName ? true : false}
                          helperText={errors.lastName?.message}
                          sx={{
                            width: "100%",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">
                      Middle Name
                    </Typography>
                    <Controller
                      name="middleName"
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          placeholder={"Middle Name"}
                          error={errors.middleName ? true : false}
                          helperText={errors.middleName?.message}
                          sx={{
                            width: "100%",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">
                      Phone Number
                    </Typography>
                    <PhoneNumberField
                      setValue={setValue}
                      name="phoneNumber"
                      clearErrors={clearErrors}
                      errorMsg={errors.phoneNumber?.message}
                      mobileValue={mobileNumWatch}
                      isDisabled={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">Age</Typography>
                    <Controller
                      name="age"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          error={errors.age ? true : false}
                          helperText={errors.age?.message}
                          sx={{
                            width: "100%",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">Sex</Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="sex"
                      options={[
                        {
                          label: "MALE",
                          value: "MALE",
                        },
                        {
                          label: "FEMALE",
                          value: "FEMALE",
                        },
                      ]}
                      isRequired={true}
                      placeholder="Department"
                      isDisabled={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">
                      Civil Status
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="civilStatus"
                      options={[
                        {
                          label: "MARRIED",
                          value: "MARRIED",
                        },
                        {
                          label: "SINGLE",
                          value: "SINGLE",
                        },
                      ]}
                      isRequired={true}
                      isDisabled={false}
                      placeholder="Department"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="textfieldLabel">
                      Date of Birth
                    </Typography>
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      disabled={false}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: errors.dateOfBirth?.message
                                  ? true
                                  : false,
                                helperText: errors.dateOfBirth?.message ?? "",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Divider
                sx={(theme) => ({
                  marginY: theme.spacing(3),
                })}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="button-primary"
                  sx={{
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={
                    saveLoading
                      ? () => {}
                      : handleSubmit(id ? onSubmitUpdate : onSubmit, onError)
                  }
                >
                  Save
                </Button>
              </Stack>
            </FormProvider>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SalesPersonelInformation;
