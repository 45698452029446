import {
  AddOrBodyModel,
  OrBookletDtoModel,
  OrDtoModel,
} from "../../../../model/OrModel";
import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const orEndpoints = {
  "or-list": (page: number, entries: number, search: string) =>
    `/or/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "add-or": "/or/add",
  "get-or": (id: number) => `/or/get/${id}`,
  "delete-or": (id: number) => `/or/delete/${id}`,
  "void-or": (id: number) => `/or/number/void/${id}`,
};

export const orSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrList: builder.query<OrDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: orEndpoints["or-list"](param.page, param.entries, param.search),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => ["or-list"],
    }),
    addOr: builder.mutation<void, AddOrBodyModel>({
      query: (body) => ({
        url: orEndpoints["add-or"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => ["or-list"],
    }),
    getOr: builder.query<OrBookletDtoModel, number>({
      query: (param) => ({
        url: orEndpoints["get-or"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [{ type: "or-get", id: arg }],
    }),
    deleteOr: builder.mutation<void, number>({
      query: (param) => ({
        url: orEndpoints["delete-or"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        "or-list",
        { type: "or-get", id: arg },
      ],
    }),
    voidOr: builder.mutation<void, number>({
      query: (param) => ({
        url: orEndpoints["void-or"](param),
        method: "PUT",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        "or-list",
        { type: "or-get", id: arg },
      ],
    }),
  }),
});

export const {
  useGetOrListQuery,
  useAddOrMutation,
  useGetOrQuery,
  useDeleteOrMutation,
  useVoidOrMutation,
} = orSlice;
