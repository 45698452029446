import React, { useEffect } from "react";
import _ from "lodash";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import {
  useAddPaymentMutation,
  useUpdatePaymentMutation,
} from "../../../service/slice/system-configuration/paymentsTypesSlice";
import CardHeader from "../../common/CardHeader";
import { thisFieldRequired } from "../../../utils/constants";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import RequiredEntries from "./RequiredEntries";
import { LoaderWithText } from "../../common/Loaders";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PaymentTypesModel } from "../../../model/paymentsTypesModel";
import useGetChartAccountsConfig from "../../../hooks/chart-accounts/useGetChartAccountsConfig";

type RequiredFieldsType = {
  id: number | null;
  value: string;
};

export interface PaymentForm {
  paymentName: string;
  chartAccount: {
    label: string;
    value: string;
  };
  subChartAccount: {
    label: string;
    value: string | number;
  };
  description: string;

  requiredFields: RequiredFieldsType[];
  disable: boolean;
}

type Props = {
  title: string;
  data: PaymentTypesModel | null;
  closeFn: () => void;
};

const PaymentsChartsForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<PaymentForm>({
    defaultValues: {
      paymentName: "",
      chartAccount: {
        label: "",
        value: "",
      },
      subChartAccount: {
        label: "",
        value: "",
      },
      description: "",
      requiredFields: [
        {
          id: null,
          value: "",
        },
      ],
      disable: false,
    },
  });

  const {
    control,
    clearErrors,
    setValue,
    reset: fieldReset,
    handleSubmit,
    setError,
  } = form;

  const [
    getSubAccountTrigger,
    { chartAccountIsLoading, chartAccountList },
    { subAccountIsLoading, subAccountList },
  ] = useGetChartAccountsConfig();
  const [
    addPaymentMutation,
    {
      isLoading: addPaymentIsLoading,
      isSuccess: addPaymentIsSuccess,
      error: addPaymentError,
      reset: addPaymentReset,
    },
  ] = useAddPaymentMutation();
  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdatePaymentMutation();

  const chartAccountWatch = useWatch({
    control,
    name: "chartAccount",
  });
  const subChartAccountWatch = useWatch({
    control,
    name: "subChartAccount",
  });

  const initLoading = chartAccountIsLoading;
  const saveLoading = addPaymentIsLoading || updateIsLoading;

  const handleChartAccountChange = (value: {
    label: string;
    value: string;
  }) => {
    const accountNumber = value.value.slice(
      value.value.indexOf("-") + 1,
      value.value.length
    );
    getSubAccountTrigger(Number(accountNumber));
    setValue("chartAccount", value);
    setValue("subChartAccount", {
      label: "",
      value: "",
    });
  };

  const validateSelectFields = (): number[] => {
    let errors: number[] = [];
    if (_.isEmpty(chartAccountWatch.value)) {
      setError("chartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }

    if (!_.isNumber(subChartAccountWatch.value)) {
      setError("subChartAccount", {
        type: "required",
        message: thisFieldRequired,
      });

      errors.push(1);
    }
    return errors;
  };

  const onError = () => {
    validateSelectFields();
  };

  const onSubmitUpdate: SubmitHandler<PaymentForm> = (dataFields) => {
    if (validateSelectFields().length > 0) {
      return;
    }
    const {
      chartAccount,
      description,
      paymentName,
      disable,
      subChartAccount,
      requiredFields,
    } = dataFields;

    updateMutations({
      queryParams: data?.id as number,
      bodyParams: {
        chartAccountId: Number(
          chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
        ),
        description,
        name: paymentName,
        status: disable ? "ENABLED" : "DISABLED",
        subChartAccountId: subChartAccount.value as number,
        fields: requiredFields.map((item) => ({ value: item.value })),
      },
    });
  };

  const onSubmit: SubmitHandler<PaymentForm> = (data) => {
    if (validateSelectFields().length > 0) {
      return;
    }
    const {
      chartAccount,
      description,
      paymentName,
      disable,
      subChartAccount,
      requiredFields,
    } = data;

    addPaymentMutation({
      chartAccountId: Number(
        chartAccount.value.slice(0, chartAccount.value.indexOf("-"))
      ),
      description,
      name: paymentName,
      status: disable ? "ENABLED" : "DISABLED",
      subChartAccountId: subChartAccount.value as number,
      fields: _.isEmpty(requiredFields[0].value)
        ? []
        : requiredFields.map((item) => ({ value: item.value })),
    });
  };

  if (addPaymentError) {
    errorDisplayOrNavigate({
      error: addPaymentError,
      toastId: "add-payment",
    });

    addPaymentReset();
  }

  if (addPaymentIsSuccess) {
    showToastSuccess({
      text: "New payment successfully added!",
      toastId: "add-payment",
    });
    addPaymentReset();
    fieldReset();
    closeFn();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Payment successfully updated!",
      toastId: "update-payment",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-payment",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        name,
        chartAccount,
        subChartAccount,
        description,
        status,
        paymentFields,
      } = data;

      setValue("paymentName", name);
      setValue("chartAccount", {
        label: `${chartAccount.accountName} - ${chartAccount.accountNumber}`,
        value: `${chartAccount.id}-${chartAccount.accountNumber}`,
      });
      setValue("subChartAccount", {
        label: subChartAccount.subAccount,
        value: subChartAccount.id,
      });
      setValue("description", description ?? "");
      setValue("disable", status === "ENABLED" ? true : false);
      setValue(
        "requiredFields",
        paymentFields.map((item) => ({
          id: item.id,
          value: item.entries,
        }))
      );
    }
  }, [data]);

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          {initLoading ? (
            <LoaderWithText text="Getting additional information" />
          ) : (
            <FormProvider {...form}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Payment Name
                      </Typography>
                      <Controller
                        name="paymentName"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: thisFieldRequired,
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            placeholder="Payment Name"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Chart of Account
                      </Typography>
                      <CustomReactSelectJs
                        control={control}
                        name="chartAccount"
                        options={chartAccountList}
                        isRequired
                        placeholder="Chart of Account"
                        // @ts-ignore
                        customOnchange={handleChartAccountChange}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Chart of Account (Sub)
                      </Typography>
                      <CustomReactSelectJs
                        control={control}
                        name="subChartAccount"
                        options={subAccountList}
                        isRequired
                        isDisabled={
                          _.isEmpty(chartAccountWatch.value) ||
                          subAccountIsLoading
                        }
                        placeholder="Chart of Account (Sub)"
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">
                        Description
                      </Typography>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            multiline
                            rows={4}
                            placeholder="Description"
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography variant="textfieldLabel">Disable</Typography>
                      <Controller
                        name="disable"
                        control={control}
                        render={({ field }) => (
                          <Switch checked={field.value} {...field} />
                        )}
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Typography variant="textfieldLabel">
                    Required Entries
                  </Typography>
                  <RequiredEntries
                    control={control}
                    clearErrors={clearErrors}
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
              <Divider
                sx={(theme) => ({
                  marginY: theme.spacing(3),
                })}
              />
              <Button
                variant="button-primary"
                sx={{
                  width: 150,
                }}
                startIcon={
                  saveLoading && (
                    <CircularProgress
                      size={20}
                      sx={(theme) => ({
                        color: theme.palette.common.white,
                      })}
                    />
                  )
                }
                onClick={
                  saveLoading
                    ? () => {}
                    : handleSubmit(data ? onSubmitUpdate : onSubmit, onError)
                }
              >
                {data ? "Update" : "Save"}
              </Button>
            </FormProvider>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default PaymentsChartsForm;
