import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { domainLink, thisFieldRequired } from "../../../utils/constants";
import { getUserCredential } from "../../../utils/helpers/storageHelper";
import UploadPhotoField from "../../common/textfields/UploadPhotoField";

import { useGetAgentListConfigQuery } from "../../../service/slice/usersSlice";
import {
  useGetLotCategoryConfigListQuery,
  useGetLotStatusConfigListQuery,
  useGetLotTypeConfigListQuery,
  useUploadLotImageMutation,
  useAddLotInformationMutation,
  useUpdateLotInformationMutation,
} from "../../../service/slice/lot-vault-crypt/lotSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";
import {
  LotInformationBodyModel,
  LotInformationModel,
  UploadLotImageDtoModel,
} from "../../../model/lotModel";
import { buyersRoute, clientManagementRoute } from "../../../routes/routeKeys";

interface InformationForm {
  owner: string;
  size: string;
  lotNumber: string;
  areaName: string;
  category: {
    label: string;
    value: number | null;
  };
  type: {
    label: string;
    value: number | null;
  };
  assignedTo: {
    label: string;
    value: number | null;
  };
  phase: string;
  block: string;
  section: string;
  amount: number | string;
  atNeed: number | string;
  status: {
    label: string;
    value: number | null;
  };
  lotImage: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
}

type Props = {
  title: string;
  data: LotInformationModel | null;
  closeFn: () => void;
};

const LotInformationForm = ({ title, data, closeFn }: Props) => {
  const navigate = useNavigate();
  const user = getUserCredential();
  const form = useForm<InformationForm>({
    defaultValues: {
      owner: "",
      atNeed: "",
      lotNumber: "",
      areaName: "",
      category: {
        label: "",
        value: null,
      },
      type: {
        label: "",
        value: null,
      },
      assignedTo: {
        label: "",
        value: null,
      },
      phase: "",
      block: "",
      section: "",
      amount: "",
      status: {
        label: "",
        value: null,
      },
      lotImage: {
        file: null,
        originalName: "",
        filetype: null,
        rawFile: null,
      },
    },
  });

  const {
    setValue,
    control,
    clearErrors,
    formState: { errors },
    handleSubmit,
    setError,
    reset: fieldReset,
  } = form;

  const {
    data: agentList,
    isLoading: agentListIsLoading,
    error: agentListError,
  } = useGetAgentListConfigQuery();

  const {
    data: categoryList,
    isLoading: categoryListIsLoading,
    error: categoryListError,
  } = useGetLotCategoryConfigListQuery();

  const {
    data: statusList,
    isLoading: statusListIsLoading,
    error: statusListError,
  } = useGetLotStatusConfigListQuery();

  const {
    data: typeList,
    isLoading: typeListIsLoading,
    error: typeListError,
  } = useGetLotTypeConfigListQuery();

  const [
    uploadLotImageMutate,
    {
      error: uploadLotImageError,
      isLoading: uploadLotImageIsLoading,
      reset: uploadtLotImageReset,
    },
  ] = useUploadLotImageMutation();

  const [
    addMutate,
    {
      isLoading: addisLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddLotInformationMutation();

  const [
    updateMutations,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateLotInformationMutation();

  const agentListOpt = agentList
    ? agentList.data
        .filter(
          (item) =>
            item.type === "AREA-DIRECTOR" || item.type === "MARKETING-HEAD"
        )
        .map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }))
    : [];

  const categoryListOpt = categoryList
    ? categoryList.data.map((item) => ({ label: item.name, value: item.id }))
    : [];

  const typeListOpt = typeList
    ? typeList.data.map((item) => ({ label: item.name, value: item.id }))
    : [];

  const statusListOpt = statusList
    ? statusList.data.map((item) => ({ label: item.name, value: item.id }))
    : [];

  const initLoading =
    agentListIsLoading ||
    categoryListIsLoading ||
    statusListIsLoading ||
    typeListIsLoading;
  const saveLoading =
    uploadLotImageIsLoading || addisLoading || updateIsLoading;

  const lotImageWatch = useWatch({
    control,
    name: "lotImage",
  });

  const catgeoryWatch = useWatch({
    control,
    name: "category",
  });

  const typeWatch = useWatch({
    control,
    name: "type",
  });

  const assignedToWatch = useWatch({
    control,
    name: "assignedTo",
  });

  const statusWatch = useWatch({
    control,
    name: "status",
  });

  const isAdminUser = (): boolean =>
    user?.role.name === "Super-Admin" || user?.role.name === "Admin"
      ? true
      : false;

  const handleOtherValidations = (): number[] => {
    const errorsArr: number[] = [];

    // if (isAdminUser() && _.isEmpty(assignedToWatch.label)) {
    //   setError("assignedTo", {
    //     type: "required",
    //     message: thisFieldRequired,
    //   });

    //   errorsArr.push(1);
    // }

    if (_.isEmpty(lotImageWatch.file)) {
      setError("lotImage", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(typeWatch.label)) {
      setError("type", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(catgeoryWatch.label)) {
      setError("category", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    if (_.isEmpty(statusWatch.label)) {
      setError("status", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    return errorsArr;
  };

  const onError = () => {
    handleOtherValidations();
  };

  const onSubmit: SubmitHandler<InformationForm> = async (dataFields) => {
    const errorsCount = handleOtherValidations();

    const newBody: LotInformationBodyModel = {
      amount: dataFields.amount as string,
      areaName: dataFields.areaName,
      assignedTo: _.isEmpty(dataFields.assignedTo.label)
        ? null
        : dataFields.assignedTo.value,
      block: dataFields.block,
      categoryId: dataFields.category.value as number,
      name: dataFields.lotNumber,
      phase: dataFields.phase,
      section: dataFields.section,
      size: dataFields.lotNumber,
      statusId: dataFields.status.value as number,
      typeId: dataFields.type.value as number,
      picture: "",
      atNeed: dataFields.atNeed as string,
    };

    if (errorsCount.length > 0) {
      return;
    }

    if (data) {
      let dataPic: null | UploadLotImageDtoModel = null;
      if (dataFields.lotImage.rawFile) {
        dataPic = await uploadLotImageMutate({
          file: dataFields.lotImage.rawFile as FileList,
        }).unwrap();
      }

      await updateMutations({
        queryParams: data.id,
        bodyParams: {
          ...newBody,
          picture: dataFields.lotImage.rawFile
            ? dataPic
              ? dataPic?.data.link
              : ""
            : (dataFields.lotImage.file?.replace(domainLink, "") as string),
        },
      });
    } else {
      const dataLotImg = await uploadLotImageMutate({
        file: dataFields.lotImage.rawFile as FileList,
      }).unwrap();

      if (dataLotImg.success) {
        await addMutate({
          ...newBody,
          picture: dataLotImg.data.link,
        });
      }
    }
  };

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-lot-information",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Lot Information successfully created!",
      toastId: "add-lot-information",
    });

    addReset();
    fieldReset();
    closeFn();
  }

  if (agentListError) {
    errorDisplayOrNavigate({
      error: agentListError,
      toastId: "agent-list-config",
    });
  }

  if (categoryListError) {
    errorDisplayOrNavigate({
      error: categoryListError,
      toastId: "lot-category-list-config",
    });
  }

  if (typeListError) {
    errorDisplayOrNavigate({
      error: typeListError,
      toastId: "lot-type-list-config",
    });
  }

  if (statusListError) {
    errorDisplayOrNavigate({
      error: statusListError,
      toastId: "lot-status-list-config",
    });
  }

  if (uploadLotImageError) {
    errorDisplayOrNavigate({
      error: uploadLotImageError,
      toastId: "upload-lot-image",
    });

    uploadtLotImageReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Lot Information successfully updated!",
      toastId: "update-lot-information",
    });

    updateReset();
    fieldReset();
    closeFn();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-lot-information",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      const {
        amount,
        agent,
        areaName,
        block,
        lotCategory,
        lotStatus,
        lotType,
        name,
        phase,
        picture,
        section,
        size,
        atNeed,
      } = data;
      setValue("amount", amount);
      setValue("atNeed", atNeed ?? "");
      setValue("areaName", areaName);
      setValue(
        "assignedTo",
        agent
          ? {
              label: `${agent.firstName} ${agent.lastName}`,
              value: agent.id,
            }
          : {
              value: null,
              label: "",
            }
      );
      setValue("block", block);
      setValue("category", {
        label: lotCategory.name,
        value: lotCategory.id,
      });
      setValue("lotImage", {
        file: picture,
        filetype: null,
        originalName: "",
        rawFile: null,
      });
      setValue("lotNumber", name);
      setValue("phase", phase ?? "");
      setValue("section", section);
      setValue("size", size);
      setValue("status", {
        label: lotStatus.name,
        value: lotStatus.id,
      });
      setValue("type", {
        label: lotType.name,
        value: lotType.id,
      });
    }
  }, [data]);

  return (
    <Box
      marginBottom={2}
      paddingY={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
      })}
    >
      <CardHeader title={title} handleCloseCard={closeFn} />
      <Box paddingX={3} paddingTop={3} paddingBottom={2}>
        {initLoading ? (
          <LoaderWithText text="Getting additional information" />
        ) : (
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Lot Number</Typography>
                    <Controller
                      name="lotNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Lot Number"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Size</Typography>
                    <Controller
                      name="size"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Size"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Area Name</Typography>
                    <Controller
                      name="areaName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Area Name"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Category</Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="category"
                      options={categoryListOpt}
                      isRequired
                      placeholder="Category"
                      isDisabled={false}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Type</Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="type"
                      options={typeListOpt}
                      isRequired
                      placeholder="Type"
                      isDisabled={false}
                    />
                  </Box>
                  {/* <Box>
                    <Typography variant="textfieldLabel">
                      Assigned To
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="assignedTo"
                      options={agentListOpt}
                      isRequired
                      placeholder="Assigned To"
                      isDisabled={!isAdminUser()}
                    />
                  </Box> */}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Phase</Typography>
                    <Controller
                      name="phase"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Phase"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Block</Typography>
                    <Controller
                      name="block"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Block"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Section</Typography>
                    <Controller
                      name="section"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Section"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                      <div>
                        <Typography variant="textfieldLabel">Amount</Typography>
                        <Controller
                          name="amount"
                          control={control}
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: thisFieldRequired,
                          //   },
                          // }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Amount"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Typography variant="textfieldLabel">
                          At Need
                        </Typography>
                        <Controller
                          name="atNeed"
                          control={control}
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: thisFieldRequired,
                          //   },
                          // }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Amount"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </Stack>
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Status</Typography>
                    <CustomReactSelectJs
                      control={control}
                      name="status"
                      options={statusListOpt}
                      isRequired
                      placeholder="Status"
                      isDisabled={false}
                    />
                  </Box>

                  {data && (
                    <Box>
                      <Typography variant="textfieldLabel" color="transparent">
                        Assign buyer
                      </Typography>
                      <Button
                        variant="button-secondary"
                        onClick={() =>
                          navigate(
                            `${clientManagementRoute}${buyersRoute}/add/buyer-information`,
                            {
                              state: data
                                ? {
                                    unitTypeId: data?.id,
                                    type: "LOT",
                                    unit: data,
                                  }
                                : null,
                            }
                          )
                        }
                      >
                        Assign Buyer
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Lot Image</Typography>
                    <UploadPhotoField
                      setValue={setValue}
                      name="lotImage"
                      clearErrors={clearErrors}
                      pictureValue={lotImageWatch}
                      errorMsg={errors.lotImage?.message}
                      changeText="Change Lot Image"
                      chooseText="Choose Lot Image"
                      width="100%"
                      height="330px"
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={saveLoading ? () => {} : handleSubmit(onSubmit, onError)}
            >
              {data ? "Update" : "Save"}
            </Button>
          </FormProvider>
        )}
      </Box>
    </Box>
  );
};

export default LotInformationForm;
