import React, { useEffect } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";

import CardHeader from "../../common/CardHeader";
import {
  pleaseEnterValidNum,
  thisFieldRequired,
} from "../../../utils/constants";
import {
  useAddAccountMutation,
  useUpdateAccountMutation,
} from "../../../service/slice/system-configuration/chartAccountsSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { AccountModel } from "../../../model/chartAccountModel";

interface AccountForm {
  id?: number;
  accountNumber: string;
  accountName: string;
  subAccount: string;
  subAccountName: string;
  type: string;
  description: string;
}

type Props = {
  title: string;
  data: null | AccountModel;
  closeFn: () => void;
};

const CreateAccountForm = ({ title, data, closeFn }: Props) => {
  const form = useForm<AccountForm>({
    defaultValues: {
      accountName: "",
      accountNumber: "",
      subAccount: "",
      subAccountName: "",
      description: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset: fieldReset,
    setValue,
    setError,
    getValues,
  } = form;

  const [
    addMutation,
    {
      isLoading: addIsLoading,
      reset: addReset,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddAccountMutation();

  const [
    updateMutation,
    {
      isLoading: updateIsLoading,
      reset: updateReset,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateAccountMutation();

  const saveLoading = addIsLoading || updateIsLoading;

  const checkAccountNumDupilcate = (main: string, sub: string): boolean =>
    _.isEqual(sub, main);

  const onError = () => {
    const values = getValues();

    if (checkAccountNumDupilcate(values.accountNumber, values.subAccount)) {
      setError("subAccount", {
        type: "validate",
        message: "Number already in use in the Account Number field",
      });
    }
  };

  const onSubmitUpdate: SubmitHandler<AccountForm> = (data) => {
    if (checkAccountNumDupilcate(data.accountNumber, data.subAccount)) {
      setError("subAccount", {
        type: "validate",
        message: "Number already in use in the Account Number field",
      });

      return;
    }

    updateMutation({
      queryParams: data.id as number,
      bodyParams: {
        accountName: data.accountName,
        accountNumber: data.accountNumber,
        description: data.description,
        subAccount: data.subAccount,
        subAccountName: data.subAccountName,
        type: data.type,
      },
    });
  };

  const onSubmit: SubmitHandler<AccountForm> = (data) => {
    if (checkAccountNumDupilcate(data.accountNumber, data.subAccount)) {
      setError("subAccount", {
        type: "validate",
        message: "Number already in use in the Account Number field",
      });

      return;
    }

    addMutation({
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      description: data.description,
      subAccount: data.subAccount,
      subAccountName: data.subAccountName,
      type: data.type,
    });
  };

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Account successfully updated!",
      toastId: "update-account",
    });

    closeFn();
    fieldReset();
    updateReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Account successfully added!",
      toastId: "add-account",
    });

    closeFn();
    fieldReset();
    addReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-account",
    });

    addReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "add-account",
    });

    updateReset();
  }

  useEffect(() => {
    if (data) {
      setValue("id", data.id);
      setValue("accountName", data.accountName);
      setValue("accountNumber", data.accountNumber);
      setValue("description", data.description);
      setValue("subAccount", data.subAccount);
      setValue("subAccountName", data.subAccountName ?? "");
      setValue("type", data.type);
    }
  }, [data]);

  return (
    <Box paddingBottom={2}>
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="textfieldLabel">Account Code</Typography>
                <Controller
                  name="accountNumber"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                    pattern: {
                      value: /^[0-9 -]+$/,
                      message: pleaseEnterValidNum,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="Account Code"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="textfieldLabel">Account Number</Typography>
                <Controller
                  name="accountName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="Account Number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="textfieldLabel">
                  Sub Account Code
                </Typography>
                <Controller
                  name="subAccount"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                    pattern: {
                      value: /^[0-9 -]+$/,
                      message: pleaseEnterValidNum,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="Sub Account Code"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="textfieldLabel">
                  Sub Account Number
                </Typography>
                <Controller
                  name="subAccountName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="Sub Account Number"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="textfieldLabel">Type</Typography>
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select a type",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <RadioGroup
                        {...field}
                        row
                        defaultValue={data?.type ?? ""}
                      >
                        <FormControlLabel
                          value="DEBIT"
                          control={<Radio />}
                          label="Debit"
                        />
                        <FormControlLabel
                          value="CREDIT"
                          control={<Radio />}
                          label="Credit"
                        />
                      </RadioGroup>
                      {fieldState.error && (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography variant="textfieldLabel">Description</Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="description"
                      rows={3}
                      multiline
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(3),
              })}
            />
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={
                saveLoading
                  ? () => {}
                  : handleSubmit(data ? onSubmitUpdate : onSubmit, onError)
              }
            >
              {data ? "Update" : "Save"}
            </Button>
          </FormProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateAccountForm;
