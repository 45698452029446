// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-event-label {
  display:  none !important;
}
.rbc-event-content {
  white-space: pre !important;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rental-and-services/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".rbc-event-label {\n  display:  none !important;\n}\n.rbc-event-content {\n  white-space: pre !important;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
