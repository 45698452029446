import React, { useState } from "react";
import _ from "lodash";
import Icon from "@mdi/react";
import { useDebounce } from "use-debounce";

import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

import { mdiCash, mdiClose, mdiHandExtendedOutline } from "@mdi/js";

import ConfirmModal from "../../common/ConfirmModal";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import { useDeletePriceListMutation } from "../../../service/slice/rental-and-services/rentalServicesSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { PriceListModel } from "../../../model/rentalServicesModel";
import { LoaderWithText } from "../../common/Loaders";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import CustomPagination from "../../common/CustomPagination";
import IconButtonMdi from "../../common/IconButtonMdi";

const headerLabels: string[] = [
  "Date Acquired",
  "Asset Name",
  "Total Quantity",
  "Total Amount",
  "Payable",
  "Receivable",
  "Status",
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  onClose: () => void;
};

const SupplierModal = ({ open, onClose }: Props) => {
  const [deleteItem, handleToggleDelete] = useDeleteTableItem<PriceListModel>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [debounceSearch] = useDebounce(searchValue, 500);
  const theme = useTheme();

  // const {
  //   data: listData,
  //   isLoading: listIsLoading,
  //   error: listError,
  //   isFetching: listIsFetching,
  // } = useGetPriceListQuery({
  //   type: serviceType.toUpperCase(),
  //   entries: entriesParam,
  //   page: pageParam - 1,
  //   search: searchParam ?? "",
  // });

  const listData: any = { data: { rows: [{}] } };
  const listIsLoading = false;
  const listError = false;
  const listIsFetching = false;

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      error: deleteError,
    },
  ] = useDeletePriceListMutation();

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data?.rows ?? [];
  const totalData = listData?.data?.count ?? 0;

  const confirmDelete = () => deleteMutate(deleteItem.data?.id as number);

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.name} successfully deleted`,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
    handleToggleDelete(false, null);
  }

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-rental-services-price-list",
    });

    deleteReset();
  }

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "rental-services-price-list",
    });
  }

  return (
    <>
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.name}?`}
        closeFn={() => handleToggleDelete(false, null)}
        confirmFn={confirmDelete}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={(theme) => ({
          "& .MuiDialog-paper": {
            width: "1100px",
            maxWidth: "90vw",
            borderRadius: 2,
          },
        })}
      >
        {loading ? (
          <Box paddingX={1} height="90vh">
            <Stack alignItems="center" justifyContent="center" height="90vh">
              <LoaderWithText text="Get Additional Details.." />
            </Stack>
          </Box>
        ) : (
          <>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">
                  <Typography component="span" marginRight={1}>
                    Supplier: ABC
                  </Typography>
                </Typography>
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "24px",
                  }}
                  onClick={onClose}
                >
                  <Icon path={mdiClose} size={1} />
                </Box>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Typography variant="textfieldLabel">Supplier Code:</Typography>
              <Typography fontWeight={600} color={"primary"} marginBottom={1} textAlign="center">
                Transactions
              </Typography>
              <Grid container alignItems="center" marginBottom={2}>
                <Grid item xs={12} lg={8}>
                  <TableSearchField
                    searchValue={debounceSearch ?? ""}
                    pathname={``}
                    querySearch={(value: string) => ""}
                    handleSearchSetState={(e) => setSearchValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <RowsPerPageComp
                    entries={entries}
                    pathname=""
                    querySearch={(entry) => ""}
                    changeEntry={(e) => setEntries(Number(e.target.value))}
                  />
                </Grid>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headerLabels.map((item, key) => (
                        <TableCell key={key} variant="head">
                          {item}
                        </TableCell>
                      ))}
                      <TableCell
                        variant="head"
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress
                            color="primary"
                            style={{ margin: "20px 0" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : list && list.length > 0 ? (
                      list.map((item: any, key: any) => (
                        <TableRow
                          key={key}
                          sx={{
                            "&:nth-of-type(even)": {
                              backgroundColor: "grey.100",

                              td: {
                                backgroundColor: "grey.100",
                              },
                            },
                            "&:nth-of-type(odd) td": {
                              backgroundColor: "common.white",
                            },
                          }}
                        >
                          <TableCell>{item.regular}</TableCell>
                          <TableCell>{item.regular}</TableCell>
                          <TableCell>{item.holiday}</TableCell>
                          <TableCell>{item.holiday}</TableCell>
                          <TableCell>{item.holiday}</TableCell>
                          <TableCell>{item.holiday}</TableCell>
                          <TableCell>{item.holiday}</TableCell>
                          <TableCell
                            sx={{
                              position: "sticky",
                              right: 0,
                              zIndex: 800,
                              width: "auto",
                            }}
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <IconButtonMdi
                                mdiIcon={mdiHandExtendedOutline}
                                iconColor={theme.palette.primary.main}
                                tooltipTitle="Receive"
                              />
                              <IconButtonMdi
                                mdiIcon={mdiCash}
                                iconColor={theme.palette.info.main}
                                tooltipTitle="Pay"
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          NO DATA FOUND
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomPagination
                pathname=""
                querySearch={(page) => ""}
                totalCount={totalData ?? 0}
                currentPage={page}
                pageSize={entries}
                nextPageSetState={() => setPage((state) => state + 1)}
                prevPageSetState={() => setPage((state) => state - 1)}
                selectPageSetState={(page) => setPage(page)}
              />
            </DialogContent>
            <DialogActions>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
                paddingX={2}
                paddingY={1}
                justifyContent="center"
              >
                <Button
                  variant="button-secondary"
                  onClick={onClose}
                  sx={{ width: 150 }}
                >
                  Close
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default SupplierModal;
