import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
  Controller,
  useFieldArray,
  useWatch,
  ArrayPath,
  PathValue,
  Path,
} from "react-hook-form";
import IconButtonMdi from "../../common/IconButtonMdi";
import { thisFieldRequired } from "../../../utils/constants";
import { ProvisionalForm } from "./CreateProvisional";
import { GetProvisionalReceiptModel } from "../../../model/provisionalReceiptModel";

type Props = {
  control: Control<ProvisionalForm>;
  clearErrors: UseFormClearErrors<ProvisionalForm>;
  setValue: UseFormSetValue<ProvisionalForm>;
  errors: FieldErrors<ProvisionalForm>;
  details?: GetProvisionalReceiptModel;
};

const ProvisionalParticulars = ({ control, details, setValue }: Props) => {
  const theme = useTheme();
  const { fields, append, remove, replace, insert } = useFieldArray({
    control,
    name: "particulars",
  });
  const fieldsWatch = useWatch({
    name: "particulars",
    control,
  });

  const handleAddRemoveContactPerson = (index: number | null) => {
    if (typeof index === "number") {
      remove(index);
    } else {
      append({
        particular: "",
        amount: "",
      });
    }
  };

  useEffect(() => {
    if (details) {
      const { particulars } = details;

      replace(
        particulars.map((item) => ({
          amount: item.amount,
          particular: item.particular,
          particularId: item.id,
        }))
      );
    }
  }, [details]);

  return (
    <>
      {fields.map((item, key) => (
        <React.Fragment key={key}>
          <Grid item xs={7} md={5}>
            <Stack
              direction="row"
              spacing={2}
              alignItems={"flex-start"}
              width="100%"
            >
              <Box paddingTop={key === 0 ? "28px" : 0}>
                <IconButtonMdi
                  mdiIcon={
                    key + 1 === fieldsWatch.length
                      ? mdiPlusCircleOutline
                      : mdiMinusCircleOutline
                  }
                  iconColor={
                    key + 1 === fieldsWatch.length
                      ? theme.palette.info.main
                      : theme.palette.error.main
                  }
                  onClick={() =>
                    handleAddRemoveContactPerson(
                      key + 1 === fieldsWatch.length ? null : key
                    )
                  }
                />
              </Box>
              <Box width={"100%"}>
                {key === 0 && (
                  <Typography variant="textfieldLabel">Particular</Typography>
                )}
                {/* <Box
                sx={{
                  "& .ql-toolbar": {
                    borderRadius: "8px 8px 0 0",
                    backgroundColor: theme.palette.grey[100],
                  },
                  "& .ql-container": {
                    borderRadius: "0 0 8px 8px",
                  },
                }}
              >
                <ReactQuill
                  formats={["list", "bullet", "indent"]}
                  modules={{
                    toolbar: [
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                    ],
                  }}
                  theme="snow"
                  value={fieldsWatch[key]?.particular ?? ""}
                  onChange={(html: string) => handleChangeEvent(html, key)}
                />
                {errors.particulars &&
                  errors.particulars[key]?.particular?.message && (
                    <FormHelperText error>{thisFieldRequired}</FormHelperText>
                  )}
              </Box> */}
                <Controller
                  name={`particulars.${key}.particular`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: thisFieldRequired,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      value={fieldsWatch[key]?.particular ?? ""}
                      placeholder="Particular"
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message ?? ""}
                      sx={{ width: "100%" }}
                      multiline
                      maxRows={4}
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={5} md={3}>
            {key === 0 && (
              <Typography variant="textfieldLabel">Amount</Typography>
            )}
            <Controller
              name={`particulars.${key}.amount`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: thisFieldRequired,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={fieldsWatch[key]?.amount ?? ""}
                  placeholder="Amount"
                  error={fieldState.error ? true : false}
                  helperText={fieldState.error?.message ?? ""}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
        </React.Fragment>
      ))}
      {fieldsWatch.length > 1 && (
        <>
          <Grid item xs={7} md={5} />
          <Grid item xs={5} md={3}>
            <Typography variant="textfieldLabel">
              Total Particular Amount
            </Typography>
            <TextField
              value={fieldsWatch.reduce(
                (accumulator, currentValue) =>
                  accumulator + Number(currentValue.amount),
                0
              )}
              sx={{ width: "100%" }}
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default ProvisionalParticulars;
