import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";
import {
  LeadBodyModel,
  LeadDtoModel,
  LeadInformationDtoModel,
  LeadSendEmailBodyModel,
} from "../../../../model/leadModel";
import { result } from "lodash";
import { error } from "console";

export const leadEndpoints = {
  "add-lead": "/buyer/lead/add",
  "lead-list": (page: number, entries: number, search: string) =>
    `/buyer/lead/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "lead-information": (id: number) => `/buyer/lead/get/${id}`,
  "update-lead": (id: number) => `/buyer/lead/update/${id}`,
  "delete-lead": (id: number) => `/buyer/lead/delete/${id}`,
  "lead-send-email": "/buyer/lead/send-email",
};

export const leadSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeadList: builder.query<LeadDtoModel, ListFetchQueryType>({
      query: (param) => ({
        url: leadEndpoints["lead-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: ["lead-list"],
    }),
    getLeadInformation: builder.query<LeadInformationDtoModel, number>({
      query: (param) => ({
        url: leadEndpoints["lead-information"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (res, err, arg) => [{ type: "lead-information", id: arg }],
    }),
    addLead: builder.mutation<void, LeadBodyModel>({
      query: (body) => ({
        url: leadEndpoints["add-lead"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lead-list"],
    }),
    updateLead: builder.mutation<
      void,
      {
        queryParam: number;
        bodyParam: LeadBodyModel;
      }
    >({
      query: (param) => ({
        url: leadEndpoints["update-lead"](param.queryParam),
        method: "PUT",
        body: param.bodyParam,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (res, error, arg) => [
        { type: "lead-information", id: arg.queryParam },
        "lead-list",
      ],
    }),
    deleteLead: builder.mutation<void, number>({
      query: (param) => ({
        url: leadEndpoints["delete-lead"](param),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: ["lead-list"],
    }),
    sendEmailLead: builder.mutation<void, LeadSendEmailBodyModel>({
      query: (body) => ({
        url: leadEndpoints["lead-send-email"],
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useAddLeadMutation,
  useGetLeadListQuery,
  useGetLeadInformationQuery,
  useUpdateLeadMutation,
  useDeleteLeadMutation,
  useSendEmailLeadMutation,
  useLazyGetLeadInformationQuery,
} = leadSlice;
