import React, { useEffect, useRef, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { thisFieldRequired } from "../../../utils/constants";
import {
  replaceAllSpaceWithHyphen,
  replaceAllHyphenWithSpace,
} from "../../../utils/helpers/stringManipulate";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import { useGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import { useGetExpenseListConfigQuery } from "../../../service/slice/system-configuration/expensesTypesSlice";
import {
  useAddAccManagExpenseMutation,
  useGetAccManagExpenseQuery,
  useUpateAccManagExpenseMutation,
  useLazyGetAccManagExpenseQuery,
} from "../../../service/slice/account-management/expense/ExpensesSlice";
import { LoaderWithText } from "../../common/Loaders";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import { endpoints } from "../../../service/slice/account-management/expense/ExpensesSlice";
import { PaymentFieldsBody } from "../../../model/buyerModel";
import { ExpenseModel } from "../../../model/expensesModel";
import { skipToken } from "@reduxjs/toolkit/query";
import ExpenseFileList from "./ExpenseFileList";
import ExpenseUpload from "./ExpenseUpload";

export interface DocType {
  path: string;
  fileName: string;
  description: string;
}

interface ExpenseForm {
  expenseType: {
    label: string;
    value: null | number;
  };
  expenseDescription: string;
  datePurchase: Date | null;
  paymentType: {
    label: string;
    value: number | null;
  };
  paymentFields: {
    [name: string]: string | Date | null | undefined;
  }[];
  mainChart: {
    label: string;
    value: null | number;
  };
  subChart: {
    label: string;
    value: null | number;
  };
  document: {
    id: number | null;
    path: string;
    format: string;
    name: string;
  }[];
  filename: string;
  description: string;
}

type Props = {
  title: string;
  data: ExpenseModel | null;
  closeFn: () => void;
};

const CreateExpense = ({ title, data, closeFn }: Props) => {
  const paymentFieldRef = useRef<string[]>([]);
  const paymentFieldIdRef = useRef<number[]>([]);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(data?.id ?? null);
  const form = useForm<ExpenseForm>({
    defaultValues: {
      expenseDescription: "",
      expenseType: {
        label: "",
        value: null,
      },
      datePurchase: null,
      paymentType: {
        label: "",
        value: null,
      },
      paymentFields: [],
      mainChart: {
        label: "",
        value: null,
      },
      subChart: {
        label: "",
        value: null,
      },
      document: [
        {
          id: null,
          path: "",
          name: "",
          format: "",
        },
      ],
      filename: "",
      description: "",
    },
  });

  const [
    addMutate,
    {
      data: addData,
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddAccManagExpenseMutation();

  const [
    updateMutate,
    {
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      error: updateError,
      reset: updateReset,
    },
  ] = useUpateAccManagExpenseMutation();

  const {
    data: paymentListData,
    isLoading: paymentListIsLoading,
    error: paymentListError,
  } = useGetPaymentsListConfigQuery();

  const {
    data: expenseTypeData,
    isLoading: expenseTypeIsLoading,
    error: expenseTypeError,
  } = useGetExpenseListConfigQuery();

  const {
    data: getData,
    isLoading: getIsLoading,
    isFetching: getIsFetching,
    error: getError,
  } = useGetAccManagExpenseQuery(id ?? skipToken);

  const loading =
    paymentListIsLoading ||
    expenseTypeIsLoading ||
    getIsLoading ||
    getIsFetching;
  const saveLoading = addIsLoading || updateIsLoading;

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const expenseTypeOptions = useMemo(() => {
    return (
      expenseTypeData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      }) ?? []
    );
  }, [expenseTypeData?.data]);

  const {
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    setError,
    handleSubmit,
  } = form;

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const documentWatch = useWatch({ name: "document", control });

  const { fields: paymentFields, replace: paymentFieldsReplace } =
    useFieldArray({
      control,
      name: "paymentFields",
    });

  const handlePaymentTypeOnChange = (value: {
    label: string;
    value: number | null;
  }) => {
    const selectedPayType = paymentListData?.data?.find(
      (item) => item.id === Number(value.value)
    );

    if (selectedPayType) {
      paymentFieldsReplace(
        selectedPayType.paymentFields.map(
          (item): { [name: string]: string } => ({
            [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
          })
        )
      );
      paymentFieldRef.current = selectedPayType.paymentFields.map(
        (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
      );
      paymentFieldIdRef.current = selectedPayType.paymentFields.map(
        (item) => item.id
      );

      setValue("paymentType", value);

      setError("paymentType", {
        type: "required",
        message: "",
      });
    }
  };

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (dataFields.paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    if (dataFields.expenseType.value === null) {
      setError("expenseType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const toggleUploadDoc = () => setOpenUpload((prev) => !prev);

  const onError = () => checkErrors();

  const onSubmitSave: SubmitHandler<ExpenseForm> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const {
      paymentFields,
      paymentType,
      datePurchase,
      expenseType,
      expenseDescription,
    } = dataFields;

    let finalPaymentAmount: string | number = 0;

    paymentFields.forEach((item, key) => {
      const itemKeyName = paymentFieldRef.current[key];

      if (
        itemKeyName.toLowerCase().includes("cash") ||
        itemKeyName.toLowerCase().includes("amount")
      ) {
        finalPaymentAmount = item[itemKeyName] as string;
      }
    });

    const finalPaymentFields: PaymentFieldsBody[] = paymentFields.map(
      (item, key) => {
        const itemId = paymentFieldIdRef.current[key];
        const itemKeyName = paymentFieldRef.current[key];
        return {
          fieldId: itemId,
          value: itemKeyName.toLowerCase().includes("date")
            ? (moment(item[itemKeyName]).format("YYYY-MM-DD") as string)
            : (item[itemKeyName] as string),
        };
      }
    );

    if (id) {
      updateMutate({
        id: id,
        body: {
          expenseId: Number(expenseType.value),
          files: [],
          description: expenseDescription,
          purchaseDate: moment(datePurchase).format("YYYY-MM-DD"),
          payment: {
            paymentId: Number(paymentType.value),
            amount: finalPaymentAmount,
            paymentFields: finalPaymentFields,
          },
        },
      });
    } else {
      addMutate({
        expenseId: Number(expenseType.value),
        files: [],
        description: expenseDescription,
        purchaseDate: moment(datePurchase).format("YYYY-MM-DD"),
        payment: {
          paymentId: Number(paymentType.value),
          amount: finalPaymentAmount,
          paymentFields: finalPaymentFields,
        },
      });
    }
  };

  if (getError) {
    errorDisplayOrNavigate({
      error: getError,
      toastId: "get-item",
    });
  }

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list",
    });
  }

  if (expenseTypeError) {
    errorDisplayOrNavigate({
      error: expenseTypeError,
      toastId: "expense-type",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-expense",
    });

    addReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-expense",
    });

    updateReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: `${data?.prNumber} successfully update!`,
      toastId: "update-expense",
    });

    updateReset();
    closeFn();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New Expense succesfully added!",
      toastId: "add-expense",
    });

    setId(addData?.data?.id ?? null);

    addReset();
    // closeFn();
  }

  useEffect(() => {
    if (getData?.data.id) {
      const { expense, payment, purchaseDate, description } = getData.data;
      const { fields, paymentType } = payment;

      const selectedPayType = paymentListData?.data.find(
        (item) => item.id === paymentType.id
      );

      setValue("paymentType", {
        label: selectedPayType?.name ?? "",
        value: selectedPayType?.id ?? null,
      });

      setValue("expenseType", {
        label: expense.name,
        value: expense.id,
      });

      setValue("datePurchase", new Date(purchaseDate));
      setValue("expenseDescription", description);

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map((item) => {
            const paymentValue = fields.find(
              (itemField) => itemField.field.id === item.id
            );

            const realValue = paymentValue?.field.entries
              .toLowerCase()
              .includes("date")
              ? new Date(paymentValue.value)
              : paymentValue?.value;

            return {
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: realValue,
            };
          })
        );

        paymentFieldRef.current = selectedPayType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = selectedPayType.paymentFields.map(
          (item) => item.id
        );
      }
    }
  }, [getData, paymentListData]);

  return (
    <Box paddingBottom={2}>
      {openUpload && (
        <ExpenseUpload
          handleToggleUpload={toggleUploadDoc}
          id={getData?.data.id as number}
          open={openUpload}
          title={`Upload Documents (${getData?.data.expense.name})`}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          {loading ? (
            <Box paddingX={2}>
              <Stack alignItems="center" justifyContent="center">
                <LoaderWithText text="Get Additional Details.." />
              </Stack>
            </Box>
          ) : (
            <>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="textfieldLabel">
                      Expense Type
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={expenseTypeOptions}
                      name="expenseType"
                      placeholder="Expense Type"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="textfieldLabel">
                      Date of Purchase
                    </Typography>
                    <Controller
                      name="datePurchase"
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: fieldState.error?.message ? true : false,
                                helperText: fieldState.error?.message,
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="textfieldLabel">
                      Expense Description
                    </Typography>
                    <Controller
                      name="expenseDescription"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder={"Expense Description"}
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                          multiline
                          rows={4}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2} width="100%">
                      <div>
                        <Typography variant="textfieldLabel">
                          Payment Type
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={paymentTypeOptions}
                          name="paymentType"
                          placeholder="Payment Type"
                          // @ts-ignore
                          customOnchange={handlePaymentTypeOnChange}
                        />
                      </div>

                      {!_.isNull(paymentTypeWatch.value) && (
                        <>
                          {paymentFields.map((item, key) => {
                            const itemKeyName = Object.keys(item)[0];

                            const itemLabelName =
                              replaceAllHyphenWithSpace(itemKeyName);

                            return (
                              <div key={key}>
                                <Typography variant="textfieldLabel">
                                  {itemLabelName}
                                </Typography>
                                <Controller
                                  name={`paymentFields.${key}.${itemKeyName}`}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => {
                                    if (
                                      itemKeyName.toLowerCase().includes("date")
                                    ) {
                                      return (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DatePicker
                                            {...field}
                                            slotProps={{
                                              textField: {
                                                variant: "outlined",
                                                error: fieldState.error?.message
                                                  ? true
                                                  : false,
                                                helperText:
                                                  fieldState.error?.message,
                                                fullWidth: true,
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      );
                                    }
                                    return (
                                      <TextField
                                        {...field}
                                        placeholder={itemLabelName}
                                        error={fieldState.error ? true : false}
                                        helperText={fieldState.error?.message}
                                        sx={{
                                          width: "100%",
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      marginTop={2}
                    >
                      <Typography variant="textfieldLabel">
                        Expense Documents
                      </Typography>
                      <Button
                        variant="button-secondary"
                        disabled={getData?.data.id ? false : true}
                        sx={{
                          width: 150,
                        }}
                        startIcon={
                          saveLoading && (
                            <CircularProgress
                              size={20}
                              sx={(theme) => ({
                                color: theme.palette.common.white,
                              })}
                            />
                          )
                        }
                        onClick={toggleUploadDoc}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider
                  sx={(theme) => ({
                    marginY: theme.spacing(3),
                  })}
                />
                <Button
                  variant="button-primary"
                  sx={{
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={
                    saveLoading ? () => {} : handleSubmit(onSubmitSave, onError)
                  }
                >
                  {id ? "Update" : "Save"}
                </Button>
              </FormProvider>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateExpense;
