import React, { useState } from "react";
import { mdiDeleteOutline, mdiCardSearchOutline } from "@mdi/js";
import { useDebounce } from "use-debounce";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TableSearchField from "../../common/textfields/TableSearchField";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import CustomPagination from "../../common/CustomPagination";
import IconButtonMdi from "../../common/IconButtonMdi";
import { PaymentDocModel } from "../../../model/buyerModel";
import useFileView from "../../../hooks/useFileView";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import { useDeletePaymentAccountManagDocMutation } from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import FileViewModal from "../../common/FileViewModal";
import ConfirmModal from "../../common/ConfirmModal";

type Props = {
  docs: PaymentDocModel[];
  loading: boolean;
  count: number;
  type: "unit" | "service";
};

const PaymentModalUploadList = ({ docs, loading, count, type }: Props) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>("");
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [viewDoc, handleToggleViewDoc] = useFileView();
  const [deleteItem, handelToggleDelete] =
    useDeleteTableItem<PaymentDocModel>();

  const [debounceSearch] = useDebounce(searchValue, 500);

  const listData = docs ?? [];

  const [
    deleteMutate,
    {
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      error: deleteError,
      reset: deleteReset,
    },
  ] = useDeletePaymentAccountManagDocMutation();

  if (deleteError) {
    errorDisplayOrNavigate({
      error: deleteError,
      toastId: "delete-doc",
    });

    deleteReset();
  }

  if (deleteIsSuccess) {
    showToastSuccess({
      text: `${deleteItem.data?.fileName} successfully deleted!`,
      toastId: "delete",
    });

    deleteReset();
    handelToggleDelete(false, null);
  }

  return (
    <>
      <FileViewModal
        open={viewDoc.open}
        path={viewDoc.path}
        title={
          viewDoc?.filename
            ? viewDoc.filename
            : viewDoc.path.substring(
                viewDoc.path.lastIndexOf("/") + 1,
                viewDoc.path.length
              )
        }
        onClose={() => handleToggleViewDoc(false, "", "")}
      />
      <ConfirmModal
        isLoading={deleteIsLoading}
        open={deleteItem.open}
        text={`Are you sure to delete ${deleteItem.data?.fileName}?`}
        closeFn={() => handelToggleDelete(false, null)}
        confirmFn={() =>
          deleteMutate({
            buyerPaymentId: deleteItem.data?.buyerPaymentId as number,
            id: deleteItem.data?.id as number,
            type,
          })
        }
      />
      <Grid container marginBottom={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          <TableSearchField
            searchValue={debounceSearch ?? ""}
            pathname={``}
            querySearch={(value: string) => ""}
            handleSearchSetState={(e) => setSearchValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entries}
            pathname=""
            querySearch={(entry) => ""}
            changeEntry={(e) => setEntries(Number(e.target.value))}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Filename
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  color: "common.white",
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                  width: "auto",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : listData && listData.length > 0 ? (
              listData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.fileName}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButtonMdi
                        mdiIcon={mdiCardSearchOutline}
                        iconColor={theme.palette.primary.main}
                        tooltipTitle="View"
                        onClick={() =>
                          handleToggleViewDoc(true, item.path, item.fileName)
                        }
                      />
                      <IconButtonMdi
                        mdiIcon={mdiDeleteOutline}
                        iconColor={theme.palette.error.main}
                        tooltipTitle="Delete"
                        onClick={() => handelToggleDelete(true, item)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname=""
        querySearch={(page) => ""}
        totalCount={count ?? 0}
        currentPage={page}
        pageSize={entries}
        nextPageSetState={() => setPage((state) => state + 1)}
        prevPageSetState={() => setPage((state) => state - 1)}
        selectPageSetState={(page) => setPage(page)}
      />
    </>
  );
};

export default PaymentModalUploadList;
